import Keycloak from 'keycloak-js'
// 'auth-server-url': 'http://localhost:8080/',

const keycloak = new (Keycloak as any)({
  url: process.env.REACT_APP_KEYCLOACK_SERVER_URL,
  realm: process.env.REACT_APP_KEYCLOACK_REALM,
  clientId: process.env.REACT_APP_KEYCLOACK_CLIENT_ID,
})

export default keycloak
