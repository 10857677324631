export const mappings = {
  mapping1: {
    sizes: [
      [[0, 0], []],
      [
        [1000, 0],
        [
          [300, 250],
          [336, 280],
          [300, 600],
        ],
      ],
    ],
  },
  mapping2: {
    sizes: [
      [
        [0, 0],
        [
          [300, 250],
          [336, 280],
          [300, 600],
        ],
      ],
      [[1000, 0], []],
    ],
  },
  mapping3: {
    sizes: [
      [[0, 0], []],
      [[750, 0], [[728, 90]]],
      [
        [1000, 0],
        [
          [970, 90],
          [970, 250],
          [728, 90],
        ],
      ],
    ],
  },
}

const SIZE_1 = [
  [300, 250],
  [300, 600],
  [336, 280],
]

const SIZE_2 = [
  [336, 280],
  [300, 250],
  [300, 600],
]

const SIZE_3 = [
  [300, 250],
  [300, 600],
]
const SIZE_4 = [
  [970, 90],
  [970, 250],
  [728, 90],
]
export const ads = {
  'div-gpt-ad-3816174-1': {
    slot: '/22358564537/Listing_Rectangle_1',
    sizes: SIZE_1,
    mapping: 'mapping1',
    collapse: true,
  },
  'div-gpt-ad-3816174-2': {
    slot: '/22358564537/Listing_Rectangle_2',
    sizes: SIZE_1,
    mapping: 'mapping1',
    collapse: true,
  },
  'div-gpt-ad-3816174-3': {
    slot: '/22358564537/Listing_Rectangle_3',
    sizes: [[300, 250]],
    mapping: 'mapping1',
    collapse: true,
  },
  'div-gpt-ad-3816174-4': {
    slot: '/22358564537/Listing_Rectangle_4',
    sizes: SIZE_2,
    mapping: 'mapping1',
    collapse: true,
  },
  'div-gpt-ad-3816174-5': {
    slot: '/22358564537/Listing_Rectangle_5',
    sizes: SIZE_2,
    mapping: 'mapping1',
    collapse: true,
  },
  'div-gpt-ad-3816174-6': {
    slot: '/22358564537/Listing_Rectangle_6',
    sizes: SIZE_3,
    mapping: 'mapping1',
    collapse: true,
  },
  'div-gpt-ad-3816174-7': {
    slot: '/22358564537/Mobile_1',
    sizes: SIZE_3,
    mapping: 'mapping2',
    collapse: true,
  },
  'div-gpt-ad-3816174-8': {
    slot: '/22358564537/Mobile_2',
    sizes: SIZE_3,
    mapping: 'mapping2',
    collapse: true,
  },
  'div-gpt-ad-3816174-9': {
    slot: '/22358564537/Mobile_3',
    sizes: SIZE_3,
    mapping: 'mapping2',
    collapse: true,
  },
  'div-gpt-ad-3816174-10': {
    slot: '/22358564537/Mobile_4',
    sizes: SIZE_3,
    mapping: 'mapping2',
    collapse: true,
  },
  'div-gpt-ad-3816174-11': {
    slot: '/22358564537/Mobile_5',
    sizes: SIZE_2,
    mapping: 'mapping2',
    collapse: true,
  },
  'div-gpt-ad-3816174-12': {
    slot: '/22358564537/Mobile_6',
    sizes: SIZE_2,
    mapping: 'mapping2',
    collapse: true,
  },
  'div-gpt-ad-3816174-13': {
    slot: '/22358564537/Billboard_1',
    sizes: SIZE_4,
    mapping: 'mapping3',
    collapse: true,
  },
  'div-gpt-ad-3816174-14': {
    slot: '/22358564537/Billboard_2',
    sizes: SIZE_4,
    mapping: 'mapping3',
    collapse: true,
  },
  'div-gpt-ad-3816174-15': {
    slot: '/22358564537/Billboard_3',
    sizes: SIZE_4,
    mapping: 'mapping3',
    collapse: true,
  },
  'div-gpt-ad-3816174-16': {
    slot: '/22358564537/Billboard_4',
    sizes: SIZE_4,
    mapping: 'mapping3',
    collapse: true,
  },
  'div-gpt-ad-3816174-17': {
    slot: '/22358564537/Billboard_5',
    sizes: SIZE_4,
    mapping: 'mapping3',
    collapse: true,
  },
  'div-gpt-ad-3816174-18': {
    slot: '/22358564537/Detail_page',
    sizes: [
      [300, 250],
      [336, 280],
    ],
    mapping: 'mapping3',
    collapse: true,
  },
}
