import React from 'react'
import { useTheme, styled } from '@mui/material/styles'
import {
  Box,
  Typography,
  TypographyProps,
  Stack,
  useMediaQuery,
  Grid,
  Divider,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

// import IconGroup from './AdCard/IconGroup'
import IconGroup from './IconGroup'
import Share from './Share'
import AddtoFavorite from './AddtoFavorite'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import { getPropertyType, parseArea } from './helpers'

import useIsOverflow from 'hooks/useIsOverflow'
import { useLocation } from 'react-router-dom'
import NumberFormattingService from 'api/services/NumberFormatting'
import { ViewType } from './types'

type Props = TypographyProps
type Ref = HTMLElement

const CustomTypography = React.forwardRef<Ref, Props>(function CustomTypography(
  props,
  ref
) {
  return <Typography component={'span'} display="block" ref={ref} {...props} />
})

const BlackTypography = styled(CustomTypography)<TypographyProps>(
  ({ theme }) => ({
    fontWeight: 900,
    fontSize: 40,
    [theme.breakpoints.down('lg')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
  })
)

interface DetailContentProps {
  propertyCategory: string
  propertyType: string
  onOpen?: () => void
  data: ParsedProperty
}

function DetailContent(props: DetailContentProps) {
  const { t } = useTranslation()
  const location = useLocation()

  const descriptionRef = React.useRef<HTMLElement>()

  const { onOpen, data, propertyCategory, propertyType } = props

  const [expand, setExpand] = React.useState(false)

  React.useEffect(() => {
    setExpand(false)
  }, [location])

  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const getInterestType = (adCategoryId: number) => {
    const BUY = [1, 2, 3]
    const LOAN = [4, 5, 6]
    const IS_BUY = BUY.includes(adCategoryId)
    if (IS_BUY) return t('detailPage.interest.buy')
    else if (LOAN) return t('detailPage.interest.loan')
  }

  const { isOverflow } = useIsOverflow(descriptionRef, [location])

  const IS_OVER_10 =
    !!data?.squareMeterSalePrice && data?.squareMeterSalePrice > 10

  const IS_LOWER_10 =
    !!data?.squareMeterSalePrice && data?.squareMeterSalePrice < 10

  const LOWER_10_OR_NULL = IS_LOWER_10
    ? NumberFormattingService.toRoundNumber(
        Number(data?.squareMeterSalePrice),
        1
      )
    : '-'

  const PRICE_PER_SQUARE = IS_OVER_10
    ? NumberFormattingService.toRoundNumber(
        Math.trunc(Number(data?.squareMeterSalePrice)),
        0
      )
    : LOWER_10_OR_NULL

  return (
    <Stack spacing={1}>
      <div>
        <Typography
          component="span"
          display="inline-block"
          sx={{ fontSize: { xs: 20, sm: 30, md: 35, lg: 40 } }}
          fontWeight={900}
        >
          {data ? t(getPropertyType(data)?.label ?? '', '') : ''}{' '}
          <Trans
            i18nKey="detailPage.squareMeters"
            values={{
              value: data?.squareMeters
                ? NumberFormattingService.toRoundNumber(data?.squareMeters)
                : '-',
            }}
          />
        </Typography>
        {` `}
        <Typography
          display="inline-block"
          sx={{ fontSize: { xs: 15, sm: 30, md: 25, lg: 30 } }}
        >
          {data?.searchArea ? parseArea(data?.searchArea) : null}
        </Typography>
      </div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          borderBottom: `1px solid black`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Box>
          {data?.salesPrice ? (
            <BlackTypography display="inline-block">
              <Typography
                component="span"
                sx={{
                  fontSize: { xs: 15, sm: 20, md: 25, lg: 30 },
                  fontWeight: 900,
                }}
              >
                {t('searchPage.salesPrice', {
                  price: NumberFormattingService.toRoundNumber(
                    data?.salesPrice
                  ),
                })}
                ,
              </Typography>
            </BlackTypography>
          ) : null}
          {` `}
          {data?.adCategoryId ? (
            <Typography
              display="inline-block"
              sx={{ fontSize: { xs: 15, sm: 20, md: 25, lg: 30 } }}
            >
              {getInterestType(data?.adCategoryId)}
            </Typography>
          ) : null}
        </Box>
        <Typography
          component="span"
          display="inline-flex"
          sx={{ fontSize: { xs: 15, sm: 20, md: 25, lg: 25 } }}
        >
          <Typography
            component="span"
            sx={{
              fontSize: { xs: 15, sm: 20, md: 25, lg: 25 },
            }}
          >
            {t('searchPage.pricePerSquare', {
              value: PRICE_PER_SQUARE,
            })}
            {<sup>2</sup>}
          </Typography>
        </Typography>
      </Stack>
      <IconGroup
        data={data}
        view={ViewType.Detail}
        propertyCategory={propertyCategory}
        propertyType={propertyType}
      />
      {matchesSM ? (
        <>
          <Divider sx={{ backgroundColor: 'black' }} />
          <Grid container justifyContent={'space-evenly'} spacing={1}>
            <Grid item>
              <Share onOpen={onOpen} mode="icon" />
            </Grid>
            {data ? (
              <Grid item>
                <AddtoFavorite mode="icon" propertyAd={data} />
              </Grid>
            ) : null}
          </Grid>
        </>
      ) : null}
      <Stack>
        <Box
          ref={descriptionRef}
          display="inline-flex"
          sx={{
            textAlign: 'left',
            height: expand ? '100%' : 100,
            overflow: expand ? 'visible' : 'hidden',
            whiteSpace: 'pre-line',
          }}
        >
          {data?.description}
        </Box>
        {isOverflow || expand ? (
          <Stack
            direction="row"
            sx={{
              cursor: 'pointer',
            }}
            alignItems="center"
            spacing={1}
            onClick={() => setExpand((prev) => !prev)}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: 'flex',
                  md: 'flex',
                },
                borderBottom: (theme) =>
                  `2px solid ${theme.palette.text.secondary}`,
              }}
            />
            <Typography
              align="center"
              color="textSecondary"
              sx={{ fontWeight: 600 }}
            >
              {t('detailPage.expand.label')}{' '}
              {expand
                ? t('detailPage.expand.less')
                : t('detailPage.expand.more')}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: 'flex',
                },
                borderBottom: (theme) =>
                  `2px solid ${theme.palette.text.secondary}`,
              }}
            />
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  )
}

export default DetailContent
