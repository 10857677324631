import React from 'react'
import { Stack, Typography, ButtonBase, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ShareSvg from 'assets/svg/icons/share.svg'
export interface ShareProps {
  onOpen?: () => void
  mode?: string
}

function Share(props: ShareProps) {
  const { t } = useTranslation()
  const { onOpen, mode } = props

  const IS_ICON_MODE = mode === 'icon'

  return (
    <Stack
      component={ButtonBase}
      direction={IS_ICON_MODE ? 'column' : 'row'}
      alignItems="center"
      justifyContent="center"
      sx={{
        margin: '0 auto',
        bgcolor: 'common.white',
        border: IS_ICON_MODE ? 'none' : `1px solid lightgrey`,
        boxShadow: IS_ICON_MODE ? 'none' : 25,
        borderRadius: 1,
        py: 0.5,
        px: 1,
        cursor: 'pointer',
        width: '100%',
      }}
      onClick={() => {
        if (onOpen) {
          onOpen()
        }
      }}
    >
      {IS_ICON_MODE ? (
        <Typography
          sx={{ fontSize: 13, fontWeight: 500, whiteSpace: 'pre-line' }}
        >
          {t('detailPage.shareDialog.label')}
        </Typography>
      ) : null}
      <Box
        component="img"
        src={ShareSvg}
        sx={{
          width: { xs: 45, sm: 20 },
          height: { xs: 45, sm: 20 },
          zIndex: 101,
        }}
      />
      {IS_ICON_MODE ? null : (
        <Typography sx={{ fontSize: 15, ml: 2, fontWeight: 500 }}>
          {t('detailPage.shareDialog.label')}
        </Typography>
      )}
    </Stack>
  )
}

export default Share
