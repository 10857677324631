/* eslint-disable no-undef */
import axios from 'axios'
import { QueryClient, QueryCache } from 'react-query'
import AuthService from 'services/AuthService'

const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
})

function getAccessToken() {
  return AuthService.getToken()
}

let newTokenOnTheWay = false

function onAccessTokenFetched(token) {
  subscribers = subscribers.filter((callback) => callback(token))
}

let subscribers = []

function addSubscriber(callback) {
  subscribers.push(callback)
}

baseApi.interceptors.request.use(async (request) => {
  if (AuthService.isLoggedIn()) {
    const accessToken = getAccessToken()
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

baseApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {
      config,
      response: { status },
    } = error

    const originalRequest = config

    if (status === 401) {
      if (
        originalRequest.url ===
        `${process.env.REACT_APP_KEYCLOACK_SERVER_URL}/realms/spourgiti/protocol/openid-connect/token`
      ) {
        window.location.reload()
        return Promise.reject(error)
      }
      if (!newTokenOnTheWay) {
        newTokenOnTheWay = true
        const successCb = () => {
          newTokenOnTheWay = false
          onAccessTokenFetched(AuthService.getToken())
          subscribers = []
        }
        const errorCb = () => {
          AuthService.clearAuthState()
        }
        AuthService.updateToken(successCb, errorCb)
      }

      return new Promise((resolve) => {
        addSubscriber((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          resolve(axios(originalRequest))
        })
      })
    }

    return Promise.reject(error)
  }
)

export const queryCache = new QueryCache({})
export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export default baseApi
