import React from 'react'
import { Typography } from '@mui/material'

import { EstateAgent } from 'types'
interface Props {
  data: EstateAgent
}

function Info(props: Props) {
  const { data } = props
  if (data?.contactInfo) {
    let index = data?.contactInfo.findIndex(
      (element) => element.defaultContactInfo === true
    )
    if (index === -1) {
      index = 0
    }

    if (
      !data.contactInfo[index]?.address?.area &&
      !data.contactInfo[index]?.address?.city &&
      !data.contactInfo[index]?.address?.street &&
      !data.contactInfo[index]?.address?.streetNumber
    ) {
      return <Typography>-</Typography>
    }

    return (
      <Typography sx={{ fontSize: { xs: 15, sm: 20 } }}>
        {`${data.contactInfo[index]?.address?.street}${
          data.contactInfo[index]?.address?.streetNumber
            ? '-' + data.contactInfo[index]?.address?.streetNumber
            : ''
        }`}{' '}
        {`${
          data.contactInfo[index]?.address?.area
            ? data.contactInfo[index]?.address?.area
            : ''
        }${', ' + data.contactInfo[index].address?.city}`}
      </Typography>
    )
  } else {
    return null
  }
}

export default Info
