import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import { ReactComponent as BedroomSVG } from 'assets/svg/icons/estate_bedroom4.svg'
import { ReactComponent as EtosSVG } from 'assets/svg/icons/etos_kataskevis.svg'
import { ReactComponent as FloorSVG } from 'assets/svg/icons/floor-icon4.svg'
import { ReactComponent as AmeaSvg } from 'assets/svg/icons/amea_prosvasi.svg'
import { ReactComponent as DieshSvg } from 'assets/svg/icons/diesh.svg'
import { ReactComponent as ApothikiSvg } from 'assets/svg/icons/apothiki.svg'
import { ReactComponent as AsfaleiaSvg } from 'assets/svg/icons/asfaleia.svg'
import { ReactComponent as BalconySvg } from 'assets/svg/icons/balcony.svg'
import { ReactComponent as BathroomsSvg } from 'assets/svg/icons/bathrooms.svg'
import { ReactComponent as DapedoSvg } from 'assets/svg/icons/dapedo.svg'
import { ReactComponent as DiamperesSvg } from 'assets/svg/icons/diamperes.svg'
import { ReactComponent as DiathrhteoSvg } from 'assets/svg/icons/diatiriteo.svg'
import { ReactComponent as ElevatorSvg } from 'assets/svg/icons/elevator.svg'
import { ReactComponent as EndodapediaThermanshSvg } from 'assets/svg/icons/endodapedia_thermansi.svg'
import { ReactComponent as EnergeiakhKlashSvg } from 'assets/svg/icons/energeiaki_klasi.svg'
import { ReactComponent as CommercialUseSvg } from 'assets/svg/icons/xrisi.svg'
import { ReactComponent as FireplaceSvg } from 'assets/svg/icons/fireplace.svg'
import { ReactComponent as EpiplwmenoSvg } from 'assets/svg/icons/furnitured.svg'
import { ReactComponent as GardenSvg } from 'assets/svg/icons/garden.svg'
import { ReactComponent as GwniakoSvg } from 'assets/svg/icons/gwniako.svg'
import { ReactComponent as HeatSvg } from 'assets/svg/icons/heat.svg'
import { ReactComponent as HliakosSvg } from 'assets/svg/icons/iliakos.svg'
import { ReactComponent as KoufomataSvg } from 'assets/svg/icons/koufomata.svg'
import { ReactComponent as ParkingSvg } from 'assets/svg/icons/parking.svg'
import { ReactComponent as PetSvg } from 'assets/svg/icons/pet.svg'
import { ReactComponent as PisinaSvg } from 'assets/svg/icons/pisina.svg'
import { ReactComponent as ProsanatolismosSvg } from 'assets/svg/icons/prosanatolismos.svg'
import { ReactComponent as ProsopshSvg } from 'assets/svg/icons/prosopsi.svg'
import { ReactComponent as RenovatedSvg } from 'assets/svg/icons/renovation.svg'
import { ReactComponent as SitaSvg } from 'assets/svg/icons/sita.svg'
import { ReactComponent as ProsvashSvg } from 'assets/svg/icons/prosvasi.svg'
import { ReactComponent as SofaSvg } from 'assets/svg/icons/sofa.svg'
import { ReactComponent as TheaSvg } from 'assets/svg/icons/thea.svg'
import { ReactComponent as ThiroTvSvg } from 'assets/svg/icons/thirotileorasi.svg'
import { ReactComponent as KoinoxristaSvg } from 'assets/svg/icons/koinoxrista.svg'
import { ReactComponent as AcSvg } from 'assets/svg/icons/air_condition.svg'
import { ReactComponent as ElevatorCarSvg } from 'assets/svg/icons/elevator-car.svg'
import { ReactComponent as ElevatorStuffSvg } from 'assets/svg/icons/elevator-emporeymata.svg'
import { ReactComponent as AytonomoiXwroiSvg } from 'assets/svg/icons/aytonomoi_xwroi.svg'
import { ReactComponent as ApoxeteyshSvg } from 'assets/svg/icons/apoxeteysh.svg'
import { ReactComponent as CctvSvg } from 'assets/svg/icons/cctv.svg'
import { ReactComponent as DomhmenhKalwdiwshSvg } from 'assets/svg/icons/domimeni_kalodiosi.svg'
import { ReactComponent as DynatothtaEpektashsSvg } from 'assets/svg/icons/dynatotita_epektasis.svg'
import { ReactComponent as EpifaneiaOikopedouSvg } from 'assets/svg/icons/epifaneia_oikopedou.svg'
import { ReactComponent as PyrasfaleiaSvg } from 'assets/svg/icons/pirasfaleia.svg'
import { ReactComponent as PseudorofhSvg } from 'assets/svg/icons/pseydorofi.svg'
import { ReactComponent as AgriculturalUseSvg } from 'assets/svg/icons/agricultural_use.svg'
import { ReactComponent as AmphitheatricalSvg } from 'assets/svg/icons/amphitheatrical.svg'
import { ReactComponent as AntiparoxhSvg } from 'assets/svg/icons/antiparoxi.svg'
import { ReactComponent as DimensionsSvg } from 'assets/svg/icons/dimensions.svg'
import { ReactComponent as DistanceSvg } from 'assets/svg/icons/distance.svg'
import { ReactComponent as HlektrodothshSvg } from 'assets/svg/icons/ilektrodotisi.svg'
import { ReactComponent as KatastashSvg } from 'assets/svg/icons/katastasi.svg'
import { ReactComponent as PerifrakshSvg } from 'assets/svg/icons/perifraksi.svg'
import { ReactComponent as DasikhKalypshSvg } from 'assets/svg/icons/dasiki_kalipsi.svg'
import { ReactComponent as CoverageFactorSvg } from 'assets/svg/icons/coverage_factor.svg'
import { ReactComponent as SyntelesthsDomhshsSvg } from 'assets/svg/icons/syntelestis_domisis.svg'
import { ReactComponent as TyposEdafousSvg } from 'assets/svg/icons/tipos_edafous.svg'
import { ReactComponent as WithinPlanSvg } from 'assets/svg/icons/within_plan.svg'
import { ReactComponent as YdreyshSvg } from 'assets/svg/icons/ydreysh.svg'
import { ReactComponent as YpsosSvg } from 'assets/svg/icons/ypsos.svg'
import { ReactComponent as PricePerSqSvg } from 'assets/svg/icons/price-per-square.svg'
import { ReactComponent as OfficesSvg } from 'assets/svg/icons/offices.svg'
import { ReactComponent as EswterikhSkalaSvg } from 'assets/svg/icons/esoteriki_skala.svg'
import { ReactComponent as DimensionsPortaSvg } from 'assets/svg/icons/dimensions_portas.svg'
import { ReactComponent as XwroiSvg } from 'assets/svg/icons/xoroi.svg'
import { ReactComponent as KitchenSvg } from 'assets/svg/icons/kitchen.svg'
import { ReactComponent as ThyrorosSvg } from 'assets/svg/icons/thiroros.svg'
import { ReactComponent as YgeionomikouSvg } from 'assets/svg/icons/ygeionomikou.svg'
import { ReactComponent as EksoplismenoSvg } from 'assets/svg/icons/equipped.svg'
import { ReactComponent as RolaSvg } from 'assets/svg/icons/security_shutters.svg'
import { ReactComponent as EidosReymatosSvg } from 'assets/svg/icons/electricity_type.svg'
import { ReactComponent as RampaSvg } from 'assets/svg/icons/rampa.svg'
import { ReactComponent as OikopedoSvg } from 'assets/svg/icons/plot.svg'
import { ReactComponent as BenzinadikoSvg } from 'assets/svg/icons/venzinadiko.svg'
import { ReactComponent as CarWashSvg } from 'assets/svg/icons/car_wash.svg'
import { ReactComponent as TheseisSvg } from 'assets/svg/icons/theseis.svg'
import { ReactComponent as FylakshSvg } from 'assets/svg/icons/security.svg'
import { ReactComponent as ConstructionTypeSvg } from 'assets/svg/icons/construction_type.svg'
import { ReactComponent as WaterHeatingSvg } from 'assets/svg/icons/water_heating.svg'
import { ReactComponent as AuxiliarySpacesSvg } from 'assets/svg/icons/auxiliary_spaces.svg'
import { ReactComponent as FacilitiesSvg } from 'assets/svg/icons/facilities.svg'
import { ReactComponent as RestaurantSvg } from 'assets/svg/icons/restaurant.svg'
import { ReactComponent as AssemblyHallSvg } from 'assets/svg/icons/assembly_hall.svg'
import { ReactComponent as GymSvg } from 'assets/svg/icons/gym.svg'
import { ReactComponent as SpaSvg } from 'assets/svg/icons/spa.svg'
import { ReactComponent as RoofGardenSvg } from 'assets/svg/icons/roof_garden.svg'
import { ReactComponent as AdeiaSvg } from 'assets/svg/icons/adeia.svg'
import { ReactComponent as MarinaSvg } from 'assets/svg/icons/marina.svg'
import { ReactComponent as OpseisSvg } from 'assets/svg/icons/goniako_opseis.svg'
import { ReactComponent as EpitrepomenoYpsosSvg } from 'assets/svg/icons/epitrepomeno_ypsos.svg'
import { ReactComponent as MeKtismaLandSvg } from 'assets/svg/icons/me_ktisma_land.svg'

import {
  getPropertyAdCode,
  parseBathroomsParsed,
  parseBooleanFieldParsed,
  parseHeat,
  parseFloors,
  getFoodService,
  getEstateElevator,
  getEstateFurnished,
  getCommercialFurnished,
  getKitchen,
  getCommercialDrains,
  getLandDrains,
  getGarden,
  getEnergyClass,
  getEstateFireplace,
  getCommercialFireplace,
  getFalseCeiling,
  getStructuredWiring,
  getRenovationYear,
  getDoorman,
  getInternalStairs,
  getEstateMaintained,
  getCommercialMaintained,
  getCommercialDisabledAccess,
  getEstateDisabledAccess,
  getSecurityBlinds,
  getCommercialCctv,
  getFireSafety,
  parseSafety,
  parseFrontage,
  getMaintenanceFees,
  parseBedrooms,
  getLivingRooms,
  getRooms,
  getSpaces,
  getEntranceHeight,
  getMaxAllowedHeight,
  getHeight,
  getPlot,
  getAuxiliarySpaces,
  getRestaurants,
  getIndependentSpaces,
  parseStorageLoft,
  getFramesWindows,
  getAccess,
  getScreensSieves,
  getEstateUnderfloorHeat,
  getEstateFacade,
  getCommercialFacade,
  getEstateCorner,
  getCommercialCorner,
  getPets,
  getDirection,
  getFloorType,
  getCargoRamp,
  getElectricityType,
  getView,
  getCommercialAmphitheatrical,
  getCargoElevator,
  getEquipment,
  getParking,
  getEnclosure,
  getWaterSupply,
  getΕlectrification,
  getWithBuilding,
  getLandCornerViews,
  getLandCondition,
  getEstateCondition,
  getDistance,
  getCompensation,
  getPool,
  getCommercialGym,
  getCommercialEventSpace,
  getSpa,
  getRoofGarden,
  getDimensions,
  getUsage,
  getBalconyTents,
  getOffices,
  getExpandability,
  getCityPlan,
  getIndustrialDoorDimensions,
  getCommercialElevator,
  getEstateAirCondition,
  getCommercialAirCondition,
  getLandFacade,
  getLandAmphitheatrical,
  getLandAgriculturalUse,
  getCoverageFactor,
  getBuildFactor,
  getCarElevator,
  getGuarding,
  getNumberOfEntrances,
  getCommercialWaterHeating,
  getMarina,
  getLandBuildable,
  getForestCover,
  getEpifaneiaOikopedou,
  getParkingSpacesDetail,
  getSalesPricePerBuildableSqrMeter,
  getBedrooms,
} from './helpers'
type Props = SvgIconProps
type Ref = SVGSVGElement

const CustomSvgIcon = React.forwardRef<Ref, Props>(function CustomSvgIcon(
  props,
  ref
) {
  return <SvgIcon inheritViewBox={true} ref={ref} {...props} />
})

interface StyledSvgIconProps extends SvgIconProps {
  component?: React.ReactNode
}

const StyledSvgIcon = styled(CustomSvgIcon)<StyledSvgIconProps>(() => ({
  width: 'auto',
  height: 28,
  color: 'black',
  '& .dapedost0': {
    fill: 'none',
  },
  '& .cctvst3': {
    fill: 'black',
  },
}))

export const icons = {
  estate: {
    home: {
      detail: [
        {
          icon: <StyledSvgIcon component={EtosSVG} />,
          valueGetter: (data) => data?.constructionYear,
          tooltip: 'tags.constructionYear.label',
        },
        {
          icon: <StyledSvgIcon component={FloorSVG} />,
          valueGetter: (data, t) => parseFloors(data, t),
          tooltip: 'tags.floor.label',
        },
        {
          icon: (
            <StyledSvgIcon
              component={BedroomSVG}
              sx={{ width: 25, height: 'auto' }}
            />
          ),
          valueGetter: (data, t) => parseBedrooms(data, t),
          tooltip: 'tags.rooms.label',
        },
      ],
      searchdetail: [
        {
          icon: <StyledSvgIcon component={EtosSVG} />,
          valueGetter: (data) => data?.constructionYear,
          tooltip: 'tags.constructionYear.label',
        },
        {
          icon: <StyledSvgIcon component={FloorSVG} />,
          valueGetter: (data, t) => parseFloors(data, t),
          tooltip: 'tags.floor.label',
        },
        {
          icon: (
            <StyledSvgIcon
              component={BedroomSVG}
              sx={{ width: 25, height: 'auto' }}
            />
          ),
          valueGetter: (data, t) => getBedrooms(data, t),
          tooltip: 'tags.rooms.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={RenovatedSvg} />,
          valueGetter: (data, t) => getRenovationYear(data, t),
          tooltip: 'tags.renovated.label',
        },
        {
          icon: <StyledSvgIcon component={HeatSvg} />,
          valueGetter: (data, t) => parseHeat(data, t),
          tooltip: 'tags.heat.label',
        },
        {
          icon: <StyledSvgIcon component={BathroomsSvg} />,
          valueGetter: (data, t) => parseBathroomsParsed(data, t),
          tooltip: 'tags.bathrooms.label',
        },
        {
          icon: <StyledSvgIcon component={BalconySvg} />,
          valueGetter: (data, t) => getBalconyTents(data, t),
          tooltip: 'tags.balconyAwning.label',
        },
        {
          icon: <StyledSvgIcon component={ParkingSvg} />,
          valueGetter: (data, t) => getParking(data, t),
          tooltip: 'tags.parking.label',
        },
        {
          icon: <StyledSvgIcon component={ElevatorSvg} />,
          valueGetter: (data, t) => getEstateElevator(data, t),
          tooltip: 'tags.elevator.label',
        },
        {
          icon: <StyledSvgIcon component={GardenSvg} />,
          valueGetter: (data, t) => getGarden(data, t),
          tooltip: 'tags.garden.label',
        },
        {
          icon: <StyledSvgIcon component={KoinoxristaSvg} />,
          valueGetter: (data, t) => getMaintenanceFees(data, t),
          tooltip: 'tags.maintenanceFees.label',
        },
        {
          icon: <StyledSvgIcon component={AsfaleiaSvg} />,
          valueGetter: (data, t) => parseSafety(data, t),
          tooltip: 'tags.safety.label',
        },
        {
          icon: <StyledSvgIcon component={FireplaceSvg} />,
          valueGetter: (data, t) => getEstateFireplace(data, t),
          tooltip: 'tags.fireplace.label',
        },
        {
          icon: <StyledSvgIcon component={EnergeiakhKlashSvg} />,
          valueGetter: (data, t) => getEnergyClass(data, t),
          tooltip: 'tags.energyClass.label',
        },
        {
          icon: <StyledSvgIcon component={EpiplwmenoSvg} />,
          valueGetter: (data, t) => getEstateFurnished(data, t),
          tooltip: 'tags.furnitured.label',
        },
      ],
      more: [
        {
          icon: <StyledSvgIcon component={KoufomataSvg} />,
          valueGetter: (data, t) => getFramesWindows(data, t),
          tooltip: 'tags.framesWindows.label',
        },
        {
          icon: <StyledSvgIcon component={HliakosSvg} />,
          valueGetter: (data, t) =>
            parseBooleanFieldParsed(
              'extras.ESTATE_PROPERTY_EXTRAS#solar_water_heater',
              'has',
              data,
              t
            ),
          tooltip: 'tags.solarWaterHeater.label',
        },
        {
          icon: <StyledSvgIcon component={SofaSvg} />,
          valueGetter: (data, t) => getLivingRooms(data, t),
          tooltip: 'tags.livingRooms.label',
        },
        {
          icon: <StyledSvgIcon component={CommercialUseSvg} />,
          valueGetter: (data, t) =>
            parseBooleanFieldParsed(
              'extras.ESTATE_PROPERTY_EXTRAS#professional_use',
              'yes',
              data,
              t
            ),
          tooltip: 'tags.professionalUse.label',
        },

        {
          icon: <StyledSvgIcon component={DiamperesSvg} />,
          valueGetter: (data, t) => parseFrontage(data, t),
          tooltip: 'tags.diamperes.label',
        },
        {
          icon: <StyledSvgIcon component={DapedoSvg} sx={{ height: 40 }} />,
          valueGetter: (data, t) => getFloorType(data, t),
          tooltip: 'tags.floorType.label',
        },
        {
          icon: <StyledSvgIcon component={EndodapediaThermanshSvg} />,
          valueGetter: (data, t) => getEstateUnderfloorHeat(data, t),
          tooltip: 'tags.underfloorHeat.label',
        },
        {
          icon: <StyledSvgIcon component={DiathrhteoSvg} />,
          valueGetter: (data, t) => getEstateMaintained(data, t),
          tooltip: 'tags.legacy.label',
        },
        {
          icon: <StyledSvgIcon component={SitaSvg} />,
          valueGetter: (data, t) => getScreensSieves(data, t),
          tooltip: 'tags.sieve.label',
        },
        {
          icon: <StyledSvgIcon component={ThiroTvSvg} />,
          valueGetter: (data, t) =>
            parseBooleanFieldParsed(
              'extras.ESTATE_PROPERTY_EXTRAS#intercom',
              'yes',
              data,
              t
            ),
          tooltip: 'tags.intercom.label',
        },
        {
          icon: <StyledSvgIcon component={AmeaSvg} />,
          valueGetter: (data, t) => getEstateDisabledAccess(data, t),
          tooltip: 'tags.amea.label',
        },
        {
          icon: <StyledSvgIcon component={ProsvashSvg} />,
          valueGetter: (data, t) => getAccess(data, t),
          tooltip: 'tags.access.label',
        },
        {
          icon: <StyledSvgIcon component={ProsopshSvg} />,
          valueGetter: (data, t) => getEstateFacade(data, t),
          tooltip: 'tags.facade.label',
        },
        {
          icon: <StyledSvgIcon component={GwniakoSvg} />,
          valueGetter: (data, t) => getEstateCorner(data, t),
          tooltip: 'tags.corner.label',
        },
        {
          icon: <StyledSvgIcon component={ProsanatolismosSvg} />,
          valueGetter: (data, t) => getDirection(data, t),
          tooltip: 'tags.orientation.label',
        },
        {
          icon: <StyledSvgIcon component={TheaSvg} />,
          valueGetter: (data, t) => getView(data, t),
          tooltip: 'tags.view.label',
        },
        {
          icon: <StyledSvgIcon component={AcSvg} />,
          valueGetter: (data, t) => getEstateAirCondition(data, t),
          tooltip: 'tags.airCondition.label',
        },
        {
          icon: <StyledSvgIcon component={PetSvg} />,
          valueGetter: (data, t) => getPets(data, t),
          tooltip: 'tags.pets.label',
        },
        {
          icon: <StyledSvgIcon component={PisinaSvg} />,
          valueGetter: (data, t) => getPool(data, t),
          tooltip: 'tags.pool.label',
        },
        {
          icon: <StyledSvgIcon component={ApothikiSvg} />,
          valueGetter: (data, t) => parseStorageLoft(data, t),
          tooltip: 'tags.atticWarehouse.label',
        },
      ],
    },
  },
  commercial: {
    combined_office_hall: {
      detail: [
        {
          icon: <StyledSvgIcon component={EtosSVG} />,
          valueGetter: (data) => data?.constructionYear,
          tooltip: 'tags.constructionYear.label',
        },
        {
          icon: <StyledSvgIcon component={FloorSVG} />,
          valueGetter: (data, t) => parseFloors(data, t),
          tooltip: 'tags.floor.label',
        },
        {
          icon: <StyledSvgIcon component={XwroiSvg} />,
          valueGetter: (data, t) => getSpaces(data, t),
          tooltip: 'tags.spaces.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={RenovatedSvg} />,
          valueGetter: (data, t) => getRenovationYear(data, t),
          tooltip: 'tags.renovated.label',
        },
        {
          icon: <StyledSvgIcon component={HeatSvg} />,
          valueGetter: (data, t) => parseHeat(data, t),
          tooltip: 'tags.heat.label',
        },
        {
          icon: <StyledSvgIcon component={BathroomsSvg} />,
          valueGetter: (data, t) => parseBathroomsParsed(data, t),
          tooltip: 'tags.bathrooms.label',
        },
        {
          icon: <StyledSvgIcon component={KitchenSvg} />,
          valueGetter: (data, t) => getKitchen(data, t),
          tooltip: 'tags.coozine.label',
        },
        {
          icon: <StyledSvgIcon component={AcSvg} />,
          valueGetter: (data, t) => getCommercialAirCondition(data, t),
          tooltip: 'tags.airCondition.label',
        },
        {
          icon: <StyledSvgIcon component={ParkingSvg} />,
          valueGetter: (data, t) => getParking(data, t),
          tooltip: 'tags.parking.label',
        },
        {
          icon: <StyledSvgIcon component={ElevatorSvg} />,
          valueGetter: (data, t) => getCommercialElevator(data, t),
          tooltip: 'tags.elevator.label',
        },
        {
          icon: <StyledSvgIcon component={EpiplwmenoSvg} />,
          valueGetter: (data, t) => getCommercialFurnished(data, t),
          tooltip: 'tags.furnitured.label',
        },
        {
          icon: <StyledSvgIcon component={ApothikiSvg} />,
          valueGetter: (data, t) => parseStorageLoft(data, t),
          tooltip: 'tags.atticWarehouse.label',
        },
        {
          icon: <StyledSvgIcon component={AsfaleiaSvg} />,
          valueGetter: (data, t) => parseSafety(data, t),
          tooltip: 'tags.safety.label',
        },
        {
          icon: <StyledSvgIcon component={BalconySvg} />,
          valueGetter: (data, t) => getBalconyTents(data, t),
          tooltip: 'tags.balconyAwning.label',
        },
        {
          icon: <StyledSvgIcon component={EnergeiakhKlashSvg} />,
          valueGetter: (data, t) => getEnergyClass(data, t),
          tooltip: 'tags.energyClass.label',
        },
      ],
      more: [
        {
          icon: <StyledSvgIcon component={DomhmenhKalwdiwshSvg} />,
          valueGetter: (data, t) => getStructuredWiring(data, t),
          tooltip: 'tags.cableManagement.label',
        },
        {
          icon: <StyledSvgIcon component={PseudorofhSvg} sx={{ width: 34 }} />,
          valueGetter: (data, t) => getFalseCeiling(data, t),
          tooltip: 'tags.falseCeiling.label',
        },
        {
          icon: <StyledSvgIcon component={DapedoSvg} sx={{ height: 40 }} />,
          valueGetter: (data, t) => getFloorType(data, t),
          tooltip: 'tags.floorType.label',
        },
        {
          icon: <StyledSvgIcon component={ThyrorosSvg} />,
          valueGetter: (data, t) => getDoorman(data, t),
          tooltip: 'tags.concierge.label',
        },
        {
          icon: <StyledSvgIcon component={FireplaceSvg} />,
          valueGetter: (data, t) => getCommercialFireplace(data, t),
          tooltip: 'tags.fireplace.label',
        },
        {
          icon: <StyledSvgIcon component={KoufomataSvg} />,
          valueGetter: (data, t) => getFramesWindows(data, t),
          tooltip: 'tags.framesWindows.label',
        },
        {
          icon: <StyledSvgIcon component={PyrasfaleiaSvg} />,
          valueGetter: (data, t) => getFireSafety(data, t),
          tooltip: 'tags.fireSafety.label',
        },
        {
          icon: <StyledSvgIcon component={DiathrhteoSvg} />,
          valueGetter: (data, t) => getCommercialMaintained(data, t),
          tooltip: 'tags.legacy.label',
        },
        {
          icon: <StyledSvgIcon component={GardenSvg} />,
          valueGetter: (data, t) => getGarden(data, t),
          tooltip: 'tags.garden.label',
        },
        {
          icon: <StyledSvgIcon component={EswterikhSkalaSvg} />,
          valueGetter: (data, t) => getInternalStairs(data, t),
          tooltip: 'tags.innerLadder.label',
        },
        {
          icon: <StyledSvgIcon component={AmeaSvg} />,
          valueGetter: (data, t) => getCommercialDisabledAccess(data, t),
          tooltip: 'tags.amea.label',
        },
        {
          icon: <StyledSvgIcon component={ProsvashSvg} />,
          valueGetter: (data, t) => getAccess(data, t),
          tooltip: 'tags.access.label',
        },
        {
          icon: <StyledSvgIcon component={ProsopshSvg} />,
          valueGetter: (data, t) => getCommercialFacade(data, t),
          tooltip: 'tags.facade.label',
        },
        {
          icon: <StyledSvgIcon component={GwniakoSvg} />,
          valueGetter: (data, t) => getCommercialCorner(data, t),
          tooltip: 'tags.corner.label',
        },
        {
          icon: <StyledSvgIcon component={ProsanatolismosSvg} />,
          valueGetter: (data, t) => getDirection(data, t),
          tooltip: 'tags.orientation.label',
        },
        {
          icon: <StyledSvgIcon component={TheaSvg} />,
          valueGetter: (data, t) => getView(data, t),
          tooltip: 'tags.view.label',
        },
      ],
    },
    store: {
      detail: [
        {
          icon: <StyledSvgIcon component={EtosSVG} />,
          tooltip: 'tags.constructionYear.label',
          valueGetter: (data) => data?.constructionYear,
        },
        {
          icon: <StyledSvgIcon component={FloorSVG} />,
          valueGetter: (data, t) => parseFloors(data, t),
          tooltip: 'tags.floor.label',
        },
        {
          icon: <StyledSvgIcon component={XwroiSvg} />,
          valueGetter: (data, t) => getSpaces(data, t),
          tooltip: 'tags.spaces.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={RenovatedSvg} />,
          valueGetter: (data, t) => getRenovationYear(data, t),
          tooltip: 'tags.renovated.label',
        },
        {
          icon: <StyledSvgIcon component={HeatSvg} />,
          valueGetter: (data, t) => parseHeat(data, t),
          tooltip: 'tags.heat.label',
        },
        {
          icon: <StyledSvgIcon component={BathroomsSvg} />,
          valueGetter: (data, t) => parseBathroomsParsed(data, t),
          tooltip: 'tags.bathrooms.label',
        },
        {
          icon: <StyledSvgIcon component={BalconySvg} />,
          valueGetter: (data, t) => getBalconyTents(data, t),
          tooltip: 'tags.balconyAwning.label',
        },
        {
          icon: <StyledSvgIcon component={ParkingSvg} />,
          valueGetter: (data, t) => getParking(data, t),
          tooltip: 'tags.parking.label',
        },
        {
          icon: (
            <StyledSvgIcon component={YgeionomikouSvg} sx={{ width: 40 }} />
          ),
          valueGetter: (data, t) => getFoodService(data, t),
          tooltip: 'tags.foodInterest.label',
        },
        {
          icon: <StyledSvgIcon component={ProsopshSvg} />,
          valueGetter: (data, t) => getCommercialFacade(data, t),
          tooltip: 'tags.facade.label',
        },
        {
          icon: <StyledSvgIcon component={AcSvg} />,
          valueGetter: (data, t) => getCommercialAirCondition(data, t),
          tooltip: 'tags.airCondition.label',
        },
        {
          icon: <StyledSvgIcon component={ApothikiSvg} />,
          valueGetter: (data, t) => parseStorageLoft(data, t),
          tooltip: 'tags.atticWarehouse.label',
        },
        {
          icon: <StyledSvgIcon component={AsfaleiaSvg} />,
          valueGetter: (data, t) => parseSafety(data, t),
          tooltip: 'tags.safety.label',
        },
        {
          icon: <StyledSvgIcon component={GardenSvg} />,
          valueGetter: (data, t) => getGarden(data, t),
          tooltip: 'tags.garden.label',
        },
        {
          icon: <StyledSvgIcon component={EnergeiakhKlashSvg} />,
          valueGetter: (data, t) => getEnergyClass(data, t),
          tooltip: 'tags.energyClass.label',
        },
        {
          icon: <StyledSvgIcon component={EksoplismenoSvg} />,
          valueGetter: (data, t) => getEquipment(data, t),
          tooltip: 'tags.equipment.label',
        },
      ],
      more: [
        {
          icon: <StyledSvgIcon component={DomhmenhKalwdiwshSvg} />,
          valueGetter: (data, t) => getStructuredWiring(data, t),
          tooltip: 'tags.cableManagement.label',
        },
        {
          icon: <StyledSvgIcon component={PseudorofhSvg} sx={{ width: 34 }} />,
          valueGetter: (data, t) => getFalseCeiling(data, t),
          tooltip: 'tags.falseCeiling.label',
        },
        {
          icon: <StyledSvgIcon component={DapedoSvg} sx={{ height: 40 }} />,
          valueGetter: (data, t) => getFloorType(data, t),
          tooltip: 'tags.floorType.label',
        },
        {
          icon: <StyledSvgIcon component={KoufomataSvg} />,
          valueGetter: (data, t) => getFramesWindows(data, t),
          tooltip: 'tags.framesWindows.label',
        },
        {
          icon: <StyledSvgIcon component={PyrasfaleiaSvg} />,
          valueGetter: (data, t) => getFireSafety(data, t),
          tooltip: 'tags.fireSafety.label',
        },
        {
          icon: <StyledSvgIcon component={DiathrhteoSvg} />,
          valueGetter: (data, t) => getCommercialMaintained(data, t),
          tooltip: 'tags.legacy.label',
        },
        {
          icon: <StyledSvgIcon component={CctvSvg} />,
          valueGetter: (data, t) => getCommercialCctv(data, t),
          tooltip: 'tags.cctv.label',
        },
        {
          icon: <StyledSvgIcon component={RolaSvg} />,
          valueGetter: (data, t) => getSecurityBlinds(data, t),
          tooltip: 'tags.securityBlinds.label',
        },
        {
          icon: <StyledSvgIcon component={EidosReymatosSvg} />,
          valueGetter: (data, t) => getElectricityType(data, t),
          tooltip: 'tags.electricityType.label',
        },
        {
          icon: <StyledSvgIcon component={AmeaSvg} />,
          valueGetter: (data, t) => getCommercialDisabledAccess(data, t),
          tooltip: 'tags.amea.label',
        },
        {
          icon: <StyledSvgIcon component={ProsvashSvg} />,
          valueGetter: (data, t) => getAccess(data, t),
          tooltip: 'tags.access.label',
        },
        {
          icon: <StyledSvgIcon component={EswterikhSkalaSvg} />,
          valueGetter: (data, t) => getInternalStairs(data, t),
          tooltip: 'tags.innerLadder.label',
        },
        {
          icon: <StyledSvgIcon component={ElevatorStuffSvg} />,
          valueGetter: (data, t) => getCargoElevator(data, t),
          tooltip: 'tags.cargoElevator.label',
        },
        {
          icon: <StyledSvgIcon component={RampaSvg} />,
          valueGetter: (data, t) => getCargoRamp(data, t),
          tooltip: 'tags.cargoRamp.label',
        },
        {
          icon: <StyledSvgIcon component={GwniakoSvg} />,
          valueGetter: (data, t) => getCommercialCorner(data, t),
          tooltip: 'tags.corner.label',
        },
        {
          icon: <StyledSvgIcon component={ProsanatolismosSvg} />,
          valueGetter: (data, t) => getDirection(data, t),
          tooltip: 'tags.orientation.label',
        },
        {
          icon: <StyledSvgIcon component={TheaSvg} />,
          valueGetter: (data, t) => getView(data, t),
          tooltip: 'tags.view.label',
        },
        {
          icon: <StyledSvgIcon component={KitchenSvg} />,
          valueGetter: (data, t) => getKitchen(data, t),
          tooltip: 'tags.coozine.label',
        },
      ],
    },
    commercial_building: {
      detail: [
        {
          icon: <StyledSvgIcon component={EtosSVG} />,
          valueGetter: (data) => data?.constructionYear,
          tooltip: 'tags.constructionYear.label',
        },
        {
          icon: <StyledSvgIcon component={EnergeiakhKlashSvg} />,
          valueGetter: (data, t) => getEnergyClass(data, t),
          tooltip: 'tags.energyClass.label',
        },
        {
          icon: <StyledSvgIcon component={XwroiSvg} />,
          valueGetter: (data, t) => getSpaces(data, t),
          tooltip: 'tags.spaces.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={RenovatedSvg} />,
          valueGetter: (data, t) => getRenovationYear(data, t),
          tooltip: 'tags.renovated.label',
        },
        {
          icon: <StyledSvgIcon component={HeatSvg} />,
          valueGetter: (data, t) => parseHeat(data, t),
          tooltip: 'tags.heat.label',
        },
        {
          icon: <StyledSvgIcon component={CommercialUseSvg} />,
          valueGetter: (data, t) => getUsage(data, t),
          tooltip: 'tags.use.label',
        },
        {
          icon: <StyledSvgIcon component={AytonomoiXwroiSvg} />,
          valueGetter: (data, t) => getIndependentSpaces(data, t),
          tooltip: 'tags.autonomousSpaces.label',
        },
        {
          icon: <StyledSvgIcon component={BalconySvg} />,
          valueGetter: (data, t) => getBalconyTents(data, t),
          tooltip: 'tags.balconyAwning.label',
        },
        {
          icon: <StyledSvgIcon component={ElevatorSvg} />,
          valueGetter: (data, t) => getCommercialElevator(data, t),
          tooltip: 'tags.elevator.label',
        },
        {
          icon: <StyledSvgIcon component={ParkingSvg} />,
          valueGetter: (data, t) => getParking(data, t),
          tooltip: 'tags.parking.label',
        },
        {
          icon: <StyledSvgIcon component={GardenSvg} />,
          valueGetter: (data, t) => getGarden(data, t),
          tooltip: 'tags.garden.label',
        },
        {
          icon: <StyledSvgIcon component={AcSvg} />,
          valueGetter: (data, t) => getCommercialAirCondition(data, t),
          tooltip: 'tags.airCondition.label',
        },
        {
          icon: <StyledSvgIcon component={AsfaleiaSvg} />,
          valueGetter: (data, t) => parseSafety(data, t),
          tooltip: 'tags.safety.label',
        },
        {
          icon: <StyledSvgIcon component={EpifaneiaOikopedouSvg} />,
          valueGetter: (data, t) => getEpifaneiaOikopedou(data, t),
          tooltip: 'tags.plotDimensions.label',
        },
        {
          icon: <StyledSvgIcon component={DynatothtaEpektashsSvg} />,
          valueGetter: (data, t) => getExpandability(data, t),
          tooltip: 'tags.expandable.label',
        },
      ],
      more: [
        {
          icon: <StyledSvgIcon component={KoufomataSvg} />,
          valueGetter: (data, t) => getFramesWindows(data, t),
          tooltip: 'tags.framesWindows.label',
        },
        {
          icon: <StyledSvgIcon component={DapedoSvg} sx={{ height: 40 }} />,
          valueGetter: (data, t) => getFloorType(data, t),
          tooltip: 'tags.floorType.label',
        },
        {
          icon: <StyledSvgIcon component={EndodapediaThermanshSvg} />,
          valueGetter: (data, t) => getEstateUnderfloorHeat(data, t),
          tooltip: 'tags.underfloorHeat.label',
        },
        {
          icon: <StyledSvgIcon component={EpiplwmenoSvg} />,
          valueGetter: (data, t) => getCommercialFurnished(data, t),
          tooltip: 'tags.furnitured.label',
        },
        {
          icon: <StyledSvgIcon component={DomhmenhKalwdiwshSvg} />,
          valueGetter: (data, t) => getStructuredWiring(data, t),
          tooltip: 'tags.cableManagement.label',
        },
        {
          icon: <StyledSvgIcon component={PseudorofhSvg} sx={{ width: 34 }} />,
          valueGetter: (data, t) => getFalseCeiling(data, t),
          tooltip: 'tags.falseCeiling.label',
        },
        {
          icon: <StyledSvgIcon component={ApoxeteyshSvg} />,
          valueGetter: (data, t) => getCommercialDrains(data, t),
          tooltip: 'tags.drains.label',
        },
        {
          icon: <StyledSvgIcon component={DiathrhteoSvg} />,
          valueGetter: (data, t) => getCommercialMaintained(data, t),
          tooltip: 'tags.legacy.label',
        },
        {
          icon: <StyledSvgIcon component={ProsopshSvg} />,
          valueGetter: (data, t) => getCommercialFacade(data, t),
          tooltip: 'tags.facade.label',
        },
        {
          icon: <StyledSvgIcon component={GwniakoSvg} />,
          valueGetter: (data, t) => getCommercialCorner(data, t),
          tooltip: 'tags.corner.label',
        },
        {
          icon: <StyledSvgIcon component={ProsanatolismosSvg} />,
          valueGetter: (data, t) => getDirection(data, t),
          tooltip: 'tags.orientation.label',
        },
        {
          icon: <StyledSvgIcon component={TheaSvg} />,
          valueGetter: (data, t) => getView(data, t),
          tooltip: 'tags.view.label',
        },
        {
          icon: <StyledSvgIcon component={CctvSvg} />,
          valueGetter: (data, t) => getCommercialCctv(data, t),
          tooltip: 'tags.cctv.label',
        },
        {
          icon: <StyledSvgIcon component={PyrasfaleiaSvg} />,
          valueGetter: (data, t) => getFireSafety(data, t),
          tooltip: 'tags.fireSafety.label',
        },
        {
          icon: <StyledSvgIcon component={AmeaSvg} />,
          valueGetter: (data, t) => getCommercialDisabledAccess(data, t),
          tooltip: 'tags.amea.label',
        },
        {
          icon: <StyledSvgIcon component={ProsvashSvg} />,
          valueGetter: (data, t) => getAccess(data, t),
          tooltip: 'tags.access.label',
        },
      ],
    },
    parking: {
      detail: [
        {
          icon: <StyledSvgIcon component={EtosSVG} />,
          valueGetter: (data) => data?.constructionYear,
          tooltip: 'tags.constructionYear.label',
        },
        {
          icon: <StyledSvgIcon component={ElevatorCarSvg} />,
          valueGetter: (data, t) => getCarElevator(data, t),
          tooltip: 'tags.carElevator.label',
        },
        {
          icon: <StyledSvgIcon component={TheseisSvg} />,
          valueGetter: (data, t) => getParkingSpacesDetail(data, t),
          tooltip: 'tags.parkingSpaces.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={RenovatedSvg} />,
          valueGetter: (data, t) => getRenovationYear(data, t),
          tooltip: 'tags.renovated.label',
        },
        {
          icon: <StyledSvgIcon component={YpsosSvg} sx={{ height: 40 }} />,
          valueGetter: (data, t) => getEntranceHeight(data, t),
          tooltip: 'tags.entranceHeight.label',
        },
        {
          icon: <StyledSvgIcon component={FloorSVG} />,
          valueGetter: (data, t) => parseFloors(data, t),
          tooltip: 'tags.floor.label',
        },
        {
          icon: <StyledSvgIcon component={XwroiSvg} />,
          valueGetter: (data, t) => getSpaces(data, t),
          tooltip: 'tags.spaces.label',
        },
        {
          icon: <StyledSvgIcon component={CctvSvg} />,
          valueGetter: (data, t) => getCommercialCctv(data, t),
          tooltip: 'tags.cctv.label',
        },
        {
          icon: <StyledSvgIcon component={BathroomsSvg} />,
          valueGetter: (data, t) => parseBathroomsParsed(data, t),
          tooltip: 'tags.bathrooms.label',
        },
        {
          icon: <StyledSvgIcon component={BenzinadikoSvg} />,
          valueGetter: (data, t) =>
            parseBooleanFieldParsed('gas_station', 'yes', data, t),
          tooltip: 'tags.petrolStation.label',
        },
        {
          icon: <StyledSvgIcon component={CarWashSvg} />,
          valueGetter: (data, t) =>
            parseBooleanFieldParsed('washing_machine', 'has', data, t),
          tooltip: 'tags.washingMachine.label',
        },
        {
          icon: <StyledSvgIcon component={AsfaleiaSvg} />,
          valueGetter: (data, t) => parseSafety(data, t),
          tooltip: 'tags.safety.label',
        },
        {
          icon: <StyledSvgIcon component={DapedoSvg} sx={{ height: 40 }} />,
          valueGetter: (data, t) => getFloorType(data, t),
          tooltip: 'tags.floorType.label',
        },
      ],
      more: [],
    },
    combined_zones: {
      detail: [
        {
          icon: <StyledSvgIcon component={EtosSVG} />,
          valueGetter: (data) => data?.constructionYear,
          tooltip: 'tags.constructionYear.label',
        },
        {
          icon: <StyledSvgIcon component={FloorSVG} />,
          valueGetter: (data, t) => parseFloors(data, t),
          tooltip: 'tags.floor.label',
        },
        {
          icon: <StyledSvgIcon component={XwroiSvg} />,
          valueGetter: (data, t) => getSpaces(data, t),
          tooltip: 'tags.spaces.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={KatastashSvg} />,
          valueGetter: (data, t) => getEstateCondition(data, t),
          tooltip: 'tags.condition.label',
        },
        {
          icon: <StyledSvgIcon component={CommercialUseSvg} />,
          valueGetter: (data, t) => getUsage(data, t),
          tooltip: 'tags.use.label',
        },
        {
          icon: <StyledSvgIcon component={RenovatedSvg} />,
          valueGetter: (data, t) => getRenovationYear(data, t),
          tooltip: 'tags.renovated.label',
        },
        {
          icon: <StyledSvgIcon component={BathroomsSvg} />,
          valueGetter: (data, t) => parseBathroomsParsed(data, t),
          tooltip: 'tags.bathrooms.label',
        },
        {
          icon: <StyledSvgIcon component={HeatSvg} />,
          valueGetter: (data, t) => parseHeat(data, t),
          tooltip: 'tags.heat.label',
        },
        {
          icon: <StyledSvgIcon component={EidosReymatosSvg} />,
          valueGetter: (data, t) => getElectricityType(data, t),
          tooltip: 'tags.electricityType.label',
        },
        {
          icon: <StyledSvgIcon component={DomhmenhKalwdiwshSvg} />,
          valueGetter: (data, t) => getStructuredWiring(data, t),
          tooltip: 'tags.cableManagement.label',
        },
        {
          icon: <StyledSvgIcon component={ElevatorStuffSvg} />,
          valueGetter: (data, t) => getCargoElevator(data, t),
          tooltip: 'tags.cargoElevator.label',
        },
        {
          icon: <StyledSvgIcon component={ProsvashSvg} />,
          valueGetter: (data, t) => getAccess(data, t),
          tooltip: 'tags.access.label',
        },
        {
          icon: <StyledSvgIcon component={ConstructionTypeSvg} />,
          valueGetter: (data) => '-',
          tooltip: 'tags.constructionType.label',
        },
        {
          icon: <StyledSvgIcon component={DapedoSvg} sx={{ height: 40 }} />,
          valueGetter: (data, t) => getFloorType(data, t),
          tooltip: 'tags.floorType.label',
        },
        {
          icon: <StyledSvgIcon component={YpsosSvg} sx={{ height: 40 }} />,
          valueGetter: (data, t) => getHeight(data, t),
          tooltip: 'tags.height.label',
        },
        {
          icon: <StyledSvgIcon component={EksoplismenoSvg} />,
          valueGetter: (data, t) => getEquipment(data, t),
          tooltip: 'tags.equipment.label',
        },
        {
          icon: <StyledSvgIcon component={OikopedoSvg} />,
          valueGetter: (data, t) => getPlot(data, t),
          tooltip: 'tags.plot.label',
        },
      ],
      more: [
        {
          icon: <StyledSvgIcon component={FylakshSvg} />,
          valueGetter: (data, t) => getGuarding(data, t),
          tooltip: 'tags.security.label',
        },
        {
          icon: <StyledSvgIcon component={AsfaleiaSvg} />,
          valueGetter: (data, t) => parseSafety(data, t),
          tooltip: 'tags.safety.label',
        },
        {
          icon: <StyledSvgIcon component={CctvSvg} />,
          valueGetter: (data, t) => getCommercialCctv(data, t),
          tooltip: 'tags.cctv.label',
        },
        {
          icon: <StyledSvgIcon component={PyrasfaleiaSvg} />,
          valueGetter: (data, t) => getFireSafety(data, t),
          tooltip: 'tags.fireSafety.label',
        },
        {
          icon: <StyledSvgIcon component={DimensionsPortaSvg} />,
          valueGetter: (data, t) => getIndustrialDoorDimensions(data, t),
          tooltip: 'tags.industrialDoorDimensions.label',
        },
        {
          icon: <StyledSvgIcon component={AsfaleiaSvg} />,
          valueGetter: (data, t) => getNumberOfEntrances(data, t),
          tooltip: 'tags.numberEntrances.label',
        },
        {
          icon: <StyledSvgIcon component={RampaSvg} />,
          valueGetter: (data, t) => getCargoRamp(data, t),
          tooltip: 'tags.cargoRamp.label',
        },
        {
          icon: <StyledSvgIcon component={EswterikhSkalaSvg} />,
          valueGetter: (data, t) => getInternalStairs(data, t),
          tooltip: 'tags.innerLadder.label',
        },
        {
          icon: <StyledSvgIcon component={PerifrakshSvg} />,
          valueGetter: (data, t) => getEnclosure(data, t),
          tooltip: 'tags.enclosed.label',
        },
        {
          icon: <StyledSvgIcon component={ProsopshSvg} />,
          valueGetter: (data, t) => getCommercialFacade(data, t),
          tooltip: 'tags.facade.label',
        },
        {
          icon: <StyledSvgIcon component={DynatothtaEpektashsSvg} />,
          valueGetter: (data, t) => getExpandability(data, t),
          tooltip: 'tags.expandable.label',
        },
        {
          icon: <StyledSvgIcon component={OfficesSvg} />,
          valueGetter: (data, t) => getOffices(data, t),
          tooltip: 'tags.offices.label',
        },
        {
          icon: <StyledSvgIcon component={KitchenSvg} />,
          valueGetter: (data, t) => getKitchen(data, t),
          tooltip: 'tags.coozine.label',
        },
        {
          icon: <StyledSvgIcon component={DistanceSvg} />,
          valueGetter: (data, t) => getDistance(data, t),
          tooltip: 'tags.distance.label',
        },
        {
          icon: <StyledSvgIcon component={ApoxeteyshSvg} />,
          valueGetter: (data, t) => getCommercialDrains(data, t),
          tooltip: 'tags.drains.label',
        },
      ],
    },
    hotel: {
      detail: [
        {
          icon: <StyledSvgIcon component={EtosSVG} />,
          valueGetter: (data) => data?.constructionYear,
          tooltip: 'tags.constructionYear.label',
        },
        {
          icon: (
            <StyledSvgIcon
              component={BedroomSVG}
              sx={{ width: 25, height: 'auto' }}
            />
          ),
          valueGetter: (data, t) => getRooms(data, t),
          tooltip: 'tags.rooms.label',
        },
        {
          icon: <StyledSvgIcon component={EnergeiakhKlashSvg} />,
          valueGetter: (data, t) => getEnergyClass(data, t),
          tooltip: 'tags.energyClass.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={RenovatedSvg} />,
          valueGetter: (data, t) => getRenovationYear(data, t),
          tooltip: 'tags.renovated.label',
        },
        {
          icon: <StyledSvgIcon component={HeatSvg} />,
          valueGetter: (data, t) => parseHeat(data, t),
          tooltip: 'tags.heat.label',
        },
        {
          icon: <StyledSvgIcon component={FloorSVG} />,
          valueGetter: (data, t) => parseFloors(data, t),
          tooltip: 'tags.floor.label',
        },
        {
          icon: <StyledSvgIcon component={BalconySvg} />,
          valueGetter: (data, t) => getBalconyTents(data, t),
          tooltip: 'tags.balconyAwning.label',
        },
        {
          icon: <StyledSvgIcon component={ParkingSvg} />,
          valueGetter: (data, t) => getParking(data, t),
          tooltip: 'tags.parking.label',
        },
        {
          icon: <StyledSvgIcon component={ElevatorSvg} />,
          valueGetter: (data, t) => getCommercialElevator(data, t),
          tooltip: 'tags.elevator.label',
        },
        {
          icon: <StyledSvgIcon component={GardenSvg} />,
          valueGetter: (data, t) => getGarden(data, t),
          tooltip: 'tags.garden.label',
        },
        {
          icon: <StyledSvgIcon component={AsfaleiaSvg} />,
          valueGetter: (data, t) => parseSafety(data, t),
          tooltip: 'tags.safety.label',
        },
        {
          icon: <StyledSvgIcon component={TheaSvg} />,
          valueGetter: (data, t) => getView(data, t),
          tooltip: 'tags.view.label',
        },
        {
          icon: <StyledSvgIcon component={OikopedoSvg} />,
          valueGetter: (data, t) => getPlot(data, t),
          tooltip: 'tags.plot.label',
        },
        {
          icon: <StyledSvgIcon component={EksoplismenoSvg} />,
          valueGetter: (data, t) => getEquipment(data, t),
          tooltip: 'tags.equipment.label',
        },
        {
          icon: <StyledSvgIcon component={AcSvg} />,
          valueGetter: (data, t) => getCommercialAirCondition(data, t),
          tooltip: 'tags.airCondition.label',
        },
      ],
      more: [
        {
          icon: <StyledSvgIcon component={EidosReymatosSvg} />,
          valueGetter: (data, t) => getElectricityType(data, t),
          tooltip: 'tags.electricityType.label',
        },
        {
          icon: <StyledSvgIcon component={DomhmenhKalwdiwshSvg} />,
          valueGetter: (data, t) => getStructuredWiring(data, t),
          tooltip: 'tags.cableManagement.label',
        },
        {
          icon: <StyledSvgIcon component={ProsvashSvg} />,
          valueGetter: (data, t) => getAccess(data, t),
          tooltip: 'tags.access.label',
        },
        {
          icon: <StyledSvgIcon component={DynatothtaEpektashsSvg} />,
          valueGetter: (data, t) => getExpandability(data, t),
          tooltip: 'tags.expandable.label',
        },
        {
          icon: <StyledSvgIcon component={DapedoSvg} sx={{ height: 40 }} />,
          valueGetter: (data, t) => getFloorType(data, t),
          tooltip: 'tags.floorType.label',
        },
        {
          icon: <StyledSvgIcon component={XwroiSvg} />,
          valueGetter: (data, t) => getSpaces(data, t),
          tooltip: 'tags.spaces.label',
        },
        {
          icon: <StyledSvgIcon component={PyrasfaleiaSvg} />,
          valueGetter: (data, t) => getFireSafety(data, t),
          tooltip: 'tags.fireSafety.label',
        },
        {
          icon: <StyledSvgIcon component={WaterHeatingSvg} />,
          valueGetter: (data, t) => getCommercialWaterHeating(data, t),
          tooltip: 'tags.waterHeater.label',
        },
        {
          icon: <StyledSvgIcon component={ProsopshSvg} />,
          valueGetter: (data, t) => getCommercialFacade(data, t),
          tooltip: 'tags.facade.label',
        },
        {
          icon: <StyledSvgIcon component={GwniakoSvg} />,
          valueGetter: (data, t) => getCommercialCorner(data, t),
          tooltip: 'tags.corner.label',
        },
        {
          icon: <StyledSvgIcon component={ProsanatolismosSvg} />,
          valueGetter: (data, t) => getDirection(data, t),
          tooltip: 'tags.orientation.label',
        },
        {
          icon: <StyledSvgIcon component={AmphitheatricalSvg} />,
          valueGetter: (data, t) => getCommercialAmphitheatrical(data, t),
          tooltip: 'tags.amphitheatrical.label',
        },
        {
          icon: <StyledSvgIcon component={EndodapediaThermanshSvg} />,
          valueGetter: (data, t) => getEstateUnderfloorHeat(data, t),
          tooltip: 'tags.underfloorHeat.label',
        },
        {
          icon: <StyledSvgIcon component={ApoxeteyshSvg} />,
          valueGetter: (data, t) => getCommercialDrains(data, t),
          tooltip: 'tags.drains.label',
        },
        {
          icon: <StyledSvgIcon component={AmeaSvg} />,
          valueGetter: (data, t) => getCommercialDisabledAccess(data, t),
          tooltip: 'tags.amea.label',
        },
        {
          icon: <StyledSvgIcon component={DiathrhteoSvg} />,
          valueGetter: (data, t) => getCommercialMaintained(data, t),
          tooltip: 'tags.legacy.label',
        },
        {
          icon: <StyledSvgIcon component={ElevatorStuffSvg} />,
          valueGetter: (data, t) => getCargoElevator(data, t),
          tooltip: 'tags.cargoElevator.label',
        },
        {
          icon: <StyledSvgIcon component={RampaSvg} />,
          valueGetter: (data, t) => getCargoRamp(data, t),
          tooltip: 'tags.cargoRamp.label',
        },
        {
          icon: <StyledSvgIcon component={AuxiliarySpacesSvg} />,
          valueGetter: (data, t) => getAuxiliarySpaces(data, t),
          tooltip: 'tags.additionalRooms.label',
        },
        {
          icon: <StyledSvgIcon component={FacilitiesSvg} />,
          valueGetter: (data) => '-',
          tooltip: 'tags.facilities.label',
        },
        {
          icon: (
            <StyledSvgIcon component={YgeionomikouSvg} sx={{ width: 40 }} />
          ),
          valueGetter: (data, t) => getFoodService(data, t),
          tooltip: 'tags.foodInterest.label',
        },
        {
          icon: <StyledSvgIcon component={RestaurantSvg} />,
          valueGetter: (data, t) => getRestaurants(data, t),
          tooltip: 'tags.diner.label',
        },
        {
          icon: <StyledSvgIcon component={AssemblyHallSvg} />,
          valueGetter: (data, t) => getCommercialEventSpace(data, t),
          tooltip: 'tags.eventHall.label',
        },
        {
          icon: <StyledSvgIcon component={GymSvg} />,
          valueGetter: (data, t) => getCommercialGym(data, t),
          tooltip: 'tags.gym.label',
        },
        {
          icon: <StyledSvgIcon component={PisinaSvg} />,
          valueGetter: (data, t) => getPool(data, t),
          tooltip: 'tags.pool.label',
        },
        {
          icon: <StyledSvgIcon component={SpaSvg} />,
          valueGetter: (data, t) => getSpa(data, t),
          tooltip: 'tags.spa.label',
        },
        {
          icon: <StyledSvgIcon component={RoofGardenSvg} />,
          valueGetter: (data, t) => getRoofGarden(data, t),
          tooltip: 'tags.roofGarden.label',
        },
      ],
    },
    // industrial_area: [],
    // craft_space: [],
    // warehouse: [],
    // room: [],
    // rest_commercial_types: [],
  },
  land: {
    plot: {
      detail: [
        {
          icon: <StyledSvgIcon component={WithinPlanSvg} />,
          valueGetter: (data, t) => getCityPlan(data, t),
          tooltip: 'tags.withinPlan.label',
        },
        {
          icon: <StyledSvgIcon component={SyntelesthsDomhshsSvg} />,
          valueGetter: (data, t) => getBuildFactor(data, t),
          tooltip: 'tags.buildFactor.label',
        },
        {
          icon: <StyledSvgIcon component={PricePerSqSvg} />,
          valueGetter: (data, t) => getSalesPricePerBuildableSqrMeter(data, t),
          tooltip: 'tags.pricePerBuildMeter.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={CommercialUseSvg} />,
          valueGetter: (data, t) => getUsage(data, t),
          tooltip: 'tags.use.label',
        },
        {
          icon: <StyledSvgIcon component={MeKtismaLandSvg} />,
          valueGetter: (data, t) => getWithBuilding(data, t),
          tooltip: 'tags.withBuilding.label',
        },
        {
          icon: <StyledSvgIcon component={CoverageFactorSvg} />,
          valueGetter: (data, t) => getCoverageFactor(data, t),
          tooltip: 'tags.coverageFactor.label',
        },
        {
          icon: <StyledSvgIcon component={AntiparoxhSvg} />,
          valueGetter: (data, t) => getCompensation(data, t),
          tooltip: 'tags.compensation.label',
        },
        {
          icon: <StyledSvgIcon component={EpitrepomenoYpsosSvg} />,
          valueGetter: (data, t) => getMaxAllowedHeight(data, t),
          tooltip: 'tags.allowedHeight.label',
        },
        {
          icon: <StyledSvgIcon component={DimensionsSvg} />,
          valueGetter: (data, t) => getDimensions(data, t),
          tooltip: 'tags.dimensions.label',
        },
        {
          icon: <StyledSvgIcon component={ProsopshSvg} />,
          valueGetter: (data, t) => getLandFacade(data, t),
          tooltip: 'tags.facade.label',
        },
        {
          icon: <StyledSvgIcon component={AmphitheatricalSvg} />,
          valueGetter: (data, t) => getLandAmphitheatrical(data, t),
          tooltip: 'tags.amphitheatrical.label',
        },
        {
          icon: <StyledSvgIcon component={TheaSvg} />,
          valueGetter: (data, t) => getView(data, t),
          tooltip: 'tags.view.label',
        },
        {
          icon: <StyledSvgIcon component={ProsvashSvg} />,
          valueGetter: (data, t) => getAccess(data, t),
          tooltip: 'tags.access.label',
        },
        {
          icon: <StyledSvgIcon component={DistanceSvg} />,
          valueGetter: (data, t) => getDistance(data, t),
          tooltip: 'tags.distance.label',
        },
        {
          icon: <StyledSvgIcon component={AgriculturalUseSvg} />,
          valueGetter: (data, t) => getLandAgriculturalUse(data, t),
          tooltip: 'tags.agriculturalUse.label',
        },
      ],
      more: [
        {
          icon: <StyledSvgIcon component={OpseisSvg} />,
          valueGetter: (data, t) => getLandCornerViews(data, t),
          tooltip: 'tags.cornerWithViews.label',
        },
        {
          icon: <StyledSvgIcon component={TyposEdafousSvg} />,
          valueGetter: (data) => '-',
          tooltip: 'tags.groundType.label',
        }, // to be supported in the future
        {
          icon: <StyledSvgIcon component={KatastashSvg} />,
          valueGetter: (data, t) => getLandCondition(data, t),
          tooltip: 'tags.condition.label',
        },
        {
          icon: <StyledSvgIcon component={DasikhKalypshSvg} />,
          valueGetter: (data, t) => getForestCover(data, t),
          tooltip: 'tags.forested.label',
        },
        {
          icon: <StyledSvgIcon component={YdreyshSvg} />,
          valueGetter: (data, t) => getWaterSupply(data, t),
          tooltip: 'tags.waterSupply.label',
        },
        {
          icon: <StyledSvgIcon component={HlektrodothshSvg} />,
          valueGetter: (data, t) => getΕlectrification(data, t),
          tooltip: 'tags.electrification.label',
        },
        {
          icon: <StyledSvgIcon component={PerifrakshSvg} />,
          valueGetter: (data, t) => getEnclosure(data, t),
          tooltip: 'tags.enclosed.label',
        },
        {
          icon: <StyledSvgIcon component={ApoxeteyshSvg} />,
          valueGetter: (data, t) => getLandDrains(data, t),
          tooltip: 'tags.drains.label',
        },
      ],
    },
    island: {
      detail: [
        {
          icon: <StyledSvgIcon component={AdeiaSvg} />,
          valueGetter: (data, t) => getLandBuildable(data, t),
          tooltip: 'tags.adeia.label',
        },
        {
          icon: <StyledSvgIcon component={SyntelesthsDomhshsSvg} />,
          valueGetter: (data, t) => getBuildFactor(data, t),
          tooltip: 'tags.buildFactor.label',
        },
        {
          icon: <StyledSvgIcon component={MarinaSvg} />,
          valueGetter: (data, t) => getMarina(data, t),
          tooltip: 'tags.marine.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={CommercialUseSvg} />,
          valueGetter: (data, t) => getUsage(data, t),
          tooltip: 'tags.use.label',
        },
        {
          icon: <StyledSvgIcon component={MeKtismaLandSvg} />,
          valueGetter: (data, t) => getWithBuilding(data, t),
          tooltip: 'tags.withBuilding.label',
        },
        {
          icon: <StyledSvgIcon component={WithinPlanSvg} />,
          valueGetter: (data, t) => getCityPlan(data, t),
          tooltip: 'tags.withinPlan.label',
        },
        {
          icon: <StyledSvgIcon component={CoverageFactorSvg} />,
          valueGetter: (data, t) => getCoverageFactor(data, t),
          tooltip: 'tags.coverageFactor.label',
        },
        {
          icon: <StyledSvgIcon component={PricePerSqSvg} />,
          valueGetter: (data, t) => getSalesPricePerBuildableSqrMeter(data, t),
          tooltip: 'tags.pricePerBuildMeter.label',
        },
        {
          icon: <StyledSvgIcon component={AntiparoxhSvg} />,
          valueGetter: (data, t) => getCompensation(data, t),
          tooltip: 'tags.compensation.label',
        },
        {
          icon: <StyledSvgIcon component={EpitrepomenoYpsosSvg} />,
          valueGetter: (data, t) => getMaxAllowedHeight(data, t),
          tooltip: 'tags.allowedHeight.label',
        },
        {
          icon: <StyledSvgIcon component={DimensionsSvg} />,
          valueGetter: (data, t) => getDimensions(data, t),
          tooltip: 'tags.dimensions.label',
        },
        {
          icon: <StyledSvgIcon component={AmphitheatricalSvg} />,
          valueGetter: (data, t) => getLandAmphitheatrical(data, t),
          tooltip: 'tags.amphitheatrical.label',
        },
        {
          icon: <StyledSvgIcon component={TheaSvg} />,
          valueGetter: (data, t) => getView(data, t),
          tooltip: 'tags.view.label',
        },
        {
          icon: <StyledSvgIcon component={DistanceSvg} />,
          valueGetter: (data, t) => getDistance(data, t),
          tooltip: 'tags.distance.label',
        },
        {
          icon: <StyledSvgIcon component={AgriculturalUseSvg} />,
          valueGetter: (data, t) => getLandAgriculturalUse(data, t),
          tooltip: 'tags.agriculturalUse.label',
        },
      ],
      more: [
        {
          icon: <StyledSvgIcon component={OpseisSvg} />,
          valueGetter: (data, t) => getLandCornerViews(data, t),
          tooltip: 'tags.cornerWithViews.label',
        },
        {
          icon: <StyledSvgIcon component={TyposEdafousSvg} />,
          valueGetter: (data) => '-',
          tooltip: 'tags.groundType.label',
        }, // to be supported in the future
        {
          icon: <StyledSvgIcon component={KatastashSvg} />,
          valueGetter: (data, t) => getLandCondition(data, t),
          tooltip: 'tags.condition.label',
        },
        {
          icon: <StyledSvgIcon component={DasikhKalypshSvg} />,
          valueGetter: (data, t) => getForestCover(data, t),
          tooltip: 'tags.forested.label',
        },
        {
          icon: <StyledSvgIcon component={YdreyshSvg} />,
          valueGetter: (data, t) => getWaterSupply(data, t),
          tooltip: 'tags.waterSupply.label',
        },
        {
          icon: <StyledSvgIcon component={HlektrodothshSvg} />,
          valueGetter: (data, t) => getΕlectrification(data, t),
          tooltip: 'tags.electrification.label',
        },
        {
          icon: <StyledSvgIcon component={PerifrakshSvg} />,
          valueGetter: (data, t) => getEnclosure(data, t),
          tooltip: 'tags.enclosed.label',
        },
        {
          icon: <StyledSvgIcon component={ApoxeteyshSvg} />,
          valueGetter: (data, t) => getLandDrains(data, t),
          tooltip: 'tags.drains.label',
        },
      ],
    },
    plot_of_land: {
      detail: [
        {
          icon: <StyledSvgIcon component={WithinPlanSvg} />,
          valueGetter: (data, t) => getCityPlan(data, t),
          tooltip: 'tags.withinPlan.label',
        },
        {
          icon: <StyledSvgIcon component={SyntelesthsDomhshsSvg} />,
          valueGetter: (data, t) => getBuildFactor(data, t),
          tooltip: 'tags.buildFactor.label',
        },
        {
          icon: <StyledSvgIcon component={ProsvashSvg} />,
          valueGetter: (data, t) => getAccess(data, t),
          tooltip: 'tags.access.label',
        },
      ],
      info: [
        {
          icon: <StyledSvgIcon component={DieshSvg} />,
          valueGetter: (data) => getPropertyAdCode(data),
          tooltip: 'tags.estateCode.label',
        },
        {
          icon: <StyledSvgIcon component={CommercialUseSvg} />,
          valueGetter: (data, t) => getUsage(data, t),
          tooltip: 'tags.use.label',
        },
        {
          icon: <StyledSvgIcon component={MeKtismaLandSvg} />,
          valueGetter: (data, t) => getWithBuilding(data, t),
          tooltip: 'tags.withBuilding.label',
        },
        {
          icon: <StyledSvgIcon component={CoverageFactorSvg} />,
          valueGetter: (data, t) => getCoverageFactor(data, t),
          tooltip: 'tags.coverageFactor.label',
        },
        {
          icon: <StyledSvgIcon component={PricePerSqSvg} />,
          valueGetter: (data, t) => getSalesPricePerBuildableSqrMeter(data, t),
          tooltip: 'tags.pricePerBuildMeter.label',
        },
        {
          icon: <StyledSvgIcon component={AntiparoxhSvg} />,
          valueGetter: (data, t) => getCompensation(data, t),
          tooltip: 'tags.compensation.label',
        },
        {
          icon: <StyledSvgIcon component={EpitrepomenoYpsosSvg} />,
          valueGetter: (data, t) => getMaxAllowedHeight(data, t),
          tooltip: 'tags.allowedHeight.label',
        },
        {
          icon: <StyledSvgIcon component={DimensionsSvg} />,
          valueGetter: (data, t) => getDimensions(data, t),
          tooltip: 'tags.dimensions.label',
        },
        {
          icon: <StyledSvgIcon component={ProsopshSvg} />,
          valueGetter: (data, t) => getLandFacade(data, t),
          tooltip: 'tags.facade.label',
        },
        {
          icon: <StyledSvgIcon component={AmphitheatricalSvg} />,
          valueGetter: (data, t) => getLandAmphitheatrical(data, t),
          tooltip: 'tags.amphitheatrical.label',
        },
        {
          icon: <StyledSvgIcon component={TheaSvg} />,
          valueGetter: (data, t) => getView(data, t),
          tooltip: 'tags.view.label',
        },
        {
          icon: <StyledSvgIcon component={DistanceSvg} />,
          valueGetter: (data, t) => getDistance(data, t),
          tooltip: 'tags.distance.label',
        },
        {
          icon: <StyledSvgIcon component={AgriculturalUseSvg} />,
          valueGetter: (data, t) => getLandAgriculturalUse(data, t),
          tooltip: 'tags.agriculturalUse.label',
        },
      ],
      more: [
        {
          icon: <StyledSvgIcon component={OpseisSvg} />,
          valueGetter: (data, t) => getLandCornerViews(data, t),
          tooltip: 'tags.cornerWithViews.label',
        },
        {
          icon: <StyledSvgIcon component={TyposEdafousSvg} />,
          valueGetter: (data) => '-',
          tooltip: 'tags.groundType.label',
        }, // to be supported in the future
        {
          icon: <StyledSvgIcon component={KatastashSvg} />,
          valueGetter: (data, t) => getLandCondition(data, t),
          tooltip: 'tags.condition.label',
        },
        {
          icon: <StyledSvgIcon component={DasikhKalypshSvg} />,
          valueGetter: (data, t) => getForestCover(data, t),
          tooltip: 'tags.forested.label',
        },
        {
          icon: <StyledSvgIcon component={YdreyshSvg} />,
          valueGetter: (data, t) => getWaterSupply(data, t),
          tooltip: 'tags.waterSupply.label',
        },
        {
          icon: <StyledSvgIcon component={HlektrodothshSvg} />,
          valueGetter: (data, t) => getΕlectrification(data, t),
          tooltip: 'tags.electrification.label',
        },
        {
          icon: <StyledSvgIcon component={PerifrakshSvg} />,
          valueGetter: (data, t) => getEnclosure(data, t),
          tooltip: 'tags.enclosed.label',
        },
        {
          icon: <StyledSvgIcon component={ApoxeteyshSvg} />,
          valueGetter: (data, t) => getLandDrains(data, t),
          tooltip: 'tags.drains.label',
        },
      ],
    },
  },
} as const
