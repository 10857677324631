/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import FullBrokenImageSvg from 'assets/svg/loader/full.svg'

interface Props {
  src: string
}

function ImageLoader(props: Props) {
  const { src } = props
  const [loaded, setLoaded] = React.useState(false)
  const [error, setError] = React.useState(false)
  const ref = React.useRef<HTMLImageElement>()

  React.useEffect(() => {
    setLoaded(false)
    setError(false)
  }, [src])

  React.useEffect(() => {
    if (!error && !loaded) {
      setLoaded(true)
    }
  }, [loaded, error])

  return (
    <>
      {loaded ? (
        <Box
          ref={ref}
          component={'img'}
          src={error ? FullBrokenImageSvg : src}
          alt=" "
          draggable="false"
          loading="lazy"
          sx={{
            display: loaded ? 'block' : 'none',
            maxHeight: 200,
            height: '100%',
            width: '100%',
            borderRadius: 10,
            objectFit: error ? 'contain' : 'cover',
            position: 'relative',
            textAlign: 'center',
            whiteSpace: 'pre-line',
            backgroundColor: error ? '#A9A9A9' : 'transparent',
          }}
          onLoad={() => setLoaded(true)}
          onError={() => {
            setError(true)
          }}
        />
      ) : null}
      {!loaded ? (
        <Box
          sx={{
            maxHeight: 200,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            disableShrink
            size={40}
            sx={{ display: 'inline-block' }}
          />
        </Box>
      ) : null}
    </>
  )
}

export default ImageLoader
