/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { styled } from '@mui/material/styles'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  DialogProps,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { ContactAgentDTO } from 'api/services/ContactService/types'
import { EMAIL_REGEX } from 'helpers/constants'
import { ContactService } from 'api/services/ContactService'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import PropertyAdAnalyticsService from 'api/services/PropertyAdAnalyticsService'

const DialogEmailDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

const DialogEmailTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'common.black',
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const defaultValues = {
  fullName: '',
  senderEmail: '',
  subject: '',
  receiverEmail: '',
  text: '',
  phoneNumber: '',
}

export interface Props {
  open: boolean
  onClose: () => void
  email: string | null | undefined
  externalId?: string | null
  propertyAd: ParsedProperty
}

export default function ModalEmailForm(props: Props) {
  const { t } = useTranslation()

  const { open, onClose, propertyAd, email, externalId } = props

  const sendAnalytic = PropertyAdAnalyticsService.hooks.useSendAnalytic()

  const { enqueueSnackbar } = useSnackbar()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const createGoogleToken = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }
    return await executeRecaptcha()
  }, [executeRecaptcha])

  const { handleSubmit, control, reset } = useForm<ContactAgentDTO>({
    defaultValues,
  })

  React.useEffect(() => {
    reset()
  }, [])

  const contactAgentMutation = ContactService.hooks.useContactAgent({
    onSuccess: () => {
      reset(defaultValues)
      enqueueSnackbar(t('contactPage.successMessage'), {
        variant: 'success',
      })
    },
    onError: () => {
      enqueueSnackbar(t('error.unspecific'), {
        variant: 'error',
      })
    },
  })

  const getInterestType = (adCategoryId: number) => {
    const BUY = [1, 2, 3]
    const IS_BUY = BUY.includes(adCategoryId)
    if (IS_BUY) return t('detailPage.interest.buy')
    else return t('detailPage.interest.loan')
  }

  const onSubmit: SubmitHandler<ContactAgentDTO> = async (data) => {
    const token = await createGoogleToken()

    if (email && token && externalId) {
      if (propertyAd.id) {
        sendAnalytic.mutate({ id: propertyAd.id, type: 'EMAIL' })
      }

      contactAgentMutation.mutate({
        ...data,
        propertyAdLink: window.location.href,
        receiverEmail: email,
        token: token,
        propertyAdTitle: document?.title.trim().split('προς')[0] ?? '',
        propertyAdExternalId: externalId,
        locationSearch: propertyAd?.searchArea ?? '',
        price: t('searchPage.salesPrice', {
          price: propertyAd?.salesPrice,
        }),
        interest:
          propertyAd?.adCategoryId && getInterestType(propertyAd?.adCategoryId)
            ? getInterestType(propertyAd.adCategoryId)
            : '',
        photoUrl:
          propertyAd?.photosURLS && propertyAd?.photosURLS[0].filePath
            ? propertyAd?.photosURLS[0]?.filePath
            : '',
      })
    }
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <DialogEmailDialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogEmailTitle onClose={onClose}>
          {t('detailPage.emailDialog.title')}
        </DialogEmailTitle>
        <DialogContent dividers>
          <Grid container spacing={4}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: 'black',
                  }}
                >
                  {`${t('detailPage.emailDialog.fullname')}*`}
                </Typography>
                <Controller
                  name="fullName"
                  rules={{
                    required: t('validation.fieldRequired'),
                  }}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: 'black',
                  }}
                >
                  {`${t('detailPage.emailDialog.email')}*`}
                </Typography>
                <Controller
                  rules={{
                    required: t('validation.fieldRequired'),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('detailPage.emailDialog.emailWrongFormat'),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                  name="senderEmail"
                  control={control}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: 'black',
                  }}
                >
                  {`${t('detailPage.emailDialog.telephone')}`}
                </Typography>
                <Controller
                  rules={{
                    required: false,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                  name="phoneNumber"
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: 'black',
                  }}
                >
                  {`${t('detailPage.emailDialog.subject')}*`}
                </Typography>
                <Controller
                  rules={{
                    required: t('validation.fieldRequired'),
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                  name="subject"
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: 'black',
                }}
              >
                {`${t('detailPage.emailDialog.message')}*`}
              </Typography>
              <Controller
                rules={{
                  required: t('validation.fieldRequired'),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    error={!!error}
                    helperText={error?.message}
                    size="small"
                    {...field}
                  />
                )}
                name="text"
                control={control}
              />
              {/* </Grid> */}
            </Grid>
            <Grid item>
              <Typography variant="caption" sx={{ color: 'lightgrey' }}>
                This site is protected by reCAPTCHA and the Google Privacy
                Policy and Terms of Service apply.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{ color: 'action.disabled', textTransform: 'none' }}
          >
            {t('detailPage.emailDialog.cancelButton')}
          </Button>
          <Button
            type="submit"
            sx={{
              textTransform: 'none',
              pointerEvenets: contactAgentMutation.isLoading
                ? 'none'
                : 'default',
            }}
          >
            {contactAgentMutation.isLoading ? (
              <>
                {' '}
                <CircularProgress size={16} />
              </>
            ) : (
              <> {t('detailPage.emailDialog.submitButton')} </>
            )}
          </Button>
        </DialogActions>
      </form>
    </DialogEmailDialog>
  )
}
