import React from 'react'
import AnalyticsService from 'services/AnalyticsService'

interface Props {
  title?: string
  runOnMount: boolean
}

interface Return {
  sendPageView: (...args: Parameters<typeof AnalyticsService.pageView>) => void
}

export default function usePageView(
  props: Props = { runOnMount: true }
): Return {
  const { runOnMount } = props

  React.useEffect(() => {
    if (!runOnMount) return
    AnalyticsService.pageView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!runOnMount) return
    AnalyticsService.pageView()
  }, [runOnMount])

  const sendPageView = (
    ...payload: Parameters<typeof AnalyticsService.pageView>
  ) => {
    AnalyticsService.pageView(...payload)
  }

  return {
    sendPageView,
  }
}

export { usePageView }
