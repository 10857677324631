import { Alert, Collapse } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'

interface Props {
  isLoading: boolean
  isError: boolean
}

function LoadingCalcButton(props: Props) {
  const { isLoading, isError } = props
  const { t } = useTranslation()
  return (
    <>
      <LoadingButton
        variant="contained"
        type="submit"
        fullWidth
        loading={isLoading}
        sx={{ maxHeight: '50px' }}
      >
        {t('detailPage.calculator.buy.calculateButton')}
      </LoadingButton>
      <Collapse in={isError} sx={{ pt: 2, width: '100%' }}>
        <Alert severity="error">{t('error.unspecific')}</Alert>
      </Collapse>
    </>
  )
}

export default LoadingCalcButton
