import create, { SetState, GetState } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { searchSlice, ISearchSlice } from './SearchSlice'
import { recentSlice, IRecentSlice } from './RecentSlice'
import { routeSlice, IRouteSlice } from './RouteSlice'
import { calculatorSlice, CalculatorSliceStore } from './CalculatorSlice'
import { authSlice, AuthSliceStore } from './AuthSlice'
import {
  favoritesListsSlice,
  IFavoritesListsSlice,
} from './FavoritesListsSlice'

export type StoreSlice<T extends object, E extends object = T> = (
  set: SetState<E extends T ? E : E & T>,
  get: GetState<E extends T ? E : E & T>
) => T

export const useRouteStore = create(
  devtools(
    (set: SetState<IRouteSlice>, get: GetState<IRouteSlice>) => ({
      ...routeSlice(set, get),
    }),
    { name: 'route' }
  )
)

export const useCalculatorStore = create(
  devtools(
    (
      set: SetState<CalculatorSliceStore>,
      get: GetState<CalculatorSliceStore>
    ) => ({
      ...calculatorSlice(set, get),
    }),
    { name: 'calculator' }
  )
)

export const useSearchStore = create(
  devtools(
    (set: SetState<ISearchSlice>, get: GetState<ISearchSlice>) => ({
      ...searchSlice(set, get),
    }),
    { name: 'search' }
  )
)
export const useAuthStore = create(
  devtools(
    persist(
      (set: SetState<AuthSliceStore>, get: GetState<AuthSliceStore>) => ({
        ...authSlice(set, get),
      }),
      {
        name: 'auth',
        getStorage: () => localStorage,
      }
    ),
    { name: 'auth' }
  )
)
export const useRecentStore = create(
  devtools(
    persist(
      (set: SetState<IRecentSlice>, get: GetState<IRecentSlice>) => ({
        ...recentSlice(set, get),
      }),
      {
        name: 'spourgiti-recent-list',
        getStorage: () => localStorage,
      }
    ),
    { name: 'recent' }
  )
)

export const useFavoritesListsStore = create(
  devtools(
    persist(
      (
        set: SetState<IFavoritesListsSlice>,
        get: GetState<IFavoritesListsSlice>
      ) => ({
        ...favoritesListsSlice(set, get),
      }),
      {
        name: 'spourgiti-favorites-lists',
        getStorage: () => localStorage,
      }
    ),
    { name: 'favorites-lists' }
  )
)
