/* eslint-disable @typescript-eslint/no-non-null-assertion */

const setRefreshToken = (value: string) =>
  localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN_SESSION_PATH, value)
const getRefreshToken = () =>
  localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_SESSION_PATH) ?? null
const removeRefreshToken = () =>
  localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_SESSION_PATH)

const setAccessToken = (value: string) =>
  localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN_SESSION_PATH, value)
const getAccessToken = () =>
  localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_SESSION_PATH) ?? null
const removeAccessToken = () =>
  localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN_SESSION_PATH)

const TokenService = {
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  setAccessToken,
  getAccessToken,
  removeAccessToken,
}

export default TokenService
