/* eslint-disable @typescript-eslint/no-explicit-any */
import { Buffer } from 'buffer'

export const encryptObject = function (payload: object) {
  const temp = JSON.stringify(payload)
  return Buffer.from(temp).toString('base64')
}

export const decryptObject = function (payload) {
  const unEncoded = payload
  const decodedRequestBodyString = Buffer.from(unEncoded, 'base64')
  const decodedObject = JSON.parse(decodedRequestBodyString.toString())
  return decodedObject
}
