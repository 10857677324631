import React from 'react'
import { Link, Typography } from '@mui/material'

import { EstateAgent } from 'types'

interface Props {
  data: EstateAgent
}

function Email(props: Props) {
  const emailDefault =
    props.data?.contactInfo?.findIndex(
      (item) => item.defaultContactInfo === true
    ) ?? 0

  if (emailDefault !== -1) {
    return (
      <>
        <Link
          href={`mailto:${props?.data?.contactInfo?.[emailDefault]?.email}`}
          color="inherit"
          underline="none"
        >
          <Typography sx={{ fontSize: { xs: 15, sm: 20 } }}>
            {props?.data?.contactInfo?.[emailDefault]?.email}
          </Typography>
        </Link>
      </>
    )
  } else {
    return (
      <>
        <Typography variant="h5">-</Typography>
      </>
    )
  }
}

export default Email
