import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as TermsSvg } from 'assets/svg/terms.svg'

import InnerLayout from 'layouts/InnerLayout'
import usePageView from 'hooks/usePageView'

const TermsPage: React.FC = () => {
  usePageView()
  return (
    <InnerLayout>
      <Box sx={{ my: 6, px: { xs: 0.5, sm: 2, md: 2, lg: 4, xl: 6 } }}>
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 4 }}>
          ΟΡΟΙ ΧΡΗΣΗΣ ΙΣΤΟΤΟΠΟΥ
        </Typography>
        <Typography paragraph>
          Η «Σπουργίτι Ηλεκτρονικές Υπηρεσίες ΙΚΕ» (εφεξής η «Εταιρεία») μέσω
          του δικτυακού ιστοτόπου της{' '}
          <a
            href={'https://www.spourgiti.com'}
            target="_blank"
            rel="noreferrer"
          >
            www.spourgiti.com
          </a>{' '}
          (εφεξής ο «Ιστότοπος») προσφέρει τις υπηρεσίες της υπό τους κάτωθι
          όρους στους επισκέπτες/χρήστες/αγγελιοδότες (εφεξής ο «Χρήστης» ή οι
          «Χρήστες»), τους οποίους καλούνται να διαβάσουν προσεκτικά και να
          προβούν σε επίσκεψη/χρήση των σελίδων/υπηρεσιών του, μόνο εφόσον τους
          αποδέχονται πλήρως.
        </Typography>
        <Typography paragraph>
          Οι «Χρήστες» έχουν την αποκλειστική ευθύνη πρόσβασης στις υπηρεσίες
          του «Ιστότοπου» και την τυχόν καταβολή τελών σε τρίτους φορείς (π.χ.
          παροχείς υπηρεσιών διαδικτύου, χρέωση χρόνου παραμονής σε αυτό).
          Επίσης, είναι αποκλειστικά υπεύθυνοι για τον προσωπικό εξοπλισμό τους
          με τα απαραίτητα τεχνολογικά μέσα που τους επιτρέπουν την πρόσβαση
          στις υπηρεσίες του.
        </Typography>
        <Typography paragraph>
          Ο «Ιστότοπος» διατηρεί το δικαίωμα να αλλάξει τους όρους χρήσης και
          των προσωπικών δεδομένων οποιαδήποτε στιγμή και χωρίς πρότερη
          ειδοποίηση, γι’ αυτό και ζητείται η ανάγνωση των όρων χρήσης σε κάθε
          επίσκεψη.
        </Typography>
        <Typography paragraph>
          Η χρήση του «Ιστότοπου» συνεπάγεται την αποδοχή του συνόλου των
          κατωτέρω όρων.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          Ι. Δεοντολογία χρήσης{' '}
        </Typography>
        <Typography paragraph>
          Είναι αμοιβαίως αποδεκτό και κατανοητό ότι όλες οι πληροφορίες, τα
          δεδομένα, τα κείμενα, τα γραφικά, οι φωτογραφίες, οι εικόνες, τα
          μουσικά αρχεία, τα βίντεο, τα μηνύματα και όλο το περιεχόμενο, είτε
          αναρτάται δημόσια είτε μεταφέρεται ιδιωτικά, παραμένει στην
          αποκλειστική ευθύνη του φυσικού ή νομικού προσώπου από το οποίο το
          περιεχόμενο πηγάζει. Αυτό σημαίνει ότι οι «Χρήστες» είναι αποκλειστικά
          υπεύθυνοι για το σύνολο του περιεχομένου που αναρτούν, δημοσιεύουν,
          αποστέλλουν, μεταφέρουν ή άλλως καθίσταται διαθέσιμο μέσω των
          υπηρεσιών του «Ιστότοπου». Ο «Ιστότοπος» δεν είναι δυνατό, λόγω του
          όγκου του, να ελέγχει το σύνολο του περιεχομένου που αναρτάται από
          τους «Χρήστες» στις υπηρεσίες του δικτυακού τόπου, οπότε δεν εγγυάται
          την ακρίβεια, την ακεραιότητα, τη νομιμότητα, ή την ποιότητα τέτοιου
          περιεχομένου. Σε καμία περίπτωση δεν μπορεί να θεωρηθεί υπεύθυνος ο
          «Ιστότοπος» για οποιοδήποτε λάθος ή παραλείψεις σε οποιοδήποτε
          περιεχόμενο ή για οποιαδήποτε βλάβη ή ζημία που τυχόν προκύψουν από τη
          χρήση οποιουδήποτε περιεχομένου που αναρτάται, αποστέλλεται,
          μεταφέρεται ή άλλως καθίσταται διαθέσιμο από τους «Χρήστες» του
          «Ιστότοπου». Οι «Χρήστες» συμφωνούν να μην κάνουν χρήση των υπηρεσιών
          του «Ιστοτόπου» και αποδέχονται ακέραιη και αποκλειστική την ευθύνη
          τους σχετικά με τα κάτωθι για:
        </Typography>
        <ol>
          <Typography component="li" paragraph>
            Ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για
            την εγκατάσταση περιεχομένου που είναι παράνομο, επιβλαβές,
            απειλητικό, προσβλητικό, επιζήμιο, δυσφημιστικό, χυδαίο, βίαιο,
            υβριστικό, ρατσιστικό ή άλλως αποδοκιμαστέο, παραβιάζει την
            προσωπικότητα και τα προσωπικά δεδομένα άλλων, οποιαδήποτε
            δικαιώματα πνευματικής ιδιοκτησίας οποιουδήποτε φορέα και προκαλεί
            συναισθήματα μίσους, ή/και οποιοδήποτε άλλο συνιστάμενο ποινικό
            αδίκημα και οποιαδήποτε άλλη μορφή ανεπιθύμητης προώθησης
            περιεχομένου, καθώς και περιεχομένου που περιέχει ψηφιακούς ιούς ή
            οποιοδήποτε άλλο ηλεκτρονικό κώδικα, αρχεία ή προγράμματα
            σχεδιασμένα να παρεμβληθούν, καταστρέψουν ή περιορίσουν τη
            λειτουργία οποιουδήποτε λογισμικού ή εξοπλισμού ηλεκτρονικών
            υπολογιστών ή τηλεπικοινωνιακού εξοπλισμού.
          </Typography>
          <Typography component="li" paragraph>
            Πρόκληση βλάβης σε ανηλίκους με οποιοδήποτε τρόπο.
          </Typography>
          <Typography component="li" paragraph>
            Μίμηση οποιουδήποτε νομικού ή φυσικού προσώπου ή ψευδή δήλωση για
            την ταυτότητα του χρήστη ή παραπλανητική δήλωση αναφορικά με τη
            σχέση ή/και συνεργασία του «Χρήστη» με κάποιο άλλο νομικό ή φυσικό
            πρόσωπο.
          </Typography>
          <Typography component="li" paragraph>
            Παραχάραξη ή άλλη αλλοίωση των αναγνωριστικών των «Χρηστών» με σκοπό
            την παραπλάνηση ως προς την προέλευση του περιεχομένου που
            μεταδίδεται μέσω των υπηρεσιών του «Ιστοτόπου».
          </Typography>
          <Typography component="li" paragraph>
            Ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για
            την εγκατάσταση περιεχομένου από άτομο που δεν έχει δικαίωμα να
            διαθέσει το συγκεκριμένο περιεχόμενο βάσει νόμου ή βάσει
            εμπιστευτικής σχέσης.
          </Typography>
          <Typography component="li" paragraph>
            Παρεμβολή στις υπηρεσίες ή διάσπαση των υπηρεσιών ή των servers ή
            των δικτύων που είναι συνδεδεμένα με τις υπηρεσίες του «Ιστοτόπου» ή
            παρακοή των προϋποθέσεων, διαδικασιών και κανόνων χρήσης των δικτύων
            αυτών.
          </Typography>
          <Typography component="li" paragraph>
            Παραβίαση, με ή χωρίς πρόθεση, οποιασδήποτε τοπικής, εθνικής,
            ευρωπαϊκής, διεθνούς νομοθεσίας ή/και οποιουδήποτε κανόνα που έχει
            νομοθετική ισχύ και αφορά ή/και καλύπτει οποιαδήποτε υπηρεσία του
            «Ιστοτόπου».
          </Typography>
          <Typography component="li" paragraph>
            Παρενόχληση με οποιονδήποτε τρόπο της ιδιωτικής ζωής και των
            ατομικών και κοινωνικών δικαιωμάτων άλλων «Χρηστών» (όπως η συλλογή
            ή/και αποθήκευση προσωπικών δεδομένων άλλων «Χρηστών»).
          </Typography>
        </ol>
        <Typography paragraph>
          Ο «Χρήστης» κατανοεί και αποδέχεται ότι ο «Ιστότοπος» δεν κάνει
          προκαταρκτικό έλεγχο του περιεχομένου, αλλά και ότι ο «Ιστότοπος» και
          οι αρμόδιοι συνεργάτες του διατηρούν το δικαίωμα (και όχι την
          υποχρέωση) της αποκλειστικής επιλογής άρνησης ανάρτησης/δημοσίευσης ή
          μετακίνησης ή διαγραφής οποιουδήποτε περιεχομένου διατίθεται μέσω των
          υπηρεσιών του. Επίσης, ο «Ιστότοπος» και οι αρμόδιοι συνεργάτες του
          διατηρούν το δικαίωμα να διαγράφουν χωρίς ειδοποίηση οποιοδήποτε
          περιεχόμενο παραβιάζει τους παρόντες όρους χρήσης.{' '}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          ΙΙ. Δικαιώματα πνευματικής ιδιοκτησίας
        </Typography>
        <Typography paragraph>
          Τα πνευματικά δικαιώματα του περιεχομένου και των υπηρεσιών του
          «Ιστότοπου», ήτοι ενδεικτικώς και όχι περιοριστικά
          συμπεριλαμβανομένων, των κειμένων, αγγελιών, φωτογραφιών, σχεδίων,
          εμπορικών και οικονομικών στοιχείων, προγραμμάτων, πάσης φύσεως
          αρχείων, σημάτων/λογοτύπων, μορφής (lay - out) του «Ιστότοπου»,
          προστατεύονται από τους ελληνικούς, κοινοτικούς και διεθνείς νόμους
          περί πνευματικής ιδιοκτησίας και ανήκουν αποκλειστικά στην «Εταιρεία»,
          διατίθενται δε στους χρήστες/επισκέπτες του αυστηρά για προσωπική
          χρήση. Η «Εταιρεία» πάντοτε στα πλαίσια της καλής πίστης και των
          οικείων συναλλακτικών ηθών καταβάλλει κάθε δυνατή προσπάθεια ώστε το
          περιεχόμενο και οι πληροφορίες που εμφανίζονται κάθε φορά στον
          «Ιστότοπο» να είναι κατά το δυνατόν ακριβή και αληθή, δεν φέρει όμως
          καμία ευθύνη για την αξιοπιστία ή πληρότητα τους.
        </Typography>
        <Typography paragraph>
          Περαιτέρω απαγορεύεται οποιαδήποτε αντιγραφή, διανομή, μεταφορά,
          μεταποίηση, μεταπώληση, δημιουργία παράγωγης εργασίας ή παραπλάνηση
          του κοινού σχετικά με τον πραγματικό παροχέα τους του περιεχομένου του
          «Ιστότοπου». Τυχόν αναπαραγωγή επανέκδοση, φόρτωση, ανακοίνωση,
          διάδοση ή μετάδοση ή οποιαδήποτε άλλη χρήση του περιεχομένου του
          «Ιστότοπου» με οποιονδήποτε τρόπο ή μέσο για εμπορικούς ή άλλους
          σκοπούς επιτρέπεται μόνο κατόπιν προηγούμενης γραπτής άδειας της
          «Εταιρείας» ή οιουδήποτε άλλου δικαιούχου των πνευματικών δικαιωμάτων.
          Σε διαφορετική περίπτωση οι ως άνω ενέργειες μπορεί να αποτελέσουν
          προσβολή των δικαιωμάτων πνευματικής /βιομηχανικής ιδιοκτησίας της
          «Εταιρείας», η οποία διατηρεί το δικαίωμα να διεκδικήσει πάσα
          προκληθείσα σε αυτή θετική και αποθετική ζημία σύμφωνα με τις
          διατάξεις της κείμενης νομοθεσίας.
        </Typography>
        <Typography paragraph>
          Ο «Χρήστης» αποδέχεται και αναγνωρίζει ότι η «Εταιρεία» έχει την
          δυνατότητα εμπορικής αξιοποίησης (είτε από την ίδια είτε από τρίτους)
          του συνόλου των στοιχείων που εμφανίζονται στον «Ιστότοπο».
        </Typography>
        <Typography paragraph>
          Ο «Χρήστης» δηλώνει υπεύθυνα ότι οι φωτογραφίες που καταχωρεί στον
          «Ιστότοπο» αποτελούν πνευματική του ιδιοκτησία και σε καμία περίπτωση
          δεν θίγουν ή/και είναι απαλλαγμένες από οποιοδήποτε δικαίωμα τρίτου. Η
          «Εταιρεία» ουδεμία ευθύνη έχει αναφορικά με το περιεχόμενο των ανωτέρω
          φωτογραφιών καθώς επίσης και για οιοδήποτε τυχόν ζήτημα ανακύψει με
          τις εν λόγω φωτογραφίες.
        </Typography>
        <Typography paragraph>
          Περαιτέρω ο «Χρήστης» αναλαμβάνει την υποχρέωση να καλύψει κάθε ζημία
          (συμπεριλαμβανομένων και τυχόν εξόδων, δικαστικών ή άλλων) που τυχόν
          θα υποστεί η «Εταιρεία» από αξιώσεις οιουδήποτε τρίτου, που συνδέονται
          καθ&apos; οιονδήποτε τρόπο με την καταχώρηση και προβολή των ανωτέρω
          φωτογραφιών.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          ΙΙΙ. Διακοπή χρήσης
        </Typography>
        <Typography paragraph>
          Ο «Χρήστης» αποδέχεται πλήρως και αναντίρρητα το αποκλειστικό δικαίωμα
          της «Εταιρείας» να διακόψει την χρήση του/των κωδικών πρόσβασης στις
          υπηρεσίες του «Ιστότοπου» και να διακόψει την διάθεση του περιεχομένου
          και των πληροφοριών του «Ιστότοπου» κάθε φορά που θεωρεί αυτή (η
          «Εταιρεία») ότι οι «Χρήστες» αυτοί έχουν παραβιάσει του παρόντες όρους
          ή την εμπορική πολιτική της «Εταιρείας».
        </Typography>
        <Typography paragraph>
          Συγκεκριμένα ο «Χρήστης» αποδέχεται πλήρως και αναντίρρητα ότι η
          «Εταιρεία» διατηρεί το δικαίωμα οποτεδήποτε κρίνει, κατά την απόλυτη
          διακριτική της ευχέρεια, να διακόψει την δημοσίευση και ανάρτησή του
          στον «Ιστότοπο».{' '}
        </Typography>
        <Typography paragraph>
          Ταυτόχρονα η «Εταιρεία» διατηρεί το δικαίωμα να μην αποδεχθεί ανάρτηση
          ή δημοσίευση οιοδήποτε περιεχομένου.{' '}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          IV. Ad-Block
        </Typography>
        <Typography paragraph>
          Απαγορεύεται ρητά στον «Χρήστη» η χρήση οιοδήποτε προγράμματος που
          έχει σκοπό τον αποκλεισμό διαφημίσεων.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          V. Βλάβη / Αποζημίωση
        </Typography>
        <Typography paragraph>
          Ο «Χρήστης» κατανοεί και αποδέχεται ότι διατηρεί την αποκλειστική
          ευθύνη να αποζημιώσει την «Εταιρεία» και τους συνεργάτες της για
          οποιαδήποτε νομική διαμάχη προκύψει μεταξύ αυτής και τρίτων φορέων
          λόγω του περιεχομένου που αυτή διαθέτει για ανάρτηση, δημοσίευση ή
          άλλη μεταφορά μέσω των υπηρεσιών του «Ιστότοπου».
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          VI. Λειτουργία «Ιστότοπου»
        </Typography>
        <Typography paragraph>
          Η «Εταιρεία» διατηρεί το αποκλειστικό δικαίωμα, το οποίο αποδέχονται
          οι «Χρήστες», να θέτει εκτός λειτουργίας τον «Ιστότοπο», προσωρινά ή
          μόνιμα, με ή χωρίς προειδοποίηση.{' '}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          VII. Υλικό που υποβάλλεται στον «Ιστότοπο»
        </Typography>
        <Typography paragraph>
          Υποβάλλοντας οποιοδήποτε υλικό στον «Ιστότοπο», ο «Χρήστης» παραχωρεί
          αυτόματα στην «Εταιρεία» το δικαίωμα και την άδεια η τελευταία να
          προβάλλει, δημοσιεύσει, μεταφράσει, αναπαραγάγει αυτό το υλικό ή μέρος
          του σε οποιοδήποτε μέρος του κόσμου, με οποιαδήποτε τεχνολογία.
          Ταυτόχρονα δε αναγνωρίζει ότι ο «Ιστότοπος» δεν έχει καμία υποχρέωση
          να προβάλλει ή δημοσιεύσει το υποβληθέν υλικό. Καθώς και πως ο
          «Ιστότοπος» διατηρεί το αποκλειστικό δικαίωμα, χωρίς προειδοποίηση ή
          αιτιολόγηση να διαγράψει ή και να αποκλείσει οποιαδήποτε αγγελία ή
          «Χρήστη» κρίνει.{' '}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          VIII. Ευθύνη από Διαφήμιση
        </Typography>
        <Typography paragraph>
          Η «Εταιρεία» ουδεμία ευθύνη φέρει αναφορικά με το περιεχόμενο των
          διαφημίσεων (οιασδήποτε κατηγορίας και μορφής) που αναρτώνται στον
          «Ιστότοπο», καθώς επίσης και με την επικοινωνία του «Χρήστη» με
          τρίτους που διαφημίζονται στον «Ιστότοπο». Περαιτέρω η «Εταιρεία» δεν
          ευθύνεται για οιοδήποτε ζήτημα ανακύψει από τυχόν σύναψη πάσης υφής
          εμπορικής συναλλαγής μεταξύ του «Χρήστη» και του τρίτου
          διαφημιζόμενου.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          ΙΧ. Στοιχεία Επικοινωνίας «Χρήστη»
        </Typography>
        <Typography paragraph>
          Ο «Χρήστης» υποχρεούται να παρέχει στον «Ιστότοπο» αληθή πλήρη και
          ισχύοντα προσωπικά του στοιχεία.
        </Typography>
        <Typography paragraph>
          Ο «Χρήστης» δημοσιεύοντας αγγελίες ή επικοινωνώντας με κάποιον άλλο
          χρήστη, αποδέχεται πως τα στοιχεία επικοινωνίας του δημοσιεύονται στον
          ιστότοπο.
        </Typography>
        <Typography paragraph>
          Η «Εταιρεία» δεν φέρει ουδεμία ευθύνη για τυχόν προωθητικές ενέργειες
          τρίτων που γίνονται μέσω αυτών των στοιχείων και πάραυτα τις θεωρεί
          αντίθετες με τους όρους χρήσης και την εμπορική πολιτική της.
        </Typography>
        <Typography paragraph>
          Ο «Χρήστης» που δημοσιεύει τα στοιχεία επικοινωνίας του μέσω του
          «Ιστότοπου» αποδέχεται πως η «Εταιρεία» δεν ευθύνεται για τυχόν
          συλλογή τους από τρίτους.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          Χ. Εμπορική Χρήση Στοιχείων Επικοινωνίας Χρήστη
        </Typography>
        <Typography paragraph>
          Απαγορεύεται ρητά η χρήση ή η συλλογή των στοιχείων επικοινωνίας που
          προβάλλονται στον «Ιστότοπο» από οιοδήποτε φυσικό ή νομικό πρόσωπο για
          την προώθηση υπηρεσιών ή αγαθών. Η «Εταιρεία» δεν φέρει ουδεμία ευθύνη
          για τη χρήση των στοιχείων επικοινωνίας του «Χρήστη» από οιοδήποτε
          τρίτο.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          ΧΙ. Περιορισμός Ευθύνης
        </Typography>
        <Typography paragraph>
          Η «Εταιρεία» σε καμία περίπτωση, συμπεριλαμβανομένης και αυτής της
          αμέλειας, δεν ευθύνεται για λάθη, παραλείψεις, ή οποιεσδήποτε
          ελλείψεις αφορούν στις πληροφορίες, για καθυστερήσεις ή διακοπές ή
          αδυναμία μεταδόσεων των πληροφοριών ή για οποιασδήποτε ζημιά προκληθεί
          στον «Χρήστη» λόγω της χρήσεως αυτών τον πληροφοριών.
        </Typography>
        <Typography paragraph>
          Το περιεχόμενο του «Ιστότοπου» δεν συνιστά οικονομική, νομική ή άλλη
          συμβουλή, παρότρυνση ή προτροπή για τη διενέργεια οποιασδήποτε
          επενδυτικής ή άλλης πράξεως και η «Εταιρεία» ουδεμία ευθύνη φέρει για
          τη χρήση του «Ιστότοπου» από τους «Χρήστες», οι οποίοι ενεργούν με
          βάση την ιδιωτική τους βούληση.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          ΧΙΙ. Εργαλεία Υπολογισμού Εξόδων Αγοράς
        </Typography>
        <Typography paragraph>
          Ο «Ιστότοπος» για την καλύτερη εμπειρία των «Χρηστών» διατηρεί
          εργαλεία τα οποία κατά προσέγγιση υπολογίζουν πιθανά κόστη, τα οποία
          ενδεχομένως θα προκύπτουν σε μια αγορά/ενοικίαση ακινήτου. Ο
          «Ιστότοπος» και η «Εταιρεία» δεν φέρουν ουδεμία ευθύνη για τη χρήση
          τους από τους «Χρήστες», οι οποίοι ενεργούν με βάση την ιδιωτική τους
          βούληση.
        </Typography>
        <Typography paragraph>
          Τα εργαλεία αυτά δεν συνιστούν οικονομική, νομική ή άλλη συμβουλή,
          παρότρυνση ή προτροπή για την διενέργεια οποιασδήποτε επενδυτικής ή
          άλλης πράξεως.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
          ΧΙΙΙ. Εφαρμοστέο Δίκαιο
        </Typography>
        <Typography paragraph>
          Οι παρόντες όροι διέπονται από το Ελληνικό Δίκαιο και σε περίπτωση που
          οιοσδήποτε εξ’ αυτών προκύπτει ότι είναι άκυρος ή/και μη εφαρμοστέος
          έστω και μερικά για οποιονδήποτε λόγο, οι όροι που δεν επηρεάζονται
          από αυτόν θα εφαρμόζονται και έχουν πλήρη ισχύ.
        </Typography>
        <Box
          component={TermsSvg}
          sx={{
            display: 'block',
            mx: 'auto',
            width: 200,
            height: 200,
          }}
        />
      </Box>
    </InnerLayout>
  )
}

export default TermsPage
