/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import shallow from 'zustand/shallow'

import TabbedSelect from 'components/Landing/TabbedSelect'
import { useSearchStore } from 'store'
import { INTEREST_FOR_OPTIONS } from 'store/SearchSlice/constants'
import { useSearchParams } from 'react-router-dom'

function Interest({ variant }) {
  const [searchFor, setSearchFor, setPage] = useSearchStore(
    (state) => [state.searchFor, state.setSearchFor, state.setPage],
    shallow
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const setToUrl = (payload: string) => {
    searchParams.set('interest', payload)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const handleChangeFor = (_event, newValue) => {
    setSearchFor(newValue)

    setPage(1)

    setToUrl(newValue)
  }

  return (
    <TabbedSelect
      variant={variant}
      value={searchFor}
      onChange={handleChangeFor}
      options={INTEREST_FOR_OPTIONS}
    />
  )
}

export default React.memo(Interest)
