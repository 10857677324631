import React from 'react'
import shallow from 'zustand/shallow'
import { Grid, SxProps, Zoom, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

import { useSearchStore } from 'store'
import SearchLabel from 'components/Landing/SearchLabel'
import SearchSelect from 'components/Search/SearchSelect'
import { ViewVariant } from 'types'
import { FLOOR_OPTIONS, FLOOR_DIRECTION_OPTIONS } from 'helpers/constants'
import UpdateControl from './UpdateControl'

interface FloorSelectProps {
  variant?: ViewVariant
  sx: SxProps
  onRefetch?: () => void
  handleCloseFilters?: () => void
}

function FloorSelect(props: FloorSelectProps) {
  const { variant, onRefetch, handleCloseFilters, sx: sxFromProps = {} } = props

  const theme = useTheme()

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()

  const IS_INNER = variant === 'inner'

  const [
    floor,
    floorDirection,
    handleFloorSelect,
    activeField,
    setActiveField,
    searchCountFn,
  ] = useSearchStore(
    (state) => [
      state.floor,
      state.floorDirection,
      state.handleFloorSelect,
      state.activeField,
      state.setActiveField,
      state.searchCountFn,
    ],
    shallow
  )

  const IS_ACTIVE = ['floor', 'floorDirection'].includes(activeField)

  const handleChange = (e, name) => {
    handleFloorSelect(e.target.value, name)
    if (IS_INNER && onRefetch) {
      setActiveField(name)
    } else searchCountFn()
  }

  const handleUpdate = () => {
    if (onRefetch) onRefetch()
    setActiveField('')
    if (matchesSM && handleCloseFilters) {
      handleCloseFilters()
    }
  }

  return (
    <>
      <Grid container sx={{ ...sxFromProps }}>
        <Grid item xs={12}>
          <SearchLabel
            cVariant={variant}
            isActive={IS_INNER ? true : floor !== ''}
          >
            {t('landingPage.moreFilters.shared.floors.label')}
          </SearchLabel>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6} sm={6} md={6}>
            <SearchSelect
              options={FLOOR_OPTIONS}
              variant={variant}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(e) => handleChange(e, 'floor')}
              value={floor}
              emptyLabel={t('landingPage.moreFilters.shared.floors.emptyLabel')}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <SearchSelect
              options={FLOOR_DIRECTION_OPTIONS}
              variant={variant}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(e) => handleChange(e, 'floorDirection')}
              value={floorDirection}
              emptyLabel={t('landingPage.moreFilters.shared.floors.emptyLabel')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Zoom in={IS_ACTIVE && IS_INNER} unmountOnExit>
        <div>
          <UpdateControl onChange={handleUpdate} />
        </div>
      </Zoom>
    </>
  )
}

export default FloorSelect
