import PFBagueSansProWoff2 from './assets/fonts/PFBagueSansPro-Bold/PFBagueSansPro-Bold.woff2'
import PFBagueSansProWoff from './assets/fonts/PFBagueSansPro-Bold/PFBagueSansPro-Bold.woff'
import PFBagueSansProTtf from './assets/fonts/PFBagueSansPro-Bold/PFBagueSansPro-Bold.ttf'
import PFBagueSansUltraBlackWoff2 from './assets/fonts/PFBagueSansPro-UltraBlack/PFBagueSansPro-UltraBlack.woff2'
import PFBagueSansUltraBlackWoff from './assets/fonts/PFBagueSansPro-UltraBlack/PFBagueSansPro-UltraBlack.woff'
import PFBagueSansUltraBlackTtf from './assets/fonts/PFBagueSansPro-UltraBlack/PFBagueSansPro-UltraBlack.ttf'
import PFBagueSansMediumWoff2 from './assets/fonts/PFBagueSansPro-Medium/PFBagueSansPro-Medium.woff2'
import PFBagueSansMediumWoff from './assets/fonts/PFBagueSansPro-Medium/PFBagueSansPro-Medium.woff'
import PFBagueSansMediumTtf from './assets/fonts/PFBagueSansPro-Medium/PFBagueSansPro-Medium.ttf'

import { createTheme } from '@mui/material/styles'
import '@mui/material/styles/createPalette'

const MAIN_ORANGE = '#FFBC2D'

const MAIN_BLACK = '#71748d'

const MAIN_GREY = 'rgba(0,0,0,.2)'

export const MAIN_SHADOW = `10px 10px 1px -2px ${MAIN_GREY}`

const FB_COLOR = '#3B5998'
const PINTEREST_COLOR = '#CB2027'
const LINKEDIN_COLOR = '#0077B5'
const TWITTER_COLOR = '#1DA1F2'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      // xs: 360,
      // sm: 600,
      // md: 900,
      // lg: 1366,
      // xl: 1920,
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          boxShadow: MAIN_SHADOW,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiButton: {},
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'PF Bague Sans Pro Bold';
        src: local('PF Bague Sans Pro Medium'), local('PF-Bague-Sans-Pro-Medium'),
        url(${PFBagueSansMediumWoff2})  format('woff2'),
        url(${PFBagueSansMediumWoff}) format('woff'),
        url(${PFBagueSansMediumTtf}) format('truetype');
        font-weight: 500;
        font-style: normal;
      }
        @font-face {
          font-family: 'PF Bague Sans Pro Bold';
          src: local('PF Bague Sans Pro Bold'), local('PF-Bague-Sans-Pro-Bold'),
          url(${PFBagueSansProWoff2})  format('woff2'),
          url(${PFBagueSansProWoff}) format('woff'),
          url(${PFBagueSansProTtf}) format('truetype');
          font-weight: 700;
          font-display: swap;
          font-style: normal;
        }
        @font-face {
          font-family: 'PF Bague Sans Pro Bold';
          src: local('PF Bague Sans Pro Ultra Black'), local('PF-Bague-Sans-Pro-Ultra-Black'),
          url(${PFBagueSansUltraBlackWoff2})  format('woff2'),
          url(${PFBagueSansUltraBlackWoff}) format('woff'),
          url(${PFBagueSansUltraBlackTtf}) format('truetype');
          font-weight: 900;
          font-style: normal;
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette: {
    common: {
      orange: MAIN_ORANGE,
      grey: MAIN_GREY,
      pinterest: PINTEREST_COLOR,
      fb: FB_COLOR,
      twitter: TWITTER_COLOR,
      linkedin: LINKEDIN_COLOR,
    },
    primary: {
      main: MAIN_ORANGE,
      contrastText: '#fff',
      dark: ' #ffae00',
    },
    secondary: {
      main: MAIN_BLACK,
      contrastText: '#fff',
    },
    orange: {
      main: MAIN_ORANGE,
      light: 'rgb(255, 201, 87)',
      contrastText: '#000000',
      dark: ' #ffae00',
    },
    white: {
      main: '#FFFFFF',
      light: 'rgb(255, 255, 255)',
      contrastText: '#000000',
      dark: 'rgb(178, 178, 178)',
    },
    background: {
      default: '#FFFFFF',
    },
    pinterest: {
      main: PINTEREST_COLOR,
      light: PINTEREST_COLOR,
      contrastText: '#fff',
      dark: PINTEREST_COLOR,
    },
    fb: {
      main: FB_COLOR,
      light: FB_COLOR,
      contrastText: '#fff',
      dark: FB_COLOR,
    },
    twitter: {
      main: TWITTER_COLOR,
      light: TWITTER_COLOR,
      contrastText: '#fff',
      dark: TWITTER_COLOR,
    },
    linkedin: {
      main: LINKEDIN_COLOR,
      light: LINKEDIN_COLOR,
      contrastText: '#fff',
      dark: LINKEDIN_COLOR,
    },
  },
  typography: {
    fontFamily: [
      'PF Bague Sans Pro Bold',
      'PF Bague Sans Pro',
      'PF Bague Sans Pro Ultra Black',
      'Georgia',
      'Roboto',
    ].join(','),
  },
})

theme.shadows.push(MAIN_SHADOW)

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    fb: true
    linkedin: true
    pinterest: true
    twitter: true
    string: true
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    fb: true
    linkedin: true
    pinterest: true
    twitter: true
    string: true
  }
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    grey: string
    orange: string
    fb: string
    linkedin: string
    pinterest: string
    twitter: string
    string: string
  }
  interface ColorOptions {
    white?: string
    orange?: string
  }
  interface PaletteOptions {
    white?: PaletteColor
    orange?: PaletteColor
    fb?: PaletteColor
    pinterest?: PaletteColor
    linkedin: PaletteColor
    twitter: PaletteColor
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    orange?: string
    white?: string
    fb?: string
    pinterest?: string
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    orange: true
    white: true
  }
  interface ChipPropsVariantOverrides {
    search: true
  }
}
declare module '@mui/material/styles/shadows' {}
