import React from 'react'
import { Box, Typography, Link, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import HomeSVG from 'assets/svg/icons/home-icon.svg'
import { Attachment } from 'types'

function EstateCard(props) {
  const { data } = props

  const { t } = useTranslation()

  const getInterestType = (adCategoryId: number) => {
    const BUY = [1, 2, 3]
    const LOAN = [4, 5, 6]
    const IS_BUY = BUY.includes(adCategoryId)
    if (IS_BUY) return t('detailPage.interest.buy')
    else if (LOAN) return t('detailPage.interest.loan')
  }

  const getPropertyTitle = (data) => {
    let finalTitle = ''
    const propertyType = data?.parsedTags?.propertyType?.value.split('#')
    if (propertyType[1] === 'appartment') {
      finalTitle += t('agentDetailPage.estateCard.apartment')
    } else if (propertyType[1] === 'single_residence') {
      finalTitle += t('agentDetailPage.estateCard.singleResidence')
    } else if (propertyType[1] === 'maisonette') {
      finalTitle += t('agentDetailPage.estateCard.maisonette')
    } else if (propertyType[1] === 'building') {
      finalTitle += t('agentDetailPage.estateCard.building')
    } else {
      finalTitle += t('agentDetailPage.estateCard.restTypes')
    }

    finalTitle += ` ${data?.squareMeters} m² ${t(
      'landingPage.interestType.resultsLabel'
    )} ${getInterestType(data?.adCategoryId)}`
    return finalTitle
  }

  const getAreas = (data) => {
    const finalArea = data?.searchArea.replaceAll(' /', ',')
    return finalArea
  }

  const [images] = React.useState<Attachment[]>(() => {
    if (data?.photosURLS?.length) {
      return data?.photosURLS
    }
    return []
  })
  return (
    <>
      <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
        <Box
          component="img"
          src={images?.length ? images[0]?.filePath : HomeSVG}
          sx={{
            maxHeight: 120,
            maxWidth: '100%',
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Link
          component={RouterLink}
          to={`/property-ads/${data?.id}`}
          underline="none"
          color="inherit"
          sx={{
            '&:hover ': { color: 'primary.main' },
            transition: (theme) =>
              theme.transitions.create(['background-color', 'color']),
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography
            component="div"
            variant="h6"
            sx={{
              fontSize: 15,
              fontWeight: 'bold',
            }}
          >
            {getPropertyTitle(data)}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 15, '&:hover': { color: 'inherit' } }}
          >
            {getAreas(data)}
          </Typography>
        </Link>
      </Grid>
    </>
  )
}

export default EstateCard
