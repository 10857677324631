import { useEffect } from 'react'
import { useRouteStore } from 'store'
import { mappings, ads } from './constants'

export function useAdSlot(id, collapse = false) {
  const pageChanged = useRouteStore((state) => state.counter)

  const parsedId = id.split('#')

  useEffect(() => {
    if (window && window.googletag && window?.googletag?.apiReady) {
      const { googletag } = window
      googletag.cmd.push(function () {
        const adMapping = googletag.sizeMapping()
        const slotToUse = ads[parsedId[0]].slot
        const sizesToUse = ads[parsedId[0]].sizes
        const mappingToUse = ads[parsedId[0]].mapping
        const collapseDiv = collapse ? collapse : ads[parsedId[0]].collapse

        const correctMapping = mappings[mappingToUse]
        correctMapping.sizes.forEach((size) => {
          adMapping.addSize(...size)
        })
        const builtMapping = adMapping.build()

        googletag
          .defineSlot(slotToUse, sizesToUse, parsedId[0] + '-' + parsedId[1])
          .defineSizeMapping(builtMapping)
          .setCollapseEmptyDiv(collapseDiv)
          .addService(googletag.pubads())

        googletag.enableServices()
      })

      googletag.cmd.push(function () {
        googletag.display(parsedId[0] + '-' + parsedId[1])
      })
    }
  }, [collapse, id, pageChanged, parsedId])
}
