/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as turf from '@turf/turf'
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Grid,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  CircularProgress,
  Grow,
  Collapse,
  Alert,
  Button,
} from '@mui/material'
import shallow from 'zustand/shallow'
import { useTranslation } from 'react-i18next'
import { createId } from '@paralleldrive/cuid2'

import InnerLayout from 'layouts/InnerLayout'
import { ReactComponent as PigSvg } from 'assets/svg/icons/tool3.svg'
import { useRecentStore } from 'store'
import AgentControl from './AgentControl'
import AdCard from './AdCard'
import AdInfo from './AdInfo'
import RecentList from './RecentList'
import Calculator from './Calculator'
import Map from './Map'
import Share from './Share'
import AddtoFavorite from './AddtoFavorite'
import DetailContent from './DetailContent'
import DialogShare from './DialogShare'
import Ad from './Ad'
import { useDocumentTitle } from 'hooks/useDocumentTitle'
import {
  calculatePropertyCategory,
  calculatePropertyType,
  getPropertyTypeParsed,
  calculateInterest,
  generatePropertyAdTitle,
} from './helpers'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import PropertyAdService from 'api/services/PropertyAd'
import { useQueryClient } from 'react-query'
import NotFound from 'components/NotFound'
import { isEmpty } from 'lodash'
import AuthService from 'services/AuthService'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import usePageView from 'hooks/usePageView'
import { Position } from 'geojson'
import { ViewType } from './types'

function Detail() {
  const { t } = useTranslation()
  const { adId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { addBeforeTitle } = useDocumentTitle()

  const { sendPageView } = usePageView({ runOnMount: false })

  const [localProperty, setLocalProperty] = React.useState<ParsedProperty>()

  const [localError, setLocalError] = React.useState<boolean>(false)

  React.useEffect(() => {
    setLocalError(false)
    const temp = queryClient.getQueryData([
      PropertyAdService.keys.QueryKeys.single,
      adId,
    ]) as ParsedProperty
    if (temp) {
      setLocalProperty(temp)
      addBeforeTitle(generatePropertyAdTitle(temp, t))
      sendPageView()
    }
  }, [])

  const [openShareDialog, setOpenShareDialog] = React.useState(false)
  const [cid, setCuid] = React.useState<string>(() => createId())

  const handleOpenShareDialog = () => {
    setOpenShareDialog(true)
  }
  const handleCloseShareDialog = () => {
    setOpenShareDialog(false)
  }

  const [addToList, removeFromList] = useRecentStore(
    (state) => [state.addToList, state.removeFromList],
    shallow
  )

  const detailData = PropertyAdService.hooks.usePropertyAd(adId, {
    enabled: !!adId && !localProperty,
    retry: false,
    onSuccess: (data) => {
      if (!data && adId) {
        removeFromList(adId)
        setLocalProperty(undefined)
      }
      if (data) {
        addToList(data)
        addBeforeTitle(generatePropertyAdTitle(data, t))
        setLocalProperty(data)
      }
      sendPageView()
    },
    onError: () => {
      setLocalError(true)
    },
  })

  const HAS_NO_IMAGES = isEmpty(localProperty?.photosURLS)
  const IS_AGENT = AuthService.isLoggedIn() && AuthService.isAgent()

  React.useEffect(() => {
    setLocalError(false)
    detailData.refetch()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    if (window?.googletag && window?.googletag?.apiReady) {
      window?.googletag?.destroySlots()
    }
    if (localError) {
      setLocalError(false)
    }
    setCuid(createId())
  }, [adId])

  const theme = useTheme()

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesSMMD = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const matchesLGU = useMediaQuery(theme.breakpoints.up('lg'))
  const matchesMDU = useMediaQuery(theme.breakpoints.up('md'))
  const matchesSMD = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesMDD = useMediaQuery(theme.breakpoints.down('md'))
  const matchesMDLG = useMediaQuery(theme.breakpoints.between('md', 'lg'))

  let interest
  let propertyCategory
  let propertyTypeOption
  let propertyType
  if (detailData.data) {
    interest = calculateInterest(detailData.data.adCategoryId)
    propertyCategory = calculatePropertyCategory(detailData.data.adCategoryId)
    propertyTypeOption = getPropertyTypeParsed(detailData.data)

    propertyType = calculatePropertyType(propertyTypeOption, detailData.data)
  }

  const PROPERTY_AD_LAT_LON = React.useMemo(() => {
    return {
      lon: detailData.data?.mapLocation?.longitude ?? null,
      lat: detailData.data?.mapLocation?.latitude ?? null,
    }
  }, [
    detailData.data?.mapLocation?.latitude,
    detailData.data?.mapLocation?.longitude,
  ])

  const SHOW_MAP = React.useMemo(() => {
    if (
      PROPERTY_AD_LAT_LON.lon &&
      PROPERTY_AD_LAT_LON.lat &&
      detailData.data?.areaLimits?.length
    ) {
      const point = turf.point([
        PROPERTY_AD_LAT_LON.lon,
        PROPERTY_AD_LAT_LON.lat,
      ])

      const poly = turf.polygon([
        [
          ...detailData.data.areaLimits.map(
            (point) => [point.longitude, point.latitude] as unknown as Position
          ),
          ...detailData.data.areaLimits
            .slice(0, 1)
            .map(
              (point) =>
                [point.longitude, point.latitude] as unknown as Position
            ),
        ],
      ])
      return turf.booleanPointInPolygon(point, poly)
    }
    return true
  }, [PROPERTY_AD_LAT_LON])

  return (
    <InnerLayout>
      <Box
        sx={{
          position: 'relative',
          py: 4,
          px: { xs: 0.5, sm: 2, md: 2, lg: 4, xl: 4 },
        }}
      >
        {detailData.isLoading ? (
          <Typography align="center" sx={{ mt: 4 }}>
            <CircularProgress color="secondary" size={30} />
          </Typography>
        ) : null}
        <Collapse in={localError}>
          <Alert severity="error" onClose={() => setLocalError(false)}>
            {t('error.unspecific')}
          </Alert>
        </Collapse>
        <Grow in={detailData.isSuccess && !detailData.data} unmountOnExit>
          <div>
            <NotFound />
          </div>
        </Grow>

        <Grow in={detailData.isSuccess && !!localProperty} unmountOnExit>
          <Box>
            <Button
              onClick={() => {
                if (window.history.state && window.history?.length > 2) {
                  navigate(-1)
                } else {
                  window.location.href = window.location.origin
                }
              }}
              sx={{
                textTransform: 'none',
                borderRadius: '8px',
                border: '1px solid #EBEBEB',
                boxShadow: '5px 5px 7px rgba(9, 9, 9, 0.3)',
                bgcolor: 'common.white',
                maxWidth: '227px',
                minWidth: '190px',
                height: '36px',
                mb: '12px',
              }}
            >
              <ArrowBackIosIcon
                sx={{ color: 'black', pl: '10px', fontSize: '28px' }}
              />

              <Typography
                noWrap
                fontWeight={700}
                sx={{
                  color: 'black',
                  fontSize: '15px',
                  ml: '4px',
                }}
              >
                {t('detailPage.backToSearch')}
              </Typography>
            </Button>
            {localProperty ? (
              <Box
                sx={{
                  mt: 0,
                  display: 'grid',
                  gap: '20px',
                  gridTemplateColumns: 'calc(100% - 620px)  620px',
                  gridTemplateAreas: `
                                        "card control"
                                        "smCardArea smCardArea"
                                        "contentAreaMDD contentAreaMDD"
                                        "agentControlAreaSMD agentControlAreaSMD"
                                        "ad8AreaSMD ad8AreaSMD"
                                        "infoArea infoArea"
                                        "moreArea moreArea"
                                        "mapAreaSMD mapAreaSMD"
                                        "ad9AreaSMD ad9AreaSMD"
                                        "ad7AreaSMD ad7AreaSMD"
                                        "calcArea calcArea"`,
                  [theme.breakpoints.down('lg')]: {
                    gridTemplateColumns: 'calc(100% - 300px) 300px',
                  },
                  [theme.breakpoints.down('sm')]: {
                    gridTemplateColumns: 'smCardArea',
                    rowgap: '20px',
                    columnGap: 0,
                  },
                }}
              >
                <AdCard
                  propertyCategory={propertyCategory}
                  propertyType={propertyType}
                  data={localProperty}
                  style={{ gridArea: matchesSMD ? 'smCardArea' : 'card' }}
                />
                {matchesLGU ? (
                  <Box>
                    <Box
                      sx={{
                        minHeight: '600px',
                        display: 'grid',
                        gap: '10px',
                        gridTemplateColumns: 'auto 300px',
                        gridTemplateRows: 'auto',
                        gridTemplateAreas: `"agent ad"`,
                      }}
                    >
                      <Stack spacing={2}>
                        <AgentControl data={localProperty} variant="xl" />
                        {HAS_NO_IMAGES ? null : (
                          <Stack spacing={2}>
                            {IS_AGENT ? null : (
                              <AddtoFavorite propertyAd={localProperty} />
                            )}
                            <Share onOpen={handleOpenShareDialog} />
                          </Stack>
                        )}
                      </Stack>

                      <Box>
                        {HAS_NO_IMAGES ? (
                          <>
                            <Stack
                              spacing={2}
                              justifyContent="space-between"
                              sx={{
                                maxWidth: '350px',
                              }}
                            >
                              {' '}
                              {IS_AGENT ? null : (
                                <AddtoFavorite propertyAd={localProperty} />
                              )}
                              <Share onOpen={handleOpenShareDialog} />
                              {SHOW_MAP && (
                                <Map
                                  hasImages={!HAS_NO_IMAGES}
                                  mapLocation={localProperty?.mapLocation}
                                />
                              )}
                            </Stack>
                          </>
                        ) : (
                          <Ad id={'div-gpt-ad-3816174-2' + '#' + cid + '1'} />
                        )}
                      </Box>
                    </Box>
                    {HAS_NO_IMAGES ? null : SHOW_MAP ? (
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Map
                          hasImages={!HAS_NO_IMAGES}
                          mapLocation={localProperty?.mapLocation}
                        />
                      </Grid>
                    ) : null}
                  </Box>
                ) : null}

                {matchesMDLG ? (
                  <Box>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          justifyContent={'space-between'}
                          sx={{ minHeight: '550px' }}
                        >
                          <Grid item>
                            <AgentControl data={localProperty} variant="lg" />
                          </Grid>
                          <Grid
                            item
                            container
                            justifyContent="center"
                            sx={{ alignSelf: 'flex-start' }}
                          >
                            <Grid item>
                              <Stack spacing={2}>
                                {IS_AGENT ? null : (
                                  <AddtoFavorite propertyAd={localProperty} />
                                )}
                                <Share onOpen={handleOpenShareDialog} />
                              </Stack>
                            </Grid>
                          </Grid>
                          {SHOW_MAP && (
                            <Grid item>
                              <Map
                                hasImages={!HAS_NO_IMAGES}
                                mapLocation={localProperty?.mapLocation}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Ad
                          id={'div-gpt-ad-3816174-1' + '#' + cid}
                          sx={{
                            mt: 1,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          OtherProps={{
                            component: Grid,
                            item: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ) : null}

                {matchesSMMD && !HAS_NO_IMAGES ? (
                  <Box>
                    <Grid container direction="column" justifyContent="center">
                      <Grid item>
                        <AgentControl data={localProperty} variant="lg" />
                      </Grid>
                      <Grid item sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                          {IS_AGENT ? null : (
                            <AddtoFavorite propertyAd={localProperty} />
                          )}

                          <Share onOpen={handleOpenShareDialog} />
                        </Stack>
                      </Grid>
                      {SHOW_MAP && (
                        <Grid item sx={{ mt: 2 }}>
                          <Map
                            hasImages={!HAS_NO_IMAGES}
                            mapLocation={localProperty?.mapLocation}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                ) : null}

                {matchesMDD ? (
                  <>
                    <Box sx={{ gridArea: 'contentAreaMDD' }}>
                      <DetailContent
                        propertyCategory={propertyCategory}
                        propertyType={propertyType}
                        data={localProperty}
                        onOpen={handleOpenShareDialog}
                      />
                    </Box>
                  </>
                ) : null}

                {matchesSM ? (
                  <Box sx={{ gridArea: 'agentControlAreaSMD' }}>
                    <AgentControl data={localProperty} variant="lg" />
                  </Box>
                ) : null}

                {matchesSMMD && HAS_NO_IMAGES ? (
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={'row'}
                      alignItems="stretch"
                      flexWrap="nowrap"
                      spacing={2}
                    >
                      <Grid xs={6} item sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          justifyContent="space-between"
                          direction="column"
                        >
                          <Grid item>
                            <AgentControl data={localProperty} variant="lg" />
                          </Grid>
                          <Grid item>
                            <Stack spacing={2}>
                              {IS_AGENT ? null : (
                                <AddtoFavorite propertyAd={localProperty} />
                              )}

                              <Share onOpen={handleOpenShareDialog} />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      {SHOW_MAP && (
                        <Grid item sx={{ flexGrow: 1 }}>
                          <Map
                            hasImages={!HAS_NO_IMAGES}
                            mapLocation={localProperty?.mapLocation}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ) : null}

                <>
                  <Box
                    sx={{
                      gridArea: 'infoArea',
                      display: 'grid',
                      gap: '10px',
                      gridTemplateColumns: 'auto',
                      gridTemplateRows: 'auto',
                      gridTemplateAreas: `
                    "info info"
                    "footer footer"`,
                      [theme.breakpoints.down('lg')]: {
                        gridTemplateAreas: `
                    "info recent"
                    "footer footer"`,
                        gridTemplateColumns: 'auto 300px',
                      },
                      [theme.breakpoints.down('sm')]: {
                        gridTemplateAreas: `
                    "info"
                    "ad"
                    `,
                        gridTemplateColumns: 'auto',
                      },
                    }}
                  >
                    <AdInfo
                      propertyType={propertyType}
                      propertyCategory={propertyCategory}
                      data={localProperty}
                      view={ViewType.Info}
                      style={{ gridArea: 'info' }}
                    />
                    {matchesMDLG ? (
                      <>
                        <Box sx={{ mt: 2 }}>
                          <RecentList />
                        </Box>
                        <Box
                          style={{
                            gridArea: 'footer',
                          }}
                        >
                          <Ad
                            id={'div-gpt-ad-3816174-13' + '#' + cid}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              my: 3,
                            }}
                            OtherProps={{
                              component: Box,
                            }}
                          />
                        </Box>
                      </>
                    ) : null}
                    {matchesLGU ? (
                      <Box
                        sx={{
                          gridArea: 'footer',
                          my: 5,
                          display: 'grid',
                          gap: '10px',
                          gridTemplateColumns: '1fr',
                        }}
                      >
                        <Grid container spacing={1} justifyContent="center">
                          <Ad
                            collapse={false}
                            id={'div-gpt-ad-3816174-13' + '#' + cid + '1'}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                            OtherProps={{
                              component: Grid,
                              item: true,
                              xs: 'auto',
                            }}
                          />

                          <Grid item xs={4}>
                            <RecentList limit={4} />
                          </Grid>
                        </Grid>
                      </Box>
                    ) : null}
                    {matchesSMMD ? (
                      <>
                        <Ad
                          id={'div-gpt-ad-3816174-7' + '#' + cid}
                          sx={{
                            height: 'fit-content',
                            width: 300,
                            mt: 2,
                          }}
                          OtherProps={{
                            component: Box,
                          }}
                        />
                      </>
                    ) : null}
                    {matchesMDD && propertyType !== 'parking' ? (
                      <Box
                        sx={{
                          gridArea: 'ad',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Ad
                          id={'div-gpt-ad-3816174-10' + '#' + cid}
                          sx={{
                            height: 'fit-content',
                            width: 300,
                            mt: 2,
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </>

                {matchesSM ? (
                  <Box
                    sx={{
                      gridArea: 'ad8AreaSMD',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Ad id={'div-gpt-ad-3816174-8' + '#' + cid} />
                  </Box>
                ) : null}

                <Box
                  sx={{
                    gridArea: 'moreArea',
                    display: 'grid',
                    gap: '10px',
                    gridTemplateColumns: 'auto 300px',
                    gridTemplateRows: 'auto',
                    gridTemplateAreas: `
                    "more ad"
                    "footer footer"`,
                    [theme.breakpoints.down('sm')]: {
                      gridTemplateAreas: `
                    "more"
                    `,
                      gridTemplateColumns: 'auto',
                    },
                  }}
                >
                  {propertyType !== 'parking' ? (
                    <AdInfo
                      propertyType={propertyType}
                      propertyCategory={propertyCategory}
                      data={localProperty}
                      view={ViewType.More}
                    />
                  ) : null}
                  {matchesMDU && propertyType !== 'parking' ? (
                    <Ad
                      id={'div-gpt-ad-3816174-3' + '#' + cid}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                      }}
                      OtherProps={{
                        component: Box,
                      }}
                    />
                  ) : null}
                  {matchesSMMD && propertyType !== 'parking' ? (
                    <Grid
                      container
                      direction="column"
                      justifyContent={'flex-start'}
                      wrap="nowrap"
                      sx={{
                        mt: 1,
                      }}
                    >
                      <Grid item>
                        <RecentList />
                      </Grid>
                      <Ad
                        id={'div-gpt-ad-3816174-6' + '#' + cid}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          mt: 2,
                        }}
                        OtherProps={{
                          component: Grid,
                          item: true,
                        }}
                      />
                    </Grid>
                  ) : null}
                </Box>

                {matchesSM && SHOW_MAP ? (
                  <Box
                    sx={{
                      gridArea: 'mapAreaSMD',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Map
                      hasImages={!HAS_NO_IMAGES}
                      mapLocation={localProperty?.mapLocation}
                    />
                  </Box>
                ) : null}

                {matchesSM ? (
                  <Box
                    sx={{
                      gridArea: 'ad9AreaSMD',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Ad
                      id={'div-gpt-ad-3816174-9' + '#' + cid}
                      sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    />
                  </Box>
                ) : null}

                {interest === 'buy' ? (
                  <Box
                    sx={{
                      gridArea: 'calcArea',
                      display: 'grid',
                      gap: '10px',
                      gridTemplateColumns: 'auto 300px',
                      gridTemplateRows: 'auto',
                      gridTemplateAreas: `"calc ad"`,
                      [theme.breakpoints.down('lg')]: {
                        gridTemplateAreas: `
                    "calc recent"
                    "footer footer"`,
                        gridTemplateColumns: 'auto',
                      },
                      [theme.breakpoints.down('md')]: {
                        gridTemplateAreas: `"calc ad"`,
                        gridTemplateColumns: 'auto 300px',
                      },
                      [theme.breakpoints.down('sm')]: {
                        gridTemplateAreas: `"calc"`,
                        gridTemplateColumns: 'auto',
                      },
                    }}
                  >
                    <Calculator data={localProperty} />
                    {matchesLGU ? (
                      <Ad
                        id={'div-gpt-ad-3816174-5' + '#' + cid}
                        sx={{
                          mt: 4,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        OtherProps={{
                          component: Box,
                        }}
                      />
                    ) : null}
                    {matchesSMMD ? (
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                      >
                        <Ad
                          id={'div-gpt-ad-3816174-6' + '#' + cid + '1'}
                          sx={{
                            mt: 1,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          OtherProps={{
                            component: Grid,
                            item: true,
                          }}
                        />

                        <Grid item sx={{ mt: '200px' }}>
                          <SvgIcon
                            component={PigSvg}
                            inheritViewBox
                            sx={{
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            align="center"
                            sx={{
                              whiteSpace: 'pre',
                              pt: 2,
                              fontSize: { xs: 15, sm: 20 },
                            }}
                          >
                            {t('detailPage.calculator.subtitle')}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            ) : null}
            <DialogShare
              open={openShareDialog}
              onClose={handleCloseShareDialog}
              sharePath={`${
                process.env.REACT_APP_SHARE_URL + '/'
              }property-ads/${detailData.data?.id}/#/property-ads/${
                detailData.data?.id
              }`}
              shareLabel={detailData.data?.title ?? ''}
              title={
                detailData.data?.title ??
                generatePropertyAdTitle(detailData?.data, t)
              }
            />
          </Box>
        </Grow>
      </Box>
    </InnerLayout>
  )
}

export default Detail
