import {
  ISearchForOption,
  IInterestForOption,
  IEstateConditionOptionLanding,
  ILandCharacteristicsOption,
} from './types'

export const SORTING_FIELDS = [
  'SQUARE_METERS',
  'PRICE',
  'PRICE_OF_SQUARE_METER',
] as const
interface ISortOption {
  label: string
  value: string
}
export const SORTING_OPTIONS: ISortOption[] = [
  {
    label: 'searchPage.pagination.sort.default',
    value: '',
  },
  {
    label: 'searchPage.pagination.sort.options.sqrA',
    value: 'SQUARE_METERS#ASC',
  },
  {
    label: 'searchPage.pagination.sort.options.sqrD',
    value: 'SQUARE_METERS#DESC',
  },
  {
    label: 'searchPage.pagination.sort.options.priceA',
    value: 'PRICE#ASC',
  },
  {
    label: 'searchPage.pagination.sort.options.priceD',
    value: 'PRICE#DESC',
  },
  {
    label: 'searchPage.pagination.sort.options.priceOfSqrA',
    value: 'PRICE_OF_SQUARE_METER#ASC',
  },
  {
    label: 'searchPage.pagination.sort.options.priceOfSqrD',
    value: 'PRICE_OF_SQUARE_METER#DESC',
  },
]

export const SEARCH_FOR_OPTIONS: ISearchForOption[] = [
  {
    label: 'landingPage.lookingFor.estate',
    value: 'estate',
  },
  {
    label: 'landingPage.lookingFor.commercial',
    value: 'commercial',
  },
  { label: 'landingPage.lookingFor.land', value: 'land' },
]

export const INTEREST_FOR_OPTIONS: IInterestForOption[] = [
  {
    label: 'landingPage.interestType.buy',
    value: 'buy',
  },
  {
    label: 'landingPage.interestType.loan',
    value: 'rent',
  },
]

export const ESTATE_CONDITION_OPTIONS: IEstateConditionOptionLanding[] = [
  {
    label: 'property.estatePropertyCondition.newBuilt.base',
    value: 'ESTATE_PROPERTY_CONDITION#new_built',
  },
  {
    label: 'property.estatePropertyCondition.semiBuilt.base',
    value: 'ESTATE_PROPERTY_CONDITION#semi_built',
  },
  {
    label: 'property.estatePropertyCondition.needOfRenovation.base',
    value: 'ESTATE_PROPERTY_CONDITION#need_of_renovation',
  },
  {
    label: 'property.estatePropertyCondition.renovated.base',
    value: 'ESTATE_PROPERTY_CONDITION#renovated',
  },
]

export const LAND_CHARACTERISTIC_OPTIONS: ILandCharacteristicsOption[] = [
  {
    label: 'landingPage.moreFilters.land.characteristics.withinPlans',
    value: 'ESTATE_LAND_PROPERTY_CITY_PLAN#in_city_plans',
  },
  {
    label: 'landingPage.moreFilters.land.characteristics.agriculturalUse',
    value: 'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#agricultural_use',
  },
  {
    label: 'landingPage.moreFilters.land.characteristics.even',
    value: 'LAND_CONDITION#even',
  },
  {
    label: 'landingPage.moreFilters.land.characteristics.buildable',
    value: 'LAND_CONDITION#buildable',
  },
]
