import React, { CSSProperties } from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import IconGroup from '../IconGroup'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import { ViewType } from '../types'
interface AdInfoProps {
  data: ParsedProperty
  propertyCategory: string
  propertyType: string
  style?: CSSProperties
  view: ViewType
}

function AdInfo(props: AdInfoProps) {
  const { t } = useTranslation()
  const { data, propertyCategory, propertyType, view, style = {} } = props
  return (
    <div style={{ ...style }}>
      <Box sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="baseline" spacing={1}>
          <Typography
            align="center"
            variant="h5"
            sx={{
              fontWeight: 900,
              fontSize: { xs: 17, sm: 20, md: 30, lg: 35 },
            }}
          >
            {t(`detailPage.iconGroup.${view}.label`)}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              borderBottom: (theme) =>
                `4px solid ${theme.palette.primary.main}`,
            }}
          />
        </Stack>
      </Box>
      <IconGroup
        data={data}
        view={view}
        propertyCategory={propertyCategory}
        propertyType={propertyType}
      />
    </div>
  )
}

export default AdInfo
