import React from 'react'
import { Stack, Typography, useMediaQuery, Avatar, Box } from '@mui/material'
import { useTheme, Theme, CSSObject } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import { icons as availableIcons } from './icons'
import { IconMatch, ViewType } from './types'

const iconsWrapperMixin = (theme: Theme, view: ViewType): CSSObject => ({
  display: 'grid',
  gap: 0,
  ...(view === 'detail' && {
    gridTemplateColumns: '1fr 1fr 1fr',
  }),
  ...(view === 'info' && {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  }),
  ...(view === 'more' && {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  }),
})

interface IconGroupProps {
  view: ViewType
  propertyCategory: string
  propertyType: string
  data: ParsedProperty
}

function IconGroup(props: IconGroupProps) {
  const { t } = useTranslation()
  const { view, propertyCategory, propertyType, data } = props

  const IS_DETAIL_VIEW = view === ViewType.Detail

  const theme = useTheme()

  const matchesSMD = useMediaQuery(theme.breakpoints.down('sm'))

  const renderIcons = () => {
    const icons = availableIcons?.[propertyCategory]?.[propertyType]?.[
      view
    ] as IconMatch[]
    if (!icons) return
    return icons.map((option) => {
      return (
        <Box
          key={option.tooltip}
          sx={{
            py: 1,
            borderBottom: `1px solid black`,
          }}
        >
          <Stack
            alignItems={matchesSMD ? 'center' : 'flex-start'}
            justifyContent={matchesSMD ? 'center' : 'flex-start'}
            direction={matchesSMD ? 'column' : 'row'}
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Avatar
              key={option.tooltip}
              sx={{
                width: 55,
                height: 55,
                bgcolor: IS_DETAIL_VIEW ? 'common.white' : 'orange.main',
                border: IS_DETAIL_VIEW ? `1px solid black` : 'none',
              }}
            >
              {option.icon}
            </Avatar>
            <Stack>
              <Typography
                component="span"
                display="block"
                align={matchesSMD ? 'center' : 'left'}
                color="primary"
                sx={{
                  wordBreak: 'break-word',
                  fontSize: { xs: 12, sm: 14, md: 16, lg: 20 },
                }}
              >
                {t(option.tooltip)}
              </Typography>
              <Typography
                component="span"
                display="block"
                align={matchesSMD ? 'center' : 'left'}
                sx={{
                  fontWeight: 600,
                  wordBreak: 'break-word',
                  fontSize: { xs: 13, sm: 15, md: 16, lg: 20 },
                }}
              >
                {option?.valueGetter
                  ? option?.valueGetter(data, t) ?? '-'
                  : '-'}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )
    })
  }

  return (
    <Box
      sx={{
        ...iconsWrapperMixin(theme, view),
      }}
    >
      {renderIcons()}
    </Box>
  )
}

export default IconGroup
