/* eslint-disable react/display-name */
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Typography,
  Grid,
  Button,
  SvgIcon,
  useMediaQuery,
  Box,
} from '@mui/material'
import { ReactComponent as NotFoundSVG } from 'assets/svg/icons/notFound.svg'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import InnerLayout from 'layouts/InnerLayout'
import usePageView from 'hooks/usePageView'

const NotFoundPage: React.FC = () => {
  usePageView()
  const { t } = useTranslation()
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  return (
    <InnerLayout>
      <Grid
        container
        columnSpacing={matchesTablet ? '0px' : '99px'}
        rowSpacing={'2px'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: { xs: '0px', sm: '30px', md: '5px', lg: '129px' },
          pb: { xs: '132px', sm: '253px', md: '107px', lg: '228px' },
        }}
      >
        <Grid item direction="column" sx={{ mt: 4 }}>
          {matchesSM && !matchesTablet ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-between',
                mx: '33px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '96px',
                  lineHeight: '118px',
                  fontWeight: 1000,
                  mt: '200px',
                  color: 'black',
                }}
              >
                {t('notFoundPage.title', { lng: 'en' })}
              </Typography>
              <SvgIcon
                component={NotFoundSVG}
                inheritViewBox
                sx={{
                  width: '130px',
                  height: '288px',
                }}
              />
            </Box>
          ) : null}
          {matchesSM || matchesTablet ? null : (
            <Typography
              sx={{
                fontSize: '128px',
                lineHeight: '172px',
                fontWeight: 1000,
                mt: '70px',
                color: 'black',
              }}
            >
              {t('notFoundPage.title', { lng: 'en' })}
            </Typography>
          )}

          {matchesTablet ? null : (
            <Typography
              sx={{
                fontSize: matchesSM ? '32px' : '48px',
                lineHeight: '37px',
                fontWeight: 700,
                whiteSpace: 'pre-line',
                mb: '100px',
                ml: matchesSM ? '33px' : 'auto',
                id: 'tablet',
              }}
            >
              {t('notFoundPage.content', { lng: 'en' })}
            </Typography>
          )}
          {matchesTablet ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mx: '85px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    fontSize: '96px',
                    lineHeight: '118px',
                    fontWeight: 1000,
                    mt: '310px',
                    color: 'black',
                  }}
                >
                  {t('notFoundPage.title', { lng: 'en' })}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '32px',
                    lineHeight: '37px',
                    fontWeight: 700,
                    whiteSpace: 'pre-line',
                    mb: '100px',
                    ml: matchesSM ? '33px' : 'auto',
                  }}
                >
                  {t('notFoundPage.content', { lng: 'en' })}
                </Typography>
              </Box>
              <SvgIcon
                component={NotFoundSVG}
                inheritViewBox
                sx={{
                  width: '237px',
                  height: '526px',
                }}
              />
            </Box>
          ) : null}
          <Typography
            sx={{
              fontSize: matchesSM || matchesTablet ? '24px' : '32px',
              lineHeight: matchesSM || matchesTablet ? '28px' : '43px',
              fontWeight: 700,
              whiteSpace: 'pre-line',
              textAlign: matchesSM || matchesTablet ? 'center' : 'left',
            }}
          >
            {matchesSM
              ? t('notFoundPage.controlContentSmallScreens', { lng: 'en' })
              : t('notFoundPage.controlContent', { lng: 'en' })}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent:
                matchesTablet || matchesSM ? 'center' : 'flex-start',
            }}
          >
            <Button
              component={RouterLink}
              to={`/`}
              variant="contained"
              sx={{
                color: 'common.black',
                textTransform: 'none',
                width: matchesSM || matchesTablet ? '188px' : '262px',
                height: matchesSM || matchesTablet ? '43px' : '63px',
                fontSize: matchesSM || matchesTablet ? '20px' : '32px',
                lineHeight: matchesSM || matchesTablet ? '23px' : '43px',
                fontWeight: 700,
                mt: '20px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {t('notFoundPage.control', { lng: 'en' })}
            </Button>
          </Box>
        </Grid>
        {!matchesSM && !matchesTablet ? (
          <Grid item sx={{ mt: '68px' }}>
            <SvgIcon
              component={NotFoundSVG}
              inheritViewBox
              sx={{
                width: { xs: '100px', sm: '231.7px' },
                height: { xs: 'auto', sm: '514.4px' },
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    </InnerLayout>
  )
}

export default NotFoundPage

NotFoundPage.propTypes = {}
