import { Box, Typography } from '@mui/material'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import UserFavoritesService from 'api/services/UserFavoritesService'
import {
  GetUserFavoritesListDTO,
  UserFavoritesList,
} from 'api/services/UserFavoritesService/types'
import { AdCategoryIdToListCategoryMap } from 'helpers/constants'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

interface ListsButtonProps {
  list: UserFavoritesList
  propertyAlternativeId: string
  onClose: () => void
  propertyAd: ParsedProperty
}

function ListsButton(props: ListsButtonProps) {
  const { enqueueSnackbar } = useSnackbar()

  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const { list, propertyAlternativeId, onClose, propertyAd } = props

  const matchedCategory = AdCategoryIdToListCategoryMap.get(
    propertyAd.adCategoryId
  )

  const IS_FAVORITE = React.useMemo(() => {
    return (
      list?.userFavorites?.length &&
      !!list.userFavorites.find((pAd) => pAd.entityId === propertyAlternativeId)
    )
  }, [propertyAlternativeId, list])

  const addToUserFavoritesListMutation =
    UserFavoritesService.hooks.useAddToUserFavoritesList({
      onSuccess: (data) => {
        if (matchedCategory) {
          queryClient.setQueryData(
            [...UserFavoritesService.keys.QueryKeys.search, matchedCategory],
            (prev: GetUserFavoritesListDTO) => {
              const listIndex = prev?.userFavoritesList?.findIndex(
                (li) => li.id === list.id
              )

              prev?.userFavoritesList?.[listIndex]?.userFavorites?.push({
                id: data.id,
                entityId: data?.alternativeId
                  ? data?.alternativeId
                  : propertyAlternativeId,
                firstImageUrl: null,
              })
              prev.userFavoritesList[listIndex].userFavoritesCount += 1
              return prev
            }
          )
        }

        enqueueSnackbar(
          t('detailPage.addFavorite.action.success') +
            `${' '}` +
            `${list?.name}`,
          {
            variant: 'success',
          }
        )
        onClose()
      },
      onError: () => {
        enqueueSnackbar(t('error.unspecific'), {
          variant: 'error',
        })
      },
    })
  const removeFromUserFavoritesListMutation =
    UserFavoritesService.hooks.useRemoveFromUserFavoritesList({
      onSuccess: () => {
        if (matchedCategory) {
          queryClient.setQueryData(
            [...UserFavoritesService.keys.QueryKeys.search, matchedCategory],
            (prev: GetUserFavoritesListDTO) => {
              const listIndex = prev?.userFavoritesList?.findIndex(
                (li) => li.id === list.id
              )
              prev.userFavoritesList[listIndex].userFavorites =
                list?.userFavorites?.filter(
                  (item) => item.entityId !== propertyAlternativeId
                )
              prev.userFavoritesList[listIndex].userFavoritesCount -= 1
              return prev
            }
          )
        }
        enqueueSnackbar(
          t('detailPage.removeFavorite.action.success') +
            `${' '}` +
            `${list?.name}`,
          {
            variant: 'success',
          }
        )
        onClose()
      },
      onError: (error) => {
        enqueueSnackbar(t('error.unspecific'), {
          variant: 'error',
        })
      },
    })

  const handleAddToFavoritesListClick = () => {
    if (!IS_FAVORITE) {
      addToUserFavoritesListMutation.mutate({
        listId: list.id,
        propertyAdAlternativeId: propertyAlternativeId,
      })
    } else {
      removeFromUserFavoritesListMutation.mutate({
        listId: list.id,
        propertyAdAlternativeId: propertyAlternativeId,
      })
    }
  }

  return (
    // <Grid xs={10}>
    <Box
      sx={{
        mb: '12px',
        px: '12px',
        py: '2px',
        height: '28px',
        maxWidth: '216px',
        width: '100%',

        backgroundColor: IS_FAVORITE ? 'primary.main' : '#ffffff',
        border: IS_FAVORITE ? '0.5px solid #FFBC2D' : '0.5px solid #333333',

        borderStyle: 'inner-border',
        borderRadius: '14px',
        transition: (theme) => theme.transitions.create(['border-color']),
        '&:hover': {
          backgroundColor: IS_FAVORITE ? 'primary.main' : '#ffffff',
          border: '0.5px solid #FFBC2D',
          borderStyle: 'inner-border',
        },
        cursor: 'pointer',
      }}
      onClick={handleAddToFavoritesListClick}
    >
      <Typography
        sx={{
          fontWeight: IS_FAVORITE ? 500 : 400,
          fontFamily: 'Roboto',
          fontSize: '15px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {list?.name}
      </Typography>
    </Box>
    // </Grid>
  )
}

export default ListsButton
