import {
  FormControl,
  FormHelperText,
  InputLabel,
  InputLabelProps,
  Typography,
  TextFieldProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

import CalculatorTextfield from './TextField'

export const CalcInputLabel = styled((props: InputLabelProps) => (
  <InputLabel shrink {...props} />
))<InputLabelProps>(({ theme }) => ({
  fontSize: '20px',
  width: '100%',
  color: 'black',
  whiteSpace: 'normal',
  overflow: 'visible',
  paddingBottom: '4px',
  '&.Mui-focused': {
    color: 'black',
  },
}))

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  label?: string
  TextFieldProps?: TextFieldProps
}

function FormField<T extends FieldValues>(props: Props<T>) {
  const {
    control,
    label = '',
    name,
    TextFieldProps: { children, ...TextProps } = {},
    ...rest
  } = props
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <CalcInputLabel error={!!error}>{label}</CalcInputLabel>
          <FormControl
            variant="standard"
            sx={{
              width: '100%',
            }}
            error={!!error}
          >
            <CalculatorTextfield error={!!error} {...TextProps} {...field}>
              {children ?? null}
            </CalculatorTextfield>
            {error ? (
              <FormHelperText>
                <Typography color="error">{error?.message}</Typography>
              </FormHelperText>
            ) : null}
          </FormControl>
        </>
      )}
      {...rest}
    />
  )
}

export default FormField
