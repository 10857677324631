import React from 'react'
import Box from '@mui/material/Box'
import { useMatch } from 'react-router-dom'
import Container from '@mui/material/Container'
import { Button, Typography } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'

import AppBar from 'components/AppBar'

function GuestLayout(props: { children?: React.ReactNode }) {
  const { children } = props
  const matchSearch = useMatch('/search')
  const matchDetail = useMatch('/property-ads/:adId')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: matchSearch || matchDetail ? 'white' : 'primary.main',
      }}
    >
      <AppBar />
      <Container maxWidth={'xl'}>{children}</Container>
      <Button
        startIcon={<SettingsIcon />}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          klaro.show()
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'black',
          },
          bgcolor: 'black',
          width: '126px',
          height: '30px',
          position: 'fixed',
          justifySelf: 'right',
          bottom: '0px',
          right: '0px',
          opacity: '0.7',
          borderRadius: '1%',
        }}
      >
        <Typography
          align="center"
          color="primary"
          sx={{
            '&:hover': {
              color: 'primary',
              opacity: '1',
            },
            opacity: '0.7',
          }}
        >
          Απορρητο{' '}
        </Typography>
      </Button>
    </Box>
  )
}

export default GuestLayout
