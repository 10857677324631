import React from 'react'
import shallow from 'zustand/shallow'
import { Grid, SxProps, Zoom, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

import SearchInput from 'components/Landing/NumberFormatInput/SearchInput'
import { useSearchStore } from 'store'
import SearchLabel from 'components/Landing/SearchLabel'
import NumberFormatCustom, {
  NumberFormatCustomProps,
} from 'components/Landing/NumberFormatInput'
import useDebounce from 'hooks/useDebounce'
import { ViewVariant } from 'types'
import UpdateControl from './UpdateControl'

export enum NumericFiltersType {
  'bedrooms' = 'Bedrooms',
  'price' = 'Price',
  'bathrooms' = 'WC',
  'rooms' = 'Rooms',
  'constructionYear' = 'ConstructionYear',
  'buildMeters' = 'BuildSqM',
  'squareMeters' = 'SqM',
  'buildFactor' = 'buildFactor',
  'coverageFactor' = 'coverageFactor',
}
export type FieldNum =
  | 'bedrooms'
  | 'price'
  | 'bathrooms'
  | 'rooms'
  | 'constructionYear'
  | 'buildMeters'
  | 'squareMeters'
  | 'coverageFactor'
  | 'buildFactor'

interface NumericFiltersProps {
  variant?: ViewVariant
  sx?: SxProps
  onRefetch?: () => void
  handleCloseFilters?: () => void
  field: FieldNum
  showCurrencyPlaceholder?: boolean
  numberFormatProps?: NumberFormatCustomProps
}

function NumericFilters(props: NumericFiltersProps) {
  const {
    variant,
    onRefetch,
    handleCloseFilters,
    field,
    showCurrencyPlaceholder,
    numberFormatProps = {},
    ...rest
  } = props

  const theme = useTheme()

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()

  const IS_INNER = variant === 'inner'

  const [
    minFieldValue,
    maxFieldValue,
    handleNumChange,
    activeField,
    setActiveField,
    searchCountFn,
  ] = useSearchStore(
    (state) => [
      state[`min${NumericFiltersType[field]}`],
      state[`max${NumericFiltersType[field]}`],
      state.handleNumChange,
      state.activeField,
      state.setActiveField,
      state.searchCountFn,
    ],
    shallow
  )

  const IS_ACTIVE = activeField === field

  const handleChange = (e) => {
    handleNumChange(e.target.value, e.target.name)
    if (IS_INNER && onRefetch) {
      setActiveField(field)
    } else searchCountFn()
  }

  const debouncedHandleChange = useDebounce(handleChange, 600)

  const handleUpdate = () => {
    if (onRefetch) onRefetch()
    setActiveField('')
    if (matchesSM && handleCloseFilters) {
      handleCloseFilters()
    }
  }

  return (
    <>
      <Grid container {...rest}>
        <Grid item xs={12}>
          <SearchLabel
            cVariant={variant}
            isActive={IS_INNER ? true : !!minFieldValue || !!maxFieldValue}
          >
            {t(`landingPage.moreFilters.fields.${field?.toLowerCase()}`)}
          </SearchLabel>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6} sm={6} md={6}>
            <NumberFormatCustom
              value={minFieldValue}
              variant={variant}
              placeholder={`${showCurrencyPlaceholder ? '\u20AC ' : ''}${t(
                `landingPage.moreFilters.minPlaceholder`
              )}`}
              name={`min${NumericFiltersType[field]}`}
              onChange={debouncedHandleChange}
              customInput={SearchInput}
              {...numberFormatProps}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <NumberFormatCustom
              value={maxFieldValue}
              variant={variant}
              placeholder={`${showCurrencyPlaceholder ? '\u20AC ' : ''}${t(
                `landingPage.moreFilters.maxPlaceholder`
              )}`}
              name={`max${NumericFiltersType[field]}`}
              onChange={debouncedHandleChange}
              customInput={SearchInput}
              {...numberFormatProps}
            />
          </Grid>
        </Grid>
      </Grid>
      <Zoom in={IS_ACTIVE} unmountOnExit>
        <div>
          <UpdateControl onChange={handleUpdate} />
        </div>
      </Zoom>
    </>
  )
}

export default NumericFilters
