import React from 'react'
import shallow from 'zustand/shallow'
import { SxProps, Zoom, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

import { useSearchStore } from 'store'
import SearchList from 'components/Landing/SearchList'
import { ViewVariant } from 'types'
import useDebounce from 'hooks/useDebounce'
import UpdateControl from './UpdateControl'
import { SelectDeselectable } from 'store/SearchSlice'
import { GeneralOption } from '../SearchSelect'

export enum ListFiltersType {
  'estatePropertyType' = 'estatePropertyType',
  'commercialType' = 'commercialType',
  'estatePropertyCondition' = 'estatePropertyCondition',
  'thermals' = 'thermals',
  'thermalsType' = 'thermalsType',
  'extras' = 'extras',
  'propertyExtras' = 'propertyExtras',
  'commercialExtras' = 'commercialExtras',
  'landType' = 'landType',
  'landCharacteristics' = 'landCharacteristics',
}

interface ListTypeProps {
  variant?: ViewVariant
  sx?: SxProps
  onRefetch?: () => void
  handleCloseFilters?: () => void
  options: GeneralOption[]
  field: SelectDeselectable
}

export default function ListFilters(props: ListTypeProps) {
  const { variant, onRefetch, handleCloseFilters, options, field, ...rest } =
    props

  const theme = useTheme()

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()

  const IS_INNER = variant === 'inner'

  const [
    fieldValues,
    handleSelectDeselect,
    activeField,
    setActiveField,
    searchCountFn,
  ] = useSearchStore(
    (state) => [
      state[`${field}`],
      state.handleSelectDeselect,
      state.activeField,
      state.setActiveField,
      state.searchCountFn,
    ],
    shallow
  )

  const FIELD = field
  const IS_ACTIVE = activeField === FIELD

  const debouncedFn = useDebounce(IS_INNER ? onRefetch : searchCountFn, 600)

  const handleChange = (option) => {
    handleSelectDeselect(option, FIELD)
    if (IS_INNER) {
      setActiveField(FIELD)
    }
    if (!IS_INNER) {
      debouncedFn()
    }
  }

  const handleUpdate = () => {
    if (onRefetch) onRefetch()
    setActiveField('')
    if (matchesSM && handleCloseFilters) {
      handleCloseFilters()
    }
  }

  return (
    <>
      <SearchList
        variant={variant}
        label={t(`landingPage.moreFilters.fields.${field}`)}
        options={options}
        getOptionKey={(option) => option.value}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option) =>
          !!(fieldValues as GeneralOption[]).find(
            (type) => type.value === option.value
          )
        }
        onChange={handleChange}
        isActive={!!fieldValues?.length}
        {...rest}
      />
      <Zoom in={IS_ACTIVE && IS_INNER} unmountOnExit>
        <div>
          <UpdateControl onChange={handleUpdate} />
        </div>
      </Zoom>
    </>
  )
}
