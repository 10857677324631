import React from 'react'
import { Grid } from '@mui/material'

import GuestLayout from 'layouts/GuestLayout'
import Top from './Top'
import Center from './Center'
import Bottom from './Bottom'
import { useSearchStore } from 'store'

function Landing() {
  const resetForm = useSearchStore((state) => state.resetForm)

  React.useLayoutEffect(() => {
    resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GuestLayout>
      <Grid
        container
        sx={{
          padding: (theme) => ({
            xs: theme.spacing(2, 1, 2),
            sm: theme.spacing(2, 2, 4),
            md: theme.spacing(2, 3, 6),
            lg: theme.spacing(2, 4, 8),
          }),
        }}
      >
        <Grid item xs={12}>
          <Top />
        </Grid>
        <Grid item xs={12}>
          <Center />
        </Grid>
        <Grid item xs={12}>
          <Bottom />
        </Grid>
      </Grid>
    </GuestLayout>
  )
}

export default Landing

Landing.propTypes = {}
