/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

export enum UrlField {
  Page = 'page',
  minPrice = 'minPrice',
  maxPrice = 'maxPrice',
  minConstructionYear = 'minConstructionYear',
  maxConstructionYear = 'maxConstructionYear',
  minSqM = 'minSqM',
  maxSqM = 'maxSqM',
  minWc = 'minWc',
  maxWc = 'maxWc',
  minBeds = 'minBeds',
  maxBeds = 'maxBeds',
  minRooms = 'minRooms',
  maxRooms = 'maxRooms',
  buildFactor = 'buildFactor',
  coverageFactor = 'coverageFactor',
  minBuildSqM = 'minBuildSqM',
  maxBuildSqM = 'maxBuildSqM',
  estateType = 'estateType',
  condition = 'condition',
  commercialExtras = 'commercialExtras',
  commercialType = 'commercialType',
  estateExtras = 'estateExtras',
  thermals = 'thermals',
  thermalsType = 'thermalsType',
  access = 'access',
  landType = 'landType',
  landExtras = 'landExtras',
  characteristics = 'characteristics',
  floorFrom = 'floorFrom',
  floorDirection = 'floorDirection',
  sortingField = 'sortingField',
  sortingBy = 'sortingBy',
  interest = 'interest',
  category = 'category',
}

export enum ListCategoryEnum {
  ESTATE_PROPERTY_SALES = 'ESTATE_PROPERTY_SALES',
  ESTATE_PROPERTY_RENT = 'ESTATE_PROPERTY_RENT',
  ESTATE_COMMERCIAL_SALES = 'ESTATE_COMMERCIAL_SALES',
  ESTATE_COMMERCIAL_RENT = 'ESTATE_COMMERCIAL_RENT',
  ESTATE_LAND_SALES = 'ESTATE_LAND_SALES',
  ESTATE_LAND_RENT = 'ESTATE_LAND_RENT',
}
export enum PropertyAdCategoryEnum {
  ESTATE_PROPERTY_SALES = 1,
  ESTATE_PROPERTY_RENT = 4,
  ESTATE_COMMERCIAL_SALES = 2,
  ESTATE_COMMERCIAL_RENT = 5,
  ESTATE_LAND_SALES = 3,
  ESTATE_LAND_RENT = 6,
}

export enum GA_Events {
  PageView = 'PAGE_VIEW',
  AddToFavorites = 'ADD_FAVORITE',
  RemoveFromFavorites = 'REMOVE_FAVORITE',
  CreateList = 'CREATE_LIST',
  SearchArea = 'SEARCH_AREA',
}

export enum SearchForParamEnum {
  estate = 'katoikia',
  commercial = 'epaggelmatika',
  land = 'gi',
}

export enum SearchInterestParamEnum {
  buy = 'agora',
  rent = 'enoikiasi',
}

export const SearchPropertyTypeParam = {
  katoikia: {
    diamerisma: 'ESTATE_PROPERTY_TYPE#appartment',
    monokatoikia: 'ESTATE_PROPERTY_TYPE#single_residence',
    mezoneta: 'ESTATE_PROPERTY_TYPE#maisonette',
    loipesKatigories: 'ESTATE_PROPERTY_TYPE#rest_types',
    ktirio: 'ESTATE_PROPERTY_TYPE#building',
  } as const,
  epaggelmatika: {
    grafeio: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#office',
    katastima: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#store',
    viomixanikosXoros: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#industrial_area',
    viotexnikosXoros: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#craft_space',
    apothiki: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#warehouse',
    ksenodoxeio: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#hotel',
    ktirio: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#commercial_building',
    aithousa: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#hall',
    parking: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#parking',
    loipesKatigories: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#rest_commercial_types',
  } as const,
  gi: {
    agrotemaxio: 'ESTATE_PROPERTY_LAND_TYPE#plot_of_land',
    nisi: 'ESTATE_PROPERTY_LAND_TYPE#island',
    oikopedo: 'ESTATE_PROPERTY_LAND_TYPE#plot',
    loipesKatigories: 'ESTATE_PROPERTY_LAND_TYPE#other_land_types',
  } as const,
} as const

export enum SupportedLocale {
  Greek = 'el',
}

export enum SearchForEnum {
  Estate = 'estate',
  Land = 'land',
  Commercial = 'commercial',
}
