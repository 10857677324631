import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug:
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging'
        ? false
        : true,
    lng: 'gr',
    fallbackLng: 'en',
    whitelist: ['en', 'gr'],
    backend: {
      loadPath: `${
        process.env.REACT_APP_ENV === 'dev' ? '.' : ''
      }/locales/{{lng}}/{{ns}}.json`,
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'sup'],
      useSuspense: true,
    },
  })

export default i18n
