import React from 'react'
import {
  Button,
  Box,
  TextField,
  Grid,
  Typography,
  Link,
  CircularProgress,
} from '@mui/material'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { grey } from '@mui/material/colors'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContactService } from 'api/services/ContactService'
import { useSnackbar } from 'notistack'

import { ContactSpourgitiDTO } from 'api/services/ContactService/types'
import ContactImage from 'assets/svg/contact.svg'
import { EMAIL_REGEX } from 'helpers/constants'
import InnerLayout from 'layouts/InnerLayout'
import usePageView from 'hooks/usePageView'

const DefaultValues = {
  fullName: '',
  senderEmail: '',
  subject: '',
  text: '',
}

const ContactPage: React.FC = () => {
  usePageView()

  const { enqueueSnackbar } = useSnackbar()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const createGoogleToken = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }
    return await executeRecaptcha()
  }, [executeRecaptcha])

  const { control, handleSubmit, reset } = useForm<ContactSpourgitiDTO>({
    defaultValues: DefaultValues,
  })

  const onSubmit = async (data) => {
    const token = await createGoogleToken()
    data.token = token
    contactSpourgitiMutation.mutate(data)
  }

  const contactSpourgitiMutation = ContactService.hooks.useContactSpourgiti({
    onSuccess: () => {
      reset(DefaultValues)
      enqueueSnackbar(t('contactPage.successMessage'), {
        variant: 'success',
      })
    },
    onError: () => {
      enqueueSnackbar(t('error.unspecific'), {
        variant: 'error',
      })
    },
  })

  const { t } = useTranslation()
  return (
    <InnerLayout>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          my: 5,
        }}
      >
        <Grid
          container
          component="form"
          justifyContent="center"
          onSubmit={handleSubmit(onSubmit)}
          alignItems="center"
          direction="column"
          rowSpacing={4}
          sx={{ width: (theme) => theme.breakpoints.values.md }}
        >
          <Grid container item>
            <Grid
              item
              sx={{
                border: 1,
                borderColor: grey[300],
                boxShadow: 25,
                borderRadius: 2.5,
                bgcolor: 'background.paper',
                p: 4,
              }}
              xs={12}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    align="center"
                    sx={{ fontWeight: 'bolder', pb: '25px' }}
                  >
                    {t('contactPage.form.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    rules={{
                      required: `${t('validation.fieldRequired')}`,
                    }}
                    name="fullName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        label={t('contactPage.form.name')}
                        variant="filled"
                        error={!!error}
                        helperText={error?.message}
                        fullWidth
                        {...field}
                      >
                        {t('contactPage.form.name')}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    rules={{
                      required: `${t('validation.fieldRequired')}`,
                      pattern: {
                        value: EMAIL_REGEX,
                        message: `${t('contactPage.form.emailWrongFormat')}`,
                      },
                    }}
                    name="senderEmail"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        label={t('contactPage.form.email')}
                        variant="filled"
                        error={!!error}
                        helperText={error?.message}
                        fullWidth
                        {...field}
                      >
                        {t('contactPage.form.email')}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="subject"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        label={t('contactPage.form.subject')}
                        variant="filled"
                        error={!!error}
                        helperText={error?.message}
                        fullWidth
                        {...field}
                      >
                        {t('contactPage.form.subject')}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="text"
                    control={control}
                    rules={{
                      required: `${t('validation.fieldRequired')}`,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        label={t('contactPage.form.message')}
                        variant="filled"
                        multiline={true}
                        rows={5}
                        error={!!error}
                        helperText={error?.message}
                        fullWidth
                        {...field}
                      >
                        {t('contactPage.form.message')}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{ textTransform: 'none', minHeight: 50 }}
                  >
                    {contactSpourgitiMutation.isLoading ? (
                      <CircularProgress style={{ color: 'white' }} size={25} />
                    ) : (
                      <Typography fontSize={25}>
                        {t('contactPage.form.submitButton')}
                      </Typography>
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="caption" sx={{ color: 'lightgrey' }}>
                    This site is protected by reCAPTCHA and the Google Privacy
                    Policy and Terms of Service apply.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid
              item
              lg={9}
              sm={8}
              xs={12}
              sx={{
                border: 1,
                borderColor: grey[300],
                boxShadow: 25,
                borderRadius: 2.5,
                py: 3,
              }}
            >
              <Link
                href="mailto:info@spourgiti.com"
                color="inherit"
                underline="none"
                variant="h5"
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    mt: '5px',
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: 'bolder' }}>
                    {t('contactPage.emailCard')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      flexWrap: 'wrap',
                      mt: '20px',
                    }}
                  >
                    <AlternateEmailIcon
                      sx={{
                        fontSize: '30px',
                        color: (theme) => theme.palette.primary.main,
                        width: '100%',
                      }}
                    ></AlternateEmailIcon>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      flexWrap: 'wrap',
                      mt: '25px',
                    }}
                  >
                    <Typography variant="h5">info@spourgiti.com</Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item lg={3} sm={4} xs={12} sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={ContactImage}
                sx={{
                  width: '100%',
                  maxWidth: { xs: 150, sm: 150 },
                  height: { xs: '100%', md: 200 },
                  mt: { xs: 2, sm: 0 },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </InnerLayout>
  )
}

export default ContactPage
