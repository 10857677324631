import * as React from 'react'
import { styled } from '@mui/material/styles'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import { NumberFormatProps } from 'react-number-format'
// InputBaseProps
interface SearchInputProps extends NumberFormatProps {
  selected?: boolean
  variant?: string
}

type Props = InputBaseProps
type Ref = HTMLInputElement

const CustomInput = React.forwardRef<Ref, Props>(function Input(props, ref) {
  return <InputBase id="searchAreaInput" inputRef={ref} {...props} />
})

const SearchInput = styled(CustomInput)<SearchInputProps>(
  ({ variant, theme, ...rest }) => ({
    width: '100%',
    borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontWeight: 900,
      },
      position: 'relative',
      backgroundColor: 'transparent',
      fontSize: 30,
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
      fontWeight: 900,
      ...(variant === 'inner' && {
        fontSize: 20,
        fontWeight: 900,
        [theme.breakpoints.up('md')]: {
          fontSize: 20,
        },
      }),
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
    },
    ...rest,
  })
)

export default SearchInput
