import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Container, BoxProps } from '@mui/material'

import Copyright from './Copyright'
import Social from './Links'

const FootWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-evenly',
    flexFlow: 'column',
  },
}))

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'common.black',
        mt: 'auto',
        p: 2,
      }}
    >
      <Container maxWidth="xl">
        <FootWrapper>
          <Copyright />
          <Social />
        </FootWrapper>
      </Container>
    </Box>
  )
}
export default Footer
