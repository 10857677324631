import { useQuery, QueryKey, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import api from 'api'
import { ReferenceDataDocument } from 'types'

const SERVICE_NAME = `ReferenceData`

const QueryKeys = {
  all: `${SERVICE_NAME}#all`,
  single: `${SERVICE_NAME}#single`,
} as const

const MutationKeys = {} as const

const CONTROLLER_PATH = `/app/spourgiti/api/application-config`

// GET single
export const getReferenceDataSingle = async ({
  queryKey,
}): Promise<ReferenceDataDocument> => {
  const [, id] = queryKey

  const { data } = await api({
    method: 'get',
    url: `${CONTROLLER_PATH}/${id}`,
  })
  return data
}
export const useReferenceDataSingle = <T = ReferenceDataDocument>(
  id: number,
  options: UseQueryOptions<T, AxiosError, ReferenceDataDocument> = {}
) => {
  return useQuery<ReferenceDataDocument>(
    [QueryKeys.single, id],
    getReferenceDataSingle,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      ...options,
    } as Omit<
      UseQueryOptions<
        ReferenceDataDocument,
        unknown,
        ReferenceDataDocument,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >
  )
}

export const getReferenceData = async (): Promise<ReferenceDataDocument[]> => {
  const { data } = await api({
    method: 'get',
    url: `${CONTROLLER_PATH}/reference-data`,
  })
  return data
}

export const useReferenceData = <T = ReferenceDataDocument[]>(
  options: UseQueryOptions<T, AxiosError, ReferenceDataDocument[]> = {}
) => {
  return useQuery<ReferenceDataDocument[]>([QueryKeys.all], getReferenceData, {
    cacheTime: Infinity,
    staleTime: Infinity,
    ...options,
  } as Omit<UseQueryOptions<ReferenceDataDocument[], unknown, ReferenceDataDocument[], QueryKey>, 'queryKey' | 'queryFn'>)
}

const ReferenceDataService = {
  getReferenceData,
  hooks: {
    useReferenceData,
  },
  keys: { QueryKeys, MutationKeys },
}

export default ReferenceDataService
