import * as React from 'react'
import {
  Box,
  Stack,
  Typography,
  Divider,
  Tooltip,
  Avatar,
  SvgIcon,
  DividerProps,
  Link,
  TypographyProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SeparatorPNG from 'assets/img/seperator.png'
import { I18nAddress } from 'types'
import { ReactComponent as HomeSVG } from 'assets/svg/icons/home-icon.svg'
import { ReactComponent as TelSVG } from 'assets/svg/tel.svg'
import { ReactComponent as WwwSVG } from 'assets/svg/www.svg'
import { IAgent } from 'api/services/Agent/types'

const BlackTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 700,
  fontSize: 23,
  textAlign: 'center',
  color: theme.palette.text.primary,
}))

const StyledDivider = styled((props) => (
  <Divider variant="middle" flexItem {...props} />
))<DividerProps>(() => ({}))

interface IProps {
  data: IAgent
}

const AgentCard = React.forwardRef(function FeatureCardInner(props: IProps) {
  const { data } = props
  const { t } = useTranslation()

  const options = [
    {
      icon: (
        <SvgIcon
          color="primary"
          component={TelSVG}
          inheritViewBox
          sx={{ width: 'auto', height: 30, fill: 'primary.main' }}
        />
      ),
      tooltip: t('findRealtorPage.card.phones'),
      valueGetter: (data) => {
        if (!data.contactInfo?.length) return undefined
        let indexToDisplay = data.contactInfo.findIndex(
          (item) => item?.defaultContactInfo === true
        )
        if (indexToDisplay === -1) indexToDisplay = 0
        if (
          data.contactInfo[indexToDisplay]?.landLineNumber ||
          data.contactInfo[indexToDisplay]?.mobileNumber ||
          data.contactInfo[indexToDisplay]?.extraNumber
        ) {
          return (
            <Stack sx={{ cursor: 'pointer' }}>
              <Link
                underline="none"
                variant="caption"
                color="textPrimary"
                href={`tel:${data.contactInfo[indexToDisplay]?.landLineNumber}`}
              >
                {data.contactInfo[indexToDisplay]?.landLineNumber}
              </Link>
              <Link
                underline="none"
                variant="caption"
                color="textPrimary"
                href={`tel:${data.contactInfo[indexToDisplay]?.mobileNumber}`}
              >
                {data.contactInfo[indexToDisplay]?.mobileNumber}
              </Link>
              <Link
                underline="none"
                variant="caption"
                color="textPrimary"
                href={`tel:${data.contactInfo[indexToDisplay]?.extraNumber}`}
              >
                {data.contactInfo[indexToDisplay]?.extraNumber}
              </Link>
            </Stack>
          )
        }
        return undefined
      },
    },
    {
      icon: (
        <SvgIcon
          color="primary"
          component={WwwSVG}
          inheritViewBox
          sx={{ width: 'auto', height: 30, fill: 'primary.main' }}
        />
      ),
      tooltip: t('findRealtorPage.card.website'),
      valueGetter: (data) => {
        if (!data?.contactInfo?.length) return undefined
        let indexToDisplay = data?.contactInfo.findIndex(
          (item) => item?.defaultContactInfo === true
        )
        if (indexToDisplay === -1) indexToDisplay = 0
        const socialWebsite = data?.contactInfo[
          indexToDisplay
        ]?.socialMediaInfo.find((item) => item?.mediaTitle === 'Website')
        if (!socialWebsite) return '-'
        return (
          <Link
            variant="caption"
            color="textPrimary"
            href={`//${socialWebsite.url}`}
            target="_blank"
          >
            {t('findRealtorPage.card.website')}
          </Link>
        )
      },
    },
    {
      icon: null,
      tooltip: t('findRealtorPage.card.social'),
      valueGetter: (data) => {
        if (!data?.contactInfo?.length) return undefined
        let indexToDisplay = data?.contactInfo.findIndex(
          (item) => item?.defaultContactInfo === true
        )
        if (indexToDisplay === -1) indexToDisplay = 0
        if (!data.contactInfo[indexToDisplay].socialMediaInfo.length)
          return undefined
        const socialMedia = data.contactInfo[
          indexToDisplay
        ].socialMediaInfo.filter((item) => item.mediaTitle !== 'Website')

        return (
          <>
            <Stack direction="row">
              {socialMedia?.map((social) => (
                <Link
                  key={social?.mediaTitle}
                  variant="caption"
                  color="inherit"
                  href={`${social?.url}`}
                  target="_blank"
                >
                  {social?.mediaTitle === 'Facebook' ? (
                    <>
                      <FacebookIcon
                        color="primary"
                        sx={{ width: 'auto', height: 35 }}
                      />
                    </>
                  ) : null}
                  {social?.mediaTitle === 'Twitter' ? (
                    <TwitterIcon
                      color="primary"
                      sx={{ width: 'auto', height: 35 }}
                    />
                  ) : null}
                  {social?.mediaTitle === 'Instagram' ? (
                    <InstagramIcon
                      color="primary"
                      sx={{ width: 'auto', height: 35 }}
                    />
                  ) : null}
                </Link>
              ))}
            </Stack>

            {socialMedia.length !== 0 ? (
              <Typography variant="caption" color="textPrimary">
                Social Media
              </Typography>
            ) : (
              <Box sx={{ pt: 4.5 }}>
                <Typography variant="caption" color="textSecondary">
                  Social Media
                </Typography>
              </Box>
            )}
          </>
        )
      },
    },
  ]

  const renderIcons = (data) => {
    return options.map((option, index) => {
      return (
        <React.Fragment key={option.tooltip}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{ width: '33.33%' }}
          >
            <Tooltip title={option.tooltip} placement="top">
              <>
                {option.icon ? (
                  <Avatar
                    key={'av' + option.tooltip}
                    sx={{ bgcolor: 'common.white' }}
                  >
                    {option.icon}
                  </Avatar>
                ) : (
                  <>{`-`}</>
                )}
              </>
            </Tooltip>

            {option.valueGetter(data)}
          </Stack>
        </React.Fragment>
      )
    })
  }

  const renderLogo = (data) => {
    if (!data.logoURL?.length) {
      return (
        <SvgIcon
          component={HomeSVG}
          color="primary"
          inheritViewBox
          style={{
            width: 'auto',
            height: '100%',
            maxHeight: 250,
          }}
        />
      )
    } else {
      return (
        <Box
          sx={{
            width: 'auto',
            height: 250,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={data.logoURL}
            alt="logo"
            sx={{
              width: '100%',
              height: 'auto',
              maxHeight: 250,
            }}
          />
        </Box>
      )
    }
  }

  const renderStreetInfo = (address: I18nAddress) => {
    if (address.street) {
      return (
        <BlackTypography sx={{ fontSize: '1rem', fontWeight: 500 }}>
          {`${address?.street} ${address?.streetNumber ?? ''}`}
        </BlackTypography>
      )
    }
    return null
  }
  const renderCityAreaCode = (address: I18nAddress) => {
    if (address?.city || address?.area || address?.postalCode) {
      return (
        <BlackTypography sx={{ fontSize: '1rem', fontWeight: 500 }}>
          {address?.city ?? ''}
          {address?.area ? ` - ${address?.area ?? ''}` : null}
          <br />
          {address?.postalCode ? ` ${address?.postalCode}` : null}
        </BlackTypography>
      )
    }
    return null
  }

  const renderCompanyInfo = (data) => {
    if (!data.contactInfo?.length) return false
    let indexToDisplay = data?.contactInfo.findIndex(
      (item) => item.defaultContactInfo === true
    )
    if (indexToDisplay === -1) indexToDisplay = 0

    if (data.contactInfo[indexToDisplay]?.address) {
      return (
        <Stack>
          {renderStreetInfo(data.contactInfo[indexToDisplay]?.address)}
          {renderCityAreaCode(data.contactInfo[indexToDisplay]?.address)}
        </Stack>
      )
    }
    return undefined
  }
  const renderCompanyName = (data) => {
    return (
      <BlackTypography sx={{ fontSize: 25 }}>
        {data?.companyTitle ?? ''}
      </BlackTypography>
    )
  }

  return (
    <Box
      sx={{
        // width: { sm: 250, md: 300, lg: 330 },
        wordBreak: 'break-word',
        height: '100%',
        width: '100%',
      }}
    >
      <Stack
        direction={'column'}
        justifyContent="space-between"
        alignItems="stretch"
        sx={{
          height: '100%',
          boxShadow: 25,
          border: '1px solid grey',
          borderRadius: 2.5,
          px: 3,
          py: 4,
        }}
        spacing={1}
      >
        {renderLogo(data)}
        <Link
          underline="none"
          color="textPrimary"
          component={RouterLink}
          to={`/estate-agents/${data.id}`} // To be changed with agent id
        >
          {renderCompanyName(data)}
          <StyledDivider sx={{ my: 2 }} />
          {renderCompanyInfo(data)}
        </Link>
        <Box
          component="img"
          sx={{
            maxWidth: 'fit-content',
            alignSelf: 'center',
          }}
          src={SeparatorPNG}
          alt="separator"
        />
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          alignContent="flex-start"
          spacing={1}
        >
          {renderIcons(data)}
        </Stack>
      </Stack>
    </Box>
  )
})

export default AgentCard

AgentCard.propTypes = {}
