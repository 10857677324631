import React from 'react'
import { Grid, Typography, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import PropertyExpensesService from 'api/services/PropertyExpenses'
import Buy from './Buy'
import Loan from './Loan'
import { type FormFields } from './types'
import InnerLayout from 'layouts/InnerLayout'
import { useCalculatorStore } from 'store'
import usePageView from 'hooks/usePageView'

const Calculator: React.FC = () => {
  const { t } = useTranslation()

  usePageView()

  const [interestRate, financePercentage] = useCalculatorStore((state) => [
    state.interestRate,
    state.financePercentage,
  ])

  const { handleSubmit, control, reset, setValue, watch } = useForm<FormFields>(
    {
      defaultValues: {
        propertyValue: 0,
        rightOfMainResidence: 0,
        maritalStatus: 'MARRIED',
        children: 0,
        mortageVat: 1,
        category: 'ESTATE_BUY',
        financingPercent: financePercentage,
        duration: 10,
        interestRate: interestRate,
      },
    }
  )

  const watchedPropertyValue = watch('propertyValue', 0)
  const watchedPercentage = watch('financingPercent', 0)

  React.useEffect(() => {
    setValue(
      'financingAmount',
      (Number(watchedPropertyValue) * (watchedPercentage / 100)).toFixed(2) ?? 0
    )
  }, [watchedPropertyValue, watchedPercentage, setValue])

  const calculateExpenses = PropertyExpensesService.hooks.useCalculateExpenses()

  const onSubmit = (data) => {
    calculateExpenses.mutate({
      duration: Number.parseInt(data?.duration),
      foundationPercentage: Number(data.financingPercent),
      interestRatePercentage: Number(data.interestRate),
      landRegistryVat: Boolean(data.mortageVat),
      maritalStatus: data.maritalStatus,
      numberOfChildren: data.children,
      propertyCategory: data.category,
      propertyCost: Number(data.propertyValue),
      rightOfMainResidence: Boolean(data.rightOfMainResidence),
    })
  }

  return (
    <InnerLayout>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ my: 6, px: { xs: 0.5, sm: 2, md: 2, lg: 4, xl: 6 } }}
      >
        <Box sx={{ py: 1 }}>
          <Stack
            direction="row"
            alignItems="baseline"
            spacing={2}
            sx={{ pt: 2, pb: 4 }}
          >
            <Box
              sx={{
                flexGrow: 1,
                borderBottom: (theme) =>
                  `4px solid ${theme.palette.primary.main}`,
              }}
            />
            <Typography align="center" variant="h5" sx={{ fontWeight: 600 }}>
              {t('calculatorPage.title')}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                borderBottom: (theme) =>
                  `4px solid ${theme.palette.primary.main}`,
              }}
            />
          </Stack>
          <Grid container columnSpacing={3}>
            <Grid item sm={12} md={6} lg={6}>
              <Buy
                value={100000}
                control={control}
                data={calculateExpenses.data}
                loading={calculateExpenses.isLoading}
                reqError={calculateExpenses.isError}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <Loan
                value={100000}
                control={control}
                data={calculateExpenses.data?.installment}
                loading={calculateExpenses.isLoading}
                reset={reset}
                reqError={calculateExpenses.isError}
              />
            </Grid>
          </Grid>
          <Typography
            align="center"
            fontWeight="bolder"
            fontSize={16}
            sx={{ whiteSpace: 'pre', pt: 2 }}
          >
            {t('detailPage.calculator.subtitle')}
          </Typography>
        </Box>
      </Box>
    </InnerLayout>
  )
}

export default Calculator
