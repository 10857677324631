/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Grid } from '@mui/material'

import SearchControls from 'components/Search/SearchControls'
import SearchBar from './SearchBar'
import ShowMoreControl from './ShowMoreControl'
import { useSearchStore } from 'store'
import NumericFilters from 'components/Search/SearchMore/NumericFilters'

function Center() {
  const [areas, searchCountFn] = useSearchStore((state) => [
    state.areas,
    state.searchCountFn,
  ])

  React.useEffect(() => {
    searchCountFn()
  }, [areas])

  return (
    <Grid container>
      <Grid item sm={12} md={6} lg={6} container direction={'column'}>
        <Grid item>
          <SearchBar />
        </Grid>
        <Grid item>
          <NumericFilters field={'price'} showCurrencyPlaceholder />
        </Grid>
        <Grid item>
          <NumericFilters field={'squareMeters'} />
        </Grid>
      </Grid>
      <Grid item sm={12} md={6} lg={6}>
        <Grid
          container
          direction="column"
          alignItems={{ sm: 'stretch', md: 'center', lg: 'flex-start' }}
          wrap="nowrap"
          spacing={1}
        >
          <Grid item sx={{ ml: { md: 2, lg: 3, xl: 8 } }}>
            <SearchControls />
          </Grid>
          <Grid item sx={{ ml: { md: 2, lg: 3, xl: 8 } }}>
            <ShowMoreControl />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Center
