import {
  useQuery,
  useMutation,
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query'

import api from 'api'
import { PropertyAd } from 'types'
import {
  SearchPropertyAdPayload,
  CountByCriteriaReturnType,
  ParsedProperty,
  SearchAndCountAgentReturn,
  MappedObject,
} from './types'

import { parseTags } from 'components/Detail/helpers'
import { AxiosError } from 'axios'

const TIME = 1000 * 60 * 10 // 10 mins

const SERVICE_NAME = `PropertyAd`

const QueryKeys = {
  single: `${SERVICE_NAME}#single`,
} as const

const MutationKeys = {
  count: `${SERVICE_NAME}#count`,
  search: `${SERVICE_NAME}#search`,
  searchAndCount: `${SERVICE_NAME}#searchAndCount`,
} as const

const CONTROLLER_PATH = `/app/spourgiti/api/propertyad`

function parseProperty(property: PropertyAd): ParsedProperty {
  const parsedTags = parseTags(property) as MappedObject
  const propertyRef: ParsedProperty = property
  propertyRef.parsedTags = parsedTags
  return propertyRef
}

// GET id
export const getPropertyAd = async ({ queryKey }): Promise<ParsedProperty> => {
  const [, id] = queryKey
  const { data } = await api({
    method: 'get',
    url: `/app/spourgiti/admin/propertyAd/alternativeId/${id}`,
  })
  if (data) {
    return parseProperty(data)
  }
  return data
}
export const usePropertyAd = (id, queryOptions = {}) => {
  return useQuery<ParsedProperty, AxiosError, ParsedProperty>(
    [QueryKeys.single, id],
    getPropertyAd,
    {
      staleTime: id ? TIME : 0,
      cacheTime: id ? TIME : 0,
      ...queryOptions,
    } as Omit<
      UseQueryOptions<ParsedProperty, AxiosError, ParsedProperty>,
      'queryKey' | 'queryFn'
    >
  )
}

// Count by criteria
export const getCountByCriteria = async (
  payload: Partial<SearchPropertyAdPayload>
): Promise<CountByCriteriaReturnType> => {
  const { data } = await api({
    method: 'post',
    url: `${CONTROLLER_PATH}/count-by-criteria`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCountByCriteria = (
  queryOptions: Omit<
    UseMutationOptions<
      CountByCriteriaReturnType,
      AxiosError,
      Partial<SearchPropertyAdPayload>
    >,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.count, getCountByCriteria, {
    ...queryOptions,
  } as Omit<UseMutationOptions<CountByCriteriaReturnType, AxiosError, Partial<SearchPropertyAdPayload>>, 'mutationKey' | 'mutationFn'>)
}

// Search PropertyAd
export const searchByCriteria = async (
  payload: SearchPropertyAdPayload
): Promise<PropertyAd[] | null> => {
  const { data } = await api({
    method: 'post',
    url: `${CONTROLLER_PATH}/search-by-criteria`,
    data: {
      ...payload,
    },
  })

  if (data?.length) return data?.map((item) => parseProperty(item))
  else return null
}
export const useSearchByCriteria = ({
  queryOptions = {},
}: {
  queryOptions: Omit<
    UseMutationOptions<
      PropertyAd[] | null,
      AxiosError,
      SearchPropertyAdPayload
    >,
    'mutationKey' | 'mutationFn'
  >
}) => {
  return useMutation(MutationKeys.search, searchByCriteria, {
    ...queryOptions,
  } as Omit<UseMutationOptions<PropertyAd[] | null, AxiosError, SearchPropertyAdPayload>, 'mutationKey' | 'mutationFn'>)
}

// searchAndCountAgent
export const searchAndCountAgent = async (
  payload: Partial<SearchPropertyAdPayload>
): Promise<SearchAndCountAgentReturn> => {
  const { data } = await api({
    method: 'post',
    url: `${CONTROLLER_PATH}/count-by-criteria`,
    data: {
      ...payload,
    },
  })
  let items: ParsedProperty[] = []
  if (data?.propertyadsCount) {
    const { data } = await api({
      method: 'post',
      url: `${CONTROLLER_PATH}/search-by-criteria`,
      data: {
        ...payload,
      },
    })
    if (data && !!data?.length) {
      items = data.map((item) => parseProperty(item))
    }
  }
  return {
    count: data?.propertyadsCount ?? 0,
    items,
  } as SearchAndCountAgentReturn
}
export const useCountAndSearchAgent = (
  queryOptions: Omit<
    UseMutationOptions<
      SearchAndCountAgentReturn,
      AxiosError,
      Partial<SearchPropertyAdPayload>
    >,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.searchAndCount, searchAndCountAgent, {
    ...queryOptions,
  } as Omit<UseMutationOptions<SearchAndCountAgentReturn, AxiosError, Partial<SearchPropertyAdPayload>>, 'mutationKey' | 'mutationFn'>)
}

const PropertyAdService = {
  getPropertyAd,
  getCountByCriteria,
  searchAndCountAgent,
  searchByCriteria,
  hooks: {
    usePropertyAd,
    useCountByCriteria,
    useCountAndSearchAgent,
    useSearchByCriteria,
  },
  keys: { QueryKeys, MutationKeys },
}

export default PropertyAdService
