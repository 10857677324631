import React from 'react'

const useIsOverflow = (
  ref,
  deps: any[] = [],
  callback: (boolean) => void = () => {}
) => {
  const [isOverflow, setIsOverflow] = React.useState<boolean>()

  const callbackRef = React.useRef(callback)
  React.useLayoutEffect(() => {
    callbackRef.current = callback
  })

  React.useLayoutEffect(() => {
    const { current } = ref

    const { clientWidth, scrollWidth, clientHeight, scrollHeight } = current

    const isVerticalOverflow = scrollHeight > clientHeight

    const isHorizontalOverflow = scrollWidth > clientWidth

    const trigger = () => {
      const hasOverflow = isVerticalOverflow || isHorizontalOverflow
      setIsOverflow(hasOverflow)
      if (callbackRef.current) callbackRef.current(hasOverflow)
    }

    if (current) {
      trigger()
    }
  }, [ref, deps])

  return { isOverflow }
}

export default useIsOverflow
