import React from 'react'
import {
  Typography,
  Button,
  Container,
  Fab,
  Box,
  Slide,
  useMediaQuery,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import { useTheme } from '@mui/material/styles'
import { ExpandLess } from '@mui/icons-material'
import { useMatch } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import Footer from 'components/Footer'
import AppBar from 'components/AppBar'

function InnerLayout(props: { children?: React.ReactNode }) {
  const { children } = props

  const theme = useTheme()

  const { ref, inView } = useInView({
    threshold: 0,
  })

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))

  const matchSearch = useMatch('/search')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: 'white',

        position: 'relative',
      }}
    >
      <AppBar variant="inner" ref={ref} />

      <Container maxWidth={'xl'}>{children}</Container>

      {matchSearch ? null : <Footer />}
      <Slide in={!inView && !!matchSearch} direction="up" unmountOnExit>
        <Fab
          size={matchesMD ? 'small' : 'large'}
          sx={{
            position: 'fixed',
            bottom: { xs: '1rem', md: '2rem' },
            right: { xs: '1rem', md: '2rem' },
          }}
          onClick={handleScrollTop}
          color="primary"
        >
          <ExpandLess />
        </Fab>
      </Slide>
      <Button
        startIcon={<SettingsIcon />}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          klaro.show()
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'black',
          },
          bgcolor: 'black',
          width: '126px',
          height: '30px',
          position: 'fixed',
          justifySelf: 'right',
          bottom: '0px',
          right: '0px',
          opacity: '0.7',
          borderRadius: '1%',
          zIndex: '700',
        }}
      >
        <Typography
          variant="subtitle2"
          align="center"
          color="primary"
          sx={{
            '&:hover': {
              color: 'primary',
              opacity: '1',
            },
            opacity: '0.7',
          }}
        >
          Απορρητο{' '}
        </Typography>
      </Button>
    </Box>
  )
}

export default InnerLayout
