/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectDeselectable, SetNumable } from 'store/SearchSlice'
import {
  ESTATE_CONDITION_OPTIONS,
  LAND_CHARACTERISTIC_OPTIONS,
} from 'store/SearchSlice/constants'
import { UrlField } from 'types/enums'
import {
  ESTATE_EXTRAS_OPTIONS,
  COMMERCIAL_EXTRAS_OPTIONS,
  ESTATE_TYPE_OPTIONS,
  LAND_TYPE_OPTIONS,
  COMMERCIAL_TYPE_OPTIONS,
  LAND_EXTRAS_OPTIONS,
  ESTATE_THERMAL_TYPE_OPTIONS,
  HEAT_OPTIONS,
} from 'helpers/constants'

export type Variable = {
  [key: string]: string | number | any[] | ''
}

export const variableToString = (varObj: Variable) =>
  Object.keys(varObj)[0] as keyof typeof UrlField

const formFieldsToUrl: Map<string, string> = new Map([
  ['minPrice', UrlField.minPrice],
  ['maxPrice', UrlField.maxPrice],
  ['minConstructionYear', UrlField.minConstructionYear],
  ['maxConstructionYear', UrlField.maxConstructionYear],
  ['minSqM', UrlField.minSqM],
  ['maxSqM', UrlField.maxSqM],
  ['minWC', UrlField.minWc],
  ['maxWC', UrlField.maxWc],
  ['minBedrooms', UrlField.minBeds],
  ['maxBedrooms', UrlField.maxBeds],
  ['minRooms', UrlField.minRooms],
  ['maxRooms', UrlField.maxRooms],
  ['buildFactor', UrlField.buildFactor],
  ['coverageFactor', UrlField.coverageFactor],
  ['minBuildSqM', UrlField.minBuildSqM],
  ['maxBuildSqM', UrlField.maxBuildSqM],
  ['estatePropertyType', UrlField.estateType],
  ['commercialExtras', UrlField.commercialExtras],
  ['commercialType', UrlField.commercialType],
  ['estatePropertyCondition', UrlField.condition],
  ['propertyExtras', UrlField.estateExtras],
  ['landCharacteristics', UrlField.characteristics],
  ['thermals', UrlField.thermals],
  ['thermalsType', UrlField.thermalsType],
  ['access', UrlField.access],
  ['landType', UrlField.landType],
  ['extras', UrlField.landExtras],
  ['floor', UrlField.floorFrom],
  ['floorDirection', UrlField.floorDirection],
  ['page', UrlField.Page],
])

type FormFieldsMap = {
  field: string
  type: string
  options?: any
}

const urlFieldsToStore: Map<string, FormFieldsMap> = new Map([
  [UrlField.interest, { field: 'searchFor', type: 'single' }],
  [UrlField.category, { field: 'searchBy', type: 'single' }],
  [UrlField.minPrice, { field: 'minPrice', type: 'numeric' }],
  [UrlField.maxPrice, { field: 'maxPrice', type: 'numeric' }],
  [UrlField.minSqM, { field: 'minSqM', type: 'numeric' }],
  [UrlField.maxSqM, { field: 'maxSqM', type: 'numeric' }],
  [UrlField.minWc, { field: 'minWC', type: 'numeric' }],
  [UrlField.maxWc, { field: 'maxWC', type: 'numeric' }],
  [UrlField.minBeds, { field: 'minBedrooms', type: 'numeric' }],
  [UrlField.maxBeds, { field: 'maxBedrooms', type: 'numeric' }],
  [UrlField.minRooms, { field: 'minRooms', type: 'numeric' }],
  [UrlField.maxRooms, { field: 'maxRooms', type: 'numeric' }],
  [UrlField.buildFactor, { field: 'buildFactor', type: 'numeric' }],
  [UrlField.coverageFactor, { field: 'coverageFactor', type: 'numeric' }],
  [UrlField.minBuildSqM, { field: 'minBuildSqM', type: 'numeric' }],
  [UrlField.maxBuildSqM, { field: 'maxBuildSqM', type: 'numeric' }],
  [
    UrlField.minConstructionYear,
    { field: 'minConstructionYear', type: 'numeric' },
  ],
  [
    UrlField.maxConstructionYear,
    { field: 'maxConstructionYear', type: 'numeric' },
  ],
  [
    UrlField.estateType,
    {
      field: 'estatePropertyType' as SelectDeselectable,
      type: 'multiple',
      options: ESTATE_TYPE_OPTIONS,
    },
  ],
  [
    UrlField.commercialExtras,
    {
      field: 'commercialExtras' as SelectDeselectable,
      type: 'multiple',
      options: COMMERCIAL_EXTRAS_OPTIONS,
    },
  ],
  [
    UrlField.commercialType,
    {
      field: 'commercialType' as SelectDeselectable,
      type: 'multiple',
      options: COMMERCIAL_TYPE_OPTIONS,
    },
  ],
  [
    UrlField.condition,
    {
      field: 'estatePropertyCondition' as SelectDeselectable,
      type: 'multiple',
      options: ESTATE_CONDITION_OPTIONS,
    },
  ],
  [
    UrlField.estateExtras,
    {
      field: 'propertyExtras' as SelectDeselectable,
      type: 'multiple',
      options: ESTATE_EXTRAS_OPTIONS,
    },
  ],
  [
    UrlField.characteristics,
    {
      field: 'landCharacteristics',
      type: 'multiple',
      options: LAND_CHARACTERISTIC_OPTIONS,
    },
  ],
  [
    UrlField.thermals,
    {
      field: 'thermals' as SelectDeselectable,
      type: 'multiple',
      options: HEAT_OPTIONS,
    },
  ],
  [
    UrlField.thermalsType,
    {
      field: 'thermalsType' as SelectDeselectable,
      type: 'multiple',
      options: ESTATE_THERMAL_TYPE_OPTIONS,
    },
  ],
  [UrlField.access, { field: 'access', type: 'single' }],
  [
    UrlField.landType,
    {
      field: 'landType' as SelectDeselectable,
      type: 'multiple',
      options: LAND_TYPE_OPTIONS,
    },
  ],
  [
    UrlField.landExtras,
    {
      field: 'extras' as SelectDeselectable,
      type: 'multiple',
      options: LAND_EXTRAS_OPTIONS,
    },
  ],
  [UrlField.floorFrom, { field: 'floor', type: 'single' }],
  [UrlField.floorDirection, { field: 'floorDirection', type: 'single' }],
  [UrlField.Page, { field: 'page', type: 'ignore' }],
])

export const handleInitUrl = (
  searchParams: URLSearchParams,
  handleNumChange: (value: string, field: SetNumable) => void,
  handleSelectDeselect: (
    payload: any,
    field: SelectDeselectable,
    key?: string
  ) => void,
  hardSet: (value: any, field: any) => void
) => {
  searchParams.forEach((value, key) => {
    const temp = urlFieldsToStore.get(key)
    if (temp) {
      if (urlFieldsToStore.get(key)?.type === 'ignore') {
        return
      } else if (urlFieldsToStore.get(key)?.type === 'numeric') {
        handleNumChange(value, temp?.field as unknown as SetNumable)
      } else if (urlFieldsToStore.get(key)?.type === 'single') {
        hardSet(value, temp?.field)
      } else if (urlFieldsToStore.get(key)?.type === 'multiple') {
        const payload = []
        if (value && temp.options && temp?.options?.length) {
          const splitValues = value.split(',')
          splitValues.forEach((value) => {
            let matchedOption: any[] = []
            matchedOption = temp?.options.find((item) => item.value === value)
            if (matchedOption) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              payload.push(matchedOption)
            }
          })

          handleSelectDeselect(payload, temp.field as SelectDeselectable)
        }
      }
    }
  })
}

export const handleSingleField = (
  payload: any,
  urlField: string,
  searchParams: URLSearchParams,
  shouldHide = false
) => {
  const urlParam = formFieldsToUrl.get(urlField)
  if (urlParam) {
    if (shouldHide || payload == null || payload === '') {
      if (searchParams.has(urlParam)) {
        searchParams.delete(urlParam)
      }
    } else {
      searchParams.set(urlParam, payload)
    }
  }
}

export const handleMultipleField = (
  payload: any[],
  urlField: string,
  searchParams: URLSearchParams,
  shouldHide = false
) => {
  const urlParam = formFieldsToUrl.get(urlField)
  if (urlParam) {
    if (shouldHide || !payload?.length) {
      if (searchParams.has(urlParam)) {
        searchParams.delete(urlParam)
      }
    } else if (payload?.length) {
      searchParams.set(
        urlParam,
        payload.map((option) => option?.value).join(',')
      )
    }
  }
}

export const handleInitMultipleFromUrl = (
  searchParams: URLSearchParams,
  urlField: string,
  field: SelectDeselectable,
  options: any[],
  handleSelectDeselect: (payload, field) => void
) => {
  if (searchParams.has(urlField)) {
    const value = searchParams.get(urlField)
    if (value) {
      const splitValues = value.split(',')
      // handleSelectDeselect(splitValues, field)
      splitValues.forEach((value) => {
        handleSelectDeselect(
          options.filter((item) => item.value === value)?.[0],
          field
        )
      })
    }
  }
}

export const setMultipleFieldToUrl = (
  options: any[],
  urlField: string,
  searchParams: URLSearchParams,
  setSearchParams: (
    nextInit: URLSearchParams,
    navigateOptions?:
      | {
          replace?: boolean
          state?: any
        }
      | undefined
  ) => void
) => {
  if (options?.length) {
    searchParams.set(urlField, options.map((option) => option?.value).join(','))
  } else {
    searchParams.delete(urlField)
  }
  setSearchParams(searchParams, {
    replace: true,
  })
}

export const parsePathname = (index: number, value: string): string => {
  const parsePaths = window.location.pathname
    .replaceAll('/', ' ')
    ?.trim()
    ?.split(' ')
  parsePaths?.splice(index, 1, value)
  return parsePaths?.join('/')
}
