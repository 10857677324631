import {
  Box,
  ClickAwayListener,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import UserFavoritesService from 'api/services/UserFavoritesService'
import { GetUserFavoritesListDTO } from 'api/services/UserFavoritesService/types'
import { ReactComponent as CheckBlackCircleSVG } from 'assets/svg/icons/check-black-circle.svg'
import { ReactComponent as PlusBlackCircleSVG } from 'assets/svg/icons/plus-black-circle.svg'
import { AdCategoryIdToListCategoryMap } from 'helpers/constants'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import AuthService from 'services/AuthService'
import { ListCategoryEnum } from 'types/enums'
import ListsButton from './ListsButton'

interface ListsPopUpProps {
  propertyCategory: ListCategoryEnum | null
  propertyAlternativeId: string
  propertyAd: ParsedProperty
  onClose: () => void
  onTextChange: (newText: string) => void
}

interface FormProps {
  name: string
}

const ListsPopUp = React.forwardRef<HTMLElement, ListsPopUpProps>(
  function ListsPopUp(props: ListsPopUpProps, ref) {
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()

    const [readOnlyTextField, setReadOnlyTextField] = React.useState(true)
    const { propertyCategory, propertyAd, propertyAlternativeId, onClose } =
      props

    const { t } = useTranslation()
    const { control, watch, trigger, resetField } = useForm<FormProps>({
      mode: 'onChange',
      defaultValues: { name: '' },
    })

    const watchedName = watch('name', '')

    const handleTextFieldClick = () => {
      setReadOnlyTextField(false)
    }

    const matchedCategory =
      AdCategoryIdToListCategoryMap.get(propertyAd.adCategoryId) || null

    const lists = UserFavoritesService.hooks.useLists(
      AdCategoryIdToListCategoryMap.get(propertyAd.adCategoryId) || null,
      { pageSize: 100, pageNumber: 0 },
      {
        retryOnMount: false,
        enabled: AuthService.isLoggedIn() && !!matchedCategory,
      }
    )

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (
        e.key === 'Enter' &&
        !createUserFavoritesListsMutation.isLoading &&
        !addToUserFavoritesListMutation.isLoading
      ) {
        createList()
      }
    }

    const handleClickAway = () => {
      if (watchedName) createList()
      else {
        resetField('name')
        setReadOnlyTextField(true)
      }
    }

    const addToUserFavoritesListMutation =
      UserFavoritesService.hooks.useAddToUserFavoritesList({
        onError: () => {
          enqueueSnackbar(t('error.unspecific'), {
            variant: 'error',
          })
        },
      })

    const createList = async () => {
      const isValid = await trigger(['name'])
      if (!isValid) return

      if (matchedCategory) {
        createUserFavoritesListsMutation.mutate({
          name: watchedName,
          listCategory: matchedCategory,
        })
      }
    }

    const createUserFavoritesListsMutation =
      UserFavoritesService.hooks.useCreateUserFavoritesList({
        onSuccess: async (newList) => {
          const newFavoritePropertyAd =
            await addToUserFavoritesListMutation.mutateAsync({
              listId: newList.id,
              propertyAdAlternativeId: propertyAd?.id?.slice(3),
            })
          if (matchedCategory && newFavoritePropertyAd) {
            // add pAd to list
            newList?.userFavorites?.push({
              id: newFavoritePropertyAd.id,
              entityId: newFavoritePropertyAd?.alternativeId
                ? newFavoritePropertyAd?.alternativeId
                : propertyAd?.id?.slice(3),
              firstImageUrl: null,
            })
            // increase favs count
            newList.userFavoritesCount = 1

            queryClient.setQueryData(
              [...UserFavoritesService.keys.QueryKeys.search, matchedCategory],
              (prev: GetUserFavoritesListDTO | '') => {
                if (prev) {
                  prev.userFavoritesList = [
                    newList,
                    ...(prev?.userFavoritesList ?? []),
                  ]
                  prev.count = prev.count + 1
                  return prev
                } else {
                  return { userFavoritesList: [newList], count: 1 }
                }
              }
            )
            enqueueSnackbar(
              t('detailPage.addFavorite.action.success') + ` ${newList.name}`,
              {
                variant: 'success',
              }
            )
            resetField('name')
            setReadOnlyTextField(true)
          }
        },
        onError: () => {
          enqueueSnackbar(t('error.unspecific'), {
            variant: 'error',
          })
        },
      })

    return (
      <Box ref={ref}>
        <Grid container justifyContent="center" rowSpacing={1}>
          <Grid xs={12} justifyContent="center" sx={{ mt: '12px' }}>
            <Typography
              align="left"
              fontWeight={'bold'}
              fontSize={15}
              sx={{
                ml: '20px',
                mb: '12px',
                lineHeight: '17px',
                maxWidth: '85%',
              }}
            >
              {propertyCategory
                ? `${t(
                    `searchPage.card.favoritesList.propertyCategory.${propertyCategory}`
                  )}`
                : null}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              maxHeight: '248px',
              overflowY: 'auto',
            }}
          >
            {lists.data?.count
              ? lists.data?.userFavoritesList?.map((item) => (
                  <ListsButton
                    propertyAd={propertyAd}
                    key={item?.id}
                    list={item}
                    propertyAlternativeId={propertyAlternativeId}
                    onClose={onClose}
                  />
                ))
              : null}
          </Grid>
          <Grid xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
                maxLength: {
                  value: 40,
                  message: t('searchPage.card.favoritesList.inputMaxLength'),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: '14px',
                      mt: '12px',
                    }}
                  >
                    <TextField
                      error={!!error}
                      {...field}
                      variant="standard"
                      helperText={error ? error.message : null}
                      placeholder={
                        readOnlyTextField
                          ? `${t('searchPage.card.favoritesList.create')}`
                          : `${t('searchPage.card.favoritesList.name')}`
                      }
                      onClick={() => handleTextFieldClick()}
                      onKeyPress={handleKeyPress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ ml: '9px' }}>
                            {readOnlyTextField ? (
                              <PlusBlackCircleSVG />
                            ) : (
                              <CheckBlackCircleSVG />
                            )}
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        sx: {
                          textAlign: 'center',
                          input: {
                            '&::placeholder': {
                              opacity: readOnlyTextField ? 1 : 0.3,
                            },
                          },
                        },
                      }}
                      sx={{
                        textTransform: 'none',
                        maxWidth: '216px',
                        width: '100%',
                        background: '#FFFFFF',
                        justifyContent: 'center',
                        border: '2px solid',
                        borderColor: error ? 'error.main' : 'primary.main',
                        borderStyle: 'inner-border',
                        borderRadius: '17px',
                        fontSize: '15px',
                        px: '4px',
                      }}
                    ></TextField>
                  </FormControl>
                </ClickAwayListener>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    )
  }
)

export default ListsPopUp
