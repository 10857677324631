import React from 'react'
import {
  Typography,
  Box,
  CircularProgress,
  TypographyProps,
  useMediaQuery,
  Grid,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SeparatorPNG from 'assets/img/seperator.png'
import SeparatorMobilePNG from 'assets/img/seperator-mobile.png'
import { useSearchStore } from 'store'
import shallow from 'zustand/shallow'
import { ViewVariant } from 'types'
import NumberFormattingService from 'api/services/NumberFormatting'

interface ICustomTypographyProps extends TypographyProps {
  type?: ViewVariant
}

const GreyTypography = styled((props) => (
  <Typography align="center" component="div" {...props} />
))<ICustomTypographyProps>(({ type, theme }) => ({
  fontWeight: 700,
  whiteSpace: 'pre-line',
  color: 'rgba(0,0,0,.2)',
  lineHeight: 1.2,
  fontSize: 34,
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
  ...(type === 'inner' && {
    fontSize: 22,
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  }),
}))

const BlackTypography = styled((props) => (
  <Typography align="center" component="div" color="textPrimary" {...props} />
))<ICustomTypographyProps>(({ type, theme }) => ({
  fontWeight: 700,
  whiteSpace: 'pre-line',
  lineHeight: 1.2,
  fontSize: 55,
  [theme.breakpoints.down('lg')]: {
    fontSize: 45,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 40,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
  },
  ...(type === 'inner' && {
    fontSize: 22,
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  }),
  ...(type === 'search' && {
    fontSize: 80,
    [theme.breakpoints.down('lg')]: {
      fontSize: 50,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  }),
}))

const BottomTypography = styled((props) => (
  <Typography align="center" component="div" {...props} />
))<ICustomTypographyProps>(({ type, theme }) => ({
  fontWeight: 700,
  whiteSpace: 'pre-line',
  lineHeight: 1.2,
  fontSize: 40,
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    fontSize: 30,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 17,
  },
  ...(type === 'inner' && {
    fontSize: 25,
  }),
}))

interface ISearchControlsProps {
  isSearching?: boolean
  isSuccess?: boolean
  variant?: ViewVariant
}

function SearchControls(props: ISearchControlsProps) {
  const { isSearching, variant } = props

  const { t } = useTranslation()

  const theme = useTheme()
  const IS_INNER = variant === 'inner'
  const IS_SEARCH = variant === 'search'

  const navigate = useNavigate()

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))

  const [areas, results, loading] = useSearchStore(
    (state) => [state.areas, state.results, state.loading],
    shallow
  )

  const renderLoading = () => {
    const DEFAULT = 100
    const INNER_OR_DEFAULT = IS_INNER ? 40 : DEFAULT
    const SEARCH_OR_INNER = IS_SEARCH ? 20 : INNER_OR_DEFAULT
    const SIZE = matchesMD ? 40 : SEARCH_OR_INNER
    return <CircularProgress size={SIZE} />
  }

  const renderNoAreasSelected = () => {
    return (
      <>
        <GreyTypography
          type={variant}
          sx={{
            fontWeight: 900,
          }}
        >
          {t('landingPage.control.button.label.noAreaSelected')}
        </GreyTypography>
      </>
    )
  }

  const renderResults = () => {
    if (results) {
      return (
        <>
          <BlackTypography type={variant}>
            <Box sx={{ fontWeight: 900 }}>
              {NumberFormattingService.toRoundNumber(results, 0)}
            </Box>
          </BlackTypography>
          <br />
          <BlackTypography
            type={variant}
            sx={{
              fontSize: {
                xs: IS_INNER ? '1rem' : 13,
                sm: IS_INNER ? 18 : 20,
                md: IS_INNER ? 20 : 30,
                lg: IS_INNER ? 22 : 30,
              },
            }}
          >
            <Box
              sx={{
                display: IS_SEARCH ? 'inline-block' : 'inline',
                ml: IS_SEARCH ? 2 : 0,
                fontWeight: 'bolder',
              }}
            >
              {' '}
              {t('landingPage.control.button.label.results')}
            </Box>
          </BlackTypography>
        </>
      )
    } else {
      return (
        <>
          <GreyTypography type={variant} sx={{ fontWeight: 900 }}>
            {t('landingPage.control.button.label.noResults')}
          </GreyTypography>
          <br />
        </>
      )
    }
  }

  const renderContent = () => {
    if (loading || isSearching) return renderLoading()
    if (!areas.length) return renderNoAreasSelected()
    return renderResults()
  }

  const handleClick = () => {
    const input = document.getElementById('searchAreaInput')
    if (IS_INNER || !results) {
      input?.focus()
      return
    }
    navigate('/search')
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: '100%',
        display: 'inline-flex',
        cursor: 'pointer',
      }}
    >
      <Grid
        container
        direction={{ sm: 'row', md: IS_SEARCH ? 'row' : 'column' }}
        justifyContent="space-evenly"
        alignItems="center"
        sx={{
          p: 1,
          bgcolor: 'background.paper',
          border: (theme) =>
            IS_INNER
              ? `1px solid ${theme.palette.common.black}`
              : '1px solid transparent',
          boxShadow: 25,
          transition: (theme) => theme.transitions.create('all'),
          '&:hover': {
            boxShadow: 25,
            bgcolor: 'primary.main',
            borderColor: 'common.grey',
            '&  p,div,span': {
              color: 'common.white',
            },
          },
          '&:hover:active': {
            bgcolor: 'primary.main',
            borderColor: 'black',
            boxShadow: `10px 10px 1px -2px  black`,
          },
          height: {
            xs: '100%',
            md: '100%',
          },
          maxHeight: {
            xs: IS_INNER ? 80 : 'auto',
            sm: IS_INNER ? 80 : 'auto',
            md: IS_INNER ? 135.5 : 'auto',
          },
          width: {
            xs: '100%',
            md: '100%',
          },
          minWidth: IS_INNER || matchesMD ? '100%' : 400,
          borderRadius: 2.5,
          my: 1,
        }}
      >
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              flexFlow: IS_SEARCH ? 'row wrap' : 'column wrap',
              alignContent: 'center',
              alignItems: IS_SEARCH ? 'center' : 'stretch',
              justifyContent: 'center',
            }}
          >
            {renderContent()}
          </Box>
        </Grid>
        {loading || isSearching ? null : (
          <>
            <Grid item>
              <Box
                component="img"
                src={matchesMD || IS_SEARCH ? SeparatorMobilePNG : SeparatorPNG}
                alt="separator"
              />
            </Grid>
            <Grid item>
              <BottomTypography type={variant}>
                {t('landingPage.control.button.subLabel')}
              </BottomTypography>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default SearchControls
