/* eslint-disable @typescript-eslint/no-explicit-any */
import { get as lget } from 'lodash'

import { StoreSlice } from '..'
import PropertyAdService from 'api/services/PropertyAd'
import {
  InterestForType,
  Tag,
  SearchPayload,
  IFloorValue,
  FloorDirection,
  ILandAccessValue,
  ILandCharacteristicsOption,
  ISortingField,
  ISortingBy,
} from './types'
import {
  ICommercialExtrasOption,
  IEstateExtrasOption,
  IEstateThermalTypeOption,
  ILandExtrasOption,
  ILandTypeOption,
  IEstateTypeOption,
  IEstateConditionOption,
  ICommercialTypeOption,
  IEstateThermalOption,
} from 'types/propertyAttributes'
import { Area } from 'types'
import {
  SEARCH_TYPES,
  INTEREST_OPTIONS,
  FLOOR_OPTIONS,
} from 'helpers/constants'
import {
  CountByCriteriaReturnType,
  SearchPropertyAdPayload,
} from 'api/services/PropertyAd/types'
import AnalyticsService from 'services/AnalyticsService'
import { SearchForEnum, SupportedLocale } from 'types/enums'

export type SelectDeselectable =
  | 'areas'
  | 'estatePropertyType'
  | 'estatePropertyCondition'
  | 'commercialType'
  | 'thermals'
  | 'thermalsType'
  | 'extras'
  | 'propertyExtras'
  | 'commercialExtras'
  | 'landType'
  | 'landCharacteristics'

export interface ISearchSlice {
  page: number
  setPage: (value: number) => void
  activeField: string
  setActiveField: (value: string) => void
  locale: string
  setLocale: (value: SupportedLocale) => void
  resetForm: () => void
  hardSet: (value: any, field: keyof ISearchSlice) => void
  sortingField: ISortingField | ''
  setSortingField: (value: ISortingField | '') => void
  sortingBy: ISortingBy | ''
  setSortingBy: (value: ISortingBy | '') => void
  loading: boolean
  setLoading: (value: boolean) => void
  success: boolean
  setSuccess: (value: boolean) => void
  searchFor: InterestForType
  setSearchFor: (value: InterestForType) => void
  searchBy: SearchForEnum
  setSearchBy: (value: SearchForEnum) => void
  showMore: boolean
  handleShowMore: (boolean) => void
  areas: Area[]
  estatePropertyType: IEstateTypeOption[]
  estatePropertyCondition: IEstateConditionOption[]
  commercialType: ICommercialTypeOption[]
  thermals: IEstateThermalOption[]
  thermalsType: IEstateThermalTypeOption[]
  extras: ILandExtrasOption[]
  propertyExtras: IEstateExtrasOption[]
  commercialExtras: ICommercialExtrasOption[]
  landType: ILandTypeOption[]
  landCharacteristics: ILandCharacteristicsOption[]
  access: ILandAccessValue
  selectAccess: (ILandAccessValue) => void
  minConstructionYear: string
  maxConstructionYear: string
  minPrice: string
  maxPrice: string
  minSqM: string
  maxSqM: string
  minBuildSqM: string
  maxBuildSqM: string
  minWC: string
  maxWC: string
  minFloor: string
  maxFloor: string
  minRooms: string
  maxRooms: string
  minBedrooms: string
  maxBedrooms: string
  buildFactor: string
  coverageFactor: string
  floor: IFloorValue
  handleFloorSelect: (
    value: IFloorValue | FloorDirection,
    name: 'floor' | 'floorDirection'
  ) => void
  floorDirection: FloorDirection
  setAreas: (payload: Area[]) => void
  results: number | null
  setResults: (payload: number | null) => void
  handleSelectDeselect: (
    payload: any,
    entity: SelectDeselectable,
    key?: string
  ) => void
  handleNumChange: (value: string, entity: SetNumable) => void
  parsePayload: (any) => any
  calculateCategoryId: () => number
  searchProperyAd: (
    adCategoryId: number,
    pageParam: number,
    pageSize: number,
    agentId?: string | null
  ) => Promise<any>
  searchFn: (payload?: {
    pageParam?: number
    pageSize?: number
    agentId?: string | null
  }) => Promise<any[]>
  searchCountFn: (
    agentId?: string | number | null
  ) => Promise<CountByCriteriaReturnType | undefined>
  clearForm: () => void
}

export type SetNumable = Pick<
  ISearchSlice,
  | 'minConstructionYear'
  | 'maxConstructionYear'
  | 'minPrice'
  | 'maxPrice'
  | 'minSqM'
  | 'maxSqM'
  | 'minBuildSqM'
  | 'maxBuildSqM'
  | 'minWC'
  | 'maxWC'
  | 'minFloor'
  | 'maxFloor'
  | 'minRooms'
  | 'maxRooms'
  | 'minBedrooms'
  | 'maxBedrooms'
  | 'buildFactor'
  | 'coverageFactor'
>

export const searchSlice: StoreSlice<ISearchSlice> = (set, get) => ({
  page: 1,
  setPage: (value) => {
    set({
      page: value,
    })
  },
  activeField: '',
  setActiveField: (value) => {
    set({
      activeField: value,
    })
  },
  locale: SupportedLocale.Greek,
  setLocale: (value) => {
    set({
      locale: value,
    })
  },
  hardSet: (value, field) =>
    set({
      [field as any]: value,
    } as Pick<ISearchSlice, keyof ISearchSlice>),
  sortingField: '',
  setSortingField: (value: ISortingField | '') => {
    set({
      sortingField: value,
    })
  },
  sortingBy: '',
  setSortingBy: (value: ISortingBy | '') => {
    set({
      sortingBy: value,
    })
  },
  resetForm: () => {
    set({
      loading: false,
      success: false,
      estatePropertyType: [],
      commercialType: [],
      estatePropertyCondition: [],
      thermals: [],
      thermalsType: [],
      extras: [],
      propertyExtras: [],
      commercialExtras: [],
      landType: [],
      landCharacteristics: [],
      minPrice: '',
      maxPrice: '',
      minSqM: '',
      maxSqM: '',
      minBuildSqM: '',
      maxBuildSqM: '',
      minWC: '',
      maxWC: '',
      minFloor: '',
      maxFloor: '',
      minRooms: '',
      maxRooms: '',
      minBedrooms: '',
      maxBedrooms: '',
      searchFor: 'buy' as InterestForType,
      searchBy: SearchForEnum.Estate,
      showMore: false,
      areas: [] as Area[],
      results: null,
      buildFactor: '',
      coverageFactor: '',
      access: '',
      floor: '',
      floorDirection: 'up',
      minConstructionYear: '',
      maxConstructionYear: '',
      activeField: '',
    })
  },
  loading: false,
  setLoading: (value) => {
    set({
      loading: value,
    })
  },
  success: false,
  setSuccess: (value) =>
    set({
      success: value,
    }),
  searchFor: INTEREST_OPTIONS.OPTION_BUY,
  setSearchFor: (value) =>
    set((state) => ({
      searchFor: value,
    })),
  searchBy: SearchForEnum.Estate,
  setSearchBy: (value) =>
    set((state) => ({
      searchBy: value,
    })),
  estatePropertyType: [],
  estatePropertyCondition: [],
  commercialType: [],
  thermals: [],
  thermalsType: [],
  extras: [],
  propertyExtras: [],
  commercialExtras: [],
  landType: [],
  areas: [] as Area[],
  landCharacteristics: [],
  setAreas: (payload) =>
    set({
      areas: payload,
    }),
  results: null,
  setResults: (payload) =>
    set({
      results: payload,
    }),
  handleSelectDeselect: (payload, entity, key = 'value') => {
    const currentState = get()
    const ENTITY = lget(currentState, entity, undefined)

    let temp
    if (ENTITY) {
      if (Array.isArray(payload)) {
        temp = payload
      } else {
        const currentIndex = ENTITY.findIndex(
          (item) => item[key] === payload[key]
        )
        const newSelected = [...ENTITY]
        if (currentIndex === -1) {
          newSelected.push(payload)
        } else {
          newSelected.splice(currentIndex, 1)
        }
        temp = newSelected
      }
      set({
        [entity as any]: temp,
      } as Pick<ISearchSlice, keyof ISearchSlice>)
    }
  },
  minPrice: '',
  maxPrice: '',
  minSqM: '',
  maxSqM: '',
  minBuildSqM: '',
  maxBuildSqM: '',
  minWC: '',
  maxWC: '',
  minFloor: '',
  maxFloor: '',
  minBedrooms: '',
  maxBedrooms: '',
  minRooms: '',
  maxRooms: '',
  minConstructionYear: '',
  maxConstructionYear: '',
  buildFactor: '',
  coverageFactor: '',
  handleNumChange: (value, entity) =>
    set({
      [entity as any]: value,
    } as Pick<ISearchSlice, keyof ISearchSlice>),
  showMore: false,
  handleShowMore: (value) => {
    set({
      showMore: value,
    })
  },
  floor: '',
  floorDirection: 'up',
  handleFloorSelect: (value, name) => {
    set({
      [name as any]: value,
    } as Pick<ISearchSlice, keyof ISearchSlice>)
  },
  access: '',
  selectAccess: (value) => {
    set({
      access: value,
    })
  },
  calculateCategoryId: () => {
    let adCategoryId
    const { searchFor, searchBy } = get()
    if (searchFor === INTEREST_OPTIONS.OPTION_BUY) {
      if (searchBy === SEARCH_TYPES.ESTATE) adCategoryId = 1
      else if (searchBy === SEARCH_TYPES.COMMERCIAL) adCategoryId = 2
      else if (searchBy === SEARCH_TYPES.LAND) adCategoryId = 3
    } else {
      if (searchBy === SEARCH_TYPES.ESTATE) adCategoryId = 4
      else if (searchBy === SEARCH_TYPES.COMMERCIAL) adCategoryId = 5
      else if (searchBy === SEARCH_TYPES.LAND) adCategoryId = 6
    }
    return adCategoryId
  },
  parsePayload: (adCategoryId) => {
    const currentState = get()
    const SEARCH_ESTATE_ACCEPTED_IDS = [1, 4]
    const SEARCH_COMMERCIAL_ESTATE_ACCEPTED_IDS = [2, 5]
    const SEARCH_LAND_ACCEPTED_IDS = [3, 6]
    const IS_ESTATE = SEARCH_ESTATE_ACCEPTED_IDS.includes(adCategoryId)
    const IS_COMMERCIAL_ESTATE =
      SEARCH_COMMERCIAL_ESTATE_ACCEPTED_IDS.includes(adCategoryId)
    const IS_LAND = SEARCH_LAND_ACCEPTED_IDS.includes(adCategoryId)

    const payload = {} as SearchPayload
    let tags: Tag[] = []

    const {
      estatePropertyType,
      estatePropertyCondition,
      propertyExtras,
      minBedrooms,
      maxBedrooms,
      minWC,
      maxWC,
      thermals,
      thermalsType,
      commercialType,
      extras: landExtras,
      landType,
      minPrice: minSalesPrice,
      maxPrice: maxSalesPrice,
      minSqM: minSquareMeters,
      maxSqM: maxSquareMeters,
      minBuildSqM: minBuildSquareMeters,
      maxBuildSqM: maxBuildSquareMeters,
      minRooms,
      landCharacteristics,
      maxRooms,
      buildFactor,
      coverageFactor,
      commercialExtras,
      access,
      floor,
      floorDirection,
      maxConstructionYear,
      minConstructionYear,
    } = currentState

    if (minConstructionYear)
      payload.minConstructionYear = Number.parseInt(minConstructionYear)
    if (maxConstructionYear)
      payload.maxConstructionYear = Number.parseInt(maxConstructionYear)
    if (minSalesPrice) payload.minSalesPrice = Number.parseInt(minSalesPrice)
    if (maxSalesPrice) payload.maxSalesPrice = Number.parseInt(maxSalesPrice)
    if (minSquareMeters)
      payload.minSquareMeters = Number.parseInt(minSquareMeters)
    if (maxSquareMeters)
      payload.maxSquareMeters = Number.parseInt(maxSquareMeters)

    //LAND
    if (IS_LAND) {
      if (minBuildSquareMeters)
        payload.minBuildSquareMeters = Number.parseInt(minBuildSquareMeters)
      if (maxBuildSquareMeters)
        payload.maxBuildSquareMeters = Number.parseInt(maxBuildSquareMeters)
      if (buildFactor) payload.minBuildFactor = Number.parseFloat(buildFactor)
      if (coverageFactor)
        payload.minCoverageFactor = Number.parseFloat(coverageFactor)
      if (landType?.length)
        tags = [...tags, ...landType.map((type) => type.value)]
      if (landCharacteristics?.length)
        tags = [...tags, ...landCharacteristics.map((type) => type.value)]
      if (landExtras?.length)
        tags = [...tags, ...landExtras.map((type) => type.value)]
      if (access) tags = [...tags, access]
    }
    //ESTATE
    if (IS_ESTATE) {
      if (estatePropertyType?.length)
        tags = [...tags, ...estatePropertyType.map((type) => type.value)]
      if (estatePropertyCondition?.length)
        tags = [...tags, ...estatePropertyCondition.map((type) => type.value)]
      if (propertyExtras?.length)
        tags = [...tags, ...propertyExtras.map((type) => type.value)]
      if (minBedrooms) payload.minBedrooms = Number.parseInt(minBedrooms)
      if (maxBedrooms) payload.maxBedrooms = Number.parseInt(maxBedrooms)
      if (minWC) payload.minBathrooms = Number.parseInt(minWC)
      if (maxWC) payload.maxBathrooms = Number.parseInt(maxWC)
    }
    //COMMERCIAL
    if (IS_COMMERCIAL_ESTATE) {
      if (commercialType?.length)
        tags = [...tags, ...commercialType.map((type) => type.value)]
      if (estatePropertyCondition?.length)
        tags = [...tags, ...estatePropertyCondition.map((type) => type.value)]
      if (commercialExtras?.length)
        tags = [...tags, ...commercialExtras.map((type) => type.value)]
      if (minRooms) payload.minRooms = Number.parseInt(minRooms)
      if (maxRooms) payload.maxRooms = Number.parseInt(maxRooms)
    }
    if (IS_ESTATE || IS_COMMERCIAL_ESTATE) {
      if (thermals?.length)
        tags = [...tags, ...thermals.map((type) => type.value)]
      if (thermalsType?.length)
        tags = [...tags, ...thermalsType.map((type) => type.value)]
      if (floor) {
        tags = [...tags, floor]
        const index = FLOOR_OPTIONS.findIndex((item) => item.value === floor)
        let relatedFloors
        if (index !== -1) {
          if (floorDirection === 'up') {
            relatedFloors = FLOOR_OPTIONS.slice(index + 1).map(
              (item) => item.value
            )
            tags = [...tags, ...relatedFloors]
          } else {
            relatedFloors = FLOOR_OPTIONS.slice(
              0,
              index === 0 ? index : index - 1
            ).map((item) => item.value)
            tags = [...tags, ...relatedFloors]
          }
        }
      }
    }
    if (tags?.length) payload.tags = tags
    return payload
  },
  searchProperyAd: async (
    adCategoryId,
    pageParam,
    pageSize = 20,
    agentId = null
  ) => {
    const { locale, sortingField, sortingBy } = get()
    const parsedPayload = get().parsePayload(adCategoryId)

    const payload: SearchPropertyAdPayload = {
      locale: locale,
      adCategoryId,
      pageNumber: pageParam,
      areas: get().areas.map((area) => area.fullPath),
      pageSize,
      ...parsedPayload,
    }
    if (agentId) payload.agentId = agentId
    if (sortingField) payload.sortingField = sortingField
    if (sortingBy) payload.sortingBy = sortingBy

    const data = await PropertyAdService.searchByCriteria(payload)
    return data
  },
  searchFn: async ({ pageParam = 0, pageSize = 20, agentId } = {}) => {
    if (!get().areas.length && !agentId) return
    set({ loading: true })
    const adCategoryId = get().calculateCategoryId()

    const response = await get().searchProperyAd(
      adCategoryId,
      pageParam,
      pageSize,
      agentId
    )
    set({ loading: false })
    return response
  },
  searchCountFn: async (agentId) => {
    const { areas } = get()
    if (!areas.length && !agentId) return
    set({ results: null })
    set({ loading: true })

    const locale = get().locale
    const adCategoryId = get().calculateCategoryId()
    const parsedPayload = get().parsePayload(adCategoryId)
    const payload = {
      locale: locale,
      adCategoryId,
      areas: get().areas.map((area) => area.fullPath),
      ...parsedPayload,
    }
    if (agentId) payload.agentId = agentId
    const response = await PropertyAdService.getCountByCriteria(payload)

    const splittedFirstSearchArea = get()
      .areas?.map((area) => area.fullPath)?.[0]
      ?.split('/')
    AnalyticsService.setSearchedFor({
      searchArea: splittedFirstSearchArea?.slice(-1)?.[0]?.trim(),
    })

    set({ results: response?.propertyadsCount })
    set({ loading: false })
    return response
  },
  clearForm: () => {
    set({
      estatePropertyType: [],
      commercialType: [],
      estatePropertyCondition: [],
      thermals: [],
      thermalsType: [],
      extras: [],
      propertyExtras: [],
      commercialExtras: [],
      landType: [],
      landCharacteristics: [],
      minConstructionYear: '',
      maxConstructionYear: '',
      minPrice: '',
      maxPrice: '',
      minSqM: '',
      maxSqM: '',
      minBuildSqM: '',
      maxBuildSqM: '',
      minWC: '',
      maxWC: '',
      minFloor: '',
      maxFloor: '',
      minRooms: '',
      maxRooms: '',
      minBedrooms: '',
      maxBedrooms: '',
      showMore: get().showMore,
      results: null,
      buildFactor: '',
      coverageFactor: '',
      access: '',
      floor: '',
      floorDirection: 'up',
      activeField: '',
    })
  },
})
