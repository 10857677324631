import { ParsedProperty } from 'api/services/PropertyAd/types'
import { TFunction } from 'react-i18next'

export interface IconMatch {
  icon: JSX.Element
  valueGetter: (
    data: ParsedProperty,
    t: TFunction
  ) => string | JSX.Element | React.ReactNode | null
  tooltip: string
}

export enum ViewType {
  Detail = 'detail',
  Info = 'info',
  More = 'more',
}
