import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Chip, ChipProps } from '@mui/material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

import { ViewVariant } from 'types'

export interface Props extends ChipProps {
  cVariant?: ViewVariant
  showArrow?: boolean
}

const SearchChip = styled(
  (props: Props) => <Chip deleteIcon={<CancelRoundedIcon />} {...props} />,
  {
    shouldForwardProp: (prop) => prop !== 'showArrow' && prop !== 'cVariant',
  }
)<Props>(({ showArrow = false, color, cVariant, theme }) => ({
  '&.MuiChip-root': {
    flexDirection: 'row-reverse',
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    padding: theme.spacing(1, 0.5),
    position: 'relative',
    ...(cVariant === 'inner' && {
      ...(color === 'white' && {
        backgroundColor: theme.palette.common.white,
      }),
      ...(color === 'primary' && {
        backgroundColor: theme.palette.primary.main,
      }),
    }),
    filter: `drop-shadow(5px 6px rgba(0,0,0,0.2))`,
    '&:hover': {
      filter: `drop-shadow(5px 6px rgba(0,0,0,0.3))`,
    },
    '&:active': {
      filter: `drop-shadow(5px 6px rgba(0,0,0,1))`,
    },
    transition: (theme) => theme.transitions.create('all'),
    ...(showArrow && {
      '&::after': {
        position: 'absolute',
        left: '50%',
        top: '100%',

        width: 0,
        height: 0,
        content: "''",
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        borderTopWidth: 15,
        borderTopStyle: 'solid',
        clear: 'both',
        transform: 'translateX(-50%)',
        borderTopColor: theme.palette.common.white,
        ...(cVariant === 'inner' && {
          ...(color === 'white' && {
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            borderTopColor: theme.palette.common.white,
          }),
          ...(color === 'primary' && {
            '&:hover': {
              backgroundColor: theme.palette.common.white,
            },
            borderTopColor: theme.palette.primary.main,
          }),
        }),
      },
    }),
  },
  '& .MuiChip-label': {
    color: theme.palette.common.black,
    fontSize: 16,
    wordBreak: 'break-all',
  },
  '& .MuiSvgIcon-root': {
    margin: 0,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
      ...(cVariant === 'inner' && {
        ...(color === 'white' && {
          color: theme.palette.primary.main,
        }),
        ...(color === 'primary' && {
          color: theme.palette.common.white,
        }),
      }),
    },
  },
}))

export default SearchChip
