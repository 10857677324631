import React from 'react'
import shallow from 'zustand/shallow'
import { Collapse, Grid, Grow, SvgIcon } from '@mui/material'
import { KeyboardArrowUp } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import SearchMoreFilters from 'components/Search/SearchMoreFilters'
import { ReactComponent as MoreFiltersSVG } from 'assets/svg/more-filter.svg'
import { useSearchStore } from 'store'
import SearchLabel from 'components/Landing/SearchLabel'

function Bottom() {
  const { t } = useTranslation()

  const [showMore, handleShowMore] = useSearchStore(
    (state) => [state.showMore, state.handleShowMore],
    shallow
  )

  const handleHideMoreFilters = () => {
    handleShowMore(false)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  // const bottomRef = React.useRef<HTMLElement>()
  const bottomRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (showMore && bottomRef.current) {
      window.scrollTo({
        top: bottomRef.current.offsetTop - 30,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [showMore])

  return (
    <Grow in={showMore} unmountOnExit ref={bottomRef}>
      <Collapse in={showMore}>
        <Grid container justifyContent="flex-start" sx={{ marginTop: 6 }}>
          <Grid
            container
            item
            xs={12}
            wrap="nowrap"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            onClick={handleHideMoreFilters}
            ref={bottomRef}
          >
            <Grid item>
              <SvgIcon
                component={MoreFiltersSVG}
                inheritViewBox
                sx={{ width: 55, height: 55 }}
              />
            </Grid>
            <Grid item>
              <SearchLabel isActive={true}>
                {t('landingPage.moreFilters.label')}
              </SearchLabel>
            </Grid>
            <Grid item>
              <KeyboardArrowUp
                sx={{
                  color: 'common.white',
                  width: 55,
                  height: 55,
                }}
              />
            </Grid>
          </Grid>
          <SearchMoreFilters />
        </Grid>
      </Collapse>
    </Grow>
  )
}

export default Bottom
