/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useTheme } from '@mui/material/styles'
import shallow from 'zustand/shallow'
import {
  Grid,
  SvgIcon,
  Grow,
  Stack,
  ButtonBase,
  useMediaQuery,
  Typography,
  Alert,
  Collapse,
  CircularProgress,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { createId } from '@paralleldrive/cuid2'

import InnerLayout from 'layouts/InnerLayout'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { ReactComponent as MoreFiltersSVG } from 'assets/svg/more-filter.svg'
import SearchMoreFilters from './SearchMoreFilters'
import Results from './Results'
import PropertyType from './SearchMore/PropertyType'
import Interest from './SearchMore/Interest'
import SearchBar from 'components/Landing/Center/SearchBar'
import { useRouteStore, useSearchStore } from 'store'
import { useTranslation } from 'react-i18next'
import AgentService from 'api/services/Agent'
import {
  SearchForParamEnum,
  SearchInterestParamEnum,
  SearchPropertyTypeParam,
  UrlField,
} from 'types/enums'
import { ISortingBy, ISortingField } from 'store/SearchSlice/types'
import { pick } from 'lodash'
import { decryptObject, encryptObject } from 'helpers'
import { handleInitUrl } from './SearchMore/helpers'
import usePageView from 'hooks/usePageView'
import AreaService from 'api/services/Area'
import { Area } from 'types'
import {
  COMMERCIAL_TYPE_OPTIONS,
  ESTATE_TYPE_OPTIONS,
  LAND_TYPE_OPTIONS,
} from 'helpers/constants'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

function SearchInner() {
  const { sendPageView } = usePageView({ runOnMount: false })

  const queryClient = useQueryClient()

  const { addTitle } = useDocumentTitle()

  const [searchParams, setSearchParams] = useSearchParams()
  const agentId = searchParams.get('agentId')

  const agent = AgentService.hooks.useSingleAgent(`el:${agentId}`, {
    enabled: !!agentId,
  })

  const { t } = useTranslation()

  const theme = useTheme()

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))

  const [, setLocalCount] = React.useState<number>(0)

  const [localIsError, setLocalIsError] = React.useState(false)

  const handleCloseError = () => {
    setLocalIsError(false)
  }

  const updateCounter = useRouteStore((state) => state.updateCounter, shallow)

  const [
    locale,
    areas,
    searchFn,
    searchBy,
    searchFor,
    searchCountFn,
    countResult,
    setSortingField,
    setSortingBy,
    setActiveField,
    handleSelectDeselect,
    handleNumChange,
    hardSet,
    page,
    setPage,
    setAreas,
    resetForm,
  ] = useSearchStore(
    (state) => [
      state.locale,
      state.areas,
      state.searchFn,
      state.searchBy,
      state.searchFor,
      state.searchCountFn,
      state.results,
      state.setSortingField,
      state.setSortingBy,
      state.setActiveField,
      state.handleSelectDeselect,
      state.handleNumChange,
      state.hardSet,
      state.page,
      state.setPage,
      state.setAreas,
      state.resetForm,
    ],
    shallow
  )

  const pageUrlRef = React.useRef<number | null>(null)

  React.useEffect(() => {
    if (page && !firstRender.current) {
      searchParams.set('page', page + '')
      setSearchParams(searchParams, {
        replace: true,
      })
    }
    pageUrlRef.current = page

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const adOffsetId = createId()

  const PAGE_LIMIT = 330

  const firstRender = React.useRef<boolean>(true)

  const fetchData = async ({ pageParam = 0 }) => {
    const urlPage = searchParams.get('page') ?? '1'
    if (
      !pageParam &&
      page === parseInt(urlPage) &&
      pageUrlRef &&
      page === pageUrlRef.current
    ) {
      if (page === 2) {
        pageParam = 0
      } else {
        pageParam = parseInt(urlPage) - 1
      }
    }
    if (pageParam + 1 > PAGE_LIMIT) {
      return {
        data: '',
        page: pageParam,
        nextPage: pageParam + 1 > PAGE_LIMIT ? pageParam : pageParam + 1,
        totalPages: 0,
        totalCount: 0,
      }
    }

    if (!areas.length && !agentId) return
    const pageSize = 30

    let countData = 0
    const responseCount = await searchCountFn(agentId)
    if (responseCount) {
      countData = responseCount?.propertyadsCount
      setLocalCount(responseCount?.propertyadsCount)
    }

    if (countData === 0) {
      return {
        data: '',
        page: pageParam,
        nextPage: 0,
        totalPages: 0,
        totalCount: 0,
      }
    }

    const advOffset = '#' + adOffsetId

    const data = await searchFn({
      pageParam,
      pageSize,
      agentId,
    })

    let counter = 0
    if (data && data?.length) {
      if (data?.length >= 4) {
        if (!matchesMD) {
          data.splice(4, 0, `div-gpt-ad-3816174-1${advOffset}`)
        } else {
          data.splice(4, 0, `div-gpt-ad-3816174-7${advOffset}`)
        }
        ++counter
      }
      if (data?.length >= 8) {
        if (!matchesMD) {
          data.splice(8 + counter, 0, `div-gpt-ad-3816174-2${advOffset}`)
        } else {
          data.splice(8 + counter, 0, `div-gpt-ad-3816174-8${advOffset}`)
        }
        ++counter
      }
      if (data?.length >= 12) {
        if (!matchesMD) {
          data.splice(12 + counter, 0, `div-gpt-ad-3816174-3${advOffset}`)
        } else {
          data.splice(12 + counter, 0, `div-gpt-ad-3816174-9${advOffset}`)
        }
        ++counter
      }
      if (data?.length >= 16) {
        if (!matchesMD) {
          data.splice(16 + counter, 0, `div-gpt-ad-3816174-4${advOffset}`)
        } else {
          data.splice(16 + counter, 0, `div-gpt-ad-3816174-10${advOffset}`)
        }
        ++counter
      }
      if (data?.length >= 20) {
        if (!matchesMD) {
          data.splice(20 + counter, 0, `div-gpt-ad-3816174-5${advOffset}`)
        } else {
          data.splice(20 + counter, 0, `div-gpt-ad-3816174-11${advOffset}`)
        }
        ++counter
      }
      if (data?.length >= 24) {
        if (!matchesMD) {
          data.splice(24 + counter, 0, `div-gpt-ad-3816174-6${advOffset}`)
        } else {
          data.splice(24 + counter, 0, `div-gpt-ad-3816174-12${advOffset}`)
        }
        ++counter
      }
    }
    return {
      data,
      page: pageParam,
      nextPage: pageParam + 1 > PAGE_LIMIT ? pageParam : pageParam + 1,
      totalPages: Math.ceil(countData / pageSize) ?? 0,
      totalCount: countData,
    }
  }

  const results = useInfiniteQuery('propertyResults', fetchData, {
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage?.page + 1 < PAGE_LIMIT) {
        const HAS_NEXT = lastPage?.nextPage < lastPage?.totalPages
        return HAS_NEXT ? lastPage?.nextPage : undefined
      }
      return undefined
    },
    getPreviousPageParam: (firstPage) => {
      if (firstPage && firstPage?.page - 1 < PAGE_LIMIT) {
        if (firstPage?.page > 0) {
          return firstPage?.page - 1
        } else return
      }
      return false
    },
    keepPreviousData: false,
    cacheTime: 0,
    enabled: false,
    retry: false,
    onError: () => {
      setLocalIsError(true)
    },
    onSuccess: () => {
      if (firstRender.current) firstRender.current = false
    },
    onSettled: () => {
      const newTitle = `${t('pages.search')} ${t(
        'landingPage.interestType.label'
      )} ${t(`landingPage.lookingFor.${searchBy}`)} ${t(
        `landingPage.interestType.resultsLabel`
      )} ${t(
        `landingPage.interestType.${searchFor === 'rent' ? 'loan' : 'buy'}`
      )}, ${areas
        ?.map((item) =>
          item?.realNameArea
            ?.split('/')
            ?.[item?.realNameArea?.split('/')?.length - 1]?.trim()
        )
        .join(', ')}`

      addTitle(newTitle)
      sendPageView({ page_title: newTitle })
    },
  })

  const parsedPathname = window.location.pathname
    ?.replaceAll('/', ' ')
    ?.trim()
    ?.split(' ')

  const HAS_AREA_PATH_PARAM = parsedPathname?.length >= 4

  const searchAreas = AreaService.hooks.useSearchAreas({
    queryOptions: {
      onSuccess: (data) => {
        if (data?.length) {
          const generalFirstArea = data.splice(0, 1)
          setAreas(generalFirstArea)
          const payload = prepareAreasForUrl(generalFirstArea)
          searchParams.set('area', payload)
          searchParams.set('category', searchBy)
          searchParams.set('interest', searchFor)
          setSearchParams(searchParams, {
            replace: true,
          })
        }
      },
    },
  })

  const SHOULD_FETCH_AREA = HAS_AREA_PATH_PARAM && !searchParams.get('area')

  React.useLayoutEffect(() => {
    if (SHOULD_FETCH_AREA) {
      resetForm()

      const propertyCategory = parsedPathname?.[1]
      const propertyInterest = parsedPathname?.[2]
      const propertySpecificType = parsedPathname?.[4]
      const IS_COM_OR_ESTATE =
        propertyCategory === SearchForParamEnum.commercial
          ? 'commercialType'
          : 'estatePropertyType'
      const FIElD_TO_SET =
        propertyCategory === SearchForParamEnum.land
          ? 'landType'
          : IS_COM_OR_ESTATE

      const fieldToSetInStore = FIElD_TO_SET

      const IS_COM_OR_ESTATE_CATEGORY =
        propertyCategory === SearchForParamEnum.commercial
          ? 'commercial'
          : 'estate'

      const categoryToSetInStore =
        propertyCategory === SearchForParamEnum.land
          ? 'land'
          : IS_COM_OR_ESTATE_CATEGORY

      const interestToSetInStore =
        propertyInterest === SearchInterestParamEnum.rent ? 'rent' : 'buy'

      hardSet(interestToSetInStore, 'searchFor')
      hardSet(categoryToSetInStore, 'searchBy')

      const ACCEPTED_VALUES_FOR_TYPE = Object.values(
        SearchPropertyTypeParam
      ).reduce<string[]>((acc, item) => {
        return [
          ...acc,
          ...(Object.entries(item).map(
            (item) => item[0]
          ) as unknown as string[]),
        ]
      }, [])
      if (
        propertySpecificType &&
        ACCEPTED_VALUES_FOR_TYPE?.includes(propertySpecificType)
      ) {
        hardSet(
          [
            ...ESTATE_TYPE_OPTIONS,
            ...COMMERCIAL_TYPE_OPTIONS,
            ...LAND_TYPE_OPTIONS,
          ].filter(
            (item) =>
              item.value ===
              SearchPropertyTypeParam[propertyCategory][propertySpecificType]
          ),

          fieldToSetInStore
        )
      }
      searchAreas.mutate({ area: parsedPathname?.[3] })
    }
  }, [])

  React.useEffect(() => {
    if (!firstRender.current) {
      handleRefetch()
    }
  }, [locale, searchBy, searchFor, areas, agentId])

  const handleRefetch = () => {
    setActiveField('')
    setLocalCount(0)
    setLocalIsError(false)
    updateCounter()
    if (window?.googletag && window?.googletag?.apiReady) {
      window?.googletag?.destroySlots()
    }
    results.remove()
    results.refetch()

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleUpdateForm = (page = 1) => {
    pageUrlRef.current = page

    setPage(1)
    setActiveField('')
    setLocalCount(0)
    setLocalIsError(false)
    updateCounter()
    if (window?.googletag && window?.googletag?.apiReady) {
      window?.googletag?.destroySlots()
    }
    results.remove()
    results.refetch()

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const prepareAreasForUrl = (areas: Area[]): string => {
    if (areas?.length) {
      return areas
        .map((area) => {
          return encryptObject(pick(area, ['fullPath', 'realNameArea']))
        })
        .join(',')
    }
    return ''
  }

  const handleInitState = async () => {
    handleInitUrl(searchParams, handleNumChange, handleSelectDeselect, hardSet)

    const field =
      (searchParams.get(UrlField.sortingField) as ISortingField) ?? ''
    const by = (searchParams.get(UrlField.sortingBy) as ISortingBy) ?? ''
    const urlPage = parseInt(searchParams.get(UrlField.Page) ?? '1')
    if (field) setSortingField(field)
    if (by) setSortingBy(by)
    if (urlPage) setPage(urlPage)
    if (areas?.length) {
      const payload = prepareAreasForUrl(areas)
      searchParams.set('area', payload)
      searchParams.set('category', searchBy)
      searchParams.set('interest', searchFor)
      setSearchParams(searchParams, {
        replace: true,
      })
    } else {
      if (searchParams.has('area')) {
        const value = searchParams.get('area')
        if (value) {
          const splitValues = value.split(',')
          handleSelectDeselect(
            splitValues.map((value) =>
              pick(decryptObject(value), ['fullPath', 'realNameArea'])
            ),
            'areas'
          )
        }
      }
    }
  }

  const initMutation = useMutation(handleInitState, {
    onSuccess() {
      if (firstRender.current) firstRender.current = false
      if (searchParams.get('page')) {
        results.refetch()
      } else {
        handleUpdateForm()
      }
    },
  })

  React.useEffect(() => {
    initMutation.mutate()
  }, [])

  const handlePageInputChange = async (page: number) => {
    if (page < 0) {
      return
    }
    if (window?.googletag && window?.googletag?.apiReady) {
      window?.googletag?.destroySlots()
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    const nextState = await results.fetchNextPage({
      pageParam: page - 1,
    })

    const newPageData = nextState.data?.pages.find(
      (val) => val?.page === page - 1
    )

    queryClient.setQueryData('propertyResults', () => {
      return {
        pages: [newPageData],
        pageParams: [page - 1],
      }
    })
  }

  const loadMoreButtonRef = React.useRef<HTMLSpanElement>(null)

  const scrollToRef = React.useRef<HTMLDivElement>(null)

  useIntersectionObserver({
    root: null,
    target: loadMoreButtonRef,
    onIntersect: results.fetchNextPage,
    enabled: !!results.hasNextPage,
    rootMargin: '800px',
  })

  const [showMoreFilters, setShowMoreFilters] = React.useState(false)

  const handleToggleFilters = () => {
    setShowMoreFilters((prev) => !prev)
  }

  const handleCloseFilters = () => {
    setShowMoreFilters(false)
  }

  const SHOW_MENU_IN_MD_AND_UNDER = matchesMD ? showMoreFilters : true

  const handleRemoveAgentFilter = () => {
    searchParams.delete('agentId')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <InnerLayout>
      {SHOULD_FETCH_AREA && searchAreas.isLoading ? (
        <CircularProgress
          size={40}
          sx={{ display: 'block ', mx: 'auto', mt: '20px' }}
        />
      ) : null}
      {(SHOULD_FETCH_AREA && searchAreas.isSuccess) || !SHOULD_FETCH_AREA ? (
        <Grid
          container
          sx={{
            padding: (theme) => ({
              xs: theme.spacing(2, 1, 4),
              sm: theme.spacing(2, 1, 6),
              md: theme.spacing(2, 2, 8),
              lg: theme.spacing(2, 2, 8),
            }),
          }}
          ref={scrollToRef}
        >
          <Grow in={matchesMD}>
            <Grid item xs={12} container justifyContent="center" sx={{ mt: 1 }}>
              <Stack
                component={ButtonBase}
                direction={'row'}
                justifyContent="center"
                sx={{
                  width: '100%',
                  maxWidth: 400,
                  borderRadius: 10,
                  bgcolor: 'primary.main',
                  py: 0.7,
                  '& path': {
                    fill: 'common.white',
                  },
                }}
                spacing={1}
                onClick={handleToggleFilters}
              >
                <SvgIcon
                  component={MoreFiltersSVG}
                  inheritViewBox
                  sx={{
                    fill: 'white',
                    width: 25,
                    height: 25,
                  }}
                />
                <Typography
                  sx={{
                    color: 'common.white',
                    fontWeight: 600,
                  }}
                >
                  {t('searchPage.filters')}
                </Typography>
              </Stack>
            </Grid>
          </Grow>
          <Grow in={SHOW_MENU_IN_MD_AND_UNDER} unmountOnExit={true}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <PropertyType variant="inner" />
              <Interest variant="inner" />
              <Grid item sx={{ mt: 4 }}>
                <SearchBar variant="inner" />
              </Grid>
              <SearchMoreFilters
                onRefetch={handleUpdateForm}
                variant="inner"
                handleCloseFilters={handleCloseFilters}
              />
            </Grid>
          </Grow>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={9}
            sx={{
              mt: 2,
            }}
          >
            {agentId ? (
              <Typography
                sx={{
                  textAlign: 'left',
                  fontWeight: 600,
                  fontSize: { xs: 16, lg: 22 },
                  pl: 3,
                }}
              >
                {t('searchPage.agent')}
                {agent.isLoading ? (
                  <Typography align="center">
                    <CircularProgress
                      sx={{
                        fontSize: { xs: 20, lg: 26 },
                      }}
                    />
                  </Typography>
                ) : null}
                {agent.isSuccess ? (
                  <Typography
                    display="inline"
                    align="center"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: 20, lg: 26 },
                    }}
                  >
                    {agent.data?.companyTitle}
                    <CancelIcon
                      color="primary"
                      sx={{ ml: '5px', mb: '-4px' }}
                      onClick={handleRemoveAgentFilter}
                    />
                  </Typography>
                ) : null}
                {agent.isError ? (
                  <Typography
                    color="error"
                    sx={{
                      fontWeight: 700,
                      fontSize: 18,
                    }}
                  >
                    {t('error.unspecific')}
                  </Typography>
                ) : null}
              </Typography>
            ) : null}
            <Collapse in={localIsError}>
              <Alert severity="error" onClose={handleCloseError}>
                {t('error.unspecific')}
              </Alert>
            </Collapse>
            <Results
              pageLimit={PAGE_LIMIT}
              onRefetch={handleRefetch}
              onChangePage={handlePageInputChange}
              count={countResult}
              areas={areas}
              isSuccess={results.isSuccess}
              isFetching={results.isFetching}
              isLoading={results.isLoading}
              items={results.data}
              isFetchingNextPage={results.isFetchingNextPage}
              isFetchingPreviousPage={results.isFetchingPreviousPage}
              hasNextPage={results.hasNextPage}
              hasPreviousPage={results.hasPreviousPage}
              fetchPreviousPage={results.fetchPreviousPage}
              ref={loadMoreButtonRef}
              agentId={agentId}
            />
          </Grid>
        </Grid>
      ) : null}
    </InnerLayout>
  )
}

export default SearchInner
