import { StoreSlice } from '..'

let counter = 0
export interface IRouteSlice {
  counter: number
  updateCounter: () => void
}

export const routeSlice: StoreSlice<IRouteSlice> = (set, get) => ({
  counter: counter,
  updateCounter: () => set({ counter: ++counter }),
})
