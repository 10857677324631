import React from 'react'
import { Grid, Link, Typography } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import { useTranslation } from 'react-i18next'

import { EstateAgent } from 'types'

const getPrefixWebsite = (arg: string) => {
  if (arg.includes('www')) {
    return '//'
  }
  return ''
}
interface Props {
  data: EstateAgent
}

function SocialMedia(props: Props) {
  const { t } = useTranslation()
  let socialMedia =
    props.data?.contactInfo?.findIndex(
      (item) => item.defaultContactInfo === true
    ) ?? 0

  if (socialMedia === -1) {
    socialMedia = 0
  }

  const iconMap = {
    Website: {
      id: 'Website',
      label: `${t('findRealtorPage.card.website')}`,
      icon: <LanguageOutlinedIcon color="primary" sx={{ fontSize: 35 }} />,
    },
    Facebook: {
      id: 'Facebook',
      label: 'Facebook',
      icon: <FacebookIcon color="primary" sx={{ fontSize: 35 }} />,
    },
    Instagram: {
      id: 'Instagram',
      label: 'Instagram',
      icon: <InstagramIcon color="primary" sx={{ fontSize: 35 }} />,
    },
    Video: {
      id: 'Video',
      label: 'Video',
      icon: <VideoLibraryIcon color="primary" sx={{ fontSize: 35 }} />,
    },
  }

  if (props.data?.contactInfo?.[socialMedia]?.socialMediaInfo?.length) {
    const socialMediaInfos =
      props.data?.contactInfo?.[socialMedia]?.socialMediaInfo ?? []

    return (
      <>
        {socialMediaInfos
          .filter((item) => !!item?.mediaTitle)
          .map((item) => {
            return (
              <React.Fragment key={item.id}>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                  {item?.mediaTitle ? iconMap?.[item.mediaTitle]?.icon : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{ my: 'auto', textAlign: { xs: 'center', md: 'left' } }}
                >
                  {item?.url && item.mediaTitle === 'Website' ? (
                    <Link
                      href={`${item.url}`}
                      underline="none"
                      color="inherit"
                      target={'_blank'}
                      rel="noopener"
                    >
                      <Typography sx={{ fontSize: { xs: 15, sm: 20 } }}>
                        {item?.mediaTitle
                          ? iconMap?.[item.mediaTitle]?.label
                          : null}
                      </Typography>
                    </Link>
                  ) : (
                    <Link
                      href={`${item.url}`}
                      underline="none"
                      color="inherit"
                      target={'_blank'}
                      rel="noopener"
                    >
                      <Typography sx={{ fontSize: { xs: 15, sm: 20 } }}>
                        {item?.mediaTitle
                          ? iconMap?.[item.mediaTitle]?.label
                          : null}
                      </Typography>
                    </Link>
                  )}
                </Grid>
              </React.Fragment>
            )
          })}
      </>
    )
  } else {
    return null
  }
}

export default SocialMedia
