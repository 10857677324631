import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Button, LinkProps as MuiLinkProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

const LinkButton = styled(Button)<LinkProps>(({ theme }) => ({
  margin: 5,
  width: 200,
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  borderRadius: 0,
  textTransform: 'none',
  fontSize: 16,
  '&:hover': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}))

const OuterLinkButton = styled((props) => (
  <Button variant="outlined" {...props} />
))<MuiLinkProps>(({ theme }) => ({
  margin: 5,
  width: 200,
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  borderRadius: 0,
  textTransform: 'none',
  fontSize: 16,
  '&:hover': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}))

const LINKS = [
  {
    label: 'footer.createProfile',
    to: `sign-up`,
    isOuterLink: true,
  },
  {
    label: 'footer.createPropertyAd',
    to: 'property-ads/create',
    isOuterLink: true,
  },
  {
    label: 'footer.termsOfUse',
    to: `/terms`,
  },
  {
    label: 'footer.contact',
    to: `/contact-us`,
  },
]

const Links = () => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <OuterLinkButton
        underline="none"
        href={`${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}`}
      >
        {t('footer.signIn')}
      </OuterLinkButton>
      {LINKS.map((link) =>
        link.isOuterLink ? (
          <OuterLinkButton
            underline="none"
            key={link.label}
            href={`${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}${link.to}`}
          >
            {' '}
            {t(link.label)}{' '}
          </OuterLinkButton>
        ) : (
          <LinkButton
            LinkComponent={RouterLink}
            to={link.to}
            variant="outlined"
            key={link.label}
            fullWidth
          >
            {t(link.label)}
          </LinkButton>
        )
      )}
    </Box>
  )
}
export default Links
