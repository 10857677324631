import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import { Typography, TypographyProps } from '@mui/material'
import { ViewVariant } from 'types'

interface SearchLabelProps extends TypographyProps {
  isActive?: boolean
  cVariant?: ViewVariant
}

const SearchLabel = styled(
  (props) => <Typography component="h2" {...props} />,
  {
    shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'cVariant',
  }
)<SearchLabelProps>(({ cVariant, isActive, theme }) => ({
  fontSize: 45,
  fontWeight: 900,
  color: 'rgba(255,255,255,.5)',
  ...(isActive && {
    color: theme.palette.common.white,
  }),
  [theme.breakpoints.down('xs')]: {
    fontSize: 16,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 25,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: 32,
  },
  ...(cVariant === 'inner' && {
    fontSize: 25,
    color: alpha(theme.palette.primary.main, 0.6),
    ...(isActive && {
      color: theme.palette.primary.main,
    }),
    [theme.breakpoints.up('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  }),
}))

export default SearchLabel
