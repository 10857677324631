import React from 'react'
import { Box, SxProps } from '@mui/material'
import { isEqual } from 'lodash'

import { useAdSlot } from 'hooks/useAdSlot'

interface AdProps {
  id: string
  sx?: SxProps
  OtherProps?: unknown
  collapse?: boolean
}
function Ad(props: AdProps) {
  const { id, sx, collapse, ...OtherProps } = props

  useAdSlot(id, collapse)

  const parsedId = id.split('#')

  return (
    <Box
      id={parsedId[0] + '-' + parsedId[1]}
      sx={{
        ...sx,
      }}
      {...OtherProps}
    ></Box>
  )
}

export default React.memo(Ad, isEqual)
