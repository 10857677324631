import { useMutation, UseMutationOptions } from 'react-query'
import axios, { type AxiosError } from 'axios'
import qs from 'qs'

import AuthService from 'services/AuthService'
import TokenService from 'services/TokenService'
import { useAuthStore } from 'store'
import { LoginDTO, LoginResponse } from './types'

const resetAuthStore = useAuthStore.getState().reset

const baseApi = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'development'
      ? process.env.REACT_APP_KEYCLOACK_SERVER_URL
      : `${window.location.origin}${process.env.REACT_APP_KEYCLOACK_SERVER_URL}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  withCredentials: true,
})

const SERVICE_NAME = `Authentication`

const QueryKeys = {} as const

const MutationKeys = {
  logout: `${SERVICE_NAME}#logout`,
  login: `${SERVICE_NAME}#login`,
} as const

const CONTROLLER_PATH = `realms/${process.env.REACT_APP_KEYCLOACK_REALM}/protocol/openid-connect`

export const createLogin = async (payload: {
  username: string
  password: string
}): Promise<LoginResponse> => {
  const { data } = await baseApi({
    method: 'post',
    url: `${CONTROLLER_PATH}/token`,
    data: qs.stringify({
      ...payload,
      grant_type: 'password',
      client_id: process.env.REACT_APP_KEYCLOACK_CLIENT_ID,
    }),
  })
  return data
}

export const useLogin = (
  options: Omit<
    UseMutationOptions<LoginResponse, AxiosError, LoginDTO>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.login, createLogin, {
    ...options,
  } as Omit<UseMutationOptions<LoginResponse, AxiosError, LoginDTO>, 'mutationKey' | 'mutationFn'>)
}

//logout
export const logout = async () => {
  const { data } = await baseApi({
    method: 'post',
    url: `${CONTROLLER_PATH}/logout`,
    data: qs.stringify({
      client_id: process.env.REACT_APP_KEYCLOACK_CLIENT_ID,
      refresh_token: AuthService.getRefreshToken(),
    }),
  })

  TokenService.removeAccessToken()
  TokenService.removeRefreshToken()
  AuthService.clearAuthState()
  resetAuthStore()

  window.location.replace('/')

  return data
}

export const useLogout = (
  options: Omit<
    UseMutationOptions<null, AxiosError, null>,
    'mutationKey' | 'mutationFn'
  >
) => {
  return useMutation(MutationKeys.logout, logout, {
    ...options,
  } as Omit<UseMutationOptions<null, AxiosError, null>, 'mutationKey' | 'mutationFn'>)
}

const AuthenticationService = {
  createLogin,
  logout,
  hooks: {
    useLogin,
    useLogout,
  },
  keys: { QueryKeys, MutationKeys },
}

export default AuthenticationService
