import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import {
  ArrowForwardIos,
  ArrowBackIosNew,
  PhotoSizeSelectActual,
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import {
  Navigation,
  Swiper as SwiperType,
  Thumbs,
  Controller,
  FreeMode,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { type Attachment } from 'types'
import { sortBy } from 'lodash'
import './styles.css'

interface ImagesCarouselProps {
  current: number
  onChange: (index: number) => void
  images: Attachment[]
  renderImage?: (Attachment) => React.ReactNode
}

function ImagesCarousel(props: ImagesCarouselProps) {
  const { onChange, images, current } = props

  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperType | null>(
    null
  )

  const theme = useTheme()

  const ForwardRef = React.useRef<HTMLElement | null>(null)
  const BackRef = React.useRef<HTMLElement | null>(null)
  const ThumbForwardRef = React.useRef<HTMLElement | null>(null)
  const ThumbBackRef = React.useRef<HTMLElement | null>(null)

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'))

  const wrapperHeight = () => {
    if (matchesSM) return 210
    if (matchesMD) return 292.8
    if (matchesLG) return 550
    return 600
  }
  const wrapperInnerHeight = () => {
    if (matchesSM) return 70
    if (matchesMD) return 87.5
    if (matchesLG) return 175
    return 200
  }

  const CALCULATED_TOP = `calc(${wrapperHeight() / 2}px - 25px)`
  const CALCULATED_TOP_THUMB = `calc(${wrapperInnerHeight() / 2}px - 25px)`

  const renderInfoBox = () => {
    if (images?.length) {
      return (
        <Box
          sx={{
            position: 'absolute',
            top: `calc(${wrapperHeight()}px - 25px)`,
            // bottom: 10,
            right: 10,
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'common.black',
            py: '1px',
            px: '3px',
            borderRadius: '4px',
            gap: '3px',
          }}
        >
          <Typography
            sx={{
              color: 'common.white',
              fontSize: '12px',
              fontWeight: 400,
              letterSpacing: '-1px',
            }}
          >
            {images?.length}
          </Typography>
          <PhotoSizeSelectActual
            sx={{
              fill: 'white',
              fontSize: '15px',
            }}
          />
        </Box>
      )
    }
    return null
  }

  return (
    <>
      {images?.length && images?.length > 1 ? <></> : null}

      <Box
        sx={{
          borderRadius: 8,
          position: 'relative',
        }}
      >
        <Box ref={BackRef} onClick={() => onChange(current - 1)}>
          <ArrowBackIosNew
            sx={{
              position: 'absolute',
              top: CALCULATED_TOP,
              left: 10,
              zIndex: 100,
              cursor: 'pointer',
              stroke: 'white',
              strokeWidth: 3,
              width: '25px',
              height: '50px',
              '&:hover': {
                filter: `drop-shadow(0 0 10px  rgba(0,0,0,0.8))`,
              },
            }}
          />
        </Box>
        <Box ref={ForwardRef}>
          <ArrowForwardIos
            sx={{
              position: 'absolute',
              top: CALCULATED_TOP,
              right: 10,
              zIndex: 100,
              cursor: 'pointer',
              stroke: 'white',
              strokeWidth: 3,
              width: '25px',
              height: '50px',
              '&:hover': {
                filter: `drop-shadow(0 0 10px  rgba(0,0,0,0.8))`,
              },
            }}
          />
        </Box>
        {renderInfoBox()}
        <Swiper
          style={{
            width: '100%',
            height: wrapperHeight(),
            borderRadius: 2.5,
          }}
          centeredSlides
          loop
          modules={[Navigation, Thumbs, Controller, FreeMode]}
          navigation={{
            nextEl: (ForwardRef?.current as HTMLElement) ?? null,
            prevEl: BackRef?.current ?? null,
          }}
          thumbs={{ swiper: thumbsSwiper }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          slidesPerView={1}
          className="mySwiperTop"
        >
          {sortBy(images, 'orderNumber').map((image, index) => (
            <SwiperSlide key={'image' + image.id}>
              <Box
                component="img"
                src={image?.filePath ?? undefined}
                sx={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'black',
                  objectFit: 'contain',
                  borderRadius: 2.5,
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Box
          sx={{
            marginTop: '16px',
            width: '100%',
            height: { xs: 70, sm: 87.5, md: 175, lg: 200 },
            position: 'relative',
          }}
        >
          <Box
            ref={ThumbBackRef}
            sx={{ position: 'absolute', left: 0, borderRadius: '12px' }}
          >
            <Box
              sx={{
                position: 'relative',
                height: wrapperInnerHeight,
                width: '50px',
                bgcolor: 'rgba(0,0,0,.5)',
                cursor: 'pointer',
                borderTopLeftRadius: '12px',
                borderBottomLeftRadius: '12px',
                zIndex: 99,
                '&:hover': {
                  bgcolor: 'rgba(0,0,0,.8)',
                  transition: theme.transitions.create(['background-color']),
                },
              }}
            >
              <ArrowBackIosNew
                sx={{
                  position: 'absolute',
                  top: CALCULATED_TOP_THUMB,
                  left: 10,
                  zIndex: 100,
                  cursor: 'pointer',
                  stroke: 'white',
                  strokeWidth: 3,
                  width: '25px',
                  height: '50px',
                  '&:hover': {
                    filter: `drop-shadow(0 0 10px  rgba(0,0,0,0.8))`,
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            ref={ThumbForwardRef}
            sx={{ position: 'absolute', right: 0, borderRadius: '12px' }}
          >
            <Box
              sx={{
                position: 'relative',
                height: wrapperInnerHeight,
                width: '50px',
                bgcolor: 'rgba(0,0,0,.5)',
                cursor: 'pointer',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                zIndex: 99,
                '&:hover': {
                  bgcolor: 'rgba(0,0,0,.8)',
                  transition: theme.transitions.create(['background-color']),
                },
              }}
            >
              <ArrowForwardIos
                sx={{
                  position: 'absolute',
                  top: CALCULATED_TOP_THUMB,
                  right: 10,
                  zIndex: 100,
                  cursor: 'pointer',
                  stroke: 'white',
                  strokeWidth: 3,
                  width: '25px',
                  height: '50px',
                  '&:hover': {
                    filter: `drop-shadow(0 0 10px  rgba(0,0,0,0.8))`,
                  },
                }}
              />
            </Box>
          </Box>
          <Swiper
            style={{
              width: '100%',
              height: wrapperInnerHeight(),
              borderRadius: '12px',
            }}
            onSwiper={(swiper) => {
              setThumbsSwiper(swiper)
            }}
            navigation={{
              nextEl: ThumbForwardRef?.current ?? null,
              prevEl: ThumbBackRef?.current ?? null,
            }}
            freeMode
            loop={true}
            spaceBetween={10}
            slidesPerView={3}
            watchSlidesProgress={true}
            modules={[Controller, Navigation]}
            className="mySwiperBottom"
          >
            {sortBy(images, 'orderNumber').map((image) => (
              // eslint-disable-next-line react/jsx-key
              <SwiperSlide key={`thumb-${image.id}`}>
                {() => (
                  <Box
                    component="img"
                    src={image?.filePath ?? undefined}
                    sx={{
                      borderRadius: '12px',

                      // opacity: 0.6,
                      transition: (theme) => theme.transitions.create(['all']),
                      '&:hover': {
                        opacity: 1,
                      },
                    }}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </>
  )
}

export default ImagesCarousel

ImagesCarousel.propTypes = {}
