import PropTypes from 'prop-types'
import { Access } from 'types'
import { KEYCLOAK_ROLES } from 'helpers/constants'

import AuthService from 'services/AuthService'

const RenderOnRole = ({
  roles,
  children,
}: {
  roles: Access
  children: JSX.Element
}) => {
  if (roles?.length === 1) {
    if (roles.includes(KEYCLOAK_ROLES.ROLE_USER)) {
      return AuthService.isOnlyUser() ? children : null
    }
    if (roles.includes(KEYCLOAK_ROLES.ROLE_ADMIN)) {
      return AuthService.isAdmin() ? children : null
    }
    if (roles.includes(KEYCLOAK_ROLES.ROLE_AGENT)) {
      return AuthService.isAgent() ? children : null
    }
  }
  return AuthService.hasRole(roles) ? children : null
}

RenderOnRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RenderOnRole
