import type {
  PropertyCategoryOption,
  MaritalStatusOptions,
  MortageVatOptions,
  ChildrenOptions,
  FirstHouseOptions,
} from 'api/services/PropertyExpenses/types'

export const PROPERTY_CATEGORY_OPTIONS: PropertyCategoryOption[] = [
  {
    label: 'detailPage.calculator.buy.fields.propertyCategory.estateBuy',
    value: 'ESTATE_BUY',
  },

  {
    label: 'detailPage.calculator.buy.fields.propertyCategory.landBuy',
    value: 'LAND_BUY',
  },

  {
    label:
      'detailPage.calculator.buy.fields.propertyCategory.proffesionalEstateBug',
    value: 'PROFESSIONAL_ESTATE_BUY',
  },
]

export const MARITAL_STATUS_OPTIONS: MaritalStatusOptions[] = [
  {
    label: 'detailPage.calculator.buy.fields.maritalStatus.options.married',
    value: 'MARRIED',
  },
  {
    label: 'detailPage.calculator.buy.fields.maritalStatus.options.unmarried',
    value: 'UNMARRIED',
  },
]

export const MORTAGE_VAT_OPTIONS: MortageVatOptions[] = [
  {
    label: 'detailPage.calculator.buy.fields.mortageVat.options.yes',
    value: 1,
  },
  {
    label: 'detailPage.calculator.buy.fields.mortageVat.options.no',
    value: 0,
  },
]

export const CHILDREN_STATUS_OPTIONS: ChildrenOptions[] = [
  {
    label: 'detailPage.calculator.buy.fields.children.0',
    value: '0',
  },
  {
    label: 'detailPage.calculator.buy.fields.children.1',
    value: '1',
  },
  {
    label: 'detailPage.calculator.buy.fields.children.2',
    value: '2',
  },
  {
    label: 'detailPage.calculator.buy.fields.children.3',
    value: '3',
  },
  {
    label: 'detailPage.calculator.buy.fields.children.4',
    value: '4',
  },
  {
    label: 'detailPage.calculator.buy.fields.children.5',
    value: '5',
  },
]

export const FIRST_HOUSE_OPTIONS: FirstHouseOptions[] = [
  {
    label: 'detailPage.calculator.buy.fields.firstHouse.options.yes',
    value: 1,
  },
  {
    label: 'detailPage.calculator.buy.fields.firstHouse.options.no',
    value: 0,
  },
]
