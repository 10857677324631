import React from 'react'
import RecentList from 'components/Detail/RecentList'
import InnerLayout from 'layouts/InnerLayout'
import { Box, Typography, Grid, Divider } from '@mui/material'
import { useRecentStore } from 'store'
import { useParams } from 'react-router-dom'
import FeatureCard from 'components/Search/Results/FeatureCard'
import { useTranslation } from 'react-i18next'
import usePageView from 'hooks/usePageView'

RecentList.propTypes = {}

function Recents({ limit = 9 }) {
  const { t } = useTranslation()
  usePageView()

  const { detailId } = useParams()

  const recentList = useRecentStore(
    React.useCallback(
      (state) =>
        state.recentList
          .filter((item) => item.id !== detailId)
          .reverse()
          .slice(0, limit),
      [detailId, limit]
    )
  )
  return (
    <InnerLayout>
      <Box sx={{ justifyContent: 'center', display: 'flex' }} pb={5}>
        <Grid container sx={{ width: (theme) => theme.breakpoints.values.lg }}>
          <Grid item container xs={12}>
            <Grid item sx={{ my: 5 }} xs={12}>
              <Typography variant="h3" color="textSecondary">
                {t('recentsPage.title')}
              </Typography>
              <Divider />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            {recentList.map((item) => {
              return (
                <Grid
                  key={item.id}
                  item
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  justifyContent="center"
                >
                  <FeatureCard data={item} />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    </InnerLayout>
  )
}

export default Recents
