import {
  useQuery,
  useMutation,
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query'
import { AxiosError } from 'axios'

import api from 'api'
import { type PropertyAd } from 'types'
import {
  AddToFavoritesList,
  CreateFavoritesList,
  UserFavoritesListsCriteriaDTO,
  RemoveFromFavoritesList,
  UserFavoritesList,
  GetUserFavoritesListDTO,
} from './types'
import { ListCategoryEnum } from 'types/enums'

interface Response {
  successful: string
}

const QueryKeys = {
  all: ['userFavorites'] as const,
  lists: ['favoritesLists'] as const,
  search: ['lists'] as const,
}

const MutationKeys = {
  favoritesLists: ['favoritesListsMutation'] as const,
  createFavList: ['createFavoritesLists'] as const,
  addToList: ['addFavorite'] as const,
  removeFromList: ['removeFavorite'] as const,
  addSingle: ['userFavoritesAddSingle'] as const,
  deleteSingle: ['userFavoritesDeleteSingle'] as const,
  deleteAll: ['userFavoritesDeleteAll'] as const,
}

const CONTROLLER_PATH = '/app/spourgiti/admin/user-favorites'

//GET all
export const getUserFavorites = async (): Promise<PropertyAd[]> => {
  const { data } = await api({
    method: 'get',
    url: `${CONTROLLER_PATH}/`,
  })
  return data
}

export const useUserFavorites = <T = PropertyAd[]>(
  options: UseQueryOptions<T, AxiosError, PropertyAd[]> = {}
) => {
  return useQuery<PropertyAd[]>(QueryKeys.all, () => getUserFavorites(), {
    staleTime: Infinity,
    cacheTime: Infinity,
    retryOnMount: false,
    ...options,
  } as Omit<UseQueryOptions<PropertyAd[], unknown, PropertyAd[], QueryKey>, 'queryKey' | 'queryFn'>)
}

//POST :id
export const addUserFavorite = async (
  alternativeId: string
): Promise<PropertyAd> => {
  const { data } = await api({
    method: 'post',
    url: `${CONTROLLER_PATH}/${alternativeId}`,
  })
  return data
}

export const useAddUserFavorite = (
  options: Omit<
    UseMutationOptions<PropertyAd, AxiosError, string>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.addSingle, addUserFavorite, {
    ...options,
  } as Omit<UseMutationOptions<PropertyAd, AxiosError, string>, 'mutationKey' | 'mutationFn'>)
}

//DELETE :id
export const removeUserFavorite = async (
  alternativeId: string
): Promise<Response> => {
  const { data } = await api({
    method: 'delete',
    url: `${CONTROLLER_PATH}/${alternativeId}`,
  })
  return data
}

export const useRemoveUserFavorite = (
  options: Omit<
    UseMutationOptions<Response, AxiosError, string>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.deleteSingle, removeUserFavorite, {
    ...options,
  } as Omit<UseMutationOptions<Response, AxiosError, string>, 'mutationKey' | 'mutationFn'>)
}

//DELETE all
export const removeAllUserFavorite = async (): Promise<Response> => {
  const { data } = await api({
    method: 'delete',
    url: `${CONTROLLER_PATH}/all`,
  })
  return data
}

export const useRemoveAllUserFavorite = (
  options: Omit<
    UseMutationOptions<Response, AxiosError, void>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.deleteAll, removeAllUserFavorite, {
    ...options,
  })
}

/* User Favorites Lists services */

//POST all
export const getUserFavoritesLists = async (
  payload: Partial<UserFavoritesListsCriteriaDTO>
): Promise<GetUserFavoritesListDTO> => {
  const { data } = await api({
    method: 'POST',
    url: `${CONTROLLER_PATH}/lists/search-by-criteria`,
    data: payload,
  })
  return data
}

export const useLists = (
  listCategory: ListCategoryEnum | null,
  paginationOptions: Partial<
    Pick<UserFavoritesListsCriteriaDTO, 'pageNumber' | 'pageSize'>
  > = {},
  options: UseQueryOptions<
    {
      listCategory: ListCategoryEnum | null
      paginationOptions: Partial<
        Pick<UserFavoritesListsCriteriaDTO, 'pageNumber' | 'pageSize'>
      >
    },
    GetUserFavoritesListDTO,
    AxiosError
  > = {}
) =>
  useQuery<GetUserFavoritesListDTO, AxiosError>(
    [...QueryKeys.search, listCategory],
    () =>
      getUserFavoritesLists({
        listCategories: listCategory ? [listCategory] : [],
        ...paginationOptions,
      }),
    {
      ...options,
      staleTime: 1000 * 60 * 5,
    } as Omit<
      UseQueryOptions<
        GetUserFavoritesListDTO,
        AxiosError,
        GetUserFavoritesListDTO,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >
  )

export const useUserFavoritesLists = (
  options: Omit<
    UseMutationOptions<
      GetUserFavoritesListDTO,
      AxiosError,
      UserFavoritesListsCriteriaDTO
    >,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.favoritesLists, getUserFavoritesLists, {
    ...options,
  } as Omit<UseMutationOptions<GetUserFavoritesListDTO, AxiosError, UserFavoritesListsCriteriaDTO>, 'mutationKey' | 'mutationFn'>)
}

//POST
export const createUserFavoritesList = async (
  payload: CreateFavoritesList
): Promise<UserFavoritesList> => {
  const { data } = await api({
    method: 'post',
    url: `${CONTROLLER_PATH}/lists`,
    data: payload,
  })
  return data
}

export const useCreateUserFavoritesList = (
  options: Omit<
    UseMutationOptions<UserFavoritesList, AxiosError, CreateFavoritesList>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.createFavList, createUserFavoritesList, {
    ...options,
  } as Omit<UseMutationOptions<UserFavoritesList, AxiosError, CreateFavoritesList>, 'mutationKey' | 'mutationFn'>)
}

//POST
export const addToUserFavoritesList = async (
  payload: AddToFavoritesList
): Promise<PropertyAd> => {
  const { data } = await api({
    method: 'POST',
    url: `${CONTROLLER_PATH}/lists/propertyAd`,
    data: payload,
  })
  return data
}

export const useAddToUserFavoritesList = (
  options: Omit<
    UseMutationOptions<PropertyAd, AxiosError, AddToFavoritesList>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.addToList, addToUserFavoritesList, {
    ...options,
  })
}

//DELETE :listId/:propertyAltId
export const removeFromUserFavoritesList = async (
  RemoveFromFavoritesList
): Promise<Response> => {
  const { data } = await api({
    method: 'delete',
    url: `${CONTROLLER_PATH}/lists/${RemoveFromFavoritesList.listId}/${RemoveFromFavoritesList.propertyAdAlternativeId}`,
  })
  return data
}

export const useRemoveFromUserFavoritesList = (
  options: Omit<
    UseMutationOptions<Response, AxiosError, RemoveFromFavoritesList>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(MutationKeys.removeFromList, removeFromUserFavoritesList, {
    ...options,
  } as Omit<UseMutationOptions<Response, AxiosError, RemoveFromFavoritesList>, 'mutationKey' | 'mutationFn'>)
}

const UserFavoritesService = {
  getUserFavorites,
  removeUserFavorite,
  removeAllUserFavorite,
  hooks: {
    useLists,
    useUserFavorites,
    useAddUserFavorite,
    useRemoveUserFavorite,
    useRemoveAllUserFavorite,
    useUserFavoritesLists,
    useCreateUserFavoritesList,
    useAddToUserFavoritesList,
    useRemoveFromUserFavoritesList,
  },
  keys: { QueryKeys },
}

export default UserFavoritesService
