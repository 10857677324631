import { useMutation, UseMutationOptions } from 'react-query'

import api from 'api'
import { ContactSpourgitiDTO, ContactAgentDTO } from './types'
import { AxiosError } from 'axios'

const contactFormMutationKeys = {
  spourgiti: ['contactSpourgitiMutation'] as const,
  agent: ['contactAgentMutation'] as const,
}

export const contactSpourgiti = async (
  payload: ContactSpourgitiDTO
): Promise<void> => {
  const { data } = await api({
    method: 'post',
    url: `/app/spourgiti/api/contact/spourgiti`,
    data: {
      ...payload,
    },
  })

  return data
}
export const useContactSpourgiti = (
  options: Omit<
    UseMutationOptions<void, AxiosError, ContactSpourgitiDTO>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(contactFormMutationKeys.spourgiti, contactSpourgiti, {
    ...options,
  } as Omit<UseMutationOptions<void, AxiosError, ContactSpourgitiDTO>, 'mutationKey' | 'mutationFn'>)
}

export const contactAgent = async (payload: ContactAgentDTO): Promise<void> => {
  const { data } = await api({
    method: 'post',
    url: `/app/spourgiti/api/contact/agent`,
    data: {
      ...payload,
    },
  })

  return data
}
export const useContactAgent = (
  options: Omit<
    UseMutationOptions<void, AxiosError, ContactAgentDTO>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(contactFormMutationKeys.agent, contactAgent, {
    ...options,
  } as Omit<UseMutationOptions<void, AxiosError, ContactAgentDTO>, 'mutationKey' | 'mutationFn'>)
}

export const ContactService = {
  contactSpourgiti,
  contactAgent,
  hooks: {
    useContactSpourgiti,
    useContactAgent,
  },
}

export default ContactService
