import React from 'react'
import { styled } from '@mui/material/styles'

import {
  Box,
  Grid,
  Typography,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
// import Buy from './Buy'
// import Loan from './Loan'
import { calculateInterest } from '../helpers'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import PropertyExpensesService from 'api/services/PropertyExpenses'
import { PropertyCategory } from 'api/services/PropertyExpenses/types'
import { useCalculatorStore } from 'store'
import Loan from 'components/Calculator/Loan'
import Buy from 'components/Calculator/Buy'
import { FormFields } from 'components/Calculator/types'

export const PROPERTY_CATEGORY = [
  {
    label: 'Κατοικία',
    value: 'ESTATE_BUY',
    id: 1,
  },
  {
    label: 'Γη',
    value: 'LAND_BUY',
    id: 3,
  },
  {
    label: 'Επαγγελματικό',
    value: 'PROFESSIONAL_ESTATE_BUY',
    id: 2,
  },
]

const CTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  function CTextField(props, ref) {
    return <TextField inputRef={ref} {...props} />
  }
)

export const StyledTextField = styled(CTextField)<TextFieldProps>(() => ({
  '& label': {
    backgroundColor: 'red',
  },
  '& .MuiInputBase-input': {
    backgroundColor: 'transparent',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
  },
}))

interface Props {
  data: ParsedProperty
}

function Calculator(props: Props) {
  const { t } = useTranslation()
  const { data } = props

  const findPropertyCat = () => {
    return PROPERTY_CATEGORY.findIndex(
      (propC) => propC.id === data?.adCategoryId
    )
  }

  const [interestRate, financePercentage] = useCalculatorStore((state) => [
    state.interestRate,
    state.financePercentage,
  ])

  const { handleSubmit, control, reset, setValue, watch } = useForm<FormFields>(
    {
      defaultValues: {
        category: PROPERTY_CATEGORY[findPropertyCat()].value,
        propertyValue: data?.salesPrice ?? 0,
        rightOfMainResidence: 0,
        maritalStatus: 'MARRIED',
        children: 0,
        mortageVat: 1,
        financingPercent: financePercentage,
        duration: 10,
        interestRate: interestRate,
      },
    }
  )

  const watchedPropertyValue = watch('propertyValue', 0)
  const watchedPercentage = watch('financingPercent', 0)

  React.useEffect(() => {
    setValue(
      'financingAmount',
      (Number(watchedPropertyValue) * (watchedPercentage / 100)).toFixed(2) ?? 0
    )
  }, [watchedPropertyValue, watchedPercentage, setValue])

  const calculateExpenses = PropertyExpensesService.hooks.useCalculateExpenses()

  const onSubmit = (data) => {
    calculateExpenses.mutate({
      duration: Number.parseInt(data?.duration),
      foundationPercentage: Number(data.financingPercent),
      interestRatePercentage: Number(data.interestRate),
      landRegistryVat: Boolean(data.mortageVat),
      maritalStatus: data.maritalStatus,
      numberOfChildren: data.children,
      propertyCategory: data.category,
      propertyCost: Number(data.propertyValue),
      rightOfMainResidence: Boolean(data.rightOfMainResidence),
    })
  }

  React.useEffect(() => {
    calculateExpenses.mutate({
      propertyCategory: PROPERTY_CATEGORY[findPropertyCat()]
        .value as PropertyCategory,
      propertyCost: data?.salesPrice ?? 0,
      rightOfMainResidence: Boolean(0),
      maritalStatus: 'MARRIED',
      numberOfChildren: 0,
      landRegistryVat: Boolean(1),
      foundationPercentage: financePercentage,
      duration: 10,
      interestRatePercentage: interestRate,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const category = calculateInterest(data.adCategoryId)

  if (category === 'loan') return null

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ py: 1 }}>
      <Stack direction="row" alignItems="baseline" spacing={1} sx={{ py: 2 }}>
        <Typography
          align="center"
          variant="h5"
          sx={{
            fontWeight: 900,
            fontSize: { xs: 17, sm: 20, md: 30, lg: 35 },
          }}
        >
          {t('detailPage.calculator.label')}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            borderBottom: (theme) => `4px solid ${theme.palette.primary.main}`,
          }}
        />
      </Stack>
      <Grid container columnSpacing={3}>
        <Grid item sm={12} md={6} lg={6}>
          <Buy
            value={100000}
            control={control}
            data={calculateExpenses.data}
            loading={calculateExpenses.isLoading}
            reqError={calculateExpenses.isError}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Loan
            value={100000}
            control={control}
            data={calculateExpenses.data?.installment}
            loading={calculateExpenses.isLoading}
            reset={reset}
            reqError={calculateExpenses.isError}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Calculator
