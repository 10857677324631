import React from 'react'
import Grid from '@mui/material/Grid'
import shallow from 'zustand/shallow'
import { useTheme } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'

import { useSearchStore } from 'store'
import { ViewVariant } from 'types'
import SearchControls from './SearchControls'

import FloorSelect from './SearchMore/FloorSelect'
import Factor from './SearchMore/Factor'
import PropertyAccess from './SearchMore/PropertyAccess'
import {
  COMMERCIAL_TYPE_OPTIONS,
  ESTATE_TYPE_OPTIONS,
  HEAT_OPTIONS,
  LAND_TYPE_OPTIONS,
  SEARCH_TYPES,
  LAND_EXTRAS_OPTIONS,
  COMMERCIAL_EXTRAS_OPTIONS,
  ESTATE_EXTRAS_OPTIONS,
  ESTATE_THERMAL_TYPE_OPTIONS,
} from 'helpers/constants'
import BusinessLG from 'assets/img/business_large.png'
import HomeLG from 'assets/img/home_large.png'
import LandLG from 'assets/img/land_large.png'
import LandSM from 'assets/img/land_small.png'
import NumericFilters from './SearchMore/NumericFilters'
import ListFilters from './SearchMore/ListFilters'
import {
  ESTATE_CONDITION_OPTIONS,
  LAND_CHARACTERISTIC_OPTIONS,
} from 'store/SearchSlice/constants'
import { SearchForEnum } from 'types/enums'

interface SearchMoreFiltersProps {
  variant?: ViewVariant
  isLoading?: boolean
  isSuccess?: boolean
  onRefetch?: (arg?: number) => void
  handleCloseFilters?: () => void
}

function SearchMoreFilters(props: SearchMoreFiltersProps) {
  const { variant, onRefetch, isLoading, isSuccess, handleCloseFilters } = props

  const IS_INNER = variant === 'inner'
  const IS_LANDING = !IS_INNER

  const theme = useTheme()

  const matchesMDUp = useMediaQuery(theme.breakpoints.up('md'))
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))

  const [searchBy] = useSearchStore((state) => [state.searchBy], shallow)

  const IS_TYPE_LAND = searchBy === SEARCH_TYPES.LAND
  const IS_TYPE_HOME = searchBy === SEARCH_TYPES.ESTATE
  const IS_TYPE_COMMERCIAL = searchBy === SEARCH_TYPES.COMMERCIAL

  const IS_LAND_MDD = matchesMD ? LandSM : LandLG
  const IS_ESTATE_OR_COM =
    searchBy === SearchForEnum.Estate ? HomeLG : BusinessLG

  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={12} sx={{ marginTop: IS_INNER ? 1 : 4 }}>
        {IS_TYPE_HOME ? (
          <ListFilters
            onRefetch={onRefetch}
            handleCloseFilters={handleCloseFilters}
            variant={variant}
            sx={{ marginTop: 1 }}
            options={ESTATE_TYPE_OPTIONS}
            field={'estatePropertyType'}
          />
        ) : null}
        {IS_TYPE_COMMERCIAL ? (
          <ListFilters
            onRefetch={onRefetch}
            handleCloseFilters={handleCloseFilters}
            variant={variant}
            options={COMMERCIAL_TYPE_OPTIONS}
            field={'commercialType'}
          />
        ) : null}
        {IS_TYPE_LAND ? (
          <ListFilters
            onRefetch={onRefetch}
            variant={variant}
            handleCloseFilters={handleCloseFilters}
            options={LAND_TYPE_OPTIONS}
            field={'landType'}
          />
        ) : null}
        {IS_INNER ? (
          <NumericFilters
            onRefetch={onRefetch}
            variant={variant}
            handleCloseFilters={handleCloseFilters}
            field={'price'}
            showCurrencyPlaceholder
          />
        ) : null}
        {IS_INNER ? (
          <NumericFilters
            onRefetch={onRefetch}
            variant={variant}
            handleCloseFilters={handleCloseFilters}
            field={'squareMeters'}
          />
        ) : null}
        {IS_TYPE_COMMERCIAL || IS_TYPE_HOME ? (
          <NumericFilters
            onRefetch={onRefetch}
            variant={variant}
            handleCloseFilters={handleCloseFilters}
            field={'constructionYear'}
            numberFormatProps={{ decimalScale: 0, thousandSeparator: false }}
          />
        ) : null}

        {IS_TYPE_LAND ? null : (
          <Box sx={{ marginTop: 1 }}>
            <ListFilters
              handleCloseFilters={handleCloseFilters}
              onRefetch={onRefetch}
              variant={variant}
              sx={{ marginTop: 1 }}
              field={'estatePropertyCondition'}
              options={ESTATE_CONDITION_OPTIONS}
            />
          </Box>
        )}
        {IS_LANDING && matchesMDUp ? (
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={6}>
              {IS_TYPE_LAND ? (
                <>
                  <Factor
                    variant={variant}
                    sx={{ marginTop: 2 }}
                    handleCloseFilters={handleCloseFilters}
                  />
                  <NumericFilters
                    variant={variant}
                    sx={{ marginTop: 2 }}
                    handleCloseFilters={handleCloseFilters}
                    field={'buildMeters'}
                  />
                  <PropertyAccess
                    variant={variant}
                    sx={{ marginTop: 2 }}
                    handleCloseFilters={handleCloseFilters}
                  />
                </>
              ) : null}
              {IS_TYPE_HOME ? (
                <>
                  <NumericFilters
                    variant={variant}
                    sx={{ marginTop: 2 }}
                    handleCloseFilters={handleCloseFilters}
                    field={'bedrooms'}
                  />
                  <NumericFilters
                    variant={variant}
                    sx={{ marginTop: 2 }}
                    handleCloseFilters={handleCloseFilters}
                    field={'bathrooms'}
                  />
                </>
              ) : null}
              {IS_TYPE_COMMERCIAL ? (
                <NumericFilters
                  variant={variant}
                  sx={{ marginTop: 2 }}
                  handleCloseFilters={handleCloseFilters}
                  field={'rooms'}
                />
              ) : null}
              {IS_TYPE_LAND ? null : (
                <FloorSelect
                  variant={variant}
                  sx={{ marginTop: IS_INNER ? 1 : 2 }}
                  handleCloseFilters={handleCloseFilters}
                />
              )}
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignContent="center"
              sx={{ mt: 2 }}
            >
              <Box
                component="img"
                src={
                  searchBy === SearchForEnum.Land
                    ? IS_LAND_MDD
                    : IS_ESTATE_OR_COM
                }
                alt={'image'}
                sx={{
                  width: searchBy === 'land' ? 400 : 'auto',
                  height: searchBy === 'land' ? 'auto' : '100%',
                  maxHeight: 400,
                  opacity: 0.4,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            {IS_TYPE_HOME ? (
              <>
                <NumericFilters
                  onRefetch={onRefetch}
                  variant={variant}
                  sx={{ marginTop: 2 }}
                  handleCloseFilters={handleCloseFilters}
                  field={'bedrooms'}
                />
                <NumericFilters
                  onRefetch={onRefetch}
                  variant={variant}
                  sx={{ marginTop: 2 }}
                  handleCloseFilters={handleCloseFilters}
                  field={'bathrooms'}
                />
              </>
            ) : null}
            {IS_TYPE_COMMERCIAL ? (
              <NumericFilters
                onRefetch={onRefetch}
                variant={variant}
                sx={{ marginTop: 2 }}
                handleCloseFilters={handleCloseFilters}
                field={'rooms'}
              />
            ) : null}
            {IS_TYPE_LAND ? (
              <>
                <Factor
                  onRefetch={onRefetch}
                  variant={variant}
                  sx={{ marginTop: 0 }}
                  handleCloseFilters={handleCloseFilters}
                />
                <NumericFilters
                  onRefetch={onRefetch}
                  variant={variant}
                  sx={{ marginTop: 0 }}
                  handleCloseFilters={handleCloseFilters}
                  field={'buildMeters'}
                />
                <PropertyAccess
                  onRefetch={onRefetch}
                  variant={variant}
                  sx={{ marginTop: 0 }}
                  handleCloseFilters={handleCloseFilters}
                />
              </>
            ) : null}
            {IS_TYPE_LAND ? null : (
              <FloorSelect
                onRefetch={onRefetch}
                variant={variant}
                sx={{ marginTop: IS_INNER ? 1 : 2 }}
                handleCloseFilters={handleCloseFilters}
              />
            )}
          </>
        )}
        {IS_TYPE_LAND ? null : (
          <>
            <ListFilters
              onRefetch={onRefetch}
              variant={variant}
              handleCloseFilters={handleCloseFilters}
              field={'thermals'}
              options={HEAT_OPTIONS}
            />
            <ListFilters
              onRefetch={onRefetch}
              variant={variant}
              handleCloseFilters={handleCloseFilters}
              field={'thermalsType'}
              options={ESTATE_THERMAL_TYPE_OPTIONS}
            />
          </>
        )}
        {IS_TYPE_HOME ? (
          <ListFilters
            onRefetch={onRefetch}
            variant={variant}
            handleCloseFilters={handleCloseFilters}
            options={ESTATE_EXTRAS_OPTIONS}
            field={'propertyExtras'}
          />
        ) : null}
        {IS_TYPE_COMMERCIAL ? (
          <ListFilters
            onRefetch={onRefetch}
            variant={variant}
            handleCloseFilters={handleCloseFilters}
            options={COMMERCIAL_EXTRAS_OPTIONS}
            field={'commercialExtras'}
          />
        ) : null}
        {IS_TYPE_LAND ? (
          <>
            <ListFilters
              onRefetch={onRefetch}
              variant={variant}
              handleCloseFilters={handleCloseFilters}
              options={LAND_CHARACTERISTIC_OPTIONS}
              field={'landCharacteristics'}
            />
            <ListFilters
              onRefetch={onRefetch}
              variant={variant}
              handleCloseFilters={handleCloseFilters}
              options={LAND_EXTRAS_OPTIONS}
              field={'extras'}
            />
          </>
        ) : null}
      </Grid>
      {IS_INNER ? null : (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <SearchControls
            isSearching={isLoading}
            isSuccess={isSuccess}
            variant="search"
          />
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(SearchMoreFilters)
