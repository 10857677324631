import React from 'react'
import {
  MapContainer,
  TileLayer,
  Circle,
  Marker,
  Popup,
  useMap,
} from 'react-leaflet'
import { LatLngExpression } from 'leaflet'
import { useTheme } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'

const ChangeMapView = ({ coords, height }) => {
  const map = useMap()
  React.useEffect(() => {
    map.invalidateSize()
  }, [coords, map, height])
  map.setView(coords, map.getZoom())
  return null
}

interface Props {
  mapLocation?: {
    latitude: number
    longitude: number
  }
  height?: number
  showMapPosition?: boolean
  hasImages?: boolean
}

const MapWrapper = (props: Props) => {
  const { hasImages, mapLocation } = props

  const theme = useTheme()

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'))

  const height = matchesSM
    ? 227
    : matchesMD
    ? hasImages
      ? 175
      : 195
    : matchesLG
    ? 248
    : hasImages
    ? 415
    : 268

  const NO_VALID_COORDS = !mapLocation?.latitude || !mapLocation?.longitude

  if (NO_VALID_COORDS) {
    return null
  }

  return (
    <Box sx={{ height, width: '100%' }}>
      <Map {...props} height={height} />
    </Box>
  )
}

interface MapProps extends Props {
  height: number
}

function Map(props: MapProps) {
  const { height, mapLocation, showMapPosition } = props

  const theme = useTheme()

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'))

  const coords: LatLngExpression = React.useMemo(
    () => [mapLocation?.latitude ?? 0, mapLocation?.longitude ?? 0],
    [mapLocation?.longitude, mapLocation?.latitude]
  )

  const fillBlueOptions = { fillColor: 'blue' }

  return (
    <>
      <MapContainer
        center={coords}
        zoom={16}
        style={{
          height: '100%',
          borderRadius: 5,
        }}
        doubleClickZoom={false}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | Spourgiti'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {showMapPosition ? (
          <Marker position={coords}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        ) : null}
        <ChangeMapView coords={coords} height={height} />
        <Circle
          center={coords}
          pathOptions={fillBlueOptions}
          radius={matchesSM ? 120 : matchesMD ? 140 : matchesLG ? 180 : 230}
        />
      </MapContainer>
    </>
  )
}

export default MapWrapper
