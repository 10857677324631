import { useMutation, UseMutationOptions } from 'react-query'

import api from 'api'
import { Area } from 'types'
import { AxiosError } from 'axios'
import { SearchAreaPayload } from './types'

const SERVICE_NAME = `Authentication`

const QueryKeys = {} as const

const MutationKeys = {
  search: `${SERVICE_NAME}#search`,
} as const

const CONTROLLER_PATH = `/app/spourgiti/api/propertyad`

export const searchAreas = async (
  payload: SearchAreaPayload
): Promise<Area[]> => {
  const { data } = await api({
    method: 'post',
    url: `${CONTROLLER_PATH}/search-areas`,
    data: {
      ...payload,
    },
  })
  return data
}

export const useSearchAreas = ({
  queryOptions = {},
}: {
  queryOptions: Omit<
    UseMutationOptions<Area[], AxiosError, SearchAreaPayload>,
    'mutationKey' | 'mutationFn'
  >
}) => {
  return useMutation(MutationKeys.search, searchAreas, {
    ...queryOptions,
  } as Omit<UseMutationOptions<Area[], AxiosError, SearchAreaPayload>, 'mutationKey' | 'mutationFn'>)
}

const AreaService = {
  searchAreas,
  hooks: {
    useSearchAreas,
  },
  keys: { QueryKeys, MutationKeys },
}

export default AreaService
