import React from 'react'
import {
  Box,
  Stack,
  Typography,
  Divider,
  SvgIcon,
  DividerProps,
  Link,
  ClickAwayListener,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import shallow from 'zustand/shallow'
import { useInView } from 'react-intersection-observer'
import SeparatorPNG from 'assets/img/seperator.png'
import ImageWrapper from './ImageWrapper'
import ImagesCarousel from './ImagesCarousel'
import TimeOutDialog from './TimeOutDialog'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import { type Attachment } from 'types'
import {
  calculatePropertyType,
  calculatePropertyCategory,
  getPropertyType,
  calculateInterest,
  parseArea,
} from 'components/Detail/helpers'
import IconGroup from './IconGroup'
import { ReactComponent as HomeSVG } from 'assets/svg/icons/home-icon.svg'
import { useSearchStore } from 'store'
import AuthService from 'services/AuthService'
import UserFavoritesService from 'api/services/UserFavoritesService'
import { useSnackbar } from 'notistack'
import ListsPopUp from './Lists/ListsPopUp'
import { AdCategoryIdToListCategoryMap } from 'helpers/constants'
import { useQueryClient } from 'react-query'
import { GetUserFavoritesListDTO } from 'api/services/UserFavoritesService/types'
import NumberFormattingService from 'api/services/NumberFormatting'

const StyledDivider = styled((props) => (
  <Divider variant="middle" flexItem {...props} />
))<DividerProps>(() => ({}))

interface IProps {
  data: ParsedProperty
}
const FeatureCard = (props: IProps) => {
  const { t } = useTranslation()
  const { data: property } = props
  const navigate = useNavigate()
  const location = useLocation()

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '600px',
  })

  const IS_IN_LOCAL_MODE = process.env.REACT_APP_ENV === 'development'

  const { enqueueSnackbar } = useSnackbar()

  const [searchParams, setSearchParams] = useSearchParams()

  const [locale, statePage] = useSearchStore(
    (state) => [state.locale, state.page],
    shallow
  )

  const queryClient = useQueryClient()

  const [openListsPopup, setOpenListsPopup] = React.useState(false)
  const handleOpenListsPopup = () => {
    setOpenListsPopup(true)
  }
  const handleCloseListsPopup = () => {
    setOpenListsPopup(false)
    setTextFieldText('')
  }

  const [openRedirect, setOpenRedirect] = React.useState(false)

  const handleOpenRedirect = () => {
    setOpenRedirect(true)
  }
  const handleCloseRedirect = () => {
    setOpenRedirect(false)
  }

  const propertyCategory = calculatePropertyCategory(property.adCategoryId)
  const propertyTypeOption = getPropertyType(property)
  const propertyType = calculatePropertyType(propertyTypeOption, property)

  const [images] = React.useState<Attachment[]>(() => {
    if (property?.photosURLS?.length) {
      return property.photosURLS
    }
    return []
  })

  const matchedCategory =
    AdCategoryIdToListCategoryMap.get(property.adCategoryId) || null

  UserFavoritesService.hooks.useLists(
    AdCategoryIdToListCategoryMap.get(property.adCategoryId) || null,
    { pageSize: 100, pageNumber: 0 },
    {
      retryOnMount: false,
      enabled: AuthService.isLoggedIn() && !!matchedCategory,
      onError: () => {
        enqueueSnackbar(t('error.unspecific'), {
          variant: 'error',
        })
      },
    }
  )

  const [textFieldText, setTextFieldText] = React.useState('')

  const addToUserFavoritesListMutation =
    UserFavoritesService.hooks.useAddToUserFavoritesList({
      onError: () => {
        enqueueSnackbar(t('error.unspecific'), {
          variant: 'error',
        })
      },
    })

  const createUserFavoritesListsMutation =
    UserFavoritesService.hooks.useCreateUserFavoritesList({
      onSuccess: async (newList) => {
        const newFavoritePropertyAd =
          await addToUserFavoritesListMutation.mutateAsync({
            listId: newList.id,
            propertyAdAlternativeId: property?.id?.slice(3),
          })
        if (matchedCategory && newFavoritePropertyAd) {
          // add pAd to list
          newList?.userFavorites?.push({
            id: newFavoritePropertyAd.id,
            entityId: newFavoritePropertyAd?.alternativeId
              ? newFavoritePropertyAd?.alternativeId
              : property?.id?.slice(3),
            firstImageUrl: null,
          })
          // increase favs count
          newList.userFavoritesCount = 1

          queryClient.setQueryData(
            [...UserFavoritesService.keys.QueryKeys.search, matchedCategory],
            (prev: GetUserFavoritesListDTO | '') => {
              if (prev) {
                prev.userFavoritesList = [
                  newList,
                  ...(prev?.userFavoritesList ?? []),
                ]
                prev.count = prev.count + 1
                return prev
              } else {
                return { userFavoritesList: [newList], count: 1 }
              }
            }
          )
          setTextFieldText('')
          enqueueSnackbar(
            t('detailPage.addFavorite.action.success') +
              ' ' +
              `${newList.name}`,
            {
              variant: 'success',
            }
          )
        }
      },
      onError: () => {
        enqueueSnackbar(t('error.unspecific'), {
          variant: 'error',
        })
      },
    })

  const handleIconFavoriteClick = () => {
    if (!AuthService.isLoggedIn()) {
      handleOpenRedirect()
    } else {
      if (openListsPopup) {
        handleCloseListsPopup()
      } else {
        handleOpenListsPopup()
      }
    }
  }

  const handleRedirect = () => {
    if (IS_IN_LOCAL_MODE) {
      searchParams.set('ad', property.id)
      searchParams.set('at', searchParams.get('page') ?? '1')
      setSearchParams(searchParams, {
        replace: true,
      })
      navigate(`/login?from=${location.pathname}?${searchParams.toString()}`)
    } else {
      window.location.href = `${
        process.env.REACT_APP_REDIRECT_ADMIN_APP_URL
      }?from=${location.pathname}?${searchParams.toString()}`
    }
  }

  const handleClickAway = () => {
    if (openListsPopup) {
      const matchedCategory =
        AdCategoryIdToListCategoryMap.get(property.adCategoryId) || null
      if (!!textFieldText && matchedCategory) {
        createUserFavoritesListsMutation.mutate({
          name: textFieldText,
          listCategory: matchedCategory,
        })
      }
      handleCloseListsPopup()
    }
  }

  const focusRef = React.useRef<HTMLDivElement | null>(null)
  const firstRender = React.useRef<boolean>(true)

  const currentFocusPage = searchParams.get('at') ?? 1

  const adToFocusTo = searchParams.get('ad')
  const SHOULD_FOCUS_ON_ELEMENT = !!adToFocusTo

  React.useEffect(() => {
    if (
      focusRef.current &&
      SHOULD_FOCUS_ON_ELEMENT &&
      adToFocusTo === property.id &&
      Number(currentFocusPage) === statePage &&
      IS_IN_LOCAL_MODE
    ) {
      setTimeout(() => {
        if (
          focusRef.current &&
          SHOULD_FOCUS_ON_ELEMENT &&
          adToFocusTo === property.id &&
          Number(currentFocusPage) === statePage
        ) {
          window.scrollTo({
            top: focusRef.current.offsetTop - 30,
            left: 0,
            behavior: 'smooth',
          })
          if (firstRender.current) firstRender.current = false
        }
      }, 1000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCLickPropertyAd = () => {
    if (IS_IN_LOCAL_MODE) {
      searchParams.set('ad', property.id)
      searchParams.set('at', searchParams.get('page') ?? '1')
      setSearchParams(searchParams, {
        replace: true,
      })
    }
  }

  return (
    <Stack
      id={property.id}
      ref={(el: HTMLDivElement) => {
        if (ref) {
          ref(el)
        }
        focusRef.current = el
      }}
      direction={'column'}
      justifyContent="space-between"
      sx={{
        justifySelf: 'center',
        maxWidth: 340,
        width: '100%',
        height: '100%',
        boxShadow: 25,
        border: '1px solid lightgrey',
        borderRadius: 2.5,
        p: '20px',
        position: 'relative',
      }}
      spacing={1}
    >
      {openRedirect ? (
        <TimeOutDialog
          open={openRedirect}
          onClose={handleCloseRedirect}
          onTimeout={handleRedirect}
        />
      ) : null}
      {openListsPopup ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            minHeight={'96px'}
            maxWidth={'100%'}
            minWidth="216px"
            position={'absolute'}
            sx={{
              boxSizing: 'border-box',
              bgcolor: 'common.white',
              left: '0%',
              right: '0%',
              top: '0%',
              zIndex: 101,
              borderRadius: '20px',
              border: 1,
              borderColor: '#EBEBEB',
              boxShadow: '10px 10px 30px 0px #09090966',
              ml: '18px',
              mr: '70px',
            }}
          >
            <ListsPopUp
              propertyAd={property}
              propertyCategory={matchedCategory}
              propertyAlternativeId={property?.id?.slice(3)}
              onClose={handleCloseListsPopup}
              onTextChange={(text) => {
                setTextFieldText(text)
              }}
            />
          </Box>
        </ClickAwayListener>
      ) : null}

      <ImageWrapper
        property={property}
        hideBorder={!!images?.length}
        DialogProps={{
          shareLabel: property?.title ?? t('detailPage.dialog.share.label'),
          sharePath: `${process.env.REACT_APP_SHARE_URL + '/'}property-ads/${
            property.id
          }/#/property-ads/${property.id}`,
          title: property?.title ?? t('detailPage.dialog.share.label'),
        }}
        areListsVisible={openListsPopup}
        handleIconFavoriteClick={handleIconFavoriteClick}
      >
        {images?.length && inView ? (
          <ImagesCarousel options={images} />
        ) : (
          <SvgIcon
            component={HomeSVG}
            inheritViewBox
            sx={{ width: 'auto', height: 200, fill: 'primary.main' }}
          />
        )}
      </ImageWrapper>
      <Box
        component="a"
        href={
          process.env.REACT_APP_SHARE_URL +
          '/' +
          `property-ads/${property.id}` +
          `/#/property-ads/${property.id}`
        }
        onClick={handleCLickPropertyAd}
        color="inherit"
        sx={{
          textTransform: 'none',
          cursor: 'pointer',
          textDecoration: 'none',
        }}
      >
        <Stack spacing={1}>
          <Typography
            component="div"
            fontSize={{ xs: 20, sm: 25, md: 25, lg: 25 }}
          >
            <Typography
              align="center"
              fontWeight={900}
              sx={{
                fontSize: 'inherit',
              }}
            >
              {`${t(
                getPropertyType(property)?.label ?? ''
              )} ${NumberFormattingService.toRoundNumber(
                Math.trunc(Number(property?.squareMeters))
              )} m`}
              <sup>2</sup>
            </Typography>
            <Typography
              align="center"
              fontWeight={700}
              fontSize={20}
              sx={{ pt: 0.2, fontSize: { xs: 15, sm: 25, md: 25, lg: 25 } }}
            >
              {`${t('landingPage.interestType.resultsLabel')} ${t(
                `landingPage.interestType.${calculateInterest(
                  property.adCategoryId
                )}`
              )} `}
            </Typography>
          </Typography>
          <StyledDivider />
          <Typography component="div">
            <Typography
              component="div"
              align="center"
              fontWeight={900}
              sx={{
                fontSize: { xs: 20, sm: 25, md: 25, lg: 25 },
              }}
            >
              <Box sx={{ fontWeight: 900 }}>
                {t('searchPage.salesPrice', {
                  price: property?.salesPrice
                    ? NumberFormattingService.toRoundNumber(
                        property?.salesPrice,
                        0
                      )
                    : '-',
                })}
              </Box>
            </Typography>
            <Typography
              component="div"
              align="center"
              sx={{ pt: 0.2, fontSize: { xs: 12, sm: 20, md: 20, lg: 20 } }}
            >
              <span>
                {t('searchPage.pricePerSquare', {
                  value:
                    property?.squareMeterSalePrice &&
                    property?.squareMeterSalePrice > 10
                      ? NumberFormattingService.toRoundNumber(
                          Math.trunc(Number(property?.squareMeterSalePrice)),
                          0
                        )
                      : property?.squareMeterSalePrice &&
                        property?.squareMeterSalePrice < 10
                      ? NumberFormattingService.toRoundNumber(
                          property?.squareMeterSalePrice,
                          1
                        )
                      : '-',
                })}
              </span>
            </Typography>
          </Typography>
          <StyledDivider />
          <Typography
            align="center"
            component="div"
            sx={{
              wordBreak: 'break-word',
              fontSize: { xs: 15, sm: 20, md: 20, lg: 20 },
            }}
          >
            {parseArea(property?.searchArea)}
          </Typography>
        </Stack>
      </Box>
      <Box
        component="img"
        sx={{
          maxWidth: 'fit-content',
          alignSelf: 'center',
        }}
        src={SeparatorPNG}
        alt="separator"
      />
      <Link
        component={RouterLink}
        underline="none"
        to={`/estate-agents/${locale}:${property.agentId}`}
        target="_blank"
        align="center"
        fontWeight={500}
        sx={{
          wordBreak: 'break-word',
          color: 'rgba(0,0,0,.2)',
          fontSize: { xs: 18, sm: 25, md: 25, lg: 25 },
        }}
      >
        {property?.agentName}
      </Link>
      <IconGroup
        data={property}
        propertyCategory={propertyCategory}
        propertyType={propertyType}
      />
    </Stack>
  )
}

export default React.memo(FeatureCard)

FeatureCard.propTypes = {}
