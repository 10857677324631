export interface ITranslatable {
  label: string
}

export interface ISortable {
  order?: number
}

export interface ISearchOption<T> extends ITranslatable {
  value: T
}

// NUMERIC TYPE PROPERTIES
export const NUMERIC_PROPERTIES = [
  'ESTATE_PROPERTY_BATHROOMS',
  'ESTATE_PROPERTY_BEDROOMS',
  'ESTATE_PROPERTY_RENOVATION_YEAR',
  'ESTATE_PROPERTY_BALCONΥ_SQR',
  'ESTATE_PROPERTY_PARKING_SLOTS',
  'ESTATE_PROPERTY_MAINTENANCE_FEES',
  'ESTATE_PROPERTY_LIVING_ROOMS',
  'ESTATE_PROPERTY_ROOMS',
  'ESTATE_PROPERTY_SPACES',
  'ESTATE_PROPERTY_WC',
  'ESTATE_PROPERTY_HEIGHT',
  'ESTATE_PROPERTY_PLOT_SQR',
  'ESTATE_PROPERTY_INDUSTRIAL_DOOR_HEIGHT',
  'ESTATE_PROPERTY_INDUSTRIAL_DOOR_WIDTH',
  'ESTATE_PROPERTY_ENTRANCES',
  'ESTATE_PROPERTY_OFFICES',
  'ESTATE_PROPERTY_DISTANCE_PORT',
  'ESTATE_PROPERTY_DISTANCE_ROAD_NETWORK',
  'ESTATE_PROPERTY_DISTANCE_AIRPORT',
  'ESTATE_PROPERTY_ENTRANCE_HEIGHT',
  'ESTATE_PROPERTY_AUXILARY_SPACES',
  'ESTATE_PROPERTY_RESTAURANTS',
  'ESTATE_LAND_PROPERTY_BUILDING_SQR',
  'ESTATE_LAND_PROPERTY_ALLOWED_HEIGHT',
  'ESTATE_PROPERTY_DISTANCE_TOWN',
  'ESTATE_PROPERTY_DISTANCE_VILLAGE',
  'ESTATE_PROPERTY_VIEWS',
  'ESTATE_LAND_PROPERTY_FOREST_COVER_SQR',
  'ESTATE_LAND_FOREST_COVER',
  'ESTATE_PROPERTY_INDEPENDET_AREAS',
  'ESTATE_PARKING_SLOTS_CAR',
  'ESTATE_PARKING_SLOTS_BIKE',
  'ESTATE_PARKING_SLOTS_BOAT',
  'ESTATE_PROPERTY_GARDEN_SQR',
  'ESTATE_PROPERTY_WAREHOUSE_SQR',
  'ESTATE_PROPERTY_LOFT_SQR',
  'ESTATE_PROPERTY_DISTANCE_SEA',
  'ESTATE_PROPERTY_MASTER_BEDROOMS',
  'ESTATE_PROPERTY_DISTANCE_BEACH',
  'ESTATE_PROPERTY_PARKING_OCCUPANCY',
  'ESTATE_LAND_PROPERTY_PLOT_WIDTH',
  'ESTATE_LAND_PROPERTY_PLOT_LENGTH',
  'ESTATE_PROPERTY_CARGO_ELEVATOR_WEIGHT',
  'ESTATE_LAND_PROPERTY_COVERAGE_FACTOR',
  'ESTATE_LAND_PROPERTY_BUILD_FACTOR',
  'ESTATE_PROPERTY_EXPANDABLE_SQR',
  'ESTATE_PROPERTY_PRICE_PER_BUILDABLE_SQR',
  'ESTATE_LAND_PROPERTY_ISLAND_PERIMETER',
  'ESTATE_LAND_PROPERTY_NUM_OF_BEACHES',
  'ESTATE_PROPERTY_NUM_OF_FIREPLACES',
  'ESTATE_PROPERTY_NUM_OF_KITCHENS',
  'ESTATE_PROPERTY_NUM_OF_GYMS',
  'ESTATE_PROPERTY_EXPANDABLE_SQR',
] as const
export type INumericProperty = typeof NUMERIC_PROPERTIES[number]

// ESTATE_PROPERTY_TYPE
export const ESTATE_TYPE_VALUES = [
  'ESTATE_PROPERTY_TYPE#appartment',
  'ESTATE_PROPERTY_TYPE#single_residence',
  'ESTATE_PROPERTY_TYPE#maisonette',
  'ESTATE_PROPERTY_TYPE#building',
  'ESTATE_PROPERTY_TYPE#rest_types',
] as const

export type IEstateTypeValue = typeof ESTATE_TYPE_VALUES[number]
export type IEstateTypeOption = ISearchOption<IEstateTypeValue>

// ESTATE_PROPERTY_COMMERCIAL_TYPE

export const COMMERCIAL_TYPE_VALUES = [
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#office',
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#store',
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#industrial_area',
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#craft_space',
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#warehouse',
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#hotel',
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#commercial_building',
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#hall',
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#parking',
  'ESTATE_PROPERTY_COMMERCIAL_TYPE#rest_commercial_types',
]

export type ICommercialTypeValue = typeof COMMERCIAL_TYPE_VALUES[number]
export type ICommercialTypeOption = ISearchOption<ICommercialTypeValue>

// ESTATE_PROPERTY_LAND_TYPE
export const LAND_TYPE_VALUES = [
  'ESTATE_PROPERTY_LAND_TYPE#plot_of_land',
  'ESTATE_PROPERTY_LAND_TYPE#island',
  'ESTATE_PROPERTY_LAND_TYPE#plot',
  'ESTATE_PROPERTY_LAND_TYPE#other_land_types',
] as const

export type ILandTypeValue = typeof LAND_TYPE_VALUES[number]
export type ILandTypeOption = ISearchOption<ILandTypeValue>

// ESTATE_PROPERTY_CONDITION
export const ESTATE_CONDITION_VALUES = [
  'ESTATE_PROPERTY_CONDITION#need_of_renovation',
  'ESTATE_PROPERTY_CONDITION#need_of_renovation_full',
  'ESTATE_PROPERTY_CONDITION#renovated',
  'ESTATE_PROPERTY_CONDITION#new_built',
  'ESTATE_PROPERTY_CONDITION#semi_built',
  'ESTATE_PROPERTY_CONDITION#good_condition',
  'ESTATE_PROPERTY_CONDITION#buildable',
  'ESTATE_PROPERTY_CONDITION#under_construction',
  'ESTATE_PROPERTY_CONDITION#under_completion',
  'ESTATE_PROPERTY_CONDITION#very_good',
  'ESTATE_PROPERTY_CONDITION#fresh_painted',
  'ESTATE_PROPERTY_CONDITION#moderate',
  'ESTATE_PROPERTY_CONDITION#amazing',
  'ESTATE_PROPERTY_CONDITION#minimal_design',
  'ESTATE_PROPERTY_CONDITION#in_the_drawings',
  'ESTATE_PROPERTY_CONDITION#neoclassic',
  '',
] as const
export type IEstateConditionValue = typeof ESTATE_CONDITION_VALUES[number]
export type IEstateConditionOption = ISearchOption<IEstateConditionValue>

//ESTATE_PROPERTY_HEAT
export const HEAT_VALUES = [
  'ESTATE_PROPERTY_HEAT#heat_without',
  'ESTATE_PROPERTY_HEAT#heat_central',
  'ESTATE_PROPERTY_HEAT#heat_individual',
  'ESTATE_PROPERTY_HEAT#heat_autonomous',
] as const

export type IEstateThermalValue = typeof HEAT_VALUES[number]
export type IEstateThermalOption = ISearchOption<IEstateThermalValue>

//ESTATE_PROPERTY_HEAT_TYPE
export const HEAT_TYPE_VALUES = [
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_natural_gas',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_convector',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_geothermal_power',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_thermal_accumulator',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_electricity',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_liquid_gas',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_underfloor',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_air_conditioning',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_stove',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_pellet',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_fan_coil',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_teleheating',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_infrared',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_petrol',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_heat_pump',
  'ESTATE_PROPERTY_HEAT_TYPE#heat_type_unspecified',
] as const
export type IEstateThermalTypeValue = typeof HEAT_TYPE_VALUES[number]
export type IEstateThermalTypeOption = ISearchOption<IEstateThermalTypeValue>

//ESTATE_PROPERTY_EXTRAS
export const ESTATE_EXTRAS_VALUES = [
  'ESTATE_PROPERTY_EXTRAS#balcony',
  'ESTATE_PROPERTY_EXTRAS#balcony_no',
  'ESTATE_PROPERTY_EXTRAS#elevator',
  'ESTATE_PROPERTY_EXTRAS#elevator_no',
  'ESTATE_PROPERTY_EXTRAS#air_condition',
  'ESTATE_PROPERTY_EXTRAS#air_condition_no',
  'ESTATE_PROPERTY_EXTRAS#penthouse',
  'ESTATE_PROPERTY_EXTRAS#penthouse_no',
  'ESTATE_PROPERTY_EXTRAS#alarm',
  'ESTATE_PROPERTY_EXTRAS#alarm_no',
  'ESTATE_PROPERTY_EXTRAS#disabled_access',
  'ESTATE_PROPERTY_EXTRAS#disabled_estate_property_access#no',
  'ESTATE_PROPERTY_EXTRAS#pets',
  'ESTATE_PROPERTY_EXTRAS#pets_no',
  'ESTATE_PROPERTY_EXTRAS#storage',
  'ESTATE_PROPERTY_EXTRAS#storage_no',
  'ESTATE_PROPERTY_EXTRAS#furnished',
  'ESTATE_PROPERTY_EXTRAS#furnished_no',
  'ESTATE_PROPERTY_EXTRAS#view',
  'ESTATE_PROPERTY_EXTRAS#view_no',
  'ESTATE_PROPERTY_EXTRAS#solar_water_heater',
  'ESTATE_PROPERTY_EXTRAS#solar_water_heater_no',
  'ESTATE_PROPERTY_EXTRAS#security_door',
  'ESTATE_PROPERTY_EXTRAS#security_door_no',
  'ESTATE_PROPERTY_EXTRAS#pool',
  'ESTATE_PROPERTY_EXTRAS#pool_no',
  'ESTATE_PROPERTY_EXTRAS#veranda',
  'ESTATE_PROPERTY_EXTRAS#veranda_no',
  'ESTATE_PROPERTY_EXTRAS#fireplace',
  'ESTATE_PROPERTY_EXTRAS#fireplace_no',
  'ESTATE_PROPERTY_EXTRAS#tents',
  'ESTATE_PROPERTY_EXTRAS#tents_no',
  'ESTATE_PROPERTY_EXTRAS#professional_use',
  'ESTATE_PROPERTY_EXTRAS#professional_use_no',
  'ESTATE_PROPERTY_EXTRAS#double_frontage',
  'ESTATE_PROPERTY_EXTRAS#double_frontage_no',
  'ESTATE_PROPERTY_EXTRAS#maintained',
  'ESTATE_PROPERTY_EXTRAS#maintained_no',
  'ESTATE_PROPERTY_EXTRAS#screens',
  'ESTATE_PROPERTY_EXTRAS#screens_no',
  'ESTATE_PROPERTY_EXTRAS#cctv',
  'ESTATE_PROPERTY_EXTRAS#cctv_no',
  'ESTATE_PROPERTY_EXTRAS#facade',
  'ESTATE_PROPERTY_EXTRAS#facade_no',
  'ESTATE_PROPERTY_EXTRAS#corner',
  'ESTATE_PROPERTY_EXTRAS#corner_no',
  'ESTATE_PROPERTY_EXTRAS#loft',
  'ESTATE_PROPERTY_EXTRAS#loft_no',
  'ESTATE_PROPERTY_EXTRAS#parking',
  'ESTATE_PROPERTY_EXTRAS#parking_no',
  'ESTATE_PROPERTY_EXTRAS#garden',
  'ESTATE_PROPERTY_EXTRAS#garden_no',
  'ESTATE_PROPERTY_EXTRAS#intercom',
  'ESTATE_PROPERTY_EXTRAS#intercom_no',
  'ESTATE_PROPERTY_EXTRAS#underfloor_heat',
  'ESTATE_PROPERTY_EXTRAS#underfloor_heat_no',
  'ESTATE_PROPERTY_EXTRAS#safe',
  'ESTATE_PROPERTY_EXTRAS#safe_no',
  'ESTATE_PROPERTY_EXTRAS#with_air',
  'ESTATE_PROPERTY_EXTRAS#with_air_no',
  'ESTATE_PROPERTY_EXTRAS#with_light',
  'ESTATE_PROPERTY_EXTRAS#with_light_no',
] as const
export type IEstateExtrasValue = typeof ESTATE_EXTRAS_VALUES[number]
export type IEstateExtrasOption = ISearchOption<IEstateExtrasValue>

//ESTATE_PROPERTY_COMMERCIAL_EXTRAS
export const COMMERCIAL_EXTRAS_VALUES = [
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#balcony',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#balcony_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#tents',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#tents_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#elevator',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#elevator_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#wc',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#wc_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#alarm',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#alarm_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#penthouse',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#penthouse_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#food_service',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#food_service_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#air_condition',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#air_condition_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#furnished',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#furnished_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cargo_elevator',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cargo_elevator_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#fire_safety',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#fire_safety_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#security_door',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#security_door_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#disabled_access',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#disabled_estate_property_access#no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cargo_ramp',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cargo_ramp_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#structured_wiring',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#structured_wiring_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#guarding',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#guarding_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cctv',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cctv_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#internal_stairs',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#internal_stairs_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#enclosure',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#enclosure_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#facade',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#facade_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#expandability',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#expandability_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#kitchen',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#kitchen_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#drains',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#drains_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#false_ceiling',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#false_ceiling_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#doorman',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#doorman_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#fireplace',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#fireplace_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#maintained',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#maintained_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#corner',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#corner_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#gas_station',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#gas_station_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#water_heating',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#water_heating_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#amphitheater',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#amphitheater_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#facilities',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#facilities_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#restaurant',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#restaurant_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#gym',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#gym_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#event_space',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#event_space_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#spa',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#spa_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#roof_garden',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#roof_garden_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#security_rolls',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#security_rolls_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#washing_machine',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#washing_machine_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#loft',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#loft_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#storage',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#storage_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#car_elevator',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#car_elevator_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#plot',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#plot_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#equipped',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#equipped_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#parking_over',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#parking_over_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cellar',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cellar_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#outbar',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#outbar_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#staff_rooms',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#staff_rooms_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#organized_beach',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#organized_beach_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#meeting_room ',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#meeting_room _no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#garden',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#garden_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#underfloor_heat',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#underfloor_heat_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#accessibility',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#accessibility_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#penthhouse',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#penthhouse_no',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#parking',
  'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#parking_no',
] as const
export type ICommercialExtrasValue = typeof COMMERCIAL_EXTRAS_VALUES[number]
export type ICommercialExtrasOption = ISearchOption<ICommercialExtrasValue>

//ESTATE_PROPERTY_LAND_TYPE_EXTRAS
export const LAND_EXTRAS_VALUES = [
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#with_building',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#with_building_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#water_supply',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#water_supply_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#electrification',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#electrification_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#compensation',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#compensation_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#enclosure',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#enclosure_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#drains',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#drains_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#view',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#view_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#non_forest',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#non_forest_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#forest_cover',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#forest_cover_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#facade',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#facade_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#amphitheater',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#amphitheater_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#agricultural_use',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#agricultural_use_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#buildable',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#buildable_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#marina',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#marina_no',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#corner',
  'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#corner_no',
] as const
export type ILandExtrasValue = typeof LAND_EXTRAS_VALUES[number]
export type ILandExtrasOption = ISearchOption<ILandExtrasValue>

//ESTATE_PROPERTY_FLOOR
export const FLOOR_VALUES = [
  'ESTATE_PROPERTY_FLOOR#2nd_basement',
  'ESTATE_PROPERTY_FLOOR#basement',
  'ESTATE_PROPERTY_FLOOR#semi_basment',
  'ESTATE_PROPERTY_FLOOR#mezzanine',
  'ESTATE_PROPERTY_FLOOR#mezzanine_raised',
  'ESTATE_PROPERTY_FLOOR#ground_floor',
  'ESTATE_PROPERTY_FLOOR#ground_floor_raised',
  'ESTATE_PROPERTY_FLOOR#1st',
  'ESTATE_PROPERTY_FLOOR#1st_raised',
  'ESTATE_PROPERTY_FLOOR#2nd',
  'ESTATE_PROPERTY_FLOOR#3rd',
  'ESTATE_PROPERTY_FLOOR#4th',
  'ESTATE_PROPERTY_FLOOR#5th',
  'ESTATE_PROPERTY_FLOOR#6th',
  'ESTATE_PROPERTY_FLOOR#7th',
  'ESTATE_PROPERTY_FLOOR#8th',
  'ESTATE_PROPERTY_FLOOR#9th',
  'ESTATE_PROPERTY_FLOOR#10th',
  'ESTATE_PROPERTY_FLOOR#11th',
  'ESTATE_PROPERTY_FLOOR#12th',
  'ESTATE_PROPERTY_FLOOR#13th',
  'ESTATE_PROPERTY_FLOOR#14th',
  'ESTATE_PROPERTY_FLOOR#15th',
  'ESTATE_PROPERTY_FLOOR#16th',
  'ESTATE_PROPERTY_FLOOR#17th',
  'ESTATE_PROPERTY_FLOOR#18th',
  'ESTATE_PROPERTY_FLOOR#19th',
  'ESTATE_PROPERTY_FLOOR#20th',
  'ESTATE_PROPERTY_FLOOR#20+',
] as const
export type IFloorValue = typeof FLOOR_VALUES[number]
export type IFloorOption = ISearchOption<IFloorValue> & ISortable

//ESTATE_PROPERTY_PARKING
export const PARKING_VALUES = [
  'ESTATE_PROPERTY_PARKING#has',
  'ESTATE_PROPERTY_PARKING#has_not',
  'ESTATE_PROPERTY_PARKING#open',
  'ESTATE_PROPERTY_PARKING#closed',
  'ESTATE_PROPERTY_PARKING#pilot',
  'ESTATE_PROPERTY_PARKING#basement',
] as const
export type IParkingTypeValue = typeof PARKING_VALUES[number]
export type IParkingTypeOption = ISearchOption<IParkingTypeValue>

//ESTATE_PROPERTY_GARDEN
export const GARDEN_VALUES = [
  'ESTATE_PROPERTY_GARDEN#has',
  'ESTATE_PROPERTY_GARDEN#has_not',
  'ESTATE_PROPERTY_GARDEN#private',
  'ESTATE_PROPERTY_GARDEN#communal',
] as const
export type IGardenValue = typeof GARDEN_VALUES[number]
export type IGardenOption = ISearchOption<IGardenValue>

//ESTATE_PROPERTY_DOOR
export const DOOR_VALUES = [
  'ESTATE_PROPERTY_DOOR#simple',
  'ESTATE_PROPERTY_DOOR#armored',
  'ESTATE_PROPERTY_DOOR#security',
] as const
export type IDoorValue = typeof DOOR_VALUES[number]
export type IDoorOption = ISearchOption<IDoorValue>

//ESTATE_PROPERTY_ENERGY_CLASS
export const ENERGY_CLASS_VALUES = [
  'ESTATE_PROPERTY_ENERGY_CLASS#a+',
  'ESTATE_PROPERTY_ENERGY_CLASS#a',
  'ESTATE_PROPERTY_ENERGY_CLASS#b',
  'ESTATE_PROPERTY_ENERGY_CLASS#b+',
  'ESTATE_PROPERTY_ENERGY_CLASS#c',
  'ESTATE_PROPERTY_ENERGY_CLASS#d',
  'ESTATE_PROPERTY_ENERGY_CLASS#e',
  'ESTATE_PROPERTY_ENERGY_CLASS#f',
  'ESTATE_PROPERTY_ENERGY_CLASS#g',
  'ESTATE_PROPERTY_ENERGY_CLASS#h',
  'ESTATE_PROPERTY_ENERGY_CLASS#z',
] as const
export type IEnergyClassValue = typeof ENERGY_CLASS_VALUES[number]
export type IEnergyClassOption = ISearchOption<IEnergyClassValue>

//ESTATE_PROPERTY_FRAMES
export const FRAME_VALUES = [
  'ESTATE_PROPERTY_FRAMES#aluminium',
  'ESTATE_PROPERTY_FRAMES#aluminium_sliding',
  'ESTATE_PROPERTY_FRAMES#synthetic',
  'ESTATE_PROPERTY_FRAMES#wooden',
  'ESTATE_PROPERTY_FRAMES#pvc',
] as const
export type IFrameValue = typeof FRAME_VALUES[number]
export type IFrameOption = ISearchOption<IFrameValue>

//ESTATE_PROPERTY_FLOOR_TYPE
export const FLOOR_TYPE_VALUES = [
  'ESTATE_PROPERTY_FLOOR_TYPE#marbles',
  'ESTATE_PROPERTY_FLOOR_TYPE#wood',
  'ESTATE_PROPERTY_FLOOR_TYPE#stone',
  'ESTATE_PROPERTY_FLOOR_TYPE#tile',
  'ESTATE_PROPERTY_FLOOR_TYPE#mosaic',
  'ESTATE_PROPERTY_FLOOR_TYPE#marble_wood',
  'ESTATE_PROPERTY_FLOOR_TYPE#marble_tile',
  'ESTATE_PROPERTY_FLOOR_TYPE#stone_wood',
  'ESTATE_PROPERTY_FLOOR_TYPE#stone_marble',
  'ESTATE_PROPERTY_FLOOR_TYPE#tile_wood',
  'ESTATE_PROPERTY_FLOOR_TYPE#mosaic_wood',
  'ESTATE_PROPERTY_FLOOR_TYPE#unspecified',
  'ESTATE_PROPERTY_FLOOR_TYPE#cement_mortat',
  'ESTATE_PROPERTY_FLOOR_TYPE#laminate',
  'ESTATE_PROPERTY_FLOOR_TYPE#granite',
  'ESTATE_PROPERTY_FLOOR_TYPE#industrial_flooring',
  'ESTATE_PROPERTY_FLOOR_TYPE#granite_wood',
  'ESTATE_PROPERTY_FLOOR_TYPE#traditional_stone',
  'ESTATE_PROPERTY_FLOOR_TYPE#canvas',
  'ESTATE_PROPERTY_FLOOR_TYPE#oak',
  'ESTATE_PROPERTY_FLOOR_TYPE#parquet',
  'ESTATE_PROPERTY_FLOOR_TYPE#plastic',
] as const
export type IFloorTypeValue = typeof FLOOR_TYPE_VALUES[number]
export type IFloorTypeOption = ISearchOption<IFloorTypeValue>

//ESTATE_PROPERTY_ACCESS
export const ACCESS_VALUES = [
  'ESTATE_PROPERTY_ACCESS#asphalt',
  'ESTATE_PROPERTY_ACCESS#pavement',
  'ESTATE_PROPERTY_ACCESS#paved',
  'ESTATE_PROPERTY_ACCESS#dirt_road',
  'ESTATE_PROPERTY_ACCESS#no_road',
  'ESTATE_PROPERTY_ACCESS#rural_road',
  'ESTATE_PROPERTY_ACCESS#minicipal_road',
  'ESTATE_PROPERTY_ACCESS#country_road',
  'ESTATE_PROPERTY_ACCESS#sea',
  'ESTATE_PROPERTY_ACCESS#national_road',
  'ESTATE_PROPERTY_ACCESS#common_are',
  'ESTATE_PROPERTY_ACCESS#subway',
  'ESTATE_PROPERTY_ACCESS#suburban',
  'ESTATE_PROPERTY_ACCESS#electric',
  'ESTATE_PROPERTY_ACCESS#attiki_odos',
  'ESTATE_PROPERTY_ACCESS#immediate',
  'ESTATE_PROPERTY_ACCESS#difficult',
  'ESTATE_PROPERTY_ACCESS#good',
  'ESTATE_PROPERTY_ACCESS#moderate',
  'ESTATE_PROPERTY_ACCESS#bus',
  'ESTATE_PROPERTY_ACCESS#ktel',
  'ESTATE_PROPERTY_ACCESS#ose',
  'ESTATE_PROPERTY_ACCESS#tram',
  'ESTATE_PROPERTY_ACCESS#sidewalk',
  'ESTATE_PROPERTY_ACCESS#unspecified',
  '',
] as const
export type ILandAccessValue = typeof ACCESS_VALUES[number]
export type ILandAccessOption = ISearchOption<ILandAccessValue>

//ESTATE_PROPERTY_ORIENTATION no match with live,to be investigated

//ESTATE_PROPERTY_VIEW
export const VIEW_VALUES = [
  'ESTATE_PROPERTY_VIEW#unlimited',
  'ESTATE_PROPERTY_VIEW#forest',
  'ESTATE_PROPERTY_VIEW#mountain',
  'ESTATE_PROPERTY_VIEW#sea',
  'ESTATE_PROPERTY_VIEW#park',
  'ESTATE_PROPERTY_VIEW#square',
  'ESTATE_PROPERTY_VIEW#good',
  'ESTATE_PROPERTY_VIEW#sea_park',
  'ESTATE_PROPERTY_VIEW#panoramic',
  'ESTATE_PROPERTY_VIEW#mountain_sea',
  'ESTATE_PROPERTY_VIEW#cityscape',
  'ESTATE_PROPERTY_VIEW#outdoors',
  'ESTATE_PROPERTY_VIEW#open_horizon',
  'ESTATE_PROPERTY_VIEW#side_view',
  'ESTATE_PROPERTY_VIEW#acropole',
  'ESTATE_PROPERTY_VIEW#garden',
] as const
export type IViewValue = typeof VIEW_VALUES[number]
export type IViewOption = ISearchOption<IViewValue>

//ESTATE_PROPERTY_POOL
export const POOL_VALUES = [
  'ESTATE_PROPERTY_POOL#interior',
  'ESTATE_PROPERTY_POOL#exterior',
  'ESTATE_PROPERTY_POOL#private',
  'ESTATE_PROPERTY_POOL#communal',
  'ESTATE_PROPERTY_POOL#no_pool',
  'ESTATE_PROPERTY_POOL#yes',
] as const
export type IPoolValue = typeof POOL_VALUES[number]
export type IPoolOption = ISearchOption<IPoolValue>

//ESTATE_PROPERTY_USAGE
export const USAGE_VALUES = [
  'ESTATE_PROPERTY_USAGE#pure_house',
  'ESTATE_PROPERTY_USAGE#industrial_zone',
  'ESTATE_PROPERTY_USAGE#craft_zone',
  'ESTATE_PROPERTY_USAGE#grazing_land',
  'ESTATE_PROPERTY_USAGE#general_house',
  'ESTATE_PROPERTY_USAGE#forest_area',
  'ESTATE_PROPERTY_USAGE#red_zone',
  'ESTATE_PROPERTY_USAGE#arable_land',
  'ESTATE_PROPERTY_USAGE#tourist_investment',
  'ESTATE_PROPERTY_USAGE#industrial_park_light_industrial_serving',
  'ESTATE_PROPERTY_USAGE#industrial_park_full_industrial_serving',
  'ESTATE_PROPERTY_USAGE#industrial_park_low_and_medium_industrial_serving',
  'ESTATE_PROPERTY_USAGE#uncharacteristic_zone',
  'ESTATE_PROPERTY_USAGE#wholesale_zone',
  'ESTATE_PROPERTY_USAGE#agricultural_area',
  'ESTATE_PROPERTY_USAGE#commercial_zone',
  'ESTATE_PROPERTY_USAGE#unspecified',
  'ESTATE_PROPERTY_USAGE#farmers_buildings',
  'ESTATE_PROPERTY_USAGE#agricultural_warehouses',
  'ESTATE_PROPERTY_USAGE#tanks',
  'ESTATE_PROPERTY_USAGE#greenhouses',
  'ESTATE_PROPERTY_USAGE#industrial_buildings',
  'ESTATE_PROPERTY_USAGE#warehouses',
  'ESTATE_PROPERTY_USAGE#sports_facilities',
  'ESTATE_PROPERTY_USAGE#schools',
  'ESTATE_PROPERTY_USAGE#charitable_foundations',
  'ESTATE_PROPERTY_USAGE#hospitals',
  'ESTATE_PROPERTY_USAGE#clinics',
  'ESTATE_PROPERTY_USAGE#offices',
  'ESTATE_PROPERTY_USAGE#stores',
  'ESTATE_PROPERTY_USAGE#tourist_facilities',
  'ESTATE_PROPERTY_USAGE#churches',
  'ESTATE_PROPERTY_USAGE#within_the_urban_plan',
  'ESTATE_PROPERTY_USAGE#out_of_the_urban_plan',
  'ESTATE_PROPERTY_USAGE#mild_harassment',
  'ESTATE_PROPERTY_USAGE#urban_center',
  'ESTATE_PROPERTY_USAGE#public_benefit_organizations',
  'ESTATE_PROPERTY_USAGE#audience_gathering_space',
  'ESTATE_PROPERTY_USAGE#religious_space',
] as const
export type IUsageValue = typeof USAGE_VALUES[number]
export type IUsageOption = ISearchOption<IUsageValue>

//ESTATE_PROPERTY_ELECTRICITY_TYPE
export const ELECTRICITY_TYPE_VALUES = [
  'ESTATE_PROPERTY_ELECTRICITY_TYPE#electricity_type_a',
  'ESTATE_PROPERTY_ELECTRICITY_TYPE#electricity_type_b',
  'ESTATE_PROPERTY_ELECTRICITY_TYPE#night_electricity',
  'ESTATE_PROPERTY_ELECTRICITY_TYPE#three_phase',
  'ESTATE_PROPERTY_ELECTRICITY_TYPE#single_phase',
  'ESTATE_PROPERTY_ELECTRICITY_TYPE#industrial',
  'ESTATE_PROPERTY_ELECTRICITY_TYPE#medium_power',
  'ESTATE_PROPERTY_ELECTRICITY_TYPE#high_power',
  'ESTATE_PROPERTY_ELECTRICITY_TYPE#substation',
] as const
export type IElectricityTypeValue = typeof ELECTRICITY_TYPE_VALUES[number]
export type IElectricityTypeOption = ISearchOption<IElectricityTypeValue>

//ESTATE_PROPERTY_EQUIPMENT
export const EQUIPMENT_VALUES = [
  'ESTATE_PROPERTY_EQUIPMENT#restaurant',
  'ESTATE_PROPERTY_EQUIPMENT#cafe',
  'ESTATE_PROPERTY_EQUIPMENT#office',
  'ESTATE_PROPERTY_EQUIPMENT#hair_salon',
  'ESTATE_PROPERTY_EQUIPMENT#pastry_shop',
  'ESTATE_PROPERTY_EQUIPMENT#jewelery_store',
  'ESTATE_PROPERTY_EQUIPMENT#bookstore',
  'ESTATE_PROPERTY_EQUIPMENT#store',
  'ESTATE_PROPERTY_EQUIPMENT#exhibition_space',
  'ESTATE_PROPERTY_EQUIPMENT#food',
  'ESTATE_PROPERTY_EQUIPMENT#printing',
  'ESTATE_PROPERTY_EQUIPMENT#chemicals',
  'ESTATE_PROPERTY_EQUIPMENT#petrochemicals_plastics',
  'ESTATE_PROPERTY_EQUIPMENT#wood_processing',
  'ESTATE_PROPERTY_EQUIPMENT#tanners',
  'ESTATE_PROPERTY_EQUIPMENT#farming',
  'ESTATE_PROPERTY_EQUIPMENT#metal_processing',
  'ESTATE_PROPERTY_EQUIPMENT#paper_processing',
  'ESTATE_PROPERTY_EQUIPMENT#textile_industry',
  'ESTATE_PROPERTY_EQUIPMENT#hotel',
] as const
export type IEquipmentValue = typeof EQUIPMENT_VALUES[number]
export type IEquipmentOption = ISearchOption<IEquipmentValue>

//ESTATE_LAND_PROPERTY_CITY_PLAN
export const CITY_PLAN_VALUES = [
  'ESTATE_LAND_PROPERTY_CITY_PLAN#out_of_city_plans',
  'ESTATE_LAND_PROPERTY_CITY_PLAN#in_city_plans',
  'ESTATE_LAND_PROPERTY_CITY_PLAN#within_settlement',
  'ESTATE_LAND_PROPERTY_CITY_PLAN#in_building_zone',
  'ESTATE_LAND_PROPERTY_CITY_PLAN#settlement_limits',
  'ESTATE_LAND_PROPERTY_CITY_PLAN#tangential_plan',
  'ESTATE_LAND_PROPERTY_CITY_PLAN#to_be_included',
] as const
export type ILandCityPlanValue = typeof CITY_PLAN_VALUES[number]
export type ILandCityPlanOption = ISearchOption<ILandCityPlanValue>

//LAND_CONDITION
export const LAND_CONDITION_VALUES = [
  'LAND_CONDITION#even',
  'LAND_CONDITION#buildable',
] as const
export type ILandConditionValue = typeof LAND_CONDITION_VALUES[number]
export type ILandConditionOption = ISearchOption<ILandConditionValue>

//ESTATE_PROPERTY_DIRECTION
export const DIRECTION_VALUES = [
  'ESTATE_PROPERTY_DIRECTION#east',
  'ESTATE_PROPERTY_DIRECTION#west',
  'ESTATE_PROPERTY_DIRECTION#east_meridian',
  'ESTATE_PROPERTY_DIRECTION#north',
  'ESTATE_PROPERTY_DIRECTION#north_east',
  'ESTATE_PROPERTY_DIRECTION#north_west',
  'ESTATE_PROPERTY_DIRECTION#west_meridian',
  'ESTATE_PROPERTY_DIRECTION#meridian',
  'ESTATE_PROPERTY_DIRECTION#south',
  'ESTATE_PROPERTY_DIRECTION#south_east',
  'ESTATE_PROPERTY_DIRECTION#south_west',
  'ESTATE_PROPERTY_DIRECTION#undefined',
  '',
] as const
export type IDirectionValue = typeof DIRECTION_VALUES[number]
export type IDirectionOption = ISearchOption<IDirectionValue>

//ESTATE_PROPERTY_WINDOWS_TYPE
export const WINDOWS_TYPE_VALUES = [
  'ESTATE_PROPERTY_WINDOWS_TYPE#single',
  'ESTATE_PROPERTY_WINDOWS_TYPE#double',
] as const
export type IWindowTypeValue = typeof WINDOWS_TYPE_VALUES[number]
export type IWindowTypeOption = ISearchOption<IWindowTypeValue>
