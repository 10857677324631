import React from 'react'
import { Box, TextField, MenuItem } from '@mui/material'
import { SORTING_OPTIONS } from 'store/SearchSlice/constants'
import { useSearchStore } from 'store'
import shallow from 'zustand/shallow'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { UrlField } from 'types/enums'

interface ResultsFiltersProps {
  onRefetch: () => void
}

function ResultsFilters(props: ResultsFiltersProps) {
  const { t } = useTranslation()
  const { onRefetch } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [sortingField, setSortingField, sortingBy, setSortingBy] =
    useSearchStore(
      (state) => [
        state.sortingField,
        state.setSortingField,
        state.sortingBy,
        state.setSortingBy,
      ],
      shallow
    )

  const handleChange = (e) => {
    if (e.target.value) {
      const split = e.target.value.split('#')
      setSortingField(split[0])
      setSortingBy(split[1])
      searchParams.set(UrlField.sortingField, split[0])
      searchParams.set(UrlField.sortingBy, split[1])
    } else {
      setSortingField('')
      setSortingBy('')
      searchParams.delete(UrlField.sortingField)
      searchParams.delete(UrlField.sortingBy)
    }
    setSearchParams(searchParams, {
      replace: true,
    })
    onRefetch()
  }
  return (
    <Box>
      <TextField
        select
        value={
          !sortingField && !sortingBy ? '' : `${sortingField}#${sortingBy}`
        }
        onChange={handleChange}
        size="small"
        SelectProps={{
          displayEmpty: true,
          MenuProps: {
            sx: {
              '& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected': {
                bgcolor: 'primary.main',
              },
              '& .MuiMenu-paper': {
                boxShadow: 25,
              },
            },
          },
        }}
      >
        {SORTING_OPTIONS.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {t(option.label)}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
}

export default ResultsFilters
