import { Grid, Box, ButtonBase, useMediaQuery, useTheme } from '@mui/material'
import shallow from 'zustand/shallow'
import { useTranslation } from 'react-i18next'

import { useSearchStore } from 'store'
import TabbedSelect from 'components/Landing/TabbedSelect'
import katoikiaPNG from 'assets/img/katoikia.png'
import epagPNG from 'assets/img/epag.png'
import giPNG from 'assets/img/gi.png'
import giMobilePNG from 'assets/img/gi-mobile.png'
import katoikiaMobilePNG from 'assets/img/katoikia-mobile.png'
import officeMobilePNG from 'assets/img/office-mobile.png'
import {
  SEARCH_FOR_OPTIONS,
  INTEREST_FOR_OPTIONS,
} from 'store/SearchSlice/constants'
import { SearchForEnum } from 'types/enums'

function Top() {
  const theme = useTheme()

  const { t } = useTranslation()
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
  const matchesMDB = useMediaQuery(theme.breakpoints.between('md', 1460))
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesSMB = useMediaQuery(theme.breakpoints.between('md', 1110))

  const [
    searchBy,
    setSearchBy,
    searchFor,
    setSearchFor,
    clearForm,
    searchCountFn,
  ] = useSearchStore(
    (state) => [
      state.searchBy,
      state.setSearchBy,
      state.searchFor,
      state.setSearchFor,
      state.clearForm,
      state.searchCountFn,
    ],
    shallow
  )

  const handleChangeBy = (_, newValue) => {
    setSearchBy(newValue)
    clearForm()
    searchCountFn()
  }
  const handleChangeFor = (_, newValue) => {
    setSearchFor(newValue)
    clearForm()
    searchCountFn()
  }

  const handleImageClick = (value) => {
    setSearchBy(value)
    clearForm()
    searchCountFn()
  }

  const IS_ESTATE_OR_COM =
    searchBy === SearchForEnum.Estate ? katoikiaPNG : epagPNG
  const IS_ESTATE_OR_COM_MOBILE =
    searchBy === SearchForEnum.Estate ? katoikiaMobilePNG : officeMobilePNG

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent={{ sm: 'center', md: 'flex-start' }}
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <TabbedSelect
              value={searchBy}
              onChange={handleChangeBy}
              label={t('landingPage.lookingFor.label')}
              options={SEARCH_FOR_OPTIONS}
            />
          </Grid>
          <Grid item md={12}>
            <TabbedSelect
              value={searchFor}
              onChange={handleChangeFor}
              label={t('landingPage.interestType.label')}
              options={INTEREST_FOR_OPTIONS}
            />
          </Grid>
          {matchesMD && !matchesSM ? (
            <Grid item sx={{ mt: 2 }}>
              <Box
                component="img"
                src={searchBy === SearchForEnum.Land ? giPNG : IS_ESTATE_OR_COM}
                alt={'image'}
                sx={{
                  maxWidth: searchBy === 'land' ? 200 : 240,
                  height: 'auto',
                }}
              />
            </Grid>
          ) : null}
          {matchesSM ? (
            <Grid item sx={{ mt: 2 }}>
              <Box
                component="img"
                src={
                  searchBy === SearchForEnum.Land
                    ? giMobilePNG
                    : IS_ESTATE_OR_COM_MOBILE
                }
                alt={'image'}
                sx={{
                  maxWidth: 73,
                  height: 'auto',
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        item
        md={6}
        lg={8}
        sx={{
          display: { xs: 'none', sm: 'none', md: 'inherit' },
        }}
        container
        justifyContent={{
          xs: 'center',
          sm: 'center',
          md: 'space-around',
          lg: 'space-between',
        }}
        alignItems="center"
        wrap="nowrap"
      >
        <ButtonBase onClick={() => handleImageClick(SearchForEnum.Estate)}>
          <Box
            component="img"
            src={katoikiaPNG}
            alt="estate"
            sx={{
              opacity: searchBy === 'estate' ? 1 : 0.4,
              maxWidth: {
                sm: 150,
                md: matchesSMB ? 140 : 200,
                lg: matchesMDB ? 200 : 240,
                xl: 340,
              },
              height: 'auto',
              position: 'relative',
              left: { md: 30, lg: matchesMDB ? 90 : 40, xl: 80 },
              top: { sm: 10, md: 0, lg: -20 },
            }}
          />
        </ButtonBase>
        <ButtonBase
          name="commercial"
          onClick={() => handleImageClick(SearchForEnum.Commercial)}
        >
          <Box
            component="img"
            src={epagPNG}
            alt="commercial"
            sx={{
              opacity: searchBy === SearchForEnum.Commercial ? 1 : 0.4,

              height: '100%',
              maxHeight: {
                sm: 180,
                md: matchesSMB ? 160 : 190,
                lg: matchesMDB ? 290 : 300,
              },
              width: 'auto',
              position: 'relative',
              left: { md: 40, lg: matchesMDB ? 160 : 80, xl: 100 },
              top: { sm: 0, md: matchesSMB ? -60 : -40, lg: -70 },
            }}
          />
        </ButtonBase>
        <ButtonBase
          name="land"
          onClick={() => handleImageClick(SearchForEnum.Land)}
        >
          <Box
            component="img"
            src={giPNG}
            alt="land"
            sx={{
              opacity: searchBy === SearchForEnum.Land ? 1 : 0.4,

              height: '100%',
              maxHeight: {
                sm: 160,
                md: matchesSMB ? 160 : 190,
                lg: matchesMDB ? 260 : 280,
              },
              width: 'auto',
              position: 'relative',
              // left: { md: 40, lg: matchesMDB ? 120 : 0, xl: 100 },
              left: { md: 40, lg: matchesMDB ? 40 : 0, xl: 30 },
              top: { sm: 20, md: matchesSMB ? 40 : 0, lg: 200 },
            }}
          />
        </ButtonBase>
      </Grid>
    </Grid>
  )
}

export default Top
