import React from 'react'
import { Grid, Typography, Box, Stack, Avatar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { grey } from '@mui/material/colors'

import Person from 'assets/img/person.png'
import { EstateAgent } from 'types'
interface Props {
  data: EstateAgent
}

function Partner(props: Props) {
  const data = props.data
  const { t } = useTranslation()

  if (data) {
    const partner = data?.contactInfo ?? []

    const partners = partner.filter(
      (item) =>
        item?.defaultContactInfo === false &&
        (!!item?.contactName || !!item?.email || !!item?.landLineNumber)
    )

    if (partners.length > 0) {
      return (
        <Grid
          item
          xs={12}
          sx={{
            border: 1,
            borderColor: grey[300],
            boxShadow: 25,
            borderRadius: 2.5,
            mt: 5,
            p: 4,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                fontSize={{ xs: 16, sm: 20, md: 24 }}
                fontWeight="bolder"
              >
                {t('agentDetailPage.partners.label')}
              </Typography>
            </Grid>
            {partners.map((partner, index) => {
              return (
                <React.Fragment key={partner.id}>
                  <Grid item>
                    {partner?.photosURLS?.length ? (
                      <Avatar
                        sx={{ height: '90px', width: '90px' }}
                        src={partner?.photosURLS[0]}
                      />
                    ) : (
                      <Box
                        height={90}
                        width={90}
                        sx={{
                          borderRadius: 50,
                          backgroundImage: `url(${Person})`,
                          backgroundSize: 'cover',
                          minWidth: 90,
                        }}
                      />
                    )}
                    {/* )} */}
                  </Grid>
                  <Grid item xs={12} md={10} sx={{ flexGrow: 1 }}>
                    <Stack>
                      <Typography
                        component="div"
                        variant="h6"
                        sx={{ fontSize: 16, fontWeight: 'bold' }}
                      >
                        {partner?.contactName}
                      </Typography>
                      {partner?.email ? (
                        <Typography
                          component="div"
                          variant="h6"
                          sx={{ fontSize: 16 }}
                        >
                          {t('agentDetailPage.partners.email')} :{' '}
                          {partner?.email}
                        </Typography>
                      ) : null}
                      {partner?.landLineNumber ? (
                        <Typography
                          component="div"
                          variant="h6"
                          sx={{ fontSize: 16 }}
                        >
                          {t('agentDetailPage.partners.phoneNumber')} :{' '}
                          {partner?.landLineNumber}
                        </Typography>
                      ) : null}
                      {partner?.description ? (
                        <Typography
                          component="div"
                          variant="h6"
                          sx={{ fontSize: 16 }}
                        >
                          {partner?.description}
                        </Typography>
                      ) : null}
                    </Stack>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </Grid>
      )
    } else {
      return null
    }
  } else {
    return null
  }
}

export default Partner
