import React from 'react'
import { Link, Typography } from '@mui/material'

import { EstateAgent } from 'types'

interface Props {
  data: EstateAgent
}

function PhoneNumber(props: Props) {
  const numbers: (string | null)[] = []

  if (props?.data?.contactInfo) {
    props?.data?.contactInfo.forEach((el) => {
      if (el?.landLineNumber) {
        numbers.push(el?.landLineNumber)
      }
      if (el?.mobileNumber) {
        numbers.push(el?.mobileNumber)
      }
      if (el?.extraNumber) {
        numbers.push(el?.extraNumber)
      }
    })
  }

  if (numbers.length) {
    return (
      <>
        {numbers.map((number) => {
          return (
            <React.Fragment key={number}>
              <Link
                color="inherit"
                underline="none"
                href={'tel:' + number}
                sx={{ fontSize: { xs: 15, sm: 20 } }}
              >
                {number}
              </Link>
              <br />
            </React.Fragment>
          )
        })}
      </>
    )
  } else {
    return (
      <>
        <Typography variant="h5">-</Typography>
      </>
    )
  }
}

export default PhoneNumber
