/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  Typography,
  TextField,
  TextFieldProps,
  Box,
  Grid,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import NumberFormatInput from 'components/Landing/NumberFormatInput'
import Filters from './Filters'
import { useTranslation, Trans } from 'react-i18next'
import useDebounce from 'hooks/useDebounce'
import NumberFormattingService from 'api/services/NumberFormatting'
import { useInView } from 'react-intersection-observer'
import { useSearchStore } from 'store'
import shallow from 'zustand/shallow'
import { useSearchParams } from 'react-router-dom'

const NumberTextField = styled((props) => (
  <TextField
    size="small"
    InputProps={{
      sx: {
        width: '6ch',
      },
    }}
    {...props}
  />
))<TextFieldProps>(() => ({}))

interface InfiniteQueryData {
  data: string | any[]
  page: number
  nextPage: number
  totalPages: number
  totalCount: number
}

interface PaginationProps {
  data: InfiniteQueryData
  page: number
  pageLimit: number
  totalCount: number
  totalPages: number
  onChangePage: (e: any) => Promise<void>
  onRefetch: () => void
}

function Pagination(props: PaginationProps) {
  const { data, page, totalPages, onChangePage, onRefetch } = props
  const { t } = useTranslation()

  const { ref, inView } = useInView({
    threshold: 1,
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const [, setPage] = useSearchStore(
    (state) => [state.page, state.setPage],
    shallow
  )

  React.useEffect(() => {
    if (inView) {
      setPage(page + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  const handlePageChange = (values) => {
    const { floatValue } = values
    const finalValue = floatValue > totalPages ? totalPages : floatValue
    if (finalValue === 1) {
      searchParams.delete('page')
      searchParams.delete('ad')
      searchParams.delete('at')
      setSearchParams(searchParams, {
        replace: true,
      })
      onRefetch()
      setPage(1)
    } else {
      onChangePage(finalValue)
      setPage(finalValue)
    }
  }

  const debouncedFn = useDebounce(handlePageChange, 600)

  return (
    <Box
      sx={{
        border: '1px solid lightgrey',
        borderRadius: 2.5,
        px: 2,
        py: 1,
        my: 2,
      }}
      ref={ref}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        spacing={{ xs: 1, sm: 0 }}
      >
        <Grid item>
          <Box>
            <Typography
              component="span"
              display="inline"
              align="center"
              sx={{
                fontSize: { xs: 12, sm: 20, md: 20, lg: 20 },
                fontWeight: 900,
              }}
            >
              {NumberFormattingService.toRoundNumber(data?.totalCount, 0)}
            </Typography>
            {` `}
            <Trans i18nKey="searchPage.pagination.key_other" />
          </Box>
        </Grid>
        <Grid item>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignContent={{ sm: 'flex-end', md: 'center' }}
            alignItems={{ xs: 'flex-end', sm: 'center' }}
            justifyContent="space-between"
            spacing={{ xs: 0, sm: 3, md: 5, lg: 7 }}
          >
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>{t('searchPage.pagination.page')}</Typography>
              </Grid>
              <Grid item>
                <NumberFormatInput
                  inputProp={NumberTextField}
                  defaultValue={page + 1}
                  onValueChange={debouncedFn}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  decimalScale={0}
                />
              </Grid>
              <Grid item>
                <Typography
                  component="span"
                  display="inline"
                  sx={{ whiteSpace: 'pre' }}
                >
                  {t('searchPage.pagination.from')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="span" display="inline" align="center">
                  {NumberFormattingService.toRoundNumber(totalPages, 0)}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item>
          <Filters onRefetch={onRefetch} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Pagination

Pagination.propTypes = {
  response: PropTypes.array,
}
