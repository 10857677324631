import React from 'react'
import shallow from 'zustand/shallow'
import {
  LinearProgress,
  Grid,
  useMediaQuery,
  Alert,
  Collapse,
  Box,
  Typography,
  ClickAwayListener,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

import SearchInput from 'components/Landing/NumberFormatInput/SearchInput'
import SearchMenu from 'components/Landing/SearchMenu'
import SearchLabel from 'components/Landing/SearchLabel'
import useDebounce from 'hooks/useDebounce'
import AreaService from 'api/services/Area'
import SearchChips from 'components/Landing/SearchChips'
import { useSearchStore } from 'store'
import { Area, ViewVariant } from 'types'
import { useSearchParams } from 'react-router-dom'
import { encryptObject } from 'helpers'
import { pick } from 'lodash'

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
)

interface SearchBarProps {
  variant?: ViewVariant
}

function SearchBar(props: SearchBarProps) {
  const searchRef = React.useRef<HTMLDivElement>(null)
  // eslint-disable-next-line no-useless-escape
  const SPECIAL_CHAR_REGEX = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  React.useEffect(() => {
    if (searchRef.current) {
      searchRef.current?.focus()
    }
  }, [])

  const { variant } = props

  const IS_INNER = variant === 'inner'

  const theme = useTheme()

  const matchesMDD = useMediaQuery(theme.breakpoints.down('md'))
  const matchesSMD = useMediaQuery(theme.breakpoints.down('sm'))
  const [inputValue, setInputValue] = React.useState('')

  const { t } = useTranslation()

  const [openResultsMenu, setOpenResultsMenu] = React.useState(false)

  const handleOpenResultsMenu = () => {
    setOpenResultsMenu(true)
  }

  const handleCloseResultsMenu = () => {
    setOpenResultsMenu(false)
  }

  const [localError, setLocalError] = React.useState<string | undefined>()
  const IS_ERROR = Boolean(localError)

  const handleCloseError = () => {
    setLocalError(undefined)
  }

  const [areas, handleSelectDeselect] = useSearchStore(
    (state) => [state.areas, state.handleSelectDeselect],
    shallow
  )

  const [resultsWidth, setResultsWidth] = React.useState<number | undefined>()

  const searchAreas = AreaService.hooks.useSearchAreas({
    queryOptions: {
      onSuccess: () => {
        if (searchRef && searchRef?.current) {
          setResultsWidth(searchRef?.current.getBoundingClientRect().width)
        }
        handleOpenResultsMenu()
      },
      onError: () => {
        setLocalError(t('error.unspecific'))
      },
    },
  })

  const handleSearchAreas = (e) => {
    setLocalError(undefined)
    if (!e.target.value || e.target.value?.length < 3) {
      handleCloseResultsMenu()
      return
    }
    if (SPECIAL_CHAR_REGEX.test(e.target.value)) {
      setLocalError(t('searchPage.invalidCharacter'))
      setOpenResultsMenu(false)
      return
    } else {
      setLocalError(undefined)
    }
    searchAreas.mutate({ area: e.target.value.trim() })
  }

  const debouncedHandleSearchAreas = useDebounce(handleSearchAreas, 600)

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      debouncedHandleSearchAreas(e)
    }
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    debouncedHandleSearchAreas(e)
  }

  const [searchParams, setSearchParams] = useSearchParams()

  const FIELD = 'areas'
  const urlField = 'area'

  type AreaFlag = 'remove' | 'add'

  const setAreaToUrl = (area: Area, flag: AreaFlag = 'add' as AreaFlag) => {
    const value = searchParams.get(urlField)
    let payload
    if (value) {
      const splitValues = value.split(',')
      if (flag === 'remove') {
        const areaToRemove = encryptObject(area)
        payload = splitValues.filter((item) => item !== areaToRemove).join(',')
      } else {
        payload = [encryptObject(area), ...splitValues].join(',')
      }
    } else {
      payload = [encryptObject(area)]
    }
    if (!payload?.length) {
      searchParams.delete(urlField)
    } else {
      searchParams.set(urlField, payload)
    }
    searchParams.delete('page')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const handleSelectArea = (area) => {
    handleSelectDeselect(
      pick(area, ['fullPath', 'realNameArea']),
      FIELD,
      'fullPath'
    )
    if (IS_INNER) {
      let mode = 'add'
      if (areas?.find((item) => item?.fullPath === area.fullPath)) {
        mode = 'remove'
      }
      setAreaToUrl(area, mode as AreaFlag)
    }
    if (matchesSMD) {
      handleCloseResultsMenu()
    }
  }

  const handleDelete = (area) => () => {
    handleSelectDeselect(
      pick(area, ['fullPath', 'realNameArea']),
      FIELD,
      'fullPath'
    )
    if (IS_INNER) {
      const mode = 'remove'
      setAreaToUrl(area, mode as AreaFlag)
    }
  }

  const generateLabel = (area: string) => {
    if (area?.length) {
      const parts = area.split(/\s\/\s/g)
      return (
        <Typography
          component="span"
          display="block"
          fontWeight="inherit"
          color="inherit"
        >
          {parts.map((part, index) => (
            <React.Fragment key={part}>
              {part}
              {index === parts.length - 1 ? null : bull}
            </React.Fragment>
          ))}
        </Typography>
      )
    }
    return ''
  }

  const generateChipLabel = (area: string) => {
    if (area?.length) {
      const parts = area.split(/\s\/\s/g)
      return (
        <Typography fontWeight="inherit" color="inherit" component="div">
          {parts[parts?.length - 1]}
        </Typography>
      )
    }
    return ''
  }

  const handleFocus = () => {
    if (
      (areas?.length && !!inputValue && !openResultsMenu) ||
      (!!inputValue && !openResultsMenu)
    )
      handleOpenResultsMenu()
  }

  const handleClickOutside = () => {
    handleCloseResultsMenu()
  }
  const IS_INNER_AND_NOT_MDD =
    IS_INNER && !matchesMDD && resultsWidth ? resultsWidth + 100 : resultsWidth

  const RESULTS_WIDTH = resultsWidth ? IS_INNER_AND_NOT_MDD : 400
  return (
    <>
      <Grid item sx={{ minHeight: 72 }}>
        {areas.length ? (
          <Grid item xs={12}>
            <SearchChips
              cVariant={variant}
              options={areas}
              getOptionKey={(option) => option.fullPath}
              getOptionLabel={(option) =>
                generateChipLabel(option.realNameArea)
              }
              onDelete={handleDelete}
              chipProps={
                IS_INNER
                  ? {
                      variant: 'search',
                      color: 'primary',
                      showArrow: true,
                    }
                  : {
                      variant: 'search',
                      color: 'white',
                      showArrow: true,
                    }
              }
            />
          </Grid>
        ) : null}
      </Grid>
      <ClickAwayListener onClickAway={handleClickOutside}>
        <Box>
          <div>
            <SearchLabel
              cVariant={variant}
              isActive={IS_INNER ? true : !!areas.length}
            >
              {IS_INNER
                ? t('landingPage.where.innerLabel')
                : t('landingPage.where.label')}
            </SearchLabel>
          </div>
          <div ref={searchRef}>
            <SearchInput
              tabIndex={0}
              value={inputValue}
              variant={variant}
              placeholder={t('landingPage.where.placeholder')}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              autoFocus
              onFocus={handleFocus}
            />
            {searchAreas.isLoading ? <LinearProgress color="primary" /> : null}

            <Collapse in={IS_ERROR}>
              <Alert severity="error" onClose={handleCloseError}>
                {localError}
              </Alert>
            </Collapse>
            <SearchMenu
              variant={variant}
              open={openResultsMenu}
              options={
                searchAreas.data
                  ? searchAreas.data.map((item) =>
                      pick(item, ['fullPath', 'realNameArea'])
                    )
                  : []
              }
              getOptionLabel={(option: Area) =>
                generateLabel(option.realNameArea)
              }
              getOptionSelected={(option: Area) =>
                !!areas.find((area: Area) => area.fullPath === option.fullPath)
              }
              width={RESULTS_WIDTH}
              onChange={handleSelectArea}
              isLoading={searchAreas.isLoading}
              onCloseResultsMenu={handleCloseResultsMenu}
            />
          </div>
        </Box>
      </ClickAwayListener>
    </>
  )
}

export default React.memo(SearchBar)
