import React from 'react'

interface DocumentHookProps {
  title?: string
}

interface DocumentHookReturn {
  documentTitle: string
  resetTitle: () => void
  setDocumentTitle: (string) => void
  addTitle: (string) => void
  addBeforeTitle: (string) => void
}

function useDocumentTitle(props: DocumentHookProps = {}): DocumentHookReturn {
  const { title } = props
  const [documentTitle, setDocumentTitle] = React.useState(() =>
    title ? title : process.env.REACT_APP_NAME
  )

  const resetTitle = () => {
    document.title = process.env.REACT_APP_NAME
  }

  const addTitle = (value: string) => {
    document.title = `${process.env.REACT_APP_NAME} | ${value}`
  }

  const addBeforeTitle = (value: string) => {
    document.title = `${value} | ${process.env.REACT_APP_NAME}`
  }

  return {
    resetTitle,
    documentTitle,
    setDocumentTitle,
    addTitle,
    addBeforeTitle,
  }
}

export { useDocumentTitle }
