import { useState } from 'react'
import {
  Stack,
  Typography,
  Tooltip,
  Avatar,
  Grid,
  useMediaQuery,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

import { icons as availableIcons } from 'components/Detail/icons'
import { ParsedProperty } from 'api/services/PropertyAd/types'

interface IconGroupProps {
  propertyCategory: string
  propertyType: string
  data: ParsedProperty
}

enum EventType {
  click = 'click',
  hover = 'hover',
}

function IconGroup(props: IconGroupProps) {
  const { t } = useTranslation()
  const { propertyCategory, propertyType, data } = props
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >()

  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const handleTooltipVisibility = (
    tooltipId: number | undefined,
    eventType: EventType
  ) => {
    if (eventType === EventType.hover && matchesSM) return
    if (eventType === EventType.click && !matchesSM) return

    setActiveTooltipIndex(tooltipId)
  }

  const renderIcons = () => {
    const icons =
      availableIcons?.[propertyCategory]?.[propertyType]?.['searchdetail']
    if (!icons) return null
    return icons.map((option, index) => {
      return (
        <Grid
          key={option.tooltip}
          item
          sx={{
            wordBreak: 'break-word',
            width: '33.333%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack
            alignItems="center"
            justifyContent={'flex-start'}
            direction={'column'}
            spacing={1}
          >
            <Tooltip
              open={
                activeTooltipIndex !== undefined && index === activeTooltipIndex
              }
              title={t(option.tooltip) ?? false}
              placement="top"
              disableHoverListener={matchesSM}
            >
              <Avatar
                key={option.tooltip}
                onMouseEnter={() =>
                  handleTooltipVisibility(index, EventType.hover)
                }
                onMouseLeave={() =>
                  handleTooltipVisibility(undefined, EventType.hover)
                }
                onClick={() => {
                  const tooltipIndex =
                    activeTooltipIndex !== undefined ? undefined : index
                  handleTooltipVisibility(tooltipIndex, EventType.click)
                }}
                sx={{
                  width: { xs: 45, sm: 50 },
                  height: { xs: 45, sm: 50 },
                  bgcolor: 'orange.main',
                  border: 'none',
                  mx: 'auto',
                }}
              >
                {option.icon}
              </Avatar>
            </Tooltip>
            <Typography
              component="span"
              display="block"
              align={'center'}
              sx={{
                fontWeight: 'bolder',
                fontSize: { xs: 16, sm: 16, md: 16, lg: 16 },
              }}
            >
              {option?.valueGetter ? option?.valueGetter(data, t) ?? '-' : '-'}
            </Typography>
          </Stack>
        </Grid>
      )
    })
  }

  return (
    <Grid
      container
      direction="row"
      columns={12}
      justifyContent={'center'}
      alignItems="stretch"
      spacing={1}
    >
      {renderIcons()}
    </Grid>
  )
}

export default IconGroup
