import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import api from 'api'

const QueryKeys = {} as const

const MutationKeys = {
  sendAnalytic: ['sendAnalytic'] as const,
} as const

//GET all
export const addPropertyAdAnalytic = async ({
  id,
  type,
}: {
  id: string
  type: string
}) => {
  await api({
    method: 'post',
    url: `/app/spourgiti/admin/property-ad-analytics/${id}/${type}`,
    data: {},
  })
}

export const useSendAnalytic = (
  options: Omit<
    UseMutationOptions<void, AxiosError, { id: string; type: string }>,
    'mutationKey' | 'mutationFn'
  > = {}
) => {
  return useMutation(...MutationKeys.sendAnalytic, addPropertyAdAnalytic, {
    ...options,
  } as Omit<UseMutationOptions<void, AxiosError, { id: string; type: string }>, 'mutationKey' | 'mutationFn'>)
}

const PropertyAdAnalyticsService = {
  addPropertyAdAnalytic,
  hooks: {
    useSendAnalytic,
  },
  keys: { MutationKeys, QueryKeys },
}

export default PropertyAdAnalyticsService
