import React from 'react'
import { Grid, Typography, SvgIcon } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Control, UseFormReset } from 'react-hook-form'
import { FormFields } from './types'
import { ReactComponent as PigSvg } from 'assets/svg/icons/tool3.svg'
import FormField from './FormField'
import LoadingCalcButton from './shared/LoadingCalcButton'
import LoanFinalPrice from './shared/LoanFinalPrice'
import { useMatch } from 'react-router-dom'

interface ILoanProps {
  value?: number | null
  control: Control<FormFields>
  data?: number
  loading?: boolean
  reset: UseFormReset<FormFields>
  reqError?: boolean
}

function Loan(props: ILoanProps) {
  const { control, data, loading, reqError } = props
  const { t } = useTranslation()
  const [finalPrice, setFinalPrice] = React.useState(0)

  React.useEffect(() => {
    if (data) {
      setFinalPrice(data)
    } else {
      setFinalPrice(0)
    }
  }, [data])

  const matchCalculator = useMatch('/calculator')

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ pt: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 } }}
    >
      <Grid item sx={{ pb: 2 }}>
        <Typography
          align="center"
          variant="h5"
          sx={{
            borderBottom: (theme) => `2px solid ${theme.palette.primary.main}`,
            px: 2,
          }}
        >
          {t('detailPage.calculator.loan.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              rules={{
                required: `${t('calculatorPage.fieldRequired')}`,
                validate: (value) =>
                  Number(value) !== 0 || `${t('calculatorPage.isNotZero')}`,
              }}
              name="propertyValue"
              control={control}
              label={t('detailPage.calculator.buy.fields.valueEstate.label')}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={6} sx={{ flexGrow: 1 }}>
                <FormField
                  rules={{
                    required: `${t('calculatorPage.fieldRequired')}`,
                    validate: (value) =>
                      (Number(value) !== 0 && Number(value) <= 100) ||
                      `${t(
                        'calculatorPage.isBiggerThanZeroSmallerThanOnehundred'
                      )}`,
                  }}
                  name="financingPercent"
                  control={control}
                  label={`${t(
                    'detailPage.calculator.loan.fields.financingPercent.label'
                  )} %`}
                />
              </Grid>
              <Grid item xs={6} sx={{ flexGrow: 1 }}>
                <FormField
                  TextFieldProps={{ disabled: true }}
                  name="financingAmount"
                  control={control}
                  label={t(
                    'detailPage.calculator.loan.fields.financingAmount.label'
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={6} sx={{ flexGrow: 1 }}>
                <FormField
                  name="duration"
                  control={control}
                  rules={{
                    required: `${t('calculatorPage.fieldRequired')}`,
                    validate: {
                      scale: (value) =>
                        (Number(value) >= 1 && Number(value) <= 40) ||
                        `${t(
                          'calculatorPage.isBiggerThanOneSmallerThanForty'
                        )}`,
                      doubleNum: (value) =>
                        Number.isInteger(Number(value)) ||
                        `${t('calculatorPage.isInteger')}`,
                    },
                  }}
                  label={t('detailPage.calculator.loan.fields.duration.label')}
                />
              </Grid>
              <Grid item xs={6} sx={{ flexGrow: 1 }}>
                <FormField
                  name="interestRate"
                  control={control}
                  rules={{
                    required: `${t('calculatorPage.fieldRequired')}`,
                    validate: (value) =>
                      Number(value) <= 100 ||
                      `${t('calculatorPage.isSmallerThanOnehundred')}`,
                  }}
                  label={`${t(
                    'detailPage.calculator.loan.fields.interestRate.label'
                  )} %`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <LoadingCalcButton
              isLoading={loading ?? false}
              isError={reqError ?? false}
            />
          </Grid>
          <Grid item direction="column" container justifyContent="center">
            <LoanFinalPrice value={data ? finalPrice : 0} />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            sx={{
              marginTop: 3,
              flexGrow: 1,
              display: { xs: 'flex', sm: 'none', md: 'flex' },
            }}
          >
            <SvgIcon
              component={PigSvg}
              inheritViewBox
              sx={{
                width: 'auto',
                height: { xs: 150, sm: 200, md: 250, lg: 350 },
                fill: 'black',
              }}
            />
          </Grid>
          {!matchCalculator ? (
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 2,
              }}
            >
              <Typography
                align="center"
                sx={{ whiteSpace: 'pre', pt: 2, fontSize: { xs: 15, sm: 20 } }}
              >
                {t('detailPage.calculator.subtitle')}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Loan
