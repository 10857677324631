import React from 'react'
import {
  Grid,
  Box,
  Typography,
  Divider,
  Link,
  TypographyProps,
} from '@mui/material'
import InnerLayout from 'layouts/InnerLayout'
import { useTranslation } from 'react-i18next'
import Gefyres from 'assets/svg/gefyres.svg'
import Eagents from 'assets/img/e_agents_logomall.png'
import RealStatus from 'assets/img/real_status.png'
import Mesitiko from 'assets/img/label_mesitiko.png'
import Estplus from 'assets/img/estplus.png'
import ProfileEpaggelmatia from 'assets/svg/profil_epaggelmatia.svg'
import EmporikiPolitiki from 'assets/svg/emporiki_politiki.svg'
import OroiXrisis from 'assets/svg/oroi_xriseis.svg'
import Epikoinonia from 'assets/svg/epikoinwnia.svg'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import usePageView from 'hooks/usePageView'

const StyledTitle = styled((props) => (
  <Typography
    component="span"
    display="block"
    align="left"
    fontWeight={900}
    {...props}
  />
))<TypographyProps>(({ theme }) => ({
  fontSize: 40,
  paddingBottom: 2,
  [theme.breakpoints.down('md')]: {
    fontSize: 30,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 25,
    textAlign: 'center',
  },
}))

const StyledText = styled((props) => (
  <Typography
    component="span"
    display="block"
    align="left"
    fontWeight={600}
    {...props}
  />
))<TypographyProps>(({ theme }) => ({
  fontSize: 24,
  paddingBottom: 2,
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    textAlign: 'center',
  },
}))

function InfoForAgent() {
  const { t } = useTranslation()
  usePageView()

  return (
    <InnerLayout>
      <Grid container direction="row">
        <Grid item xs={12} sx={{ pt: 6 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={5}
          >
            <Grid item>
              <Box component="img" src={Eagents} sx={{ width: 150 }}></Box>
            </Grid>
            <Grid item>
              <Box component="img" src={RealStatus} sx={{ width: 150 }}></Box>
            </Grid>
            <Grid item>
              <Box component="img" src={Mesitiko} sx={{ width: 150 }}></Box>
            </Grid>
            <Grid item>
              <Box component="img" src={Estplus} sx={{ width: 150 }}></Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ py: { xs: 3, md: 5, lg: 7 } }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={7}>
          <StyledTitle>{t('infoForAgents.crm.title')}</StyledTitle>
          <StyledText>{t('infoForAgents.crm.text')}</StyledText>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Box
            component="img"
            src={Gefyres}
            sx={{
              height: '100%',
              width: 'auto',
              maxHeight: {
                xs: '110px',
                md: '156px',
                lg: '189px',
              },
            }}
          ></Box>
        </Grid>
      </Grid>

      <Divider
        variant="middle"
        sx={{
          pt: { xs: 3, md: 5, lg: 10 },
          mb: { xs: 3, md: 5, lg: 10 },
          borderBottomWidth: 4,
          borderColor: 'primary.main',
        }}
      />

      <Grid
        container
        bgcolor="primary.main"
        sx={{
          pl: 2,
          py: { xs: 3, md: 5, lg: 7 },
          flexWrap: { xs: 'wrap-reverse', md: 'nowrap' },
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: { xs: 'center', md: 'center' } }}
        >
          <Box
            component="img"
            src={ProfileEpaggelmatia}
            sx={{
              height: 'auto',
              maxWidth: {
                xs: '168px',
                md: '193px',
                lg: '266px',
              },
            }}
          ></Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTitle>
            {t('infoForAgents.createBusinessProfile.title')}
          </StyledTitle>
          <StyledText>
            {t('infoForAgents.createBusinessProfile.text')}
          </StyledText>
        </Grid>
      </Grid>

      <Divider
        variant="middle"
        sx={{
          pt: { xs: 3, md: 5, lg: 10 },
          mb: { xs: 3, md: 5, lg: 10 },
          borderBottomWidth: 4,
          borderColor: 'primary.main',
        }}
      />

      <Link
        component={RouterLink}
        to={`/business-policy`}
        underline="none"
        color="inherit"
      >
        <Grid container spacing={2} sx={{ py: { xs: 3, md: 5, lg: 7 } }}>
          <Grid item xs={12} md={5}>
            <StyledTitle>{t('infoForAgents.businessPolicy.title')}</StyledTitle>
            <StyledText>{t('infoForAgents.businessPolicy.text')}</StyledText>
          </Grid>
          <Grid item xs={12} md={7} sx={{ textAlign: 'center' }}>
            <Box
              component="img"
              src={EmporikiPolitiki}
              sx={{
                width: 'auto',
                height: { xs: '93px', md: '132px', lg: '214px' },
              }}
            ></Box>
          </Grid>
        </Grid>
      </Link>

      <Divider
        variant="middle"
        sx={{
          pt: { xs: 3, md: 5, lg: 10 },
          mb: { xs: 3, md: 5, lg: 10 },
          borderBottomWidth: 4,
          borderColor: 'primary.main',
        }}
      />

      <Link
        component={RouterLink}
        to={`/terms`}
        underline="none"
        color="inherit"
      >
        <Box
          sx={{
            bgcolor: 'primary.main',
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              pl: 2,
              py: { xs: 3, md: 5, lg: 7 },
              flexWrap: { xs: 'wrap-reverse', md: 'nowrap' },
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={OroiXrisis}
                sx={{
                  width: 'auto',
                  height: { xs: '160px', md: '222px', lg: '281px' },
                }}
              ></Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <StyledTitle>{t('infoForAgents.termsOfUse.title')}</StyledTitle>
              <StyledText>{t('infoForAgents.termsOfUse.text')}</StyledText>
            </Grid>
          </Grid>
        </Box>
      </Link>

      <Divider
        variant="middle"
        sx={{
          pt: { xs: 3, md: 5, lg: 10 },
          mb: { xs: 3, md: 5, lg: 10 },
          borderBottomWidth: 4,
          borderColor: 'primary.main',
        }}
      />

      <Link
        component={RouterLink}
        to={`/contact-us`}
        underline="none"
        color="inherit"
      >
        <Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{ py: { xs: 3, md: 5, lg: 7 } }}
          >
            <Grid item xs={12} md={6}>
              <StyledTitle align="right">
                {t('infoForAgents.contact.title')}
              </StyledTitle>
              <StyledText align="right">
                {t('infoForAgents.contact.text')}
              </StyledText>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={Epikoinonia}
                sx={{
                  width: 'auto',
                  height: { xs: '155px', md: '227px', lg: '296px' },
                }}
              ></Box>
            </Grid>
          </Grid>
        </Grid>
      </Link>
    </InnerLayout>
  )
}

export default InfoForAgent
