import React from 'react'
import { Box, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import SearchTabs from './SearchTabs'
import SearchTab from './SearchTab'
import SearchLabel from 'components/Landing/SearchLabel'
import { Option, ViewVariant } from 'types'

interface TabbedSelectProps {
  label?: string
  options: Option[]
  value: string
  variant?: ViewVariant
  onChange?: (any, string) => void
  sx?: SxProps
}

const TabbedSelect = (props: TabbedSelectProps) => {
  const { t } = useTranslation()

  const {
    label,
    options = [],
    value,
    onChange,
    variant,
    sx: sxFromProps = {},
  } = props

  const calcFlexGrow = (index: number) => {
    if (index === 2) return 0.2
    if (index === 0) return 0.6
    return 1
  }
  return (
    <Box sx={{ width: '100%', ...sxFromProps }}>
      {label ? (
        <SearchLabel cVariant={variant} isActive={true}>
          {label}
        </SearchLabel>
      ) : null}
      <SearchTabs
        cVariant={variant}
        variant="fullWidth"
        centered={true}
        value={value}
        onChange={onChange}
      >
        {options?.length
          ? options.map((opt, index) => (
              <SearchTab
                key={opt.value}
                variant={variant}
                value={opt.value}
                label={t(opt.label)}
                style={{ flexGrow: calcFlexGrow(index) }}
              />
            ))
          : null}
      </SearchTabs>
    </Box>
  )
}
export default TabbedSelect
