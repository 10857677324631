import { Dialog, DialogContent, Typography, Box, SvgIcon } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TailSpourgitiSvg } from 'assets/svg/tailSpourgiti.svg'

interface Props {
  open: boolean
  onClose: () => void
  onTimeout: () => void
}

function TimeOutDialog(props: Props) {
  const { t } = useTranslation()
  const { open, onClose, onTimeout } = props

  React.useEffect(() => {
    const timer = setTimeout(() => {
      onTimeout()
    }, 2000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          bgcolor: 'transparent',
          overflowY: 'revert',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '360px',
            bgcolor: 'transparent',
            py: '60px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              position: 'relative',
              zIndex: 2,
              minHeight: '111px',
              width: '255px',
              borderRadius: '20px',
              mx: '24px',
              py: '20px',
            }}
          >
            <Typography
              align="center"
              fontWeight={400}
              fontSize={'16px'}
              sx={{ whiteSpace: 'pre', lineHeigth: '18px' }}
            >
              {t('detailPage.redirectDialog.message')}
            </Typography>
            <SvgIcon
              component={TailSpourgitiSvg}
              inheritViewBox
              sx={{
                position: 'absolute',
                bottom: '-30px',
                left: '-41px',
                color: 'black',
                height: '60px',
                width: '60px',
                zIndex: 1,
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TimeOutDialog
