import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'

import api from 'api'
import { IAgent, ISearchAgentsPayload } from './types'
import { EstateAgent } from 'types'
import { AxiosError } from 'axios'

const TIME = 1000 * 60 * 10 // 10 mins

const SERVICE_NAME = `Agent`

const QueryKeys = {
  search: `${SERVICE_NAME}#search`,
  count: `${SERVICE_NAME}#count`,
  single: `${SERVICE_NAME}#single`,
} as const

const MutationKeys = {
  search: `${SERVICE_NAME}#search`,
} as const

const CONTROLLER_PATH = `/app/spourgiti/api/agent`

// GET all
export const getAgents = async ({
  queryKey,
}: QueryFunctionContext<[string, number, string | null]>): Promise<
  IAgent[]
> => {
  const [, page, areaAndCompany] = queryKey
  const PER_PAGE = 10
  const payload = {
    pageNumber: page,
    pageSize: PER_PAGE,
  } as unknown as ISearchAgentsPayload
  if (areaAndCompany) payload.areaAndCompany = areaAndCompany
  const { data } = await api({
    method: 'post',
    url: `${CONTROLLER_PATH}/search-by-criteria`,
    data: payload,
  })

  return data
}
export const useAgents = (
  page: number,
  areaAndCompany: string,
  queryOptions: Omit<
    UseQueryOptions<IAgent[], AxiosError, IAgent[]>,
    'queryKey' | 'queryFn'
  > = {}
) => {
  return useQuery<IAgent[], AxiosError>(
    [QueryKeys.search, page, areaAndCompany],
    getAgents,
    {
      ...queryOptions,
    } as Omit<
      UseQueryOptions<IAgent[], AxiosError, IAgent[]>,
      'queryKey' | 'queryFn'
    >
  )
}

// GET single agent

export const getSingleAgent = async ({
  queryKey,
}: QueryFunctionContext<
  [string, string | null]
>): Promise<EstateAgent | null> => {
  const [, id] = queryKey
  if (id) {
    const { data } = await api({
      method: 'get',
      url: `${CONTROLLER_PATH}/${id}`,
    })
    return data
  }
  return null
}

export const useSingleAgent = (
  id: string | null,
  queryOptions: Omit<
    UseQueryOptions<EstateAgent | null, AxiosError, EstateAgent>,
    'queryKey' | 'queryFn'
  > = {}
) => {
  return useQuery<EstateAgent | null, AxiosError, EstateAgent>(
    [QueryKeys.single, id],
    getSingleAgent,
    {
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 30,
      ...queryOptions,
    } as Omit<
      UseQueryOptions<EstateAgent | null, AxiosError, EstateAgent>,
      'queryKey' | 'queryFn'
    >
  )
}

// COUNT all
export const getCountAgents = async ({
  queryKey,
}: QueryFunctionContext<[string, string | null]>): Promise<number> => {
  const [, areaAndCompany] = queryKey
  const payload = {} as ISearchAgentsPayload
  if (areaAndCompany) payload.areaAndCompany = areaAndCompany
  const { data } = await api({
    method: 'post',
    url: `${CONTROLLER_PATH}/count-by-criteria`,
    data: payload,
  })
  return data
}
export const useCountAgents = (
  areaAndCompany: string | null,
  queryOptions: Omit<
    UseQueryOptions<number, AxiosError, number>,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const TIME = 1000 * 60 * 60

  return useQuery([QueryKeys.count, areaAndCompany], getCountAgents, {
    keepPreviousData: true,
    staleTime: TIME,
    cacheTime: TIME,
    ...queryOptions,
  } as Omit<UseQueryOptions<number, AxiosError, number>, 'queryKey' | 'queryFn'>)
}

const AgentService = {
  getCountAgents,
  getSingleAgent,
  getAgents,
  hooks: {
    useCountAgents,
    useSingleAgent,
    useAgents,
  },
  keys: { QueryKeys, MutationKeys },
}

export default AgentService
