import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tab, { TabProps } from '@mui/material/Tab'

interface SearchTabProps extends TabProps {
  variant?: string
}

const SearchTab = styled((props: TabProps) => (
  <Tab {...props} />
))<SearchTabProps>(({ variant, theme }) => ({
  color: theme.palette.common.grey,
  textTransform: 'none',
  fontSize: 30,
  fontWeight: 900,
  paddingTop: 0,
  [theme.breakpoints.down('lg')]: {
    fontSize: 25,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 22,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
  marginBottom: theme.spacing(0.5),
  ...(variant === 'inner' && {
    fontSize: 20,
    marginBottom: theme.spacing(0),
    paddingBottom: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  }),
  '&.Mui-selected': {
    color: theme.palette.common.black,
  },
}))

export default SearchTab
