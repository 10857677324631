import * as React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { ViewVariant } from 'types'

export interface NumberFormatCustomProps extends NumberFormatProps {
  name?: string
  onChange?: (any) => void
  variant?: ViewVariant
  inputProp?: React.FC<unknown>
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props: NumberFormatCustomProps,
  ref
) {
  const { onChange, inputProp, ...other } = props

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={(values, sourceInfo) => {
        if (onChange && sourceInfo.source === 'event') {
          onChange({
            target: {
              name: props.name ?? '',
              value: values.value,
            },
          })
        }
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      customInput={inputProp ? inputProp : undefined}
      thousandsGroupStyle="thousand"
      allowNegative={false}
      fixedDecimalScale={false}
      allowEmptyFormatting={false}
      style={{ width: '100%' }}
      onChange={() => {}}
      {...other}
    />
  )
})

export default React.memo(NumberFormatCustom)
