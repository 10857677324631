import { useEffect } from 'react'
import * as Klaro from 'klaro'
import { useTranslation } from 'react-i18next'

function useKlaro() {
  const { t } = useTranslation()
  const config = {
    lang: 'el',
    htmlTexts: true,
    translations: {
      el: {
        ok: t('cookieConsent.ok'),
        acceptSelected: t('cookieConsent.acceptSelected'),
        decline: t('cookieConsent.decline'),
        purposeItem: { service: t('cookieConsent.service') },
        consentNotice: {
          description: t('cookieConsent.consentNoticeDescription'),
        },
        consentModal: {
          description: t('cookieConsent.consentModalDescription'),

          title: t('cookieConsent.consentModalTitle'),
        },
      },
    },

    hideLearnMore: false,
    cookieDomain: '.spourgiti.com',
    cookieExpiresAfterDays: 30,

    services: [
      {
        name: 'google-analytics',
        default: true,
        title: 'Google Analytics',
        purposes: ['tracking'],
        cookies: [/^ga/i],
      },
    ],
  }
  useEffect(() => {
    window.klaro = Klaro
    window.klaroConfig = config
    Klaro.setup(config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useKlaro
