import React from 'react'
import { Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import Partner from './Partner'
import { EstateAgent } from 'types'

interface Props {
  data: EstateAgent
  view?: 'small'
}
function Details(props: Props) {
  const IS_SMALL = props.view === 'small'

  return (
    <>
      <Grid
        container
        sx={{
          display: {
            xs: IS_SMALL ? 'none' : 'initial',
            sm: IS_SMALL ? 'initial' : 'none',
          },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            border: 1,
            borderColor: grey[300],
            boxShadow: 25,
            borderRadius: 2.5,
            mt: 5,
            p: 4,
          }}
        >
          <Typography
            align="left"
            fontSize={{ xs: 16, sm: 20, md: 24 }}
            fontWeight="bolder"
          >
            {props?.data?.companyTitle}
          </Typography>
        </Grid>
        {props.data?.description ? (
          <Grid
            item
            xs={12}
            sx={{
              border: 1,
              borderColor: grey[300],
              boxShadow: 25,
              borderRadius: 2.5,
              mt: 5,
              p: 4,
            }}
          >
            <Typography
              align="left"
              variant="subtitle1"
              sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
            >
              {props.data.description}
            </Typography>
          </Grid>
        ) : null}

        <Partner data={props?.data} />
      </Grid>
    </>
  )
}

export default Details
