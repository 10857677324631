import React from 'react'
import shallow from 'zustand/shallow'

import { useSearchStore } from 'store'
import TabbedSelect from 'components/Landing/TabbedSelect'
import { useSearchParams } from 'react-router-dom'
import { ISearchForOption } from 'store/SearchSlice/types'
import { useMediaQuery, useTheme } from '@mui/material'

function PropertyType({ variant }) {
  const theme = useTheme()

  const MATCHES_OVERFLOW_SCREEN = useMediaQuery(
    theme.breakpoints.between(945, 1380)
  )

  const MATCHES_SM = useMediaQuery(theme.breakpoints.down('sm'))

  const SEARCH_FOR_OPTIONS: ISearchForOption[] = [
    {
      label: 'landingPage.lookingFor.estate',
      value: 'estate',
    },
    {
      label:
        MATCHES_OVERFLOW_SCREEN || MATCHES_SM
          ? 'landingPage.lookingFor.commercialShort'
          : 'landingPage.lookingFor.commercial',
      value: 'commercial',
    },
    { label: 'landingPage.lookingFor.land', value: 'land' },
  ]

  const [searchBy, setSearchBy, clearForm] = useSearchStore(
    (state) => [state.searchBy, state.setSearchBy, state.clearForm],
    shallow
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const setCategoryToUrl = (search: string) => {
    searchParams.set('category', search)
    const newSearchParams = new URLSearchParams(
      Array.from(searchParams.entries()).filter((item) => {
        if (['area', 'interest', 'category', 'agentId']?.includes(item[0])) {
          return item
        }
        return false
      })
    )
    setSearchParams(newSearchParams, {
      replace: true,
    })
  }

  const handleChangeBy = (_event, newValue) => {
    clearForm()
    setSearchBy(newValue)
    setCategoryToUrl(newValue)
  }

  return (
    <TabbedSelect
      variant={variant}
      value={searchBy}
      onChange={handleChangeBy}
      options={SEARCH_FOR_OPTIONS}
    />
  )
}

export default PropertyType
