/* eslint-disable no-unused-vars */
import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Grid, useMediaQuery } from '@mui/material'
import Slider from 'react-slick'

import ImagesCarousel from './ImagesCarousel'

import DetailContent from '../DetailContent'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import { type Attachment } from 'types'

interface Props {
  data: ParsedProperty
  propertyCategory: string
  propertyType: string
  onOpen?: () => void
  style: React.CSSProperties
}
function AdCard(props: Props) {
  const {
    data: property,
    onOpen,
    propertyCategory,
    propertyType,
    style,
  } = props

  const theme = useTheme()

  const matchesMDLG = useMediaQuery(theme.breakpoints.down('md'))

  const [images, setImages] = React.useState<Attachment[]>([])

  const sliderRef = React.useRef<Slider>(null)

  React.useEffect(() => {
    if (property?.photosURLS?.length) {
      setImages(property.photosURLS)
    }
  }, [property?.photosURLS])

  const [current, setCurrent] = React.useState(0)

  const [, setActiveItemIndex] = React.useState(0)

  const handleChangeIndex = (index) => {
    let indexTo = index
    if (index >= images?.length) {
      indexTo = 0
    }
    if (index < 0) {
      indexTo = images?.length - 1
    }
    setCurrent(indexTo)
    setActiveItemIndex(indexTo)
    if (sliderRef?.current) {
      sliderRef.current?.slickGoTo(indexTo)
    }
  }

  return (
    <Grid container style={style}>
      {!images.length ? null : (
        <Grid item xs={12}>
          {/* <Stack spacing={0}> */}
          {/* <ImageWrapper> */}
          <ImagesCarousel
            current={current}
            images={images}
            onChange={handleChangeIndex}
          ></ImagesCarousel>
          {/* </Stack> */}
        </Grid>
      )}
      {matchesMDLG ? null : (
        <Grid item xs={12}>
          <DetailContent
            data={property}
            propertyCategory={propertyCategory}
            propertyType={propertyType}
            onOpen={onOpen ? onOpen : undefined}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default AdCard
