/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Attachment } from 'types'
import { sortBy } from 'lodash'
import { useSwipeable } from 'react-swipeable'
import Image from './Image'
interface ImagesCarouselProps {
  current?: number
  onChange?: (number) => void
  children?: React.ReactNode
  options: Attachment[]
}

function ImagesCarousel(props: ImagesCarouselProps) {
  const { options } = props

  const SWIPABLE_CONFIG = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => handleChangeIndex(current + 1),
    onSwipedRight: () => handleChangeIndex(current - 1),
    ...SWIPABLE_CONFIG,
  })

  const myRef = React.useRef()

  const refPassthrough = (el) => {
    handlers.ref(el)
    myRef.current = el
  }

  const [images] = React.useState(
    options?.length ? sortBy(options, 'orderNumber') : []
  )
  const [current, setCurrent] = React.useState(0)

  const handleChangeIndex = (index) => {
    if (index > images?.length - 1) setCurrent(0)
    else if (index < 0) setCurrent(images?.length - 1)
    else setCurrent(index)
  }

  const wrapperHeight = 200

  const CALCULATED_TOP = `calc(${wrapperHeight / 2}px - 20px)`

  return (
    <>
      {' '}
      {images?.length > 1 ? (
        <>
          <ArrowBackIosNew
            onClick={() => handleChangeIndex(current - 1)}
            sx={{
              position: 'absolute',
              top: CALCULATED_TOP,
              left: 5,
              zIndex: 100,
              cursor: 'pointer',
              stroke: 'white',
              strokeWidth: 3,
              width: '25px',
              height: '50px',
              '&:hover': {
                filter: `drop-shadow(0 0 10px  rgba(0,0,0,0.8))`,
              },
            }}
          />
          <ArrowForwardIos
            onClick={() => handleChangeIndex(current + 1)}
            sx={{
              position: 'absolute',
              top: CALCULATED_TOP,
              right: 5,
              zIndex: 100,
              cursor: 'pointer',
              stroke: 'white',
              strokeWidth: 3,
              width: '25px',
              height: '50px',
              '&:hover': {
                filter: `drop-shadow(0 0 10px  rgba(0,0,0,0.8))`,
              },
            }}
          />
        </>
      ) : null}
      <Box
        {...handlers}
        ref={refPassthrough}
        sx={{
          width: '100%',
          height: wrapperHeight,
          zIndex: 99,
          backgroundColor: 'common.white',
        }}
      >
        <Image
          src={
            images[current]?.thumbnailPath &&
            images[current]?.thumbnailPath !== 'FAILED'
              ? images[current]?.thumbnailPath ?? ''
              : images[current]?.filePath ?? ''
          }
        />
      </Box>
    </>
  )
}

export default ImagesCarousel

ImagesCarousel.propTypes = {}
