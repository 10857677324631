import { queryCache } from 'api'

export interface AuthSliceStore {
  accessToken: string | null
  refreshToken: string | null
  setAccessToken: (value: string | null) => void
  setRefreshToken: (value: string | null) => void
  reset: () => void
}

export const authSlice = (set, get) => ({
  accessToken: null,
  refreshToken: null,
  setAccessToken: (payload: string | null) =>
    set({
      accessToken: payload,
    }),
  setRefreshToken: (payload: string | null) =>
    set({
      refreshToken: payload,
    }),
  reset: () => {
    queryCache.clear()
    set({
      accessToken: null,
      refreshToken: null,
      isAuthenticated: false,
      isAuthenticating: false,
      user: null,
    })
  },
})
