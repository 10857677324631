import { StoreSlice } from '..'

export interface CalculatorSliceStore {
  interestRate: number
  financePercentage: number
  setInterest: (value: number) => void
  setFinancePercentage: (value: number) => void
}

export const calculatorSlice: StoreSlice<CalculatorSliceStore> = (
  set,
  get
) => ({
  interestRate: 3.1,
  financePercentage: 70,
  setInterest: (value) => {
    value = value * 100
    set({ interestRate: value })
  },
  setFinancePercentage: (value) => {
    value = value * 100
    set({ financePercentage: value })
  },
})
