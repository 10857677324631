import React from 'react'
import { Typography, Box, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import shallow from 'zustand/shallow'
import { useSearchStore } from 'store'
import {
  INTEREST_FOR_OPTIONS,
  SEARCH_FOR_OPTIONS,
} from 'store/SearchSlice/constants'

function SaveSearch() {
  const { t } = useTranslation()

  const [areas, searchBy, searchFor] = useSearchStore(
    (state) => [state.areas, state.searchBy, state.searchFor],
    shallow
  )

  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpen(false)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [open])

  return (
    <Box sx={{ mb: { xs: 2 }, ml: { xs: 3, md: 1 } }}>
      <Grid container alignItems={'center'} spacing={3}>
        <Grid item>
          <Typography
            component="span"
            display="block"
            color="textSecondary"
            fontWeight={600}
            fontSize={18}
          >
            <Typography
              fontWeight={600}
              fontSize={20}
              display={'inline'}
              color="textPrimary"
            >
              {t('searchPage.saveSearch.title')}
            </Typography>
            {` `}
            {t(
              SEARCH_FOR_OPTIONS?.find((option) => option?.value === searchBy)
                ?.label ?? ''
            )}
            {`, `}
            {t(
              INTEREST_FOR_OPTIONS?.find(
                (option) => option?.value === searchFor
              )?.label ?? ''
            )}
            {`, `}

            {areas.map((area, index) => {
              const parts = area.realNameArea.split(/\s\/\s/g)
              return (
                <Typography
                  key={area.fullPath}
                  display={'inline'}
                  fontWeight="inherit"
                  fontSize="inherit"
                  color="inherit"
                  component="span"
                  sx={{ borderBottom: '1px dashed gray', mx: '5px' }}
                >
                  {parts[parts?.length - 1]}
                  {index < areas?.length - 1 ? `,` : ''}
                </Typography>
              )
            })}
          </Typography>
        </Grid>
        {/* DO NOT REMOVE, hidden for now according to romanos */}
        {/* <Grid item>
          <Button
            variant="contained"
            onClick={handleClick}
            sx={{ textTransform: 'none', fontSize: 18, py: 0.2, px: 1 }}
          >
            {t('searchPage.saveSearch.save')}
          </Button>
          <Popper
            id={popperId}
            open={open}
            anchorEl={anchorEl}
            placement="top"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={150}>
                <Alert
                  severity="success"
                  sx={{
                    borderRadius: '4px',
                    py: 0.2,
                    mb: 1,
                  }}
                >
                  <AlertTitle>{t('searchPage.saveSearch.copy')}</AlertTitle>
                </Alert>
              </Fade>
            )}
          </Popper>
        </Grid> */}
      </Grid>
    </Box>
  )
}

export default SaveSearch
