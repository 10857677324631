import * as React from 'react'
import { Typography, Box } from '@mui/material'

import logoPNG from 'assets/img/logo-footer.png'

const Copyright = () => {
  const [currentYear] = React.useState<number>(() => new Date().getFullYear())
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <Box component="img" src={logoPNG} alt="logo" />
      </Box>
      <Typography
        noWrap
        component="div"
        sx={{
          fontSize: 13,
          color: 'common.white',
        }}
      >
        {currentYear} © All rights reserved - Spourgiti
      </Typography>
    </Box>
  )
}
export default Copyright
