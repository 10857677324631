import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NumberFormattingService from 'api/services/NumberFormatting'

interface Props {
  value: number
}
function LoanFinalPrice({ value }: Props) {
  const { t } = useTranslation()
  return (
    <>
      <Typography align="center" sx={{ fontWeight: 700, fontSize: 20 }}>
        {t('detailPage.calculator.loan.fields.monthlyPayment.label')}
      </Typography>
      <Box
        sx={{
          boxShadow: 25,
          py: 1.5,
          borderRadius: 2.5,
          bgcolor: 'primary.main',
          display: 'flex',
          justifyContent: 'center',
          maxWidth: 400,
          mx: 'auto',
          width: '100%',
        }}
      >
        <Box
          sx={{
            fontSize: 25,
            color: 'common.white',
            fontWeight: 600,
            letterSpacing: 1.1,
          }}
        >
          {NumberFormattingService.toRoundNumber(value)}
        </Box>
      </Box>
    </>
  )
}

export default LoanFinalPrice
