import React from 'react'
import {
  Box,
  Typography,
  CircularProgress,
  Grow,
  Grid,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
} from '@mui/material'
import { Search } from '@mui/icons-material'
import {
  useForm,
  Controller,
  SubmitHandler,
  useWatch,
  Control,
} from 'react-hook-form'
import { useTranslation, TFunction } from 'react-i18next'

import InnerLayout from 'layouts/InnerLayout'
import AgentCard from './Card'
import AgentService from 'api/services/Agent'
import usePageView from 'hooks/usePageView'
import ErrorAlert from './ErrorAlert'

interface IFormProps {
  areaAndCompany: string
}

interface ISearchInputProps {
  control: Control<IFormProps>
  t: TFunction
  onMouseDown: (e) => void
  onSubmit: (data: IFormProps) => void
  onKeyDown: (e) => void
}

const SearchInput = (props: ISearchInputProps) => {
  const { control, onMouseDown, onKeyDown, onSubmit, t } = props

  const watchAreaAndCompany = useWatch({
    control,
    name: 'areaAndCompany',
  })
  React.useEffect(() => {
    if (watchAreaAndCompany === '') {
      onSubmit({ areaAndCompany: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAreaAndCompany])
  return (
    <Controller
      render={({ field }) => (
        <TextField
          fullWidth
          label={t('findRealtorPage.inputSearch.label')}
          helperText={t('findRealtorPage.inputSearch.helper')}
          onKeyDown={onKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  color="primary"
                  onMouseDown={onMouseDown}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            mt: 6,
            maxWidth: (theme) => theme.breakpoints.values.sm,
          }}
          {...field}
        />
      )}
      rules={{ required: true }}
      name={`areaAndCompany`}
      control={control}
    />
  )
}

const FindRealtorsPage: React.FC = () => {
  const { sendPageView } = usePageView({ runOnMount: false })
  const { t } = useTranslation()

  const { handleSubmit, control } = useForm<IFormProps>({
    defaultValues: {
      areaAndCompany: '',
    },
  })

  const [page, setPage] = React.useState<number>(0)
  const [areaAndCompany, setAreaAndCompany] = React.useState<string>('')
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (value - 1 === page) return
    setPage(value - 1)
    agents.refetch()
  }

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [page])

  const count = AgentService.hooks.useCountAgents(areaAndCompany)
  const agents = AgentService.hooks.useAgents(page, areaAndCompany, {
    retry: false,
    onSuccess: () => {
      sendPageView()
    },
  })

  React.useEffect(() => {
    agents.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit: SubmitHandler<IFormProps> = ({ areaAndCompany }) => {
    setAreaAndCompany(areaAndCompany)
    count.refetch()
    setPage(0)
    agents.refetch()
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)
    }
  }

  return (
    <InnerLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            position: 'relative',
            pb: 4,
            px: { xs: 0.5, sm: 2, md: 2, lg: 4, xl: 6 },
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6}>
              <SearchInput
                t={t}
                control={control}
                onSubmit={onSubmit}
                onKeyDown={handleKeyPress}
                onMouseDown={handleMouseDown}
              />
            </Grid>
          </Grid>

          {agents.isLoading || agents.isFetching ? (
            <Typography align="center" sx={{ mt: 4 }}>
              <CircularProgress color="secondary" size={30} />
            </Typography>
          ) : null}
          {agents.isError ? <ErrorAlert /> : null}
          <Grow in={agents.isSuccess}>
            <Grid container direction="column" rowSpacing={2} sx={{ mt: 3 }}>
              <Grid item sx={{ alignSelf: 'flex-end' }}>
                <Grow in={count.isSuccess}>
                  <Typography>
                    <strong>{count.data}</strong>{' '}
                    {t('findRealtorPage.inputSearch.results')}
                  </Typography>
                </Grow>
              </Grid>
              <Divider flexItem />
              {agents.data ? (
                <Grid
                  item
                  container
                  spacing={6}
                  justifyContent="center"
                  alignItems="stretch"
                >
                  {agents.data?.map((item) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                      <AgentCard data={item} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography
                  variant="h5"
                  color="textSecondary"
                  align="center"
                  sx={{ pt: 4 }}
                >
                  {t('findRealtorPage.inputSearch.noResults')}
                </Typography>
              )}

              {count.isSuccess ? (
                <Grid item sx={{ alignSelf: 'center', mt: 5 }}>
                  <Pagination
                    color="primary"
                    size="large"
                    count={Math.ceil(count.data / 10)}
                    shape="rounded"
                    page={page + 1}
                    onChange={handleChangePage}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grow>
        </Box>
      </form>
    </InnerLayout>
  )
}

export default FindRealtorsPage
