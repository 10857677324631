/* eslint-disable no-constant-condition */
import { TFunction } from 'react-i18next'
import { Typography } from '@mui/material'
import { get, sortBy } from 'lodash'
import NumberFormattingService from 'api/services/NumberFormatting'

import {
  IEstateTypeValue,
  ICommercialTypeValue,
  ICommercialTypeOption,
  ILandTypeValue,
  ILandTypeOption,
  IEstateConditionValue,
  IEstateConditionOption,
  IParkingTypeValue,
  IEstateThermalValue,
  IEstateThermalTypeValue,
  IEstateExtrasValue,
  ILandExtrasValue,
  ICommercialExtrasValue,
  IFloorValue,
  IEnergyClassValue,
  IGardenValue,
  IDoorValue,
  IFrameValue,
  IWindowTypeValue,
  ILandAccessValue,
  IDirectionValue,
  IFloorTypeValue,
  IElectricityTypeValue,
  IViewValue,
  IEquipmentValue,
  ILandConditionValue,
  IPoolValue,
  IUsageValue,
  ILandCityPlanValue,
  ESTATE_TYPE_VALUES,
  COMMERCIAL_TYPE_VALUES,
  LAND_TYPE_VALUES,
  ESTATE_CONDITION_VALUES,
  HEAT_VALUES,
  HEAT_TYPE_VALUES,
  ESTATE_EXTRAS_VALUES,
  COMMERCIAL_EXTRAS_VALUES,
  LAND_EXTRAS_VALUES,
  FLOOR_VALUES,
  PARKING_VALUES,
  GARDEN_VALUES,
  DOOR_VALUES,
  ENERGY_CLASS_VALUES,
  FRAME_VALUES,
  FLOOR_TYPE_VALUES,
  ACCESS_VALUES,
  VIEW_VALUES,
  POOL_VALUES,
  USAGE_VALUES,
  ELECTRICITY_TYPE_VALUES,
  EQUIPMENT_VALUES,
  CITY_PLAN_VALUES,
  LAND_CONDITION_VALUES,
  DIRECTION_VALUES,
  NUMERIC_PROPERTIES,
  INumericProperty,
} from 'types/propertyAttributes'
import {
  ESTATE_TYPE_OPTIONS,
  COMMERCIAL_TYPE_OPTIONS,
  LAND_TYPE_OPTIONS,
  ESTATE_CONDITION_OPTIONS,
  HEAT_OPTIONS,
  ESTATE_THERMAL_TYPE_OPTIONS,
  PARKING_TYPE_OPTIONS,
  FLOOR_OPTIONS,
  ENERGY_CLASS_OPTIONS,
  GARDEN_OPTIONS,
  DOOR_OPTIONS,
  FRAME_OPTIONS,
  WINDOW_OPTIONS,
  LAND_ACCESS_OPTIONS,
  DIRECTION_OPTIONS,
  FLOOR_TYPE_OPTIONS,
  ELECTRICITY_TYPE_OPTIONS,
  VIEW_OPTIONS,
  EQUIPMENT_OPTIONS,
  LAND_CONDITION_OPTIONS,
  POOL_OPTIONS,
  USAGE_OPTIONS,
  CITY_PLAN_OPTIONS,
} from 'helpers/constants'
import {
  ParsedProperty,
  ValueLabelPair,
  MappedObject,
} from 'api/services/PropertyAd/types'
import React from 'react'
import { PropertyAdCategoryEnum, SearchForEnum } from 'types/enums'

const ESTATE_ACCEPTED_IDS = [
  PropertyAdCategoryEnum.ESTATE_PROPERTY_SALES,
  PropertyAdCategoryEnum.ESTATE_PROPERTY_RENT,
]
const COMMERCIAL_ESTATE_ACCEPTED_IDS = [
  PropertyAdCategoryEnum.ESTATE_COMMERCIAL_SALES,
  PropertyAdCategoryEnum.ESTATE_COMMERCIAL_RENT,
]
const LAND_ACCEPTED_IDS = [
  PropertyAdCategoryEnum.ESTATE_LAND_RENT,
  PropertyAdCategoryEnum.ESTATE_LAND_SALES,
]

const INTEREST_BUY = [
  PropertyAdCategoryEnum.ESTATE_COMMERCIAL_SALES,
  PropertyAdCategoryEnum.ESTATE_LAND_SALES,
  PropertyAdCategoryEnum.ESTATE_PROPERTY_SALES,
]
const INTEREST_LOAN = [
  PropertyAdCategoryEnum.ESTATE_COMMERCIAL_RENT,
  PropertyAdCategoryEnum.ESTATE_LAND_RENT,
  PropertyAdCategoryEnum.ESTATE_PROPERTY_RENT,
]

/**
 * It takes a string as an argument and returns a string
 * @param {string} s - string
 * @returns A function that takes a string and returns a string with the first letter capitalized.
 */
const capitalize = (s: string): string => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const getInterestType = (adCategoryId: PropertyAdCategoryEnum): string => {
  const IS_BUY = INTEREST_BUY.includes(adCategoryId)
  const IS_LOAN = INTEREST_LOAN.includes(adCategoryId)
  if (IS_BUY) return 'detailPage.interest.buy'
  else if (IS_LOAN) return 'detailPage.interest.loan'
  return ''
}

/**
 * It returns a string based on the value of a number
 * @param {number} adCategoryId - number
 * @returns 'buy'
 */
export function calculateInterest(
  adCategoryId: PropertyAdCategoryEnum
): string {
  const IS_BUY = INTEREST_BUY.includes(adCategoryId)
  const IS_LOAN = INTEREST_LOAN.includes(adCategoryId)
  if (IS_BUY) return 'buy'
  else if (IS_LOAN) return 'loan'
  else return ''
}

/**
 * It takes a string, splits it on the '/' character, and then joins the resulting array with a comma
 * and space.
 * @param {string | null} areaName - string | null
 * @returns A string
 */
export const parseArea = (areaName: string | null): string => {
  if (areaName) {
    const split = areaName.split(/\s\/\s/g)
    let final = ''
    split.forEach((part, index) => {
      if (split.length === 1) final += part
      else
        final = final
          .concat(part)
          .concat(index === split.length - 1 ? '' : ', ')
    })
    return final
  }
  return ''
}

export const generatePropertyAdTitle = (
  data: ParsedProperty | undefined,
  t: TFunction
): string => {
  if (data) {
    const areaPart = parseArea(data?.searchArea)
    const propertyType = getPropertyTypeParsed(data)

    return `${t(propertyType?.label ?? '')} ${data?.squareMeters} m2 ${t(
      'landingPage.interestType.resultsLabel'
    )} ${t(getInterestType(data?.adCategoryId))} ${data?.salesPrice} ${t(
      `tagResponses.price`
    )} ${areaPart}`
  }
  return ''
}

export function calculatePropertyCategory(
  adCategoryId: PropertyAdCategoryEnum
): SearchForEnum {
  const IS_ESTATE = ESTATE_ACCEPTED_IDS.includes(adCategoryId)
  const IS_COMMERCIAL_ESTATE =
    COMMERCIAL_ESTATE_ACCEPTED_IDS.includes(adCategoryId)
  if (IS_ESTATE) return SearchForEnum.Estate
  else if (IS_COMMERCIAL_ESTATE) return SearchForEnum.Commercial
  else return SearchForEnum.Land
}

type ReturnCalculatePropertyType =
  | 'home'
  | 'combined_office_hall'
  | 'store'
  | 'commercial_building'
  | 'parking'
  | 'combined_zones'
  | 'hotel'
  | 'plot'
  | 'island'
  | 'plot_of_land'

type CalcPropType =
  | IEstateConditionOption
  | ICommercialTypeOption
  | ILandTypeOption
  | null
export function calculatePropertyType(
  option: CalcPropType,
  data: ParsedProperty
): ReturnCalculatePropertyType {
  let type
  if (option && data) {
    const category = calculatePropertyCategory(data.adCategoryId)
    switch (category) {
      case 'estate':
        type = 'home'
        break
      case 'commercial':
        if (
          [
            'ESTATE_PROPERTY_COMMERCIAL_TYPE#office',
            'ESTATE_PROPERTY_COMMERCIAL_TYPE#hall',
            'ESTATE_PROPERTY_COMMERCIAL_TYPE#rest_commercial_types',
          ].includes(option.value)
        ) {
          type = 'combined_office_hall'
        } else if (
          [
            'ESTATE_PROPERTY_COMMERCIAL_TYPE#warehouse',
            'ESTATE_PROPERTY_COMMERCIAL_TYPE#craft_space',
            'ESTATE_PROPERTY_COMMERCIAL_TYPE#industrial_area',
          ].includes(option.value)
        ) {
          type = 'combined_zones'
        } else if (option.value === 'ESTATE_PROPERTY_COMMERCIAL_TYPE#store') {
          type = 'store'
        } else if (option.value === 'ESTATE_PROPERTY_COMMERCIAL_TYPE#hotel') {
          type = 'hotel'
        } else if (
          option.value === 'ESTATE_PROPERTY_COMMERCIAL_TYPE#commercial_building'
        ) {
          type = 'commercial_building'
        } else if (option.value === 'ESTATE_PROPERTY_COMMERCIAL_TYPE#parking') {
          type = 'parking'
        } else type = 'rest'
        break

      case 'land':
        if (option.value === 'ESTATE_PROPERTY_LAND_TYPE#plot_of_land')
          type = 'plot_of_land'
        else if (option.value === 'ESTATE_PROPERTY_LAND_TYPE#island')
          type = 'island'
        else if (option.value === 'ESTATE_PROPERTY_LAND_TYPE#plot')
          type = 'plot'
        else if (option.value === 'ESTATE_PROPERTY_LAND_TYPE#other_land_types')
          type = 'plot'
        break
    }
  }
  return type
}

export const getPropertyType = (
  data: ParsedProperty
): IEstateConditionOption | ICommercialTypeOption | ILandTypeOption | null => {
  if (data) {
    const IS_ESTATE = ESTATE_ACCEPTED_IDS.includes(data.adCategoryId)
    const IS_COMMERCIAL = COMMERCIAL_ESTATE_ACCEPTED_IDS.includes(
      data.adCategoryId
    )
    const IS_LAND = LAND_ACCEPTED_IDS.includes(data.adCategoryId)

    let propertyType
    let type
    if (IS_ESTATE) {
      const AVAILABLE_VALUES = ESTATE_TYPE_VALUES
      propertyType = data?.tags.find((tag) =>
        AVAILABLE_VALUES.includes(tag as IEstateTypeValue)
      )
      const index = ESTATE_TYPE_OPTIONS.findIndex(
        (item) => item.value === propertyType
      )
      if (index !== -1) type = ESTATE_TYPE_OPTIONS[index]
      return type
    } else if (IS_COMMERCIAL) {
      const AVAILABLE_VALUES = COMMERCIAL_TYPE_VALUES
      propertyType = data?.tags.find((tag) => AVAILABLE_VALUES.includes(tag))
      const index = COMMERCIAL_TYPE_OPTIONS.findIndex(
        (item) => item.value === propertyType
      )
      if (index !== -1) type = COMMERCIAL_TYPE_OPTIONS[index]
      return type
    } else if (IS_LAND) {
      const AVAILABLE_VALUES = LAND_TYPE_VALUES
      propertyType = data?.tags.find((tag) =>
        AVAILABLE_VALUES.includes(tag as ILandTypeValue)
      )
      const index = LAND_TYPE_OPTIONS.findIndex(
        (item) => item.value === propertyType
      )
      if (index !== -1) type = LAND_TYPE_OPTIONS[index]
      return type
    }
  }
  return null
}

export const getPropertyTypeParsed = (
  data: ParsedProperty
):
  | IEstateConditionOption
  | ICommercialTypeOption
  | ILandTypeOption
  | undefined => {
  return data?.parsedTags?.propertyType as
    | IEstateConditionOption
    | ICommercialTypeOption
    | ILandTypeOption
}

export const parseBooleanFieldParsed = (
  property: string,
  format: 'yes' | 'has' | 'allow',
  data: ParsedProperty,
  t: TFunction
): React.ReactElement | string => {
  const IS_YES = format === 'yes'
  const IS_HAS = format === 'has'

  const NEG_ALLOW_OR_HAS = IS_HAS ? 'hasNot' : 'allowNot'
  const NOT_FOUND_MESSAGE = IS_YES ? 'no' : NEG_ALLOW_OR_HAS

  const foundNegativeTag = get(data?.parsedTags, property + '_no')
  if (foundNegativeTag) {
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        {capitalize(t(`tagResponses.${NOT_FOUND_MESSAGE}`))}
      </Typography>
    )
  }
  const FOUND_MESSAGE = IS_YES ? 'yes' : 'has'
  const foundTag = get(data?.parsedTags, property)
  if (foundTag) {
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        {foundTag ? capitalize(t(`tagResponses.${FOUND_MESSAGE}`)) : null}
      </Typography>
    )
  }
  return <>{`-`}</>
}

export const parseSingleValueField = (
  property: string,
  data: ParsedProperty,
  t: TFunction
): React.ReactElement | string => {
  const foundTag = get(data?.parsedTags, property) as ValueLabelPair

  if (foundTag) {
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        {t(foundTag.label)}
      </Typography>
    )
  }
  return <>{`-`}</>
}

export const parseNumericalField = (
  property: string,
  data: ParsedProperty,
  t: TFunction
): React.ReactElement | string => {
  const foundTag = get(data?.parsedTags, property) as ValueLabelPair
  return (
    <Typography
      component="span"
      display="inline-block"
      fontWeight={'inherit'}
      fontSize="inherit"
    >
      {foundTag ? (foundTag as unknown as number) : '-'}
    </Typography>
  )
}

export const getPropertyAdCode = (data: ParsedProperty): string => {
  return data?.externalId ? data?.externalId : '-'
}

export const getParkingSpaces = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const carValue = data?.parsedTags?.ESTATE_PARKING_SLOTS_CAR
  const boatValue = data?.parsedTags?.ESTATE_PARKING_SLOTS_BOAT
  const bikeValue = data?.parsedTags?.ESTATE_PARKING_SLOTS_BIKE

  const parkingSlotsValue = data?.parsedTags?.ESTATE_PROPERTY_PARKING_SLOTS
  const PARKING_SLOTS = parkingSlotsValue
    ? Number(parkingSlotsValue)?.toFixed(0)
    : '-'
  const PARKING_VALUE_MESSAGE = carValue
    ? Number(carValue)?.toFixed(0)
    : PARKING_SLOTS
  return (
    <>
      <Typography fontWeight={'inherit'} fontSize="inherit">
        {`${t('tags.parking.car')}: ${PARKING_VALUE_MESSAGE}`}
        <br />
        {t('tags.parking.bike')}:{' '}
        {bikeValue ? Number(bikeValue)?.toFixed(0) : '-'}
        <br />
        {t('tags.parking.boat')}:{' '}
        {boatValue ? Number(boatValue)?.toFixed(0) : '-'}
      </Typography>
    </>
  )
}

export const getParkingSpacesDetail = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const carValue = data?.parsedTags?.ESTATE_PARKING_SLOTS_CAR ?? 0

  const boatValue = data?.parsedTags?.ESTATE_PARKING_SLOTS_BOAT ?? 0
  const bikeValue = data?.parsedTags?.ESTATE_PARKING_SLOTS_BIKE ?? 0
  const parkingSlotsValue = data?.parsedTags?.ESTATE_PROPERTY_PARKING_SLOTS
  const totalNumber =
    Number(carValue)?.toFixed(0) +
    Number(bikeValue)?.toFixed(0) +
    Number(boatValue)?.toFixed(0) +
    Number(parkingSlotsValue)?.toFixed(0)

  return (
    <>
      <Typography fontWeight={'inherit'} fontSize="inherit">
        {Number(totalNumber) ? Number(totalNumber) : '-'}
      </Typography>
    </>
  )
}

export const getParking = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const parkingType = data?.parsedTags?.parking as ValueLabelPair
  const value = data?.parsedTags?.ESTATE_PROPERTY_PARKING_SLOTS

  return (
    <>
      <Typography fontWeight={'inherit'} fontSize="inherit">
        {!parkingType && !value ? '-' : null}
        {`${parkingType ? t(parkingType?.label) : ''}
        ${value ? ', ' + t('tags.parking.prefix') + ': ' : ''}
        ${value ? Number(value)?.toFixed(0) : ''}`}
      </Typography>
    </>
  )
}

export const getDimensions = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const width = data?.parsedTags?.ESTATE_LAND_PROPERTY_PLOT_WIDTH ?? 0
  const length = data?.parsedTags?.ESTATE_LAND_PROPERTY_PLOT_LENGTH ?? 0
  if (!width && !length) {
    return (
      <Typography fontWeight={'inherit'} fontSize="inherit">
        -
      </Typography>
    )
  }
  return (
    <>
      <Typography fontWeight={'inherit'} fontSize="inherit">
        {length
          ? t('tags.dimensions.prefix.length') +
            ` : ${Number(length)}${t('metrics.m')}`
          : ''}
      </Typography>

      <Typography fontWeight={'inherit'} fontSize="inherit">
        {width
          ? t('tags.dimensions.prefix.width') +
            ` : ${Number(width)}${t('metrics.m')}`
          : ''}
      </Typography>
    </>
  )
}

export const getIndustrialDoorDimensions = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const width = data?.parsedTags?.ESTATE_PROPERTY_INDUSTRIAL_DOOR_WIDTH
  const height = data?.parsedTags?.ESTATE_PROPERTY_INDUSTRIAL_DOOR_HEIGHT

  return (
    <>
      <Typography
        fontSize="inherit"
        fontWeight={'inherit'}
        whiteSpace="pre-wrap"
      >
        {t('tags.industrialDoorDimensions.prefix.width')}:{' '}
        {width ? `${Number(width)}${t('metrics.m')}` : '-'}
      </Typography>
      {'\n'}
      <Typography fontWeight={'inherit'} fontSize="inherit">
        {t('tags.industrialDoorDimensions.prefix.height')}:{' '}
        {height ? `${Number(height)}${t('metrics.m')}` : '-'}
      </Typography>
    </>
  )
}

export const getFramesWindows = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const frame = data?.parsedTags?.frame as ValueLabelPair
  const window = data?.parsedTags?.window as ValueLabelPair

  return (
    <>
      <Typography fontWeight={'inherit'} fontSize="inherit">
        {!frame && !window ? '-' : null}
        {frame ? (
          <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
            {t('tags.framesWindows.framePrefix')}: {t(frame?.label)}
          </Typography>
        ) : null}
        {window ? (
          <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
            {t('tags.framesWindows.windowPrefix')}: {t(window?.label)}
          </Typography>
        ) : null}
      </Typography>
    </>
  )
}

export const parseHeat = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const heat = data?.parsedTags?.heat as ValueLabelPair
  const heatTypes = data?.parsedTags?.heatTypes ?? ({} as ValueLabelPair)

  return (
    <>
      <Typography fontWeight={'inherit'} fontSize="inherit">
        {heat ? t(heat?.label) : '-'}
        {Object.values(heatTypes)
          ? Object.values(heatTypes).map(
              (type, index) =>
                ` ${t(type.label)}${
                  index === Object.values(heatTypes).length - 1 ? '' : ', '
                }`
            )
          : '-'}
      </Typography>
    </>
  )
}
export const getPool = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const poolOptions = data?.parsedTags?.poolOptions
  const hasPool = get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#pool')
  const noPool = get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#pool_no')

  let poolTag
  if (poolOptions) {
    poolTag = Object.values(poolOptions).map(
      (type, index) =>
        ` ${t(type.label)}${
          index === Object.values(poolOptions).length - 1 ? '' : ', '
        }`
    )
  } else if (hasPool) {
    poolTag = t('tagResponses.yes')
  } else if (noPool) {
    poolTag = t('tagResponses.no')
  } else if (!poolTag) {
    return <>{`-`}</>
  }

  return (
    <>
      <Typography fontWeight={'inherit'} fontSize="inherit">
        {poolTag}
      </Typography>
    </>
  )
}

export const parseFloors = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const floors = data?.parsedTags?.floors ?? ({} as ValueLabelPair)
  const floorsArray = sortBy(Object.values(floors), 'order')
  return (
    <>
      <Typography fontWeight={'inherit'} fontSize="inherit">
        {floorsArray.length
          ? sortBy(Object.values(floors), 'order').map(
              (type, index) =>
                ` ${t(type.label)}${
                  index === Object.values(floors).length - 1 ? '' : ', '
                }`
            )
          : '-'}
      </Typography>
    </>
  )
}

export const parseSafety = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const door = data?.parsedTags?.door as ValueLabelPair
  const noSecurityDoor =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#security_door_no') ||
    get(
      data?.parsedTags?.extras,
      'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#security_door_no'
    )
  const securityDoor =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#security_door') ||
    get(
      data?.parsedTags?.extras,
      'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#security_door'
    )

  let doorTag
  if (door) {
    doorTag = door?.label
  } else if (securityDoor) {
    doorTag = 'tagResponses.has'
  } else if (noSecurityDoor) {
    doorTag = 'tagResponses.hasNot'
  }

  const alarm =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#alarm') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#alarm')
  const safe =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#safe') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#safe')

  const noAlarm =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#alarm_no') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#alarm_no')
  const noSafe =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#safe_no') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#safe_no')

  const RENDER_NO_ALARm = noAlarm ? (
    <Typography component="span" fontWeight={'inherit'} fontSize="inherit">
      {t('tagResponses.hasNot')}
    </Typography>
  ) : (
    '-'
  )

  const RENDER_NO_SAFE = noSafe ? (
    <Typography component="span" fontWeight={'inherit'} fontSize="inherit">
      {t('property.estatePropertyExtras.safe.base')}:{` `}
      {t('tagResponses.hasNot')}
    </Typography>
  ) : null

  return (
    <>
      <Typography
        component="div"
        fontWeight={'inherit'}
        whiteSpace="pre-wrap"
        fontSize="inherit"
      >
        {t('property.estatePropertyDoor.label')}: {` `}
        {doorTag ? (
          <Typography
            component="span"
            fontWeight={'inherit'}
            fontSize="inherit"
          >
            {t(doorTag)}
          </Typography>
        ) : (
          '-'
        )}
        {'\n'}
        {t('property.estatePropertyExtras.alarm.base')}:{` `}
        {alarm ? (
          <Typography
            component="span"
            fontWeight={'inherit'}
            fontSize="inherit"
          >
            {t('tagResponses.has')}
          </Typography>
        ) : (
          RENDER_NO_ALARm
        )}
        {'\n'}
        {safe ? (
          <Typography
            component="span"
            fontWeight={'inherit'}
            fontSize="inherit"
          >
            {t('property.estatePropertyExtras.safe.base')}:{` `}
            {t('tagResponses.has')}
          </Typography>
        ) : (
          RENDER_NO_SAFE
        )}
      </Typography>
    </>
  )
}

export const parseFrontage = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const doubleFrontage = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_EXTRAS#double_frontage'
  )

  const withLight = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_EXTRAS#with_light'
  )

  const withAir = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_EXTRAS#with_air'
  )
  const noDoubleFrontage = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_EXTRAS#double_frontage_no'
  )

  const noLight = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_EXTRAS#with_light_no'
  )
  const noAir = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_EXTRAS#with_air_no'
  )

  let doubleFrontageTag
  if (doubleFrontage) doubleFrontageTag = t('tagResponses.yes')
  if (noDoubleFrontage) doubleFrontageTag = t('tagResponses.no')
  if (!doubleFrontageTag) {
    return <>{`-`}</>
  }

  let withLightTag
  if (withLight)
    withLightTag = ', ' + t('property.estatePropertyExtras.withLight.label')
  if (noLight || (!noLight && !withLight)) withLightTag = ''

  let withAirTag
  if (withAir)
    withAirTag = ', ' + t('property.estatePropertyExtras.withAir.label')
  if (noAir || (!noAir && !withAir)) withAirTag = ''

  const tag = doubleFrontageTag + withLightTag + withAirTag

  return (
    <Typography
      component="div"
      fontWeight={'inherit'}
      whiteSpace="pre-wrap"
      fontSize="inherit"
    >
      {t(tag)}
    </Typography>
  )
}

export const getBalconyTents = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const balcony =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#balcony') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#balcony')
  const noBalcony =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#balcony_no') ||
    get(
      data?.parsedTags?.extras,
      'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#balcony_no'
    )

  const balconyValue = data?.parsedTags?.ESTATE_PROPERTY_BALCONΥ_SQR
  const tents =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#tents') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#tents')

  const noTents =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#tents_no') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#tents_no')

  const RENDER_NO_BLACONY = noBalcony ? (
    <Typography component="span" fontWeight={'inherit'} fontSize="inherit">
      {t('tagResponses.no')}
    </Typography>
  ) : (
    '-'
  )

  const RENDER_NO_TENTS = noTents ? (
    <Typography component="span" fontWeight={'inherit'} fontSize="inherit">
      {t('tagResponses.no')}
    </Typography>
  ) : (
    '-'
  )

  return (
    <>
      <Typography
        component="div"
        fontWeight={'inherit'}
        whiteSpace="pre-wrap"
        fontSize="inherit"
      >
        {t('tags.balconyAwning.prefix.balcony')}:{' '}
        {balcony ? (
          <Typography
            component="span"
            fontWeight={'inherit'}
            fontSize="inherit"
          >
            {t('tagResponses.yes')}
            {balconyValue ? (
              <>
                {` , ${Number(balconyValue)?.toFixed(1)} ${t('metrics.sqr')}`}
                <sup>2</sup>
              </>
            ) : null}
          </Typography>
        ) : (
          RENDER_NO_BLACONY
        )}
        {'\n'}
        {t('tags.balconyAwning.prefix.awning')}:{` `}
        {tents ? (
          <Typography
            component="span"
            fontWeight={'inherit'}
            fontSize="inherit"
          >
            {t('tagResponses.yes')}
          </Typography>
        ) : (
          RENDER_NO_TENTS
        )}
      </Typography>
    </>
  )
}

export const parseBedrooms = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const bedrooms = data?.parsedTags?.ESTATE_PROPERTY_BEDROOMS
  const masterBedrooms = data?.parsedTags?.ESTATE_PROPERTY_MASTER_BEDROOMS
  const bedroomsFallback = data?.parsedTags?.ESTATE_PROPERTY_ROOMS
  return (
    <>
      {!bedrooms && !bedroomsFallback && !masterBedrooms ? (
        '-'
      ) : (
        <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
          <>
            {bedrooms ? (
              <Typography
                component="div"
                fontWeight={'inherit'}
                fontSize="inherit"
              >
                {t('tags.bedrooms.label')}: {Number(bedrooms)?.toFixed(0)}
              </Typography>
            ) : (
              bedroomsFallback
            )}
            {masterBedrooms ? (
              <Typography
                component="div"
                fontWeight={'inherit'}
                fontSize="inherit"
              >
                Master:{` `}
                {Number(masterBedrooms)?.toFixed(0)}
              </Typography>
            ) : null}
          </>
        </Typography>
      )}
    </>
  )
}

export const getExpandability = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const foundNegativeTag = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#expandability_no'
  )
  const foundTag = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#expandability'
  )

  const value = data?.parsedTags?.ESTATE_PROPERTY_EXPANDABLE_SQR
  if (foundTag || foundNegativeTag || value) {
    const POSITIVE = foundTag ? capitalize(t(`tagResponses.yes`)) + `, ` : null
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        {foundNegativeTag ? capitalize(t(`tagResponses.no`)) : POSITIVE}
        {value ? (
          <span>
            {value as string} {t('metrics.sqr')}
            <sup>2</sup>
          </span>
        ) : null}
      </Typography>
    )
  }
  return <>{`-`}</>
}

export const getDistance = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const airport = data?.parsedTags?.ESTATE_PROPERTY_DISTANCE_AIRPORT
  const town = data?.parsedTags?.ESTATE_PROPERTY_DISTANCE_TOWN
  const sea = data?.parsedTags?.ESTATE_PROPERTY_DISTANCE_SEA
  const port = data?.parsedTags?.ESTATE_PROPERTY_DISTANCE_PORT
  const road = data?.parsedTags?.ESTATE_PROPERTY_DISTANCE_ROAD_NETWORK
  const village = data?.parsedTags?.ESTATE_PROPERTY_DISTANCE_VILLAGE
  return (
    <>
      <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
        {!airport && !town && !sea && !port && !road && !village ? '-' : null}
        {[
          { value: airport, label: 'airportPrefix' },
          { value: town, label: 'townPrefix' },
          { value: sea, label: 'seaPrefix' },
          { value: port, label: 'portPrefix' },
          { value: road, label: 'roadPrefix' },
          { value: village, label: 'villagePrefix' },
        ]
          .filter((item) => Boolean(item.value))
          .map((tagValue) => (
            <React.Fragment key={tagValue.label}>
              {tagValue ? (
                <Typography
                  component="div"
                  fontWeight={'inherit'}
                  fontSize="inherit"
                >
                  {t(`tags.distance.${tagValue.label}`)}:{' '}
                  {Number(tagValue.value) < 1000
                    ? `${(tagValue.value as number).toFixed(1)} ${t(
                        'metrics.m'
                      )}`
                    : `${((tagValue.value as number) / 1000).toFixed(2)} ${t(
                        'metrics.km'
                      )}`}
                  {` `}
                </Typography>
              ) : null}
            </React.Fragment>
          ))}
      </Typography>
    </>
  )
}

export const parseStorageLoft = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const storageTag =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#storage') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#storage')

  const loftTag =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#loft') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#loft')

  const noStorageTag =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#storage_no') ||
    get(
      data?.parsedTags?.extras,
      'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#storage_no'
    )
  const noLoftTag =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#loft_no') ||
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#loft_no')

  const storageValue = data?.parsedTags?.ESTATE_PROPERTY_WAREHOUSE_SQR
  const loftValue = data?.parsedTags?.ESTATE_PROPERTY_LOFT_SQR
  return (
    <>
      <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
        {!loftTag && loftValue ? (
          <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
            {`${t('tags.attic.label')}: ${
              loftValue
                ? Number(loftValue)?.toFixed(0) + ` `
                : t('tagResponses.yes')
            }`}
            {loftValue ? (
              <span>
                {t('metrics.sqr')}
                <sup>2</sup>{' '}
              </span>
            ) : null}
          </Typography>
        ) : (
          ''
        )}
        {!storageTag &&
        !loftTag &&
        !noStorageTag &&
        !noLoftTag &&
        !loftValue &&
        !storageValue
          ? '-'
          : null}
        {storageTag ? (
          <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
            {`${t('tags.warehouse.label')}: ${
              storageValue
                ? Number(storageValue)?.toFixed(0) + ` `
                : t('tagResponses.yes')
            }`}
            {storageValue ? (
              <span>
                {t('metrics.sqr')}
                <sup>2</sup>{' '}
              </span>
            ) : null}
          </Typography>
        ) : (
          ''
        )}

        {loftTag ? (
          <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
            {`${t('tags.attic.label')}: ${
              loftValue
                ? Number(loftValue)?.toFixed(0) + ` `
                : t('tagResponses.yes')
            }`}
            {loftValue ? (
              <span>
                {t('metrics.sqr')}
                <sup>2</sup>{' '}
              </span>
            ) : null}
          </Typography>
        ) : (
          ''
        )}
        {noStorageTag ? (
          <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
            {`${t('tags.warehouse.label')}: ${t('tagResponses.no')}`}
          </Typography>
        ) : (
          ''
        )}
        {noLoftTag ? (
          <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
            {`${t('tags.attic.label')}: ${t('tagResponses.no')}`}
          </Typography>
        ) : (
          ''
        )}
      </Typography>
    </>
  )
}

export const getPlot = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const foundNegativeTag = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#plot_no'
  )
  const foundTag = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#plot'
  )
  const value = data?.parsedTags?.ESTATE_PROPERTY_PLOT_SQR
  if (foundTag || foundNegativeTag || value) {
    const POSITIVE = foundTag ? capitalize(t(`tagResponses.yes`)) + `, ` : null
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        {foundNegativeTag ? capitalize(t(`tagResponses.no`)) : POSITIVE}
        {value ? (
          <span>
            {Number(value)?.toFixed(0)} {t('metrics.sqr')}
            <sup>2</sup>
          </span>
        ) : null}
      </Typography>
    )
  }
  return <>{`-`}</>
}

export const getGarden = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const garden = data?.parsedTags?.garden as ValueLabelPair
  const value = data?.parsedTags?.ESTATE_PROPERTY_GARDEN_SQR
  const hasGarden = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_EXTRAS#garden'
  )
  const noGarden = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_EXTRAS#garden_no'
  )

  let gardenTag
  if (garden) {
    gardenTag = garden?.label
  } else if (hasGarden) {
    gardenTag = 'tagResponses.has'
  } else if (noGarden) {
    gardenTag = 'tagResponses.hasNot'
  }
  return (
    <>
      <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
        {gardenTag ? `${t(gardenTag)}` : '-'}
        {value ? (
          <Typography
            component="div"
            display="inline-block"
            fontWeight={'inherit'}
            fontSize="inherit"
          >
            {value ? `, ${value} ${t('metrics.sqr')}` : ''} <sup>2</sup>
          </Typography>
        ) : (
          ''
        )}
      </Typography>
    </>
  )
}

export const getSalesPricePerBuildableSqrMeter = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const value = data?.salesPricePerBuildableSqrMeter
  return (
    <>
      <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
        {value ? (
          <>
            {NumberFormattingService.toRoundNumber(value)}
            {t('tagResponses.price')}
          </>
        ) : (
          '-'
        )}
      </Typography>
    </>
  )
}

export const getEpifaneiaOikopedou = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const value = data?.parsedTags?.ESTATE_PROPERTY_PLOT_SQR
  return (
    <>
      {value ? (
        <Typography
          fontWeight={'inherit'}
          fontSize="inherit"
          component="span"
          display="block"
        >
          {`${value} ${t('metrics.sqr')}`} <sup>2</sup>
        </Typography>
      ) : (
        '-'
      )}
    </>
  )
}

// salesPricePerSqrMeter
export const getSalesPricePerSqrMeter = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const value = data?.buildSquareMeters
  return (
    <>
      <Typography
        component="div"
        fontWeight={'inherit'}
        fontSize="inherit"
        sx={{ bgcolor: 'red' }}
      >
        {value ? (
          <>
            {NumberFormattingService.toRoundNumber(value, 0)}
            {t('tagResponses.price')}
          </>
        ) : (
          '-'
        )}
      </Typography>
    </>
  )
}

export const getWithBuilding = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const foundNegativeTag = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#with_building_no'
  )
  if (foundNegativeTag) {
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        {capitalize(t(`tagResponses.no`))}
      </Typography>
    )
  }
  const tagFound = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#with_building'
  )
  const value = data?.parsedTags?.ESTATE_LAND_PROPERTY_BUILDING_SQR
  const BUILDING_SQR = value
    ? `, ${Number(value)?.toFixed(0)} ${t('metrics.sqr')}`
    : ''
  if (tagFound) {
    return (
      <>
        <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
          {`${tagFound ? t('tagResponses.yes') : ''}
        `}
          {value ? (
            <Typography
              component="div"
              display="inline-block"
              fontWeight={'inherit'}
              fontSize="inherit"
            >
              {BUILDING_SQR}
              <sup>2</sup>
            </Typography>
          ) : (
            ''
          )}
        </Typography>
      </>
    )
  }
  return <>{`-`}</>
}

export const getLandCornerViews = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const foundNegativeTag = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#corner_no'
  )
  if (foundNegativeTag) {
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        {capitalize(t(`tagResponses.no`))}
      </Typography>
    )
  }
  const tagFound = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#corner'
  )
  const value = data?.parsedTags?.ESTATE_PROPERTY_VIEWS
  const ESTATE_VIEW = value ? `, ${Number(value)?.toFixed(0)}` : ''
  if (tagFound) {
    return (
      <>
        <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
          {`${tagFound ? t('tagResponses.yes') : ''}
        `}
          {value ? (
            <Typography
              component="div"
              display="inline-block"
              fontWeight={'inherit'}
              fontSize="inherit"
            >
              {ESTATE_VIEW}
            </Typography>
          ) : (
            ''
          )}
        </Typography>
      </>
    )
  }
  return <>{`-`}</>
}

export const getCargoElevator = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const foundNegativeTag = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cargo_elevator_no'
  )
  if (foundNegativeTag) {
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        {capitalize(t(`tagResponses.hasNot`))}
      </Typography>
    )
  }
  const cargoElevator = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cargo_elevator'
  )
  const value = data?.parsedTags?.ESTATE_PROPERTY_CARGO_ELEVATOR_WEIGHT
  const CARGO_ELEVATOR_KG = value ? `, ${Number(value)?.toFixed(0)}` : ''
  if (!foundNegativeTag && !cargoElevator) {
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        -
      </Typography>
    )
  }
  return (
    <>
      <Typography component="div" fontWeight={'inherit'} fontSize="inherit">
        {`${cargoElevator ? t('tagResponses.has') : ''}
        `}
        {value ? (
          <Typography
            component="div"
            display="inline-block"
            fontWeight={'inherit'}
            fontSize="inherit"
          >
            {CARGO_ELEVATOR_KG} kg
          </Typography>
        ) : (
          ''
        )}
      </Typography>
    </>
  )
}

export const parseBathroomsParsed = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const bathroomsValue = data?.parsedTags?.ESTATE_PROPERTY_BATHROOMS
  const wcValue = data?.parsedTags?.ESTATE_PROPERTY_WC
  return (
    <>
      <Typography
        component={'span'}
        display={'inline-block'}
        fontWeight={'inherit'}
        fontSize="inherit"
        whiteSpace="pre-wrap"
      >
        {capitalize(t('tags.bathrooms.prefix.bath'))}:{' '}
        {bathroomsValue ? `${Number(bathroomsValue)?.toFixed(0) ?? 0}` : '-'}
        {'\n'}
        {capitalize(t('tags.bathrooms.prefix.wc'))}:{' '}
        {wcValue ? Number(wcValue)?.toFixed(0) : '-'}
      </Typography>
    </>
  )
}

export const getForestCover = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement => {
  const foundTag = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#non_forest_no'
  )
  const foundNegativeTag = get(
    data?.parsedTags,
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#non_forest'
  )

  const value = data?.parsedTags?.ESTATE_LAND_PROPERTY_FOREST_COVER_SQR
  const forestCoverFactor = data?.parsedTags?.ESTATE_LAND_FOREST_COVER
  if (foundTag || foundNegativeTag || value) {
    const POSITIVE = foundTag ? capitalize(t(`tagResponses.yes`)) : null
    return (
      <Typography
        component="span"
        display="inline-block"
        fontWeight={'inherit'}
        fontSize="inherit"
      >
        {foundNegativeTag ? capitalize(t(`tagResponses.no`)) : POSITIVE}
        {forestCoverFactor ? (
          <span>
            {`, ${t(
              'property.estatePropertyLandExtras.forestPercentage.base'
            )} ${forestCoverFactor}`}
            {'%'}
          </span>
        ) : null}
        <br />
        {value ? (
          <span>
            {`${t('tags.forested.cover')} ${value} m`}
            <sup>2</sup>
          </span>
        ) : null}
      </Typography>
    )
  }
  return <>{`-`}</>
}

export const parseView = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement | string => {
  const viewOptions = data?.parsedTags?.view as ValueLabelPair
  const hasView = get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#view')
  const noView = get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#view_no')

  let viewTag
  if (viewOptions) {
    viewTag = viewOptions?.label
  } else if (hasView) {
    viewTag = 'tagResponses.has'
  } else if (noView) {
    viewTag = 'tagResponses.hasNot'
  }
  return (
    <Typography
      component="div"
      fontWeight={'inherit'}
      whiteSpace="pre-wrap"
      fontSize="inherit"
    >
      {viewTag ? (
        <Typography component="span" fontWeight={'inherit'} fontSize="inherit">
          {t(viewTag)}
        </Typography>
      ) : (
        '-'
      )}
    </Typography>
  )
}
export const parseEquipment = (
  data: ParsedProperty,
  t: TFunction
): React.ReactElement | string => {
  const equipmentOptions = data?.parsedTags?.equipment as ValueLabelPair
  const hasEquipment = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#equipped'
  )
  const noEquipment = get(
    data?.parsedTags?.extras,
    'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#equipped_no'
  )

  let equipmentTag
  if (equipmentOptions) {
    equipmentTag = equipmentOptions?.label
  } else if (hasEquipment) {
    equipmentTag = 'tagResponses.has'
  } else if (noEquipment) {
    equipmentTag = 'tagResponses.hasNot'
  }
  return (
    <Typography
      component="div"
      fontWeight={'inherit'}
      whiteSpace="pre-wrap"
      fontSize="inherit"
    >
      {equipmentTag ? (
        <Typography component="span" fontWeight={'inherit'} fontSize="inherit">
          {t(equipmentTag)}
        </Typography>
      ) : (
        '-'
      )}
    </Typography>
  )
}

/**
 * Check if the property is ESTATE, COMMERCIAL or LAND with the
 * adCategoryId and the includes function.It splits the tags and create a
 * array with the tags we need.
 * It returns an object with any tag we need for every property.
 * @param data - object
 * @returns - object
 */
export const parseTags = (data: ParsedProperty): object => {
  const IS_ESTATE = ESTATE_ACCEPTED_IDS.includes(data.adCategoryId)
  const IS_COMMERCIAL = COMMERCIAL_ESTATE_ACCEPTED_IDS.includes(
    data.adCategoryId
  )
  const IS_LAND = LAND_ACCEPTED_IDS.includes(data.adCategoryId)

  const mappedTags = data?.tags.reduce((acc, tag) => {
    if (
      NUMERIC_PROPERTIES.includes(
        tag
          .split('#')
          .slice(0, tag.split('#').length - 1)
          .join('_') as INumericProperty
      )
    ) {
      const numTag = NUMERIC_PROPERTIES.find((value) => tag.startsWith(value))
      if (numTag) {
        const valuePart = tag.split(`${numTag}#`)[1]
        acc[numTag] = Number(valuePart)
      }

      return acc
    } else if (
      IS_ESTATE &&
      !acc.propertyType &&
      ESTATE_TYPE_VALUES.includes(tag as IEstateTypeValue)
    ) {
      const index = ESTATE_TYPE_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.propertyType = {
          value: tag,
          label: ESTATE_TYPE_OPTIONS[index].label,
        }
      } else {
        acc.propertyType = {} as ValueLabelPair
      }
      return acc
    } else if (
      IS_COMMERCIAL &&
      !acc.propertyType &&
      COMMERCIAL_TYPE_VALUES.includes(tag as ICommercialTypeValue)
    ) {
      const index = COMMERCIAL_TYPE_OPTIONS.findIndex(
        (item) => item.value === tag
      )
      if (index !== -1) {
        acc.propertyType = {
          value: tag,
          label: COMMERCIAL_TYPE_OPTIONS[index].label,
        }
      } else {
        acc.propertyType = {} as ValueLabelPair
      }
      return acc
    } else if (
      IS_LAND &&
      !acc.propertyType &&
      LAND_TYPE_VALUES.includes(tag as ILandTypeValue)
    ) {
      const index = LAND_TYPE_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.propertyType = {
          value: tag,
          label: LAND_TYPE_OPTIONS[index].label,
        }
      } else {
        acc.propertyType = {} as ValueLabelPair
      }
      return acc
    } else if (
      (IS_ESTATE || IS_COMMERCIAL) &&
      !acc.condition &&
      ESTATE_CONDITION_VALUES.includes(tag as IEstateConditionValue)
    ) {
      const index = ESTATE_CONDITION_OPTIONS.findIndex(
        (item) => item.value === tag
      )
      if (index !== -1) {
        acc.condition = {
          value: tag,
          label: ESTATE_CONDITION_OPTIONS[index].label,
        }
      } else {
        acc.condition = {} as ValueLabelPair
      }
      return acc
    } else if (
      (IS_ESTATE || IS_COMMERCIAL) &&
      !acc.heat &&
      HEAT_VALUES.includes(tag as IEstateThermalValue)
    ) {
      const index = HEAT_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.heat = {
          value: tag,
          label: HEAT_OPTIONS[index].label,
        }
      } else {
        acc.heat = {} as ValueLabelPair
      }
      return acc
    } else if (
      (IS_ESTATE || IS_COMMERCIAL) &&
      !acc.energyClass &&
      ENERGY_CLASS_VALUES.includes(tag as IEnergyClassValue)
    ) {
      const index = ENERGY_CLASS_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.energyClass = {
          value: tag,
          label: ENERGY_CLASS_OPTIONS[index].label,
        }
      }
      return acc
    } else if (
      (IS_ESTATE || IS_COMMERCIAL) &&
      HEAT_TYPE_VALUES.includes(tag as IEstateThermalTypeValue)
    ) {
      if (!acc.heatTypes) acc.heatTypes = {} as ValueLabelPair

      const index = ESTATE_THERMAL_TYPE_OPTIONS.findIndex(
        (item) => item.value === tag
      )
      if (index !== -1) {
        acc.heatTypes[tag] = {
          value: tag,
          label: ESTATE_THERMAL_TYPE_OPTIONS[index].label,
        }
      }
      return acc
    } else if (
      (IS_ESTATE && ESTATE_EXTRAS_VALUES.includes(tag as IEstateExtrasValue)) ||
      (IS_COMMERCIAL &&
        COMMERCIAL_EXTRAS_VALUES.includes(tag as ICommercialExtrasValue)) ||
      (IS_LAND && LAND_EXTRAS_VALUES.includes(tag as ILandExtrasValue))
    ) {
      if (!acc.extras) acc.extras = {} as ValueLabelPair

      acc.extras[tag] = true
      return acc
    } else if (
      (IS_COMMERCIAL || IS_ESTATE) &&
      FLOOR_VALUES.includes(tag as IFloorValue)
    ) {
      if (!acc.floors) acc.floors = {} as ValueLabelPair
      const index = FLOOR_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.floors[tag] = {
          value: tag,
          label: FLOOR_OPTIONS[index].label,
          order: FLOOR_OPTIONS[index].order,
        }
      }
      return acc
    } else if (
      (IS_COMMERCIAL || IS_ESTATE) &&
      !acc.parking &&
      PARKING_VALUES.includes(tag as IParkingTypeValue)
    ) {
      const index = PARKING_TYPE_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.parking = {
          value: tag,
          label: PARKING_TYPE_OPTIONS[index].label,
        }
      } else {
        acc.parking = {} as ValueLabelPair
      }
      return acc
    } else if (
      (IS_COMMERCIAL || IS_ESTATE) &&
      !acc.garden &&
      GARDEN_VALUES.includes(tag as IGardenValue)
    ) {
      const index = GARDEN_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.garden = {
          value: tag,
          label: GARDEN_OPTIONS[index].label,
        }
      } else {
        acc.garden = {} as ValueLabelPair
      }
      return acc
    } else if (DOOR_VALUES.includes(tag as IDoorValue) && !acc.door) {
      const index = DOOR_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.door = {
          value: tag,
          label: DOOR_OPTIONS[index].label,
        }
      } else {
        acc.door = {} as ValueLabelPair
      }
      return acc
    } else if (
      (IS_COMMERCIAL || IS_ESTATE) &&
      !acc.frame &&
      FRAME_VALUES.includes(tag as IFrameValue)
    ) {
      const index = FRAME_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.frame = {
          value: tag,
          label: FRAME_OPTIONS[index].label,
        }
      } else {
        acc.frame = {} as ValueLabelPair
      }
      return acc
    } else if (
      !acc.window &&
      [...WINDOW_OPTIONS.map((option) => option.value)].includes(
        tag as IWindowTypeValue
      )
    ) {
      const AVAILABLE_VALUES = WINDOW_OPTIONS.map((option) => option.value)
      const index = AVAILABLE_VALUES.findIndex((value) => value === tag)
      if (index !== -1) {
        acc.window = {
          value: tag,
          label: WINDOW_OPTIONS[index].label,
        }
      }
      return acc
    } else if (!acc.access && ACCESS_VALUES.includes(tag as ILandAccessValue)) {
      const index = LAND_ACCESS_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.access = {
          value: tag,
          label: LAND_ACCESS_OPTIONS[index].label,
        }
      } else {
        acc.access = {} as ValueLabelPair
      }
      return acc
    } else if (
      !acc.direction &&
      DIRECTION_VALUES.includes(tag as IDirectionValue)
    ) {
      const index = DIRECTION_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.direction = {
          value: tag,
          label: DIRECTION_OPTIONS[index].label,
        }
      } else {
        acc.direction = {} as ValueLabelPair
      }
      return acc
    } else if (
      !acc.floorType &&
      FLOOR_TYPE_VALUES.includes(tag as IFloorTypeValue)
    ) {
      const index = FLOOR_TYPE_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.floorType = {
          value: tag,
          label: FLOOR_TYPE_OPTIONS[index].label,
        }
      } else {
        acc.floorType = {} as ValueLabelPair
      }
      return acc
    } else if (
      !acc.electricityType &&
      ELECTRICITY_TYPE_VALUES.includes(tag as IElectricityTypeValue)
    ) {
      const index = ELECTRICITY_TYPE_OPTIONS.findIndex(
        (item) => item.value === tag
      )
      if (index !== -1) {
        acc.electricityType = {
          value: tag,
          label: ELECTRICITY_TYPE_OPTIONS[index].label,
        }
      } else {
        acc.electricityType = {} as ValueLabelPair
      }
      return acc
    } else if (!acc.view && VIEW_VALUES.includes(tag as IViewValue)) {
      const index = VIEW_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.view = {
          value: tag,
          label: VIEW_OPTIONS[index].label,
        }
      } else {
        acc.view = {} as ValueLabelPair
      }
      return acc
    } else if (
      !acc.equipment &&
      EQUIPMENT_VALUES.includes(tag as IEquipmentValue)
    ) {
      const index = EQUIPMENT_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.equipment = {
          value: tag,
          label: EQUIPMENT_OPTIONS[index].label,
        }
      } else {
        acc.equipment = {} as ValueLabelPair
      }
      return acc
    } else if (
      !acc.landCondition &&
      LAND_CONDITION_VALUES.includes(tag as ILandConditionValue)
    ) {
      const index = LAND_CONDITION_OPTIONS.findIndex(
        (item) => item.value === tag
      )
      if (index !== -1) {
        acc.landCondition = {
          value: tag,
          label: LAND_CONDITION_OPTIONS[index].label,
        }
      } else {
        acc.landCondition = {} as ValueLabelPair
      }
      return acc
    } else if (
      (IS_ESTATE || IS_COMMERCIAL) &&
      POOL_VALUES.includes(tag as IPoolValue)
    ) {
      if (!acc.poolOptions) acc.poolOptions = {} as ValueLabelPair
      const index = POOL_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.poolOptions[tag] = {
          value: tag,
          label: POOL_OPTIONS[index].label,
        }
      }
      return acc
    } else if (!acc.usage && USAGE_VALUES.includes(tag as IUsageValue)) {
      const index = USAGE_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.usage = {
          value: tag,
          label: USAGE_OPTIONS[index].label,
        }
      } else {
        acc.usage = {} as ValueLabelPair
      }
      return acc
    } else if (
      !acc.cityPlan &&
      CITY_PLAN_VALUES.includes(tag as ILandCityPlanValue)
    ) {
      const index = CITY_PLAN_OPTIONS.findIndex((item) => item.value === tag)
      if (index !== -1) {
        acc.cityPlan = {
          value: tag,
          label: CITY_PLAN_OPTIONS[index].label,
        }
      } else {
        acc.cityPlan = {} as ValueLabelPair
      }
      return acc
    }
    return acc
  }, {} as MappedObject)
  return mappedTags
}
//boolean getters

//estate
export const getEstateElevator = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_EXTRAS#elevator',
    'yes',
    data,
    t
  )
export const getEstateAirCondition = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_EXTRAS#air_condition',
    'yes',
    data,
    t
  )
export const getEstateFireplace = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_EXTRAS#fireplace',
    'yes',
    data,
    t
  )
export const getEstateFurnished = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_EXTRAS#furnished',
    'yes',
    data,
    t
  )
export const getPets = (data, t) =>
  parseBooleanFieldParsed('extras.ESTATE_PROPERTY_EXTRAS#pets', 'yes', data, t)
export const getEstateFacade = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_EXTRAS#facade',
    'yes',
    data,
    t
  )
export const getEstateMaintained = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_EXTRAS#maintained',
    'yes',
    data,
    t
  )
export const getEstateDisabledAccess = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_EXTRAS#disabled_access',
    'yes',
    data,
    t
  )
export const getEstateCctv = (data, t) =>
  parseBooleanFieldParsed('extras.ESTATE_PROPERTY_EXTRAS#cctv', 'has', data, t)
export const getScreensSieves = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_EXTRAS#screens',
    'yes',
    data,
    t
  )
export const getEstateCorner = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_EXTRAS#corner',
    'yes',
    data,
    t
  )
export const getEstateUnderfloorHeat = (data: ParsedProperty, t: TFunction) => {
  const hasUnderfloorHeating =
    get(data?.parsedTags?.extras, 'ESTATE_PROPERTY_EXTRAS#underfloor_heat') ||
    get(
      data?.parsedTags?.extras,
      'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#underfloor_heat'
    )

  const noUnderfloorHeating =
    get(
      data?.parsedTags?.extras,
      'ESTATE_PROPERTY_EXTRAS#underfloor_heat_no'
    ) ||
    get(
      data?.parsedTags?.extras,
      'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#underfloor_heat_no'
    )
  let underfloorHeatTag
  if (hasUnderfloorHeating) {
    underfloorHeatTag = 'tagResponses.yes'
  } else if (noUnderfloorHeating) {
    underfloorHeatTag = 'tagResponses.no'
  }

  return (
    <>
      {underfloorHeatTag ? (
        <Typography
          component="div"
          fontWeight={'inherit'}
          whiteSpace="pre-wrap"
          fontSize="inherit"
        >
          {t(underfloorHeatTag)}
        </Typography>
      ) : (
        '-'
      )}
    </>
  )
}

//commercial
export const getCommercialElevator = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#elevator',
    'yes',
    data,
    t
  )

export const getCommercialGym = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#gym',
    'has',
    data,
    t
  )
export const getCommercialEventSpace = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#event_space',
    'has',
    data,
    t
  )
export const getSpa = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#spa',
    'has',
    data,
    t
  )
export const getRoofGarden = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#roof_garden',
    'has',
    data,
    t
  )
export const getFalseCeiling = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#false_ceiling',
    'yes',
    data,
    t
  )
export const getStructuredWiring = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#structured_wiring',
    'yes',
    data,
    t
  )
export const getInternalStairs = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#internal_stairs',
    'yes',
    data,
    t
  )
export const getSecurityBlinds = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#security_rolls',
    'has',
    data,
    t
  )
export const getFoodService = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#food_service',
    'yes',
    data,
    t
  )
export const getCargoRamp = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cargo_ramp',
    'yes',
    data,
    t
  )
export const getCommercialAirCondition = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#air_condition',
    'yes',
    data,
    t
  )
export const getCommercialFireplace = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#fireplace',
    'yes',
    data,
    t
  )
export const getCommercialFurnished = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#furnished',
    'yes',
    data,
    t
  )
export const getDoorman = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#doorman',
    'yes',
    data,
    t
  )
export const getCommercialFacade = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#facade',
    'yes',
    data,
    t
  )
export const getCommercialMaintained = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#maintained',
    'yes',
    data,
    t
  )
export const getCommercialDisabledAccess = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#disabled_access',
    'yes',
    data,
    t
  )
export const getFireSafety = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#fire_safety',
    'has',
    data,
    t
  )
export const getKitchen = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#kitchen',
    'has',
    data,
    t
  )
export const getCommercialCctv = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cctv',
    'has',
    data,
    t
  )
export const getCommercialAmphitheatrical = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#amphitheater',
    'yes',
    data,
    t
  )
export const getCommercialDrains = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#drains',
    'yes',
    data,
    t
  )
export const getCommercialCorner = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#corner',
    'yes',
    data,
    t
  )
export const getCarElevator = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#car_elevator',
    'yes',
    data,
    t
  )
export const getGuarding = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#guarding',
    'yes',
    data,
    t
  )
export const getCommercialWaterHeating = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_COMMERCIAL_EXTRAS#water_heating',
    'yes',
    data,
    t
  )

// land
export const getLandFacade = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#facade',
    'yes',
    data,
    t
  )
export const getCompensation = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#compensation',
    'yes',
    data,
    t
  )
export const getEnclosure = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#enclosure',
    'yes',
    data,
    t
  )
export const getWaterSupply = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#water_supply',
    'yes',
    data,
    t
  )
export const getΕlectrification = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#electrification',
    'yes',
    data,
    t
  )

export const getLandAmphitheatrical = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#amphitheater',
    'yes',
    data,
    t
  )
export const getLandDrains = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#drains',
    'yes',
    data,
    t
  )
export const getLandAgriculturalUse = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#agricultural_use',
    'yes',
    data,
    t
  )

export const getMarina = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#marina',
    'yes',
    data,
    t
  )

export const getLandBuildable = (data, t) =>
  parseBooleanFieldParsed(
    'extras.ESTATE_PROPERTY_LAND_TYPE_EXTRAS#buildable',
    'yes',
    data,
    t
  )

export const getView = (data, t) => parseView(data, t)

//single value getter
export const getEnergyClass = (data, t) =>
  parseSingleValueField('energyClass', data, t)
export const getCityPlan = (data, t) =>
  parseSingleValueField('cityPlan', data, t)
export const getAccess = (data, t) => parseSingleValueField('access', data, t)
export const getDirection = (data, t) =>
  parseSingleValueField('direction', data, t)
export const getFloorType = (data, t) =>
  parseSingleValueField('floorType', data, t)
export const getElectricityType = (data, t) =>
  parseSingleValueField('electricityType', data, t)
export const getEquipment = (data, t) => parseEquipment(data, t)
export const getLandCondition = (data, t) =>
  parseSingleValueField('landCondition', data, t)
export const getEstateCondition = (data, t) =>
  parseSingleValueField('condition', data, t)
export const getUsage = (data, t) => parseSingleValueField('usage', data, t)
// numeric getter
export const getRenovationYear = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_RENOVATION_YEAR', data, t)
export const getMaintenanceFees = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_MAINTENANCE_FEES', data, t)
export const getBedrooms = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_BEDROOMS', data, t)
export const getLivingRooms = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_LIVING_ROOMS', data, t)
export const getRooms = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_ROOMS', data, t)
export const getSpaces = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_SPACES', data, t)
export const getEntranceHeight = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_ENTRANCE_HEIGHT', data, t)
export const getMaxAllowedHeight = (data, t) =>
  parseNumericalField('ESTATE_LAND_PROPERTY_ALLOWED_HEIGHT', data, t)
export const getHeight = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_HEIGHT', data, t)
export const getEntrances = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_ENTRANCES', data, t)
export const getAuxiliarySpaces = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_AUXILARY_SPACES', data, t)
export const getRestaurants = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_RESTAURANTS', data, t)
export const getMaxBuildingSqr = (data, t) =>
  parseNumericalField('ESTATE_LAND_PROPERTY_BUILDING_SQR', data, t)
export const getNumberOfViews = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_VIEWS', data, t)
export const getIndependentSpaces = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_INDEPENDET_AREAS', data, t)
export const getOffices = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_OFFICES', data, t)
export const getCoverageFactor = (data, t) => {
  return data.coverageFactor
}
export const getBuildFactor = (data, t) => {
  return data.buildFactor
}
export const getNumberOfEntrances = (data, t) =>
  parseNumericalField('ESTATE_PROPERTY_ENTRANCES', data, t)

//intercom
