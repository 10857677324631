import React from 'react'
import { Grid, Grow, SvgIcon, Typography, Button } from '@mui/material'
import shallow from 'zustand/shallow'
import { useTranslation } from 'react-i18next'

import { useSearchStore } from 'store'
import { ReactComponent as MoreFiltersSVG } from 'assets/svg/more-filter.svg'

function ShowMoreControl() {
  const { t } = useTranslation()

  const [showMore, handleShowMore] = useSearchStore(
    (state) => [state.showMore, state.handleShowMore],
    shallow
  )

  const handleShowMoreFilters = () => {
    handleShowMore(true)
  }

  return (
    <>
      <Grow in={!showMore} unmountOnExit>
        <Grid item sx={{ flexGrow: 1 }}>
          <Button
            startIcon={
              <SvgIcon
                component={MoreFiltersSVG}
                inheritViewBox
                style={{ width: 35, height: 35 }}
              />
            }
            color="primary"
            variant="contained"
            sx={{
              boxShadow: 25,
              marginTop: (theme) => theme.spacing(2),
              height: { xs: '100%', md: 60 },
              width: { xs: '100%', md: 400 },
              borderRadius: 2.5,
              border: '1px solid',
              borderColor: 'common.grey',
              px: 6,
              py: 2,
              justifyContent: 'space-between',
              color: 'common.black',
              textTransform: 'none',
              transition: (theme) => theme.transitions.create('all'),
              '&:hover': {
                borderColor: 'common.black',
                boxShadow: 25,
                bgcolor: 'primary.main',
                '& p': {
                  color: 'white',
                },
              },

              '&:hover:active': {
                boxShadow: `10px 10px 1px -2px  black`,
              },
            }}
            onClick={handleShowMoreFilters}
          >
            <Typography fontSize={24} sx={{ fontWeight: 700 }}>
              {t('landingPage.control.label')}
            </Typography>
          </Button>
        </Grid>
      </Grow>
    </>
  )
}

export default ShowMoreControl
