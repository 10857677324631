import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { ReactComponent as BusinessPolicySvg } from 'assets/svg/emporiki_politiki.svg'
import InnerLayout from 'layouts/InnerLayout'
import usePageView from 'hooks/usePageView'

const PolicyPage: React.FC = () => {
  usePageView()
  return (
    <InnerLayout>
      <Box sx={{ my: 6, px: { xs: 0.5, sm: 2, md: 2, lg: 4, xl: 6 } }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" sx={{ fontWeight: 600, mb: 4 }}>
              Εμπορική Πολιτική
            </Typography>
            <Typography paragraph>
              Σας καλωσορίζουμε στο Spourgiti, το νέο portal ακινήτων για την
              Ελλάδα.
            </Typography>
            <Typography paragraph>
              Με την πολυετή εμπειρία μας στον χώρο των media, αντιλαμβανόμενοι
              την ανάγκη του κοινού για ελεύθερη ενημέρωση αλλά και τις
              δυσκολίες και τις προκλήσεις που αντιμετωπίζει η μεσιτική αγορά
              λόγω του δυσμενούς κλίματος της ελληνικής οικονομίας τα τελευταία
              χρόνια, δημιουργήσαμε το Spourgiti, μια μοντέρνα ιστοσελίδα που
              σκοπεύει να γίνει για το αναγνωστικό κοινό σημείο αναφοράς στην
              ανεύρεση ακινήτων.
            </Typography>
            <Typography paragraph>
              Το Spourgiti είναι ένα νέο και καινοτόμο προϊόν για το ελληνικό
              ίντερνετ. Με την εικαστική και τεχνολογική του υπεροχή θα
              προσφέρει την απάντηση στο τεράστιο ενδιαφέρον του κοινού να βρει
              το ακίνητο που αναζητά. Στόχος, όραμα και δέσμευσή μας είναι να
              γίνουμε συνεργάτες με τον Έλληνα μεσίτη. Οι αγγελίες, τα προφίλ
              των μεσιτών και η ταξινόμηση των αγγελιών δεν αποτελούν, ούτε και
              θα αποτελέσουν στο μέλλον, εμπορικό προϊόν για εμάς. Αποτελούν το
              μοναδικό περιεχόμενο και τον κύριο λόγο ύπαρξης της ίδιας της
              ιστοσελίδας.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={true}>
            <Box
              component={BusinessPolicySvg}
              sx={{
                display: 'block',
                mx: 'auto',
                width: '100%',
                maxWidth: 300,
                height: '100%',
                maxHeight: 300,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </InnerLayout>
  )
}

export default PolicyPage
