import React from 'react'
import { Typography, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

interface NotFoundProps {
  title?: string
  content?: string
}
const NotFound = (props: NotFoundProps) => {
  const { title, content } = props
  const { t } = useTranslation()

  return (
    <Stack alignItems="flex-start" sx={{ mt: 4 }}>
      <Typography sx={{ fontSize: '2.4rem', mb: 4 }}>
        {title ? title : t('notFoundPage.title')}
      </Typography>
      <Typography sx={{ fontSize: '1rem', mb: 4 }}>
        {content ? content : t('notFoundPage.content')}
      </Typography>
    </Stack>
  )
}

export default NotFound

NotFound.propTypes = {}
