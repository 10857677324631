import React from 'react'
import {
  Stack,
  Typography,
  ButtonBase,
  CircularProgress,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { AddCircle, CheckCircle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSearchStore } from 'store'
import { useQueryClient } from 'react-query'
import UserFavoritesService from 'api/services/UserFavoritesService'
import AuthService from 'services/AuthService'
import ListsPopUp from 'components/Search/Results/FeatureCard/Lists/ListsPopUp'
import CancelIcon from '@mui/icons-material/Cancel'
import { AdCategoryIdToListCategoryMap } from 'helpers/constants'
import { GetUserFavoritesListDTO } from 'api/services/UserFavoritesService/types'
import TimeOutDialog from 'components/Search/Results/FeatureCard/TimeOutDialog'

export interface Props {
  mode?: string
  propertyAd: ParsedProperty
}

function AddToFavorite(props: Props) {
  const { propertyAd } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [openListsPopup, setOpenListsPopup] = React.useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const { mode } = props

  const locale = useSearchStore((state) => state.locale)

  const queryClient = useQueryClient()

  const matchedCategory =
    AdCategoryIdToListCategoryMap.get(propertyAd?.adCategoryId) || null

  const [textFieldText, setTextFieldText] = React.useState('')

  const lists = UserFavoritesService.hooks.useLists(
    AdCategoryIdToListCategoryMap.get(propertyAd.adCategoryId) || null,
    { pageSize: 100, pageNumber: 0 },
    {
      retryOnMount: false,
      enabled: AuthService.isLoggedIn() && !!matchedCategory,
      onError: (err) => {
        enqueueSnackbar(t('error.unspecific'), {
          variant: 'error',
        })
      },
    }
  )

  const createUserFavoritesListsMutation =
    UserFavoritesService.hooks.useCreateUserFavoritesList({
      onSuccess: async (newList) => {
        const newFavoritePropertyAd =
          await addToUserFavoritesListMutation.mutateAsync({
            listId: newList.id,
            propertyAdAlternativeId: propertyAd?.id?.slice(3),
          })

        if (matchedCategory && newFavoritePropertyAd) {
          // add pAd to list
          newList?.userFavorites?.push({
            id: newFavoritePropertyAd.id,
            entityId: newFavoritePropertyAd?.alternativeId
              ? newFavoritePropertyAd?.alternativeId
              : propertyAd?.id?.slice(3),
            firstImageUrl: null,
          })
          // increase favs count
          newList.userFavoritesCount = 1

          queryClient.setQueryData(
            [...UserFavoritesService.keys.QueryKeys.search, matchedCategory],
            (prev: GetUserFavoritesListDTO | '') => {
              if (prev) {
                prev.userFavoritesList = [
                  ...(prev?.userFavoritesList ?? []),
                  newList,
                ]
                prev.count = prev.count + 1
                return prev
              } else {
                return { userFavoritesList: [newList], count: 1 }
              }
            }
          )
          setTextFieldText('')
          enqueueSnackbar(t('detailPage.addFavorite.action.success'), {
            variant: 'success',
          })
        }
      },
      onError: () => {
        enqueueSnackbar(t('error.unspecific'), {
          variant: 'error',
        })
      },
    })

  const addToUserFavoritesListMutation =
    UserFavoritesService.hooks.useAddToUserFavoritesList({
      onSuccess: () => {},
      onError: () => {
        enqueueSnackbar(t('error.unspecific'), {
          variant: 'error',
        })
      },
    })

  const IS_ICON_MODE = mode === 'icon'

  const FAVORITE_ENTITY_IDS =
    lists.data?.userFavoritesList?.reduce((acc, item) => {
      return [
        ...acc,
        ...(item?.userFavorites?.map((fav) => `${locale}:${fav.entityId}`) ??
          []),
      ]
    }, []) ?? []

  const IS_FAVORITE = FAVORITE_ENTITY_IDS.includes(propertyAd.id)

  const handleOpenListsPopup = () => {
    setOpenListsPopup(true)
  }
  const handleCloseListsPopup = () => {
    setOpenListsPopup(false)
    setTextFieldText('')
  }

  const [openRedirect, setOpenRedirect] = React.useState(false)

  const handleOpenRedirect = () => {
    setOpenRedirect(true)
  }
  const handleCloseRedirect = () => {
    setOpenRedirect(false)
  }

  const handleIconFavoriteClick = () => {
    if (!AuthService.isLoggedIn()) {
      handleOpenRedirect()
    } else {
      if (openListsPopup) {
        return
      } else {
        handleOpenListsPopup()
      }
    }
  }

  const handleRedirect = () => {
    if (process.env.REACT_APP_ENV === 'development') {
      navigate(`/login?from=${location.pathname}${location.search}`)
    } else {
      window.location.href = `${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}?from=${location.pathname}${location.search}`
    }
  }

  const handleClickAway = () => {
    if (openListsPopup) {
      if (textFieldText && matchedCategory) {
        createUserFavoritesListsMutation.mutate({
          name: textFieldText,
          listCategory: matchedCategory,
        })
      }
      handleCloseListsPopup()
    }
  }

  return (
    <>
      {openRedirect ? (
        <TimeOutDialog
          open={openRedirect}
          onClose={handleCloseRedirect}
          onTimeout={handleRedirect}
        />
      ) : null}
      <Dialog
        open={openListsPopup}
        onBackdropClick={handleClickAway}
        maxWidth={'xs'}
        PaperProps={{ sx: { maxWidth: '328px', borderRadius: '20px' } }}
      >
        <DialogTitle>
          <Typography
            align="center"
            sx={{ fontSize: 15, fontWeight: 700, whiteSpace: 'pre-line' }}
          >
            {t('detailPage.addFavorite.addToList')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <ListsPopUp
            propertyAd={propertyAd}
            propertyCategory={matchedCategory}
            propertyAlternativeId={propertyAd?.id?.slice(3)}
            onClose={handleClickAway}
            onTextChange={(text) => {
              setTextFieldText(text)
            }}
          />
          <IconButton
            onClick={handleCloseListsPopup}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <CancelIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
      <Stack
        component={ButtonBase}
        direction={IS_ICON_MODE ? 'column' : 'row'}
        alignItems="center"
        justifyContent="center"
        sx={{
          margin: '0 auto',
          bgcolor: 'common.white',
          border: IS_ICON_MODE ? 'none' : `1px solid lightgrey`,
          boxShadow: IS_ICON_MODE ? 'none' : 25,
          borderRadius: 1,
          py: 0.5,
          px: 1,
          cursor: 'pointer',
          width: '100%',
        }}
        onClick={handleIconFavoriteClick}
      >
        {IS_ICON_MODE ? (
          <Typography
            sx={{ fontSize: 13, fontWeight: 500, whiteSpace: 'pre-line' }}
          >
            {t('detailPage.addFavorite.label')}
          </Typography>
        ) : null}
        {lists.isLoading ? (
          <Box
            component={CircularProgress}
            sx={{
              zIndex: 101,
              width: { xs: 45, sm: 20 },
              height: { xs: 45, sm: 20 },
            }}
          />
        ) : (
          <Box
            onClick={handleIconFavoriteClick}
            sx={{
              width: { xs: 45, md: 20 },
              height: { xs: 45, sm: 20 },
              cursor: 'pointer',
              zIndex: 101,
            }}
          >
            {IS_FAVORITE ? (
              <CheckCircle
                color="success"
                sx={{ width: { xs: 45, md: 20 }, height: { xs: 45, sm: 20 } }}
              />
            ) : (
              <AddCircle
                htmlColor="#FFBC2D"
                sx={{
                  width: { xs: '45px', sm: 'auto' },
                  height: { xs: '45px', sm: 'auto' },
                }}
              />
            )}
          </Box>
        )}
        {IS_ICON_MODE ? null : (
          <Typography sx={{ fontSize: 15, ml: 2, fontWeight: 500 }}>
            {t(`detailPage.addFavorite.label`)}
          </Typography>
        )}
      </Stack>
    </>
  )
}

export default AddToFavorite
