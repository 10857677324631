import React from 'react'
import HomeSVG from 'assets/svg/icons/home-icon.svg'
import { Box } from '@mui/material'
import { EstateAgent } from 'types'

interface Props {
  data: EstateAgent
}
function Logo(props: Props) {
  if (props.data?.logoURL) {
    return (
      <Box
        component="img"
        sx={{ maxHeight: 'auto', mx: 'auto', width: '100%' }}
        src={props.data?.logoURL}
      />
    )
  } else {
    return (
      <Box
        component="img"
        sx={{ maxHeight: 'auto', mx: 'auto', width: '100%' }}
        src={HomeSVG}
      />
    )
  }
}

export default Logo
