import React from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import {
  Grid,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Link,
  Grow,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import { useTranslation } from 'react-i18next'
import EmailIcon from '@mui/icons-material/Email'

import InnerLayout from 'layouts/InnerLayout'
import Details from './Details'
import Logo from './Logo'
import SocialMedia from './SocialMedia'
import PhoneNumber from './PhoneNumber'
import Info from './Info'
import NotFound from 'components/NotFound'
import Banner from './Banner'
import EstateCard from './EstateCard'
import PropertyAdService from 'api/services/PropertyAd'
import AgentService from 'api/services/Agent'
import { useDocumentTitle } from 'hooks/useDocumentTitle'
import Email from './Email'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import { chain } from 'lodash'
import { calculatePropertyCategory } from 'components/Detail/helpers'
import { useSearchStore } from 'store'
import usePageView from 'hooks/usePageView'
import NumberFormattingService from 'api/services/NumberFormatting'
import ErrorAlert from 'components/PublicAgents/ErrorAlert'

export function calculateInterest(adCategoryId: number): string {
  const INTEREST_BUY = [1, 2, 3]
  const INTEREST_LOAN = [4, 5, 6]

  const IS_BUY = INTEREST_BUY.includes(adCategoryId)
  const IS_LOAN = INTEREST_LOAN.includes(adCategoryId)
  if (IS_BUY) return 'buy'
  else if (IS_LOAN) return 'rent'
  else return 'unreachable'
}

function PublicAgent() {
  const { sendPageView } = usePageView({ runOnMount: false })
  const [items, setItems] = React.useState<ParsedProperty[]>([])
  const [count, setCount] = React.useState(0)

  const { id } = useParams()

  const { t } = useTranslation()

  const [, setLocalError] = React.useState<boolean>(false)

  const [notFound, setNotFound] = React.useState<boolean>(false)

  const [estateNumber, setEstateNumber] = React.useState<number>()

  const { addTitle } = useDocumentTitle()

  const getSearchAndCount = PropertyAdService.hooks.useCountAndSearchAgent({
    onSuccess: (data) => {
      if (data?.count) {
        setCount(data?.count)
      }

      if (data?.items) {
        setItems(data?.items)
      }
    },
  })
  const getAgent = AgentService.hooks.useSingleAgent(id ?? null, {
    enabled: !!id,
    cacheTime: 0,
    onSuccess: (data) => {
      if (data) {
        addTitle(data?.companyName)

        getSearchAndCount.mutate(
          {
            agentId: id?.slice(3),
            locale: 'el',
          },
          {
            onSuccess: (data) => {
              setEstateNumber(data.count)
            },
          }
        )
      }
      sendPageView()
      if (!data) {
        setNotFound(true)
      }
    },
    onError: (err) => {
      if (err?.response?.status === 404) {
        setNotFound(true)
      } else {
        setLocalError(true)
      }
    },
  })

  const resetForm = useSearchStore((state) => state.resetForm)

  React.useEffect(() => {
    resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InnerLayout>
      <Grow in={notFound} unmountOnExit>
        <div>
          <NotFound />
        </div>
      </Grow>
      {getAgent.isError ? <ErrorAlert /> : null}
      {getAgent.isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
              maxWidth: (theme) => theme.breakpoints.values.lg,
              mb: 5,
            }}
          >
            <Grid item container xs={12} justifyContent="center">
              <CircularProgress />
            </Grid>
          </Grid>
        </Box>
      ) : null}
      {getAgent.isSuccess && !!getAgent.data ? (
        <Box display="flex" justifyContent="center">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            columnSpacing={4}
            sx={{
              maxWidth: (theme) => theme.breakpoints.values.lg,
              mb: 5,
            }}
          >
            {getAgent.data?.bannerURL ? (
              <Grid item xs={12} sx={{ mt: 5 }}>
                <Banner data={getAgent.data} />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={4}>
              <Grid
                item
                container
                direction="row"
                alignItems="baseline"
                rowSpacing={3}
                sx={{
                  border: 1,
                  borderColor: grey[300],
                  boxShadow: 25,
                  borderRadius: 2.5,
                  mt: 5,
                  p: 4,
                }}
              >
                <Logo data={getAgent.data} />
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="baseline"
                rowSpacing={3}
                sx={{
                  border: 1,
                  borderColor: grey[300],
                  boxShadow: 25,
                  borderRadius: 2.5,
                  mt: 5,
                  p: 4,
                  order: { md: 2 },
                  align: { sx: 'center', md: 'left' },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                  <PhoneInTalkOutlinedIcon
                    color="primary"
                    sx={{ fontSize: 35 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{ my: 'auto', textAlign: { xs: 'center', md: 'left' } }}
                >
                  <PhoneNumber data={getAgent.data} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                  <PinDropOutlinedIcon color="primary" sx={{ fontSize: 35 }} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{ my: 'auto', textAlign: { xs: 'center', md: 'left' } }}
                >
                  <Info data={getAgent.data} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                  <EmailIcon color="primary" sx={{ fontSize: 35 }} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    my: 'auto',
                    textAlign: { xs: 'center', md: 'left' },
                    wordWrap: 'break-word',
                  }}
                >
                  <Email data={getAgent.data} />
                </Grid>
                <SocialMedia data={getAgent.data} />
              </Grid>
              <Details data={getAgent.data} />

              <Grid
                item
                sx={{
                  border: 1,
                  borderColor: grey[300],
                  boxShadow: 25,
                  borderRadius: 2.5,
                  mt: 5,
                  p: 4,
                }}
              >
                <Grid container direction="row" rowSpacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {t('realtorProfilePage.latestEntries.title')}
                    </Typography>
                  </Grid>

                  {getSearchAndCount.isLoading ? (
                    <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{
                          maxWidth: (theme) => theme.breakpoints.values.lg,
                          mb: 5,
                        }}
                      >
                        <Grid item container xs={12} justifyContent="center">
                          <CircularProgress />
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                  {getSearchAndCount.isError ? <ErrorAlert /> : null}
                  {getSearchAndCount.isSuccess ? (
                    <>
                      {estateNumber !== 0 ? (
                        <>
                          <Grid item xs={12}>
                            <Grid item container spacing={2} xs={12}>
                              {items.slice(0, 5).map((item) => {
                                return (
                                  <React.Fragment key={item.alternativeId}>
                                    <EstateCard data={item} />
                                  </React.Fragment>
                                )
                              })}
                            </Grid>
                            <Divider sx={{ mt: 3 }} />
                            <Link
                              color="inherit"
                              component={RouterLink}
                              underline="none"
                              onClick={resetForm}
                              to={`/search?agentId=${id?.slice(
                                3
                              )}&category=${calculatePropertyCategory(
                                chain(items)
                                  .groupBy('adCategoryId')
                                  .values()
                                  .reduce((acc: any[], item: any[]) => {
                                    if (item?.length > acc?.length) {
                                      return item
                                    } else return acc
                                  })
                                  .head()
                                  .value()?.adCategoryId
                              )}&interest=${calculateInterest(
                                chain(items)
                                  .groupBy('adCategoryId')
                                  .values()
                                  .reduce((acc: any[], item: any[]) => {
                                    if (item?.length > acc?.length) {
                                      return item
                                    } else return acc
                                  })
                                  .head()
                                  .value()?.adCategoryId
                              )}`}
                            >
                              <Typography
                                variant="h6"
                                color="primary"
                                align="center"
                                sx={{ pt: 3 }}
                              >
                                {`${t('agentDetailPage.seeMore')}`}
                                {`${' '}`}
                                {NumberFormattingService.toRoundNumber(
                                  count,
                                  0
                                )}
                              </Typography>
                            </Link>
                          </Grid>
                        </>
                      ) : (
                        <Grid item>
                          <Typography>
                            {t('realtorProfilePage.latestEntries.fail')}
                          </Typography>
                        </Grid>
                      )}
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Details view="small" data={getAgent.data} />
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </InnerLayout>
  )
}

export default PublicAgent
