function toRoundNumber(number: number, digits = 2) {
  const numberFormatted = new Intl.NumberFormat('el-GR', {
    maximumFractionDigits: digits,
  }).format(number)
  return numberFormatted
}

export const NumberFormattingService = {
  toRoundNumber,
}
export default NumberFormattingService
