import React from 'react'
import {
  Box,
  Stack,
  CircularProgress,
  Tooltip,
  IconButton,
  useMediaQuery,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import DialogShare from 'components/Detail/DialogShare'
import UserFavoritesService from 'api/services/UserFavoritesService'
import AuthService from 'services/AuthService'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import { useSearchStore } from 'store'
import CancelIcon from '@mui/icons-material/Cancel'
import Share from 'assets/svg/icons/share.svg'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useSnackbar } from 'notistack'
import { CheckCircle } from '@mui/icons-material'
import { AdCategoryIdToListCategoryMap } from 'helpers/constants'
import { theme } from 'theme'

interface IDialogProps {
  sharePath: string
  shareLabel: string
  title: string
}

interface Props {
  children?: React.ReactNode
  hideBorder: boolean
  DialogProps: IDialogProps
  property: ParsedProperty
  areListsVisible: boolean
  handleIconFavoriteClick: () => void
}

function ImageWrapper(props: Props) {
  const {
    children,
    DialogProps,
    property,
    areListsVisible,
    handleIconFavoriteClick,
  } = props

  const { t } = useTranslation()

  const locale = useSearchStore((state) => state.locale)

  const { enqueueSnackbar } = useSnackbar()

  const [openShareDialog, setOpenShareDialog] = React.useState(false)

  const handleOpenShareDialog = () => {
    setOpenShareDialog(true)
  }
  const handleCloseShareDialog = () => {
    setOpenShareDialog(false)
  }

  const matchedCategory =
    AdCategoryIdToListCategoryMap.get(property.adCategoryId) || null

  const lists = UserFavoritesService.hooks.useLists(
    AdCategoryIdToListCategoryMap.get(property.adCategoryId) || null,
    { pageSize: 100, pageNumber: 0 },
    {
      retryOnMount: false,
      enabled:
        AuthService.isLoggedIn() && !!matchedCategory && !AuthService.isAgent(),
      onError: () => {
        enqueueSnackbar(t('error.unspecific'), {
          variant: 'error',
        })
      },
    }
  )

  const handleClick = () => {
    handleIconFavoriteClick()
  }

  const FAVORITE_ENTITY_IDS =
    lists.data?.userFavoritesList?.reduce((acc, item) => {
      return [
        ...acc,
        ...(item?.userFavorites?.map((fav) => `${locale}:${fav.entityId}`) ??
          []),
      ]
    }, []) ?? []

  const IS_FAVORITE = FAVORITE_ENTITY_IDS.includes(property.id)
  const IS_AGENT = AuthService.isLoggedIn() && AuthService.isAgent()
  const matchesXS = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ position: 'absolute', top: '-17.5px', right: 10 }}
        >
          {IS_AGENT ? null : (
            <Box>
              {lists.isLoading ? (
                <Box
                  sx={{
                    zIndex: 101,
                    width: {
                      xs: 45,
                      sm: 35,
                    },
                    height: { xs: 45, sm: 35 },
                  }}
                >
                  <CircularProgress size="31px" />
                </Box>
              ) : (
                <Tooltip
                  title={`${t('searchPage.card.actions.addFavorite')}`}
                  placement="top"
                >
                  <IconButton
                    onClick={handleClick}
                    sx={{
                      width: { xs: 45, sm: 35 },
                      height: { xs: 45, sm: 35 },
                      cursor: 'pointer',
                      zIndex: 102,
                      color: 'primary.main',
                      bgcolor: 'common.white',
                      '&:hover': {
                        backgroundColor: 'common.white',
                      },
                    }}
                  >
                    {!areListsVisible && IS_FAVORITE ? (
                      <CheckCircle
                        color="success"
                        fontSize="large"
                        sx={{ fontSize: matchesXS ? '45px' : null }}
                      />
                    ) : !areListsVisible && !IS_FAVORITE ? (
                      <AddCircleIcon
                        fontSize="large"
                        sx={{ fontSize: matchesXS ? '45px' : null }}
                      />
                    ) : (
                      <CancelIcon
                        fontSize="large"
                        sx={{ fontSize: matchesXS ? '45px' : null }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
          <Tooltip
            title={`${t('searchPage.card.actions.share')}`}
            placement="top"
          >
            <Box
              onClick={handleOpenShareDialog}
              component="img"
              src={Share}
              sx={{
                width: { xs: 45, sm: 35 },
                height: { xs: 45, sm: 35 },
                zIndex: 102,
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </Stack>
        {children}
      </Box>
      <DialogShare
        open={openShareDialog}
        onClose={handleCloseShareDialog}
        {...DialogProps}
      />
    </>
  )
}

export default ImageWrapper
