import React from 'react'
import { styled, lighten } from '@mui/material/styles'
import {
  Typography,
  IconButton,
  List,
  MenuItemProps,
  MenuItem,
  Zoom,
  Box,
  ListProps,
  SxProps,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { Area, ViewVariant } from 'types'

interface SearchMenuItemProps extends MenuItemProps {
  variant?: ViewVariant
}

const SearchMenuItem = styled(MenuItem)<SearchMenuItemProps>(
  ({ variant, theme }) => ({
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.common.grey}`,
    zIndex: 103,
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.3)',

    '&:hover': {
      '&.Mui-selected': {
        backgroundColor: lighten(theme.palette.common.grey, 0.8),
        fontWeight: 900,
        color: theme.palette.text.primary,
      },
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      fontWeight: 900,
      color: theme.palette.text.primary,
    },
    '&:hover p div': {
      color: 'rgba(0, 0, 0, 0.8)',
    },
    ...(variant === 'inner' && {
      borderBottom: `1px solid ${theme.palette.common.black}`,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        fontWeight: 900,
        color: theme.palette.text.primary,
      },
      '&:hover': {
        '&.Mui-selected': {
          backgroundColor: lighten(theme.palette.primary.main, 0.1),
          fontWeight: 900,
          color: theme.palette.text.primary,
        },
      },
    }),
  })
)

interface SearchListProps extends ListProps {
  variant?: ViewVariant
  resultsProps?: SxProps
  width?: number
}

const SearchList = styled(List, {
  shouldForwardProp: (prop) => prop !== 'resultsProps' && prop !== 'isLoading',
})<SearchListProps>(({ variant, width, theme }) => ({
  zIndex: 102,
  border: '2px solid white',
  backgroundColor: 'white',
  maxHeight: 250,
  overflowX: 'auto',
  overflowY: 'auto',
  ...(width && { width: width }),
  '& .MuiListItem-root': {
    '&:last-child': {
      border: 'none',
    },
  },
  ...(variant === 'inner' && {
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
  }),
}))

interface Props {
  variant?: ViewVariant
  options: Area[]
  onChange: (area: Area) => void
  getOptionLabel: (area: Area) => string | React.ReactNode
  getOptionSelected: (area: Area) => boolean
  onCloseResultsMenu?: () => void
  resultsProps?: SxProps
  width?: number
  open: boolean
  isLoading?: boolean
  wrapperSx?: SxProps
}

function SearchMenu(props: Props) {
  const { t } = useTranslation()

  const handleKeyPress = (e, index, option) => {
    if (e.key === 'Enter') {
      onChange(option)
    }
  }

  const {
    options = [],
    onChange,
    getOptionLabel,
    getOptionSelected,
    open = false,
    onCloseResultsMenu,
    variant,
    width,
    wrapperSx,
    ...rest
  } = props

  const renderOptions = () => {
    if (!options.length) {
      return (
        <Typography
          align="center"
          sx={{ width: '100%' }}
          color={variant === 'inner' ? 'textPrimary' : 'textSecondary'}
          noWrap
        >
          {t('landingPage.where.noResults')}
        </Typography>
      )
    } else {
      return options.map((option, index) => {
        return (
          <SearchMenuItem
            role="option"
            tabIndex={0}
            key={option?.fullPath ?? index}
            selected={getOptionSelected(option)}
            onClick={() => onChange(option)}
            onKeyDown={(e) => handleKeyPress(e, index, option)}
            variant={variant}
          >
            <Typography variant="inherit" noWrap>
              {getOptionLabel(option)}
            </Typography>
          </SearchMenuItem>
        )
      })
    }
  }

  return (
    <Zoom in={open} unmountOnExit>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 102,
          backgroundColor: (theme) =>
            variant === 'inner' ? theme.palette.primary.main : 'common.white',
          padding: (theme) => theme.spacing(0.5, 0),
          width: 'fit-content',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          display: 'flex',
          flexFlow: 'column',
          ...wrapperSx,
        }}
      >
        <IconButton
          onClick={onCloseResultsMenu}
          sx={{
            alignSelf: 'flex-end',
            p: 0,
            my: 1,
            mr: 1,
          }}
        >
          <Close fontSize="medium" />
        </IconButton>
        <SearchList
          role="listbox"
          tabIndex={0}
          variant={variant}
          width={width}
          {...rest}
        >
          {renderOptions()}
        </SearchList>
      </Box>
    </Zoom>
  )
}

export default SearchMenu
