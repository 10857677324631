import React from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Grid, Typography, Link, TypographyProps } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { getPropertyTypeParsed } from './helpers'
import SeparatorPNG from 'assets/img/seperator.png'
import { useRecentStore } from 'store'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import NumberFormattingService from 'api/services/NumberFormatting'

RecentList.propTypes = {}

const StyledTypography = styled((props) => (
  <Typography
    component="span"
    display="block"
    fontWeight={600}
    color="textPrimary"
    {...props}
  />
))<TypographyProps>(() => ({
  fontSize: 17,
}))

function parserDataArea(area) {
  const splitted = area.split('/')
  if (splitted.length === 1) {
    return `${splitted[0]}`
  }
  if (splitted.length > 1) {
    const firstAreaName = splitted[splitted.length - 1]
    const otherAreaName = splitted
      .slice(0, splitted.length - 1)
      .reduce((acc, item, index, array) => {
        acc += `${item.trim()}`
        if (index !== array.length - 1) {
          acc += `, `
        }
        return acc
      }, '')
    return `${firstAreaName}  (${otherAreaName})`
  }
}

function RecentList({ limit = 4 }) {
  const { t } = useTranslation()
  const { adId } = useParams()

  const theme = useTheme()

  const getInterestType = (adCategoryId: number) => {
    const BUY = [1, 2, 3]
    const LOAN = [4, 5, 6]
    const IS_BUY = BUY.includes(adCategoryId)
    if (IS_BUY) return t('detailPage.interest.buy')
    else if (LOAN) return t('detailPage.interest.loan')
  }

  const recentList = useRecentStore(
    React.useCallback(
      (state) =>
        state.recentList
          .filter((item) => item.id !== adId)
          .reverse()
          .slice(0, limit),
      [adId, limit]
    )
  )

  return (
    <Box
      sx={{
        boxShadow: 25,
        border: `1px solid grey`,
        borderRadius: 2.5,
        p: 2,
      }}
    >
      <Grid container justifyContent={'center'} alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Typography
            align="center"
            color="primary"
            sx={{ fontWeight: 700, fontSize: 28 }}
          >
            {t('detailPage.recent.label')}
          </Typography>
        </Grid>
        <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box component="img" src={SeparatorPNG} />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              [theme.breakpoints.down('lg')]: {
                gridTemplateColumns: '1fr',
              },
              gap: 2,
            }}
          >
            {recentList.map((item: ParsedProperty) => (
              <Box key={item.id} sx={{ borderBottom: '1px solid black' }}>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to={`/property-ads/${item?.id}`}
                  underline="none"
                >
                  <StyledTypography
                    align="center"
                    sx={{ wordBreak: 'break-word' }}
                  >
                    {item?.searchArea ? parserDataArea(item?.searchArea) : ''}
                  </StyledTypography>
                  <StyledTypography align="center" fontWeight={400}>
                    <Box display="inline">
                      <Trans
                        i18nKey="detailPage.squareMeters"
                        values={{
                          value: item?.squareMeters
                            ? NumberFormattingService.toRoundNumber(
                                item?.squareMeters
                              )
                            : '-',
                        }}
                      />
                    </Box>

                    {`, `}

                    <Box display="inline">
                      {t('searchPage.salesPrice', {
                        price: item?.salesPrice
                          ? NumberFormattingService.toRoundNumber(
                              item?.salesPrice,
                              0
                            )
                          : '-',
                      })}
                    </Box>
                  </StyledTypography>
                  <StyledTypography fontWeight={400} align="center">
                    {t(getPropertyTypeParsed(item)?.label ?? '')}
                    {` ${t('landingPage.interestType.resultsLabel')} `}
                    {getInterestType(item?.adCategoryId)}
                  </StyledTypography>
                </Link>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RecentList
