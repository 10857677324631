import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Tabs, { TabsProps } from '@mui/material/Tabs'

interface SearchTabsProps extends TabsProps {
  cVariant?: string
}

const SearchTabs = styled(
  (props: SearchTabsProps) => (
    <Tabs
      scrollButtons="auto"
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
        style: { transition: 'none' },
      }}
      {...props}
    />
  ),
  { shouldForwardProp: (prop) => prop !== 'cVariant' }
)<SearchTabsProps>(({ cVariant, theme }) => ({
  borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.16)}`,
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 8,
    [theme.breakpoints.down('lg')]: {
      height: 6,
    },
    [theme.breakpoints.down('md')]: {
      height: 5,
    },
    ...(cVariant === 'inner' && {
      height: 5,
    }),
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: theme.palette.common.black,
  },
}))

export default SearchTabs
