/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Zoom,
  Grid,
  CircularProgress,
  Box,
  Button,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import Page from './Page'
import SaveSearch from './InfoCopy'
import { useSearchStore } from 'store'

interface IItems {
  pages: any[]
  pageParams: unknown[]
}
interface IResultsProps {
  onRefetch: () => void
  count: number | null
  isFetchingNextPage: boolean
  isFetchingPreviousPage: boolean
  isFetching: boolean
  isLoading: boolean
  isSuccess: boolean
  hasNextPage?: boolean
  fetchPreviousPage: () => void
  hasPreviousPage?: boolean
  items?: IItems
  onChangePage: (e: any) => Promise<void>
  areas: any[]
  agentId?: string | null
  pageLimit: number
}

const Results = React.forwardRef(function Results(
  props: IResultsProps,
  ref: React.RefObject<HTMLSpanElement>
) {
  const { t } = useTranslation()
  const {
    onRefetch,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isFetching,
    isLoading,
    isSuccess,
    count,
    hasNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    items,
    onChangePage,
    agentId,
    pageLimit,
  } = props

  const [setPage, statePage, areas] = useSearchStore((state) => [
    state.setPage,
    state.page,
    state.areas,
  ])

  if (isLoading) {
    return (
      <Typography align="center" sx={{ mt: 5 }}>
        <CircularProgress color="primary" />
      </Typography>
    )
  }
  if (!areas?.length && !agentId && !isLoading) {
    return (
      <Typography
        align="center"
        color={'textSecondary'}
        fontWeight={700}
        fontSize={20}
        sx={{ mt: 5 }}
      >
        {t('searchPage.noAreaSelected')}
      </Typography>
    )
  }
  if (isSuccess && !items) {
    return null
  }

  if (isSuccess && count === 0) {
    return (
      <Zoom in={true}>
        <Box sx={{ px: { xs: 0, md: 2 } }}>
          <SaveSearch />

          <Typography
            align="center"
            fontWeight={700}
            fontSize={20}
            sx={{ mt: 5 }}
          >
            {t('searchPage.noResults')}
          </Typography>
        </Box>
      </Zoom>
    )
  }

  const handleClickFetchPrev = () => {
    setPage(statePage - 1)
    fetchPreviousPage()
  }

  return (
    <Zoom in={isSuccess}>
      <div>
        {hasPreviousPage ? (
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                onClick={handleClickFetchPrev}
                disabled={!hasPreviousPage || isFetchingPreviousPage}
                sx={{
                  textTransform: 'none',
                  fontSize: {
                    xs: 16,
                    sm: 18,
                    md: 22,
                  },
                }}
              >
                {isFetchingPreviousPage ? (
                  <CircularProgress color="primary" />
                ) : null}
                {hasPreviousPage ? t('searchPage.loadPrevious') : null}
              </Button>
            </Grid>
          </Grid>
        ) : null}

        <Typography align="center">
          {isFetchingNextPage ? <CircularProgress color="primary" /> : null}
        </Typography>
        <Box sx={{ p: { xs: 0, md: 2 } }}>
          <SaveSearch />
          {isSuccess
            ? items?.pages.map((page, index) =>
                page?.page + 1 < pageLimit ? (
                  <React.Fragment key={'page' + page?.page + index}>
                    <Page
                      pageLimit={pageLimit}
                      onRefetch={onRefetch}
                      data={page}
                      totalCount={page?.totalCount}
                      totalPages={page?.totalPages}
                      page={page?.page}
                      onChangePage={onChangePage}
                    />
                  </React.Fragment>
                ) : (
                  <Typography
                    key={'page-error' + page?.page + index}
                    align="center"
                    fontWeight={600}
                    fontSize={20}
                    sx={{ mt: '10px' }}
                  >
                    {t('error.redisErrorMessage')}
                  </Typography>
                )
              )
            : null}
        </Box>
        <Typography align="center" ref={ref}>
          {isFetching || isFetchingNextPage ? (
            <CircularProgress color="primary" />
          ) : null}
        </Typography>
        {isSuccess ? (
          <>
            <Typography align="center" fontWeight={700} fontSize={20}>
              {!hasNextPage && !isFetchingNextPage
                ? t('searchPage.resultsEnd')
                : null}
            </Typography>
            <Typography align="center" fontWeight={600} fontSize={20}>
              {items?.pages[items.pages.length - 1]?.page + 1 === pageLimit &&
              !hasNextPage
                ? t('error.redisErrorMessage')
                : null}
            </Typography>
          </>
        ) : null}
      </div>
    </Zoom>
  )
})

export default Results

Results.propTypes = {}
