import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import {
  SvgIcon,
  Button,
  Container,
  Toolbar,
  Box,
  useMediaQuery,
  AppBar as MuiAppBar,
  Link,
} from '@mui/material'
import { useMatch } from 'react-router-dom'

import logoPNG from 'assets/img/logo-new.png'

import { ReactComponent as LogoOrangeSVG } from 'assets/svg/logo_orange.svg'
import { ReactComponent as MenuIconSVG } from 'assets/svg//menu/menu_icon.svg'
import { ReactComponent as MenuIconMobileSVG } from 'assets/svg//menu/menu_icon_mobile.svg'

import StyledMenu from './Menu'

interface Props {
  variant?: string
}

const AppBar = React.forwardRef<HTMLDivElement, Props>(function AppBar(
  props,
  ref
) {
  const [open, setOpen] = React.useState<boolean>(false)

  const { variant } = props

  const IS_VARIANT_INNER = variant === 'inner'

  const handleOpenMenu = () => {
    setOpen(true)
  }
  const handleCloseMenu = () => {
    setOpen(false)
  }
  const theme = useTheme()
  const matchSearch = useMatch('/search')
  const matchDetail = useMatch('/property-ads/:adId')
  const matchPublicAgents = useMatch('/estate-agents')
  const matchPublicAgentsId = useMatch('estate-agents/:id')
  const matchCalculator = useMatch('/calculator')
  const matchTerms = useMatch('/terms')
  const matchContact = useMatch('/contact-us')
  const match404 = useMatch('404')
  const matchRecents = useMatch('/recent')
  const matchRealtorsInfo = useMatch('/realtors-info')

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const MATCHES_INNER_ROUTES =
    matchSearch ||
    matchDetail ||
    matchCalculator ||
    matchTerms ||
    matchCalculator ||
    matchRecents ||
    matchPublicAgents ||
    match404 ||
    matchContact ||
    matchRealtorsInfo ||
    matchPublicAgentsId

  return (
    <MuiAppBar
      ref={ref}
      position="static"
      sx={{
        backgroundColor: IS_VARIANT_INNER ? 'primary.main' : 'common.white',
        p: 0,
        borderShadow: 'none',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link href={window.location.origin} underline="none">
            <Box sx={{ mb: -2, ml: 1, pt: 1, cursor: 'pointer' }}>
              {IS_VARIANT_INNER ? (
                <SvgIcon
                  component={LogoOrangeSVG}
                  inheritViewBox
                  sx={{
                    width: 'auto',
                    height: { xs: 50, sm: 90, md: 100, lg: 136 },
                    fill: 'black',
                  }}
                />
              ) : (
                <Box
                  component="img"
                  src={logoPNG}
                  alt="logo"
                  sx={{
                    width: 'auto',
                    height: { xs: 50, sm: 90, md: 100, lg: 136 },
                  }}
                />
              )}
            </Box>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}></Box>
          <Box
            sx={{
              flexGrow: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'nowrap',
              mr: 2,
              bgcolor: 'transparent',
              '&:hover': {
                bgcolor: 'transparent',
              },
            }}
            component={Button}
            onClick={handleOpenMenu}
          >
            <Button
              component="div"
              disableElevation
              sx={{
                bgcolor: 'transparent',
                color: 'common.black',

                py: 0.1,
                '&:hover': {
                  bgcolor: 'transparent',
                  opacity: 1,
                },
              }}
            >
              {matchesSM ? (
                MATCHES_INNER_ROUTES ? (
                  <SvgIcon
                    alt="menu"
                    component={MenuIconMobileSVG}
                    inheritViewBox
                    sx={{
                      fill: 'black',
                      bgcolor: 'transparent',
                      width: { xs: 40 },
                      height: { xs: 40 },
                      mr: -7,
                      '&:hover': {
                        bgcolor: 'transparent',
                        fill: theme.palette.common.white,
                      },
                    }}
                  />
                ) : (
                  <SvgIcon
                    alt="menu"
                    component={MenuIconMobileSVG}
                    inheritViewBox
                    sx={{
                      fill: 'black',

                      width: { xs: 40 },
                      height: { xs: 40 },
                      mr: -7,
                      '&:hover': {
                        bgcolor: theme.palette.common.white,
                        fill: theme.palette.primary.main,
                      },
                    }}
                  />
                )
              ) : MATCHES_INNER_ROUTES ? (
                <SvgIcon
                  alt="menu"
                  component={MenuIconSVG}
                  inheritViewBox
                  sx={{
                    fill: 'black',
                    bgcolor: 'transparent',
                    width: { sm: 160 },
                    height: { sm: 50 },
                    mr: 1,
                    mb: -2,
                    '&:hover': {
                      bgcolor: 'transparent',
                      fill: theme.palette.common.white,
                    },
                  }}
                />
              ) : (
                <SvgIcon
                  alt="menu"
                  component={MenuIconSVG}
                  inheritViewBox
                  sx={{
                    fill: 'black',

                    width: { sm: 160 },
                    height: { sm: 50 },
                    mr: 1,
                    mb: -2,
                    '&:hover': {
                      bgcolor: theme.palette.common.white,
                      fill: theme.palette.primary.main,
                    },
                  }}
                />
              )}
            </Button>
          </Box>
          {open ? <StyledMenu open={open} onClose={handleCloseMenu} /> : null}
        </Toolbar>
      </Container>
    </MuiAppBar>
  )
})
export default AppBar
