import React from 'react'
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Stack,
  SvgIcon,
  Link,
} from '@mui/material'
import { Phone, Drafts } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import SeparatorPNG from 'assets/img/seperator.png'
import DialogEmailForm from './DialogEmailForm'
import DialogPhone from './DialogPhone'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import { ReactComponent as HomeSVG } from 'assets/svg/icons/home-icon.svg'
import { Link as RouterLink } from 'react-router-dom'
interface AgentControlProps {
  variant?: 'lg' | 'xl'
  data?: ParsedProperty
}

function AgentControl(props: AgentControlProps) {
  const { t } = useTranslation()

  const { variant, data } = props

  const IS_XL = variant === 'xl'
  const IS_LG = variant === 'lg'

  const [openPhoneDialog, setOpenPhoneDialog] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const handleClickOpenDialog = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpenPhoneDialog = () => {
    setOpenPhoneDialog(true)
  }
  const handleClosePhoneDialog = () => {
    setOpenPhoneDialog(false)
  }

  const renderAddress = (data) => {
    if (
      !data?.agentContactInfo?.address?.street &&
      !data?.agentContactInfo?.address?.streetNo &&
      !data?.agentContactInfo?.address?.city &&
      !data?.agentContactInfo?.address?.area
    ) {
      return null
    }
    return (
      <>
        <Grid item container justifyContent="center">
          <Box component="img" src={SeparatorPNG} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="span"
            display="block"
            align="center"
            sx={{ fontSize: { xs: '15px', lg: '20px' } }}
          >
            {`${data?.agentContactInfo?.address?.street ?? ''} 
            ${
              data?.agentContactInfo?.address?.streetNo
                ? data?.agentContactInfo?.address?.streetNo + ' , '
                : ''
            }`}
          </Typography>
          <Typography
            component="span"
            display="block"
            align="center"
            sx={{ fontSize: { xs: '15px', lg: '20px' } }}
          >
            {`${data?.agentContactInfo?.address?.city ?? ''} 
      ${data?.agentContactInfo?.address?.area ?? ''}`}
          </Typography>
        </Grid>
      </>
    )
  }

  return (
    <>
      {IS_XL ? (
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            boxShadow: 25,
            border: (theme) => `1px solid ${theme.palette.common.grey}`,
            borderRadius: 2.5,
            px: 2,
            py: IS_XL ? 4 : 2,
            minHeight: IS_XL ? 400 : 'default',
            maxWidth: 'default',
            height: '100%',
          }}
        >
          <Link
            component={RouterLink}
            to={`/estate-agents/el:${data?.agentId}`}
            underline="none"
          >
            <Grid item container justifyContent="center">
              {data?.agentLogoURL ? (
                <Box
                  component="img"
                  src={data?.agentLogoURL}
                  sx={{ height: 'auto', width: '100%', maxWidth: 150 }}
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor: 'transparent',
                    width: 100,
                    height: 100,
                  }}
                >
                  <SvgIcon
                    component={HomeSVG}
                    inheritViewBox
                    sx={{
                      width: '80px',
                      height: 'auto',
                    }}
                  />
                </Avatar>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 900,
                  pt: 4,
                  fontSize: '20px',
                  color: 'text.primary',
                  textAlign: 'center',
                }}
              >
                {data?.agentName}
              </Typography>
            </Grid>
          </Link>
          {renderAddress(data)}
          <Grid item container justifyContent="center">
            <Box component="img" src={SeparatorPNG} />
          </Grid>
          <Grid item xs={12} sx={{ alignSelf: 'flex-end' }}>
            <Stack spacing={1}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderRadius: 1,
                  bgcolor: 'primary.main',
                  cursor: 'pointer',
                  py: 1,
                  px: 1,
                  width: '100%',
                }}
                onClick={handleOpenPhoneDialog}
              >
                <Avatar
                  sx={{
                    bgcolor: 'common.white',
                    width: 'fit-content',
                    height: 'fit-content',
                    p: 0.4,
                  }}
                >
                  <Phone fontSize="medium" color="primary" />
                </Avatar>
                <Typography
                  component="span"
                  display="block"
                  color="textPrimary"
                  sx={{ ml: 3, fontSize: 15, fontWeight: '400' }}
                >
                  {t('detailPage.dialog.phone.label')}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderRadius: 1,
                  bgcolor: 'primary.main',
                  cursor: 'pointer',
                  py: 1,
                  px: 1,
                  width: '100%',
                }}
                onClick={handleClickOpenDialog}
              >
                <Avatar
                  sx={{
                    bgcolor: 'common.white',
                    width: 'fit-content',
                    height: 'fit-content',
                    p: 0.4,
                  }}
                >
                  <Drafts fontSize="medium" color="primary" />
                </Avatar>
                <Typography
                  color="textPrimary"
                  sx={{ ml: 3, fontSize: 15, fontWeight: '400' }}
                >
                  {t('detailPage.dialog.contact.label')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      ) : null}
      {IS_LG ? (
        <Box
          sx={{
            boxShadow: 25,
            border: (theme) => `1px solid ${theme.palette.common.grey}`,
            borderRadius: 2.5,
            p: { xs: '20px', sm: '20px' },
            minHeight: { xs: '110px', sm: '100%', md: '100%' },
            display: 'grid',
            alignItems: 'center',
            gap: 2,
            gridTemplateColumns: '100px auto',
            gridTemplateAreas: `"avatar content"`,
          }}
        >
          <Link
            component={RouterLink}
            to={`/estate-agents/el:${data?.agentId}`}
            underline="none"
          >
            {data?.agentLogoURL ? (
              <Box
                component="img"
                src={data?.agentLogoURL}
                sx={{ height: 'auto', width: '100%', maxWidth: 150 }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: 'transparent',
                  width: 100,
                  height: 100,
                }}
              >
                <SvgIcon
                  component={HomeSVG}
                  inheritViewBox
                  sx={{
                    width: '90px',
                    height: 'auto',
                  }}
                />
              </Avatar>
            )}
          </Link>
          <Stack justifyContent="space-around" spacing={1}>
            <Typography
              component="span"
              display="block"
              align="center"
              fontWeight="bolder"
              sx={{
                fontSize: { xs: '15px', lg: '20px' },
                fontWeight: 900,
                textAlign: 'center',
              }}
            >
              {data?.agentName}
            </Typography>

            <Box
              sx={{
                display: 'grid',
                gap: 2,
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              <Box
                sx={{
                  bgcolor: 'primary.main',
                  minHeight: '37.5px',
                  borderRadius: 2.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  py: 1,
                }}
                onClick={handleOpenPhoneDialog}
              >
                <Avatar
                  sx={{
                    bgcolor: 'common.white',
                    width: 'fit-content',
                    height: 'fit-content',
                    p: '4px',
                  }}
                >
                  <Phone color="primary" />
                </Avatar>
              </Box>
              <Box
                sx={{
                  bgcolor: 'primary.main',
                  minHeight: '37.5px',
                  borderRadius: 2.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  py: 1,
                }}
                onClick={handleClickOpenDialog}
              >
                <Avatar
                  sx={{
                    bgcolor: 'common.white',
                    width: 'fit-content',
                    height: 'fit-content',
                    p: '4px',
                  }}
                >
                  <Drafts color="primary" />
                </Avatar>
              </Box>
            </Box>
          </Stack>
        </Box>
      ) : null}
      {data ? (
        <>
          {open ? (
            <DialogEmailForm
              onClose={handleClose}
              open={open}
              email={data?.agentContactInfo?.email}
              externalId={data?.externalId}
              propertyAd={data}
            />
          ) : null}
          {openPhoneDialog ? (
            <DialogPhone
              open={openPhoneDialog}
              onClose={handleClosePhoneDialog}
              landLineNumber={data?.agentContactInfo?.landLineNumber}
              landLineNumber2={data?.agentContactInfo?.landLineNumber2}
              mobileNumber={data?.agentContactInfo?.mobileNumber}
              mobileNumber2={data?.agentContactInfo?.mobileNumber2}
              propertyAd={data}
            />
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default AgentControl
