import { StoreSlice } from '..'
import { ParsedProperty } from 'api/services/PropertyAd/types'

const MAX_SAVED = 8

export interface IRecentSlice {
  recentList: ParsedProperty[]
  resetRecent: () => void
  addToList: (ParsedProperty) => void
  removeFromList: (id: string) => void
}

export const recentSlice: StoreSlice<IRecentSlice> = (set, get) => ({
  recentList: [],
  resetRecent: () =>
    set({
      recentList: [],
    }),
  addToList: (payload) => {
    let recent = get().recentList
    const currentIndex = recent.findIndex((item) => item.id === payload.id)
    if (currentIndex >= 0) {
      recent.splice(currentIndex, 1)
    }
    if (recent.length > MAX_SAVED) recent = recent.slice(1)
    recent.push(payload)
    set({ recentList: recent })
  },
  removeFromList: (id) => {
    const recent = get().recentList
    const newRecent = recent.filter((item) => item.id !== id)
    set({ recentList: newRecent })
  },
})
