import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Grow, BoxProps } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'

import { Area, ViewVariant } from 'types'

import SearchChip, { Props as CustomCHipProps } from './SearchChip'

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(1.5, 1),
}))

const List = styled((props) => <Box component="ul" {...props} />)<BoxProps>(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1, 0),
    margin: 0,
  })
)

interface ISearchChipsProps {
  options?: Area[]
  getOptionLabel: (area: Area) => string | React.ReactNode
  getOptionKey: (area: Area) => string | number
  onDelete?: (area: any) => void | any
  cVariant?: ViewVariant
  chipProps?: CustomCHipProps
}

export default function SearchChips(props: ISearchChipsProps) {
  const {
    options = [],
    getOptionLabel,
    getOptionKey,
    onDelete,
    cVariant,
    chipProps,
  } = props
  return (
    <TransitionGroup component={List}>
      {options.map((opt) => (
        <Grow key={getOptionKey(opt)}>
          <ListItem>
            <SearchChip
              label={getOptionLabel(opt)}
              onDelete={onDelete ? onDelete(opt) : undefined}
              cVariant={cVariant}
              {...chipProps}
            />
          </ListItem>
        </Grow>
      ))}
    </TransitionGroup>
  )
}
