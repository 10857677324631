import TokenService from './TokenService'
import { useAuthStore } from 'store'
import { KEYCLOAK_ROLES } from 'helpers/constants'
import _kc from 'keycloak'

const resetAuthStore = useAuthStore.getState().reset
const setAccessTokenInStore = useAuthStore.getState().setAccessToken
const setRefreshToken = useAuthStore.getState().setRefreshToken

const initKeycloak = (cb: any) => {
  _kc
    .init({
      messageReceiveTimeout: 5000,
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin +
        (process.env.REACT_APP_ENV === 'dev'
          ? `/${process.env.REACT_APP_PUBLIC_URL}`
          : '') +
        '/silent-check-sso.html',
      checkLoginIframe: false,
      enableLogging: process.env.REACT_APP_ENV === 'production' ? false : true,
      token: TokenService.getAccessToken() ?? undefined,
      refreshToken: TokenService.getRefreshToken() ?? undefined,
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!')
      }
      cb()
    })
    .catch((error) => {
      console.error(error)
      cb()
    })
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const getRefreshToken = () => _kc.refreshToken

const isLoggedIn = () => _kc.authenticated

const updateToken = (successCallback, errorCallback) =>
  _kc
    .updateToken(30)
    .then((refreshed) => {
      if (!!_kc.token && _kc.refreshToken) {
        TokenService.setAccessToken(_kc.token)
        TokenService.setRefreshToken(_kc.refreshToken)
        setAccessTokenInStore(_kc.token)
        setRefreshToken(_kc.refreshToken)
      }

      return successCallback(_kc.token)
    })
    .catch(() => {
      if (errorCallback) {
        errorCallback()
      }
      resetAuthStore()
    })

const clearAuthState = () => _kc.clearToken()

const getUsername = () => _kc.tokenParsed?.preferred_username
const getParsedToken = () => _kc.tokenParsed
const getRealmAccess = () => _kc.realmAccess
const getSubject = () => _kc.subject
const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))
const isAdmin = () => _kc.hasRealmRole(KEYCLOAK_ROLES.ROLE_ADMIN)
const isAgent = () => _kc.hasRealmRole(KEYCLOAK_ROLES.ROLE_AGENT)
const isOnlyUser = () =>
  _kc.hasRealmRole(KEYCLOAK_ROLES.ROLE_USER) && !isAgent() && !isAdmin()

const AuthService = {
  initKeycloak,
  getParsedToken,
  clearAuthState,
  getSubject,
  getRealmAccess,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getRefreshToken,
  updateToken,
  getUsername,
  hasRole,
  isAdmin,
  isAgent,
  isOnlyUser,
}

export default AuthService
