import React from 'react'
import { lighten } from '@mui/material/styles'
import { MenuItem, Select, FormControl } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  Option,
  IFloorOption,
  IFloorDirectionOption,
} from 'store/SearchSlice/types'
import { ViewVariant } from 'types'

export type GeneralOption = Option | IFloorOption | IFloorDirectionOption
interface SearchSelectProps {
  variant?: ViewVariant
  options: GeneralOption[]
  getOptionValue: (GeneralOption) => string
  getOptionLabel: (GeneralOption) => string
  value: string
  onChange: (any) => void
  emptyLabel?: string
}
export default function SearchSelect(props: SearchSelectProps) {
  const { t } = useTranslation()

  const {
    variant,
    options,
    getOptionValue,
    getOptionLabel,
    value,
    onChange,
    emptyLabel = '',
  } = props
  const IS_INNER = variant === 'inner'

  return (
    <FormControl
      variant="standard"
      sx={{
        width: '100%',
        minWidth: 120,
        '&:focus': {
          bgcolor: 'red',
        },
      }}
    >
      <Select
        // open={true}
        displayEmpty
        value={value}
        onChange={onChange}
        sx={{
          width: '100%',
          '&:active': {
            bgcolor: 'red',
          },
        }}
        MenuProps={{
          sx: {
            '&  .MuiPopover-paper': {
              boxShadow: 25,
              maxHeight: 300,
              border: '1px solid',
              borderColor: 'common.grey',
            },
            '& .MuiList-root': {},
            '& .MuiMenuItem-root': {
              mr: 1,
              width: '100%',
              '&:hover': {
                fontWeight: 'bolder',
                backgroundColor: (theme) =>
                  lighten(theme.palette.common.grey, 0.5),
                '&.Mui-selected': {
                  backgroundColor: 'common.grey',
                },
              },
              '&.Mui-selected': {
                backgroundColor: (theme) =>
                  variant === 'inner'
                    ? theme.palette.primary.main
                    : lighten(theme.palette.primary.main, 0.2),
                '&:hover': {
                  fontWeight: 'bolder',
                  backgroundColor: (theme) =>
                    variant === 'inner'
                      ? theme.palette.primary.main
                      : lighten(theme.palette.primary.main, 0.2),
                },
                '&.Mui-focused': {
                  backgroundColor: (theme) =>
                    variant === 'inner'
                      ? theme.palette.primary.main
                      : lighten(theme.palette.primary.main, 0.2),
                },
              },
            },
          },
        }}
        inputProps={{
          sx: {
            fontWeight: 900,
            lineHeight: 1.5,
            fontSize: {
              sm: IS_INNER ? 20 : 24,
              md: IS_INNER ? 20 : 30,
              lg: IS_INNER ? 20 : 30,
            },
            color: value !== '' ? 'common.black' : 'rgba(0, 0, 0, 0.35)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
            '&::before': {
              display: 'none',
              '&:hover': {
                borderColor: 'transparent',
              },
              '&:active': {
                borderColor: 'transparent',
              },
              '&:focus': {
                borderColor: 'transparent',
              },
            },
            '&:hover': {
              borderColor: 'transparent',
            },
            '&:active': {
              borderColor: 'transparent',
            },
            '&:focus': {
              borderColor: 'transparent',
            },
          },
        }}
      >
        <MenuItem
          sx={{
            color: 'text.secondary',
          }}
          value=""
        >
          {emptyLabel}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={getOptionValue(option)}>
            {t(getOptionLabel(option))}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
