import * as React from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import { styled, darken } from '@mui/material/styles'
import {
  SvgIcon,
  Button,
  Typography,
  Box,
  Grid,
  Stack,
  Grow,
  ClickAwayListener,
  Paper,
  LinkProps as MuiLinkProps,
  SxProps,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ContactSupport } from '@mui/icons-material'

import SeparatorPNG from 'assets/img/seperator.png'
import { ReactComponent as FindRealtorSVG } from 'assets/svg/menu/find_realtor.svg'
import { ReactComponent as BussProfileSVG } from 'assets/svg/menu/buss_profile.svg'
import { ReactComponent as CreateAccountSVG } from 'assets/svg/menu/create_account.svg'
import { ReactComponent as InfoForRealtorsSVG } from 'assets/svg/menu/info_for_realtors.svg'
import { ReactComponent as SigninSVG } from 'assets/svg/menu/signin.svg'
import { ReactComponent as ViewLatestSVG } from 'assets/svg/menu/view_latest.svg'
import { ReactComponent as CalculateSVG } from 'assets/svg/menu/calculate.svg'
import { ReactComponent as SignOutSVG } from 'assets/svg/menu/sign_out.svg'
import { ReactComponent as ManagementSVG } from 'assets/svg/menu/management.svg'
import { ReactComponent as MyAdsSVG } from 'assets/svg/menu/my_ads.svg'
import { ReactComponent as MyListsSVG } from 'assets/svg/menu/my_lists.svg'
import { ReactComponent as NewAdSVG } from 'assets/svg/menu/new_ad.svg'
import AuthService from 'services/AuthService'
import { KEYCLOAK_ROLES } from 'helpers/constants'
import RenderOnRole from 'components/access/RenderOnRole'
import { Access } from 'types'
import AuthenticationService from 'api/services/Authentication'

const LinkButton = styled(Button)<LinkProps>(({ theme }) => ({
  py: 1,
  borderRadius: 10,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: darken(theme.palette.common.white, 0.1),
    transition: theme.transitions.create(['background-color']),
  },
  textTransform: 'none',
  color: theme.palette.text.primary,
  minWidth: '100%',
}))

const NavItem = ({
  icon,
  labelPath,
  avatarSxProps = {},
  sxIconProps = {},
}: {
  avatarSxProps: SxProps
  icon: React.ElementType
  sxIconProps: SxProps
  labelPath: string
}) => {
  const { t } = useTranslation()
  return (
    <Stack
      justifyContent="flex-start"
      alignItems="center"
      sx={{ height: '100%' }}
      rowGap={1}
    >
      <Box
        sx={{
          padding: 1,
          width: 40,
          height: 40,
          borderRadius: '100vmax',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          ...avatarSxProps,
        }}
      >
        <SvgIcon
          component={icon}
          color="primary"
          inheritViewBox
          sx={{
            ...sxIconProps,
          }}
        />
      </Box>
      <Typography
        align="center"
        variant="caption"
        sx={{
          textTransform: 'none',
          color: (theme) => theme.palette.text.primary,
        }}
      >
        {t(labelPath)}
      </Typography>
    </Stack>
  )
}

interface Props {
  variant?: string
  open: boolean
  onClose: () => void
}

const OuterLinkButton = styled(Button)<MuiLinkProps>(({ theme }) => ({
  py: 1,
  borderRadius: 10,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: darken(theme.palette.common.white, 0.1),
    transition: theme.transitions.create(['background-color']),
  },
  textTransform: 'none',
  minWidth: '100%',
  color: theme.palette.text.primary,
}))

interface Props {
  variant?: string
  open: boolean
  onClose: () => void
}

const StyledMenu = (props: Props) => {
  const IS_AUTHENTICATED = AuthService.isLoggedIn()

  const { open, onClose } = props

  const { t } = useTranslation()

  const logoutMutation = AuthenticationService.hooks.useLogout({})

  const HAS_OUTER_PATH =
    process.env.REACT_APP_ENV === 'dev'
      ? window.location.pathname.replaceAll('/', ' ')?.trim()?.split(' ')
          ?.length > 1
      : window.location.pathname.replaceAll('/', ' ')?.trim()?.split(' ')
          ?.length

  const iconsOptions = [
    {
      icon: ManagementSVG,
      requiresLogin: true,
      isOuterLink: true,
      to: `${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}`,
      access: [
        KEYCLOAK_ROLES.ROLE_ADMIN,
        KEYCLOAK_ROLES.ROLE_AGENT,
        KEYCLOAK_ROLES.ROLE_USER,
      ] as Access,
      labelPath: 'appbar.menu.management',
      sxIconProps: {
        color: 'black',
        width: 24,
        height: 'auto',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: SignOutSVG,
      requiresLogin: true,
      handler: () => {
        logoutMutation.mutate(null)
      },
      labelPath: 'appbar.menu.signOut',
      sxIconProps: {
        color: 'black',
        width: 24,
        height: 'auto',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: NewAdSVG,
      requiresLogin: true,
      to: `${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}property-ads/create`,
      access: [KEYCLOAK_ROLES.ROLE_AGENT] as Access,
      labelPath: 'appbar.menu.newAd',
      sxIconProps: {
        color: 'black',
        width: 24,
        height: 'auto',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: MyAdsSVG,
      requiresLogin: true,
      isOuterLink: true,
      to: `${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}my-property-ads`,
      access: [KEYCLOAK_ROLES.ROLE_AGENT] as Access,
      labelPath: 'appbar.menu.myAds',
      sxIconProps: {
        color: 'black',
        width: 24,
        height: 'auto',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: MyListsSVG,
      requiresLogin: true,
      isOuterLink: true,
      to: `${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}lists`,
      access: [KEYCLOAK_ROLES.ROLE_USER] as Access,
      labelPath: 'appbar.menu.myLists',
      sxIconProps: {
        color: 'black',
        width: 24,
        height: 'auto',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: SigninSVG,
      requiresLogin: false,
      requiresLoggedOut: true,
      isOuterLink: process.env.REACT_APP_ENV === 'development' ? false : true,
      isInnerLink: process.env.REACT_APP_ENV === 'development' ? true : false,
      to:
        process.env.REACT_APP_ENV === 'development'
          ? '/login'
          : `${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}login`,
      labelPath: 'appbar.menu.signIn',
      sxIconProps: {
        color: 'black',
        width: 24,
        height: 'auto',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: CreateAccountSVG,
      requiresLogin: false,
      requiresLoggedOut: true,
      isOuterLink: true,
      to: `${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}sign-up-user`,
      labelPath: 'appbar.menu.createAccount',
      sxIconProps: {
        width: 28,
        height: 32,
        position: 'relative',
        top: 5,
        color: 'black',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: ViewLatestSVG,
      isInnerLink: !HAS_OUTER_PATH,
      isOuterLink: !!HAS_OUTER_PATH,
      to: HAS_OUTER_PATH ? `/#/recent` : `/recent`,
      requiresLogin: false,
      labelPath: 'appbar.menu.latest',
      sxIconProps: {
        color: 'black',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: FindRealtorSVG,
      isInnerLink: !HAS_OUTER_PATH,
      isOuterLink: !!HAS_OUTER_PATH,
      to: HAS_OUTER_PATH ? `/#/estate-agents` : `/estate-agents`,
      requiresLogin: false,
      labelPath: 'appbar.menu.findRealtor',
      sxIconProps: {
        color: 'black',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: ContactSupport,
      requiresLogin: false,
      isInnerLink: !HAS_OUTER_PATH,
      isOuterLink: !!HAS_OUTER_PATH,
      to: HAS_OUTER_PATH ? `/#/contact-us` : `/contact-us`,
      labelPath: 'appbar.menu.contact',
      sxGridItemProps: {
        xs: 6,
      },
      sxIconProps: {
        color: 'black',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
    {
      icon: CalculateSVG,
      requiresLogin: false,
      isInnerLink: !HAS_OUTER_PATH,
      isOuterLink: !!HAS_OUTER_PATH,
      to: HAS_OUTER_PATH ? `/#/calculator` : `/calculator`,
      labelPath: 'appbar.menu.calculateCost',
      sxGridItemProps: {
        xs: 6,
      },
      sxIconProps: {
        color: 'black',
      },
      avatarSxProps: {
        overflow: 'visible',
        bgcolor: 'common.orange',
        border: '1px solid black',
      },
    },
  ]
  const renderOption = (link) => {
    const NavType = link?.isInnerLink ? (
      <LinkButton LinkComponent={RouterLink} to={link.to}>
        <NavItem
          labelPath={link.labelPath}
          icon={link.icon}
          avatarSxProps={link.avatarSxProps}
          sxIconProps={link.sxIconProps}
        />
      </LinkButton>
    ) : link?.isOuterLink ? (
      <OuterLinkButton href={link.to}>
        <NavItem
          labelPath={link.labelPath}
          icon={link.icon}
          avatarSxProps={link.avatarSxProps}
          sxIconProps={link.sxIconProps}
        />
      </OuterLinkButton>
    ) : (
      <Button onClick={link.handler} sx={{ minWidth: '100%' }}>
        <NavItem
          labelPath={link.labelPath}
          icon={link.icon}
          avatarSxProps={link.avatarSxProps}
          sxIconProps={link.sxIconProps}
        />
      </Button>
    )
    if (link?.access) {
      return (
        <RenderOnRole key={link.labelPath} roles={link.access}>
          {NavType}
        </RenderOnRole>
      )
    }

    return <>{NavType}</>
  }

  const BUSSINESS_ICONS = [
    {
      icon: BussProfileSVG,
      requiresLogin: false,
      requiresLoggedOut: true,
      isOuterLink: true,
      to: `${process.env.REACT_APP_REDIRECT_ADMIN_APP_URL}sign-up`,
      labelPath: 'appbar.menu.businessProfile',
      sxIconProps: {},
      avatarSxProps: { overflow: 'visible', bgcolor: 'common.black' },
    },
    {
      icon: InfoForRealtorsSVG,
      requiresLogin: false,
      isInnerLink: true,
      to: '/realtors-info',
      labelPath: 'appbar.menu.infoForRealtors',
      sxIconProps: {
        position: 'relative',
        top: 4,
        left: 1,
        height: 34,
        color: 'black',
      },
      avatarSxProps: { overflow: 'visible', bgcolor: 'common.black' },
    },
  ]

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClose()
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <Paper
          sx={{
            width: { xs: '100%', sm: 250 },
            boxShadow: 25,
            border: '1px solid grey',
            maxWidth: '100%',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            top: 80,
            right: { xs: 0, sm: 20 },
            zIndex: 2000,
            px: 1,
            py: 1,
          }}
        >
          <Typography variant="h6" align="center" color="primary">
            {t('appbar.menu.welcome')}
          </Typography>
          <Box
            component="img"
            src={SeparatorPNG}
            sx={{ width: 'fit-content', maxWidth: 80, mx: 'auto', my: 1 }}
          />
          <Grid container alignItems="stretch">
            {iconsOptions
              .filter((option) =>
                !IS_AUTHENTICATED
                  ? option.requiresLoggedOut || !option.requiresLogin
                  : option.requiresLogin || !option.requiresLoggedOut
              )
              .map((option) => (
                <Grid
                  key={option.labelPath}
                  item
                  xs={6}
                  {...option.sxGridItemProps}
                >
                  {renderOption(option)}
                </Grid>
              ))}
          </Grid>
          <Box
            component="img"
            src={SeparatorPNG}
            sx={{ width: 'fit-content', maxWidth: 80, mx: 'auto', my: 1 }}
          />
          <Typography
            variant="body1"
            align="center"
            color="textPrimary"
            sx={{ textTransform: 'uppercase' }}
          >
            {t('appbar.menu.forBusinesses')}
          </Typography>
          <Grid container alignItems="stretch" sx={{ mt: 1 }}>
            {BUSSINESS_ICONS.filter((option) =>
              !IS_AUTHENTICATED
                ? option.requiresLoggedOut || !option.requiresLogin
                : option.requiresLogin || !option.requiresLoggedOut
            ).map((option) => renderOption(option))}
          </Grid>
        </Paper>
      </Grow>
    </ClickAwayListener>
  )
}
export default StyledMenu
