import { GA_Events } from 'types/enums'
import { DLPayload } from './types'

function pushToDL(payload: DLPayload) {
  window.dataLayer.push({
    page_location: window.location.href,
    page_title: document.title,
    ...payload,
  })
}

type pageViewArgs = Partial<Pick<DLPayload, 'page_title'>>
type searchAreaArgs = Partial<Pick<DLPayload, 'page_title' | 'searchArea'>>

const pageView = (args: pageViewArgs = {}) => {
  pushToDL({ event: GA_Events.PageView, ...args })
}

const setSearchedFor = (args: searchAreaArgs = {}) => {
  pushToDL({ event: GA_Events.SearchArea, ...args })
}

const addFavorite = (entityId: string) => {
  pushToDL({ event: GA_Events.AddToFavorites, entityId: entityId })
}

const removeFavorite = () => {
  pushToDL({ event: GA_Events.RemoveFromFavorites })
}

const createList = () => {
  pushToDL({ event: GA_Events.CreateList })
}

const AnalyticsService = {
  addFavorite,
  removeFavorite,
  createList,
  pageView,
  setSearchedFor,
}

export default AnalyticsService
