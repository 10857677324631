import { useMutation } from 'react-query'
import api from 'api'
import { ExpensesPayload, ExpensesResponse } from './types'

const SERVICE_NAME = `PropertyExpenses`

const QueryKeys = {} as const

const MutationKeys = {
  calculate: `${SERVICE_NAME}#calculate`,
} as const

const CONTROLLER_PATH = `/app/spourgiti/api/buy-property-expenses`

// Calculate Expenses
export const calculateExpenses = async (
  payload: Partial<ExpensesPayload>
): Promise<ExpensesResponse> => {
  const { data } = await api({
    method: 'post',
    url: `${CONTROLLER_PATH}/calculate`,
    data: {
      ...payload,
    },
  })

  return data
}
export const useCalculateExpenses = (queryOptions = {}) => {
  return useMutation(MutationKeys.calculate, calculateExpenses, {
    ...queryOptions,
  })
}

const PropertyExpensesService = {
  calculateExpenses,
  hooks: {
    useCalculateExpenses,
  },
  keys: { QueryKeys, MutationKeys },
}

export default PropertyExpensesService
