import React from 'react'
import { Routes, Route, Outlet, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Landing from 'components/Landing'
import Search from 'components/Search'
import Detail from 'components/Detail'
import NotFoundPage from 'components/NotFoundPage'
import Terms from 'components/Terms'
import PublicAgents from 'components/PublicAgents'
import Policy from 'components/Policy'
import InfoForAgent from 'components/InfoForAgent'
import PublicAgent from 'components/PublicAgent'
import Calculator from 'components/Calculator'
import Recents from 'components/Recents'
import ContactPage from 'components/Contact'
import Login from 'components/Login'

import { useDocumentTitle } from 'hooks/useDocumentTitle'

const AppRoutes: React.FC = () => {
  const { t } = useTranslation()
  const { addTitle } = useDocumentTitle()

  const matchLanding = useMatch('/')
  const matchSearch = useMatch('/search')
  const matchPublicAgents = useMatch('/estate-agents')
  const matchCalculator = useMatch('/calculator')
  const matchTerms = useMatch('/terms')
  const matchContact = useMatch('/contact-us')
  const match404 = useMatch('404')
  const matchRecents = useMatch('/recent')

  if (matchLanding) addTitle(t('pages.landing'))
  else if (matchSearch) addTitle(t('pages.search'))
  else if (matchPublicAgents) addTitle(t('pages.findRealtor'))
  else if (matchCalculator) addTitle(t('pages.calculator'))
  else if (matchTerms) addTitle(t('pages.terms'))
  else if (matchContact) addTitle(t('pages.contact'))
  else if (match404) addTitle(t('pages.404'))
  else if (matchRecents) addTitle(t('pages.recents'))

  return (
    <Routes>
      <Route index element={<Landing />} />
      <Route path="home" element={<Landing />} />

      <Route
        path="contact-us"
        element={
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
          >
            <ContactPage />
          </GoogleReCaptchaProvider>
        }
      />
      <Route path="search" element={<Search />} />
      <Route path="recent" element={<Recents />} />
      <Route path="estate-agents" element={<PublicAgents />} />
      <Route path="estate-agents/:id" element={<PublicAgent />} />
      <Route path="calculator" element={<Calculator />} />
      <Route path="property-ads" element={<Outlet />}>
        <Route
          path=":adId"
          element={
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
            >
              <Detail key={window.location.href} />
            </GoogleReCaptchaProvider>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      {process.env.REACT_APP_ENV === 'development' ? (
        <Route path="login" element={<Login />} />
      ) : null}
      <Route path="terms" element={<Terms />} />
      <Route path="realtors-info" element={<InfoForAgent />} />
      <Route path="business-policy" element={<Policy />} />
      <Route path="404" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
