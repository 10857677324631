import React from 'react'
import { styled } from '@mui/material/styles'
import Button, { ButtonProps } from '@mui/material/Button'

import { ViewVariant } from 'types'

export interface SearchButtonProps extends ButtonProps {
  selected?: boolean
  cVariant?: ViewVariant
  label?: string
}

const ToggleButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'cVariant',
})<SearchButtonProps>(({ selected = false, cVariant, theme }) => ({
  minHeight: 50,
  maxWidth: 350,
  wordBreak: 'break-word',
  width: '100%',
  fontSize: 30,
  [theme.breakpoints.down('md')]: {
    minHeight: 40,
    fontSize: 23,
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 30,
    fontSize: 20,
  },
  fontWeight: 500,
  textAlign: 'center',
  textTransform: 'none',
  padding: '0',
  borderRadius: 0,
  lineHeight: 1,
  backgroundColor: 'transparent',
  color: theme.palette.common.black,
  border: `1px solid ${theme.palette.common.black}`,
  '&[aria-pressed=true]': {
    border: `1px solid`,
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    ...(cVariant === 'inner' && {
      borderColor: theme.palette.common.black,
    }),
    '&:hover': {
      backgroundColor: theme.palette.common.black,
      borderColor: theme.palette.common.white,
      ...(cVariant === 'inner' && {
        backgroundColor: theme.palette.common.black,
      }),
    },
  },
  transition: theme.transitions.create(['background-color', 'color']),
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: theme.palette.common.black,
  },
  outline: 'none',
  ...(cVariant === 'inner' && {
    minHeight: 30,
    fontSize: 17,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  }),
}))

const SearchButton = React.forwardRef<HTMLButtonElement, SearchButtonProps>(
  function SearchButton({ label, selected, ...rest }: SearchButtonProps, ref) {
    const [isToggled, setToggled] = React.useState(selected ?? false)

    React.useEffect(() => {
      setToggled(selected ?? false)
    }, [isToggled, selected])

    return (
      <ToggleButton
        ref={ref}
        selected={isToggled}
        {...rest}
        aria-pressed={!!isToggled}
      >
        {label ?? ''}
      </ToggleButton>
    )
  }
)

export default SearchButton
