import React from 'react'
import { styled } from '@mui/material/styles'
import {
  Grid,
  Typography,
  InputBase,
  MenuItem,
  InputBaseProps,
  Collapse,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  CHILDREN_STATUS_OPTIONS,
  PROPERTY_CATEGORY_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  MORTAGE_VAT_OPTIONS,
  FIRST_HOUSE_OPTIONS,
} from './constants'
import { useWatch, Control } from 'react-hook-form'
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts'
import { ChartData, FormFields } from './types'
import { toNumber } from 'lodash'
import NumberFormattingService from 'api/services/NumberFormatting'
import { ExpensesResponse } from 'api/services/PropertyExpenses/types'
import FormField, { CalcInputLabel } from './FormField'
import LoadingCalcButton from './shared/LoadingCalcButton'
import { useMatch } from 'react-router-dom'

export const StyledInput = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(4),
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.text.secondary}`,
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderWidth: 1,
      borderColor: theme.palette.primary.main,
    },
  },
}))

interface IBuyProps {
  value?: number
  control: Control<FormFields>
  data?: ExpensesResponse
  loading: boolean
  reqError?: boolean
}

function Buy(props: IBuyProps) {
  const { control, data, loading, reqError } = props

  const matchCalculator = useMatch('/calculator')

  const { t } = useTranslation()
  const [chartData, setChartData] = React.useState<ChartData[]>([
    {
      id: 'tax',
      name: 'calculatorPage.chart.tax',
      value: 0,
      color: '#6871DC',
    },
    {
      id: 'notary',
      name: 'calculatorPage.chart.notary',
      value: 0,
      color: '#6894DC',
    },
    {
      id: 'registry',
      name: 'calculatorPage.chart.registry',
      value: 0,
      color: '#68B7DC',
    },
  ])

  const watchFirstHouse = useWatch({
    control,
    defaultValue: 0,
    name: 'rightOfMainResidence',
  })

  const watchPropertyType = useWatch({ control, name: 'category' })

  React.useEffect(() => {
    if (data) {
      setChartData(
        chartData.map((item, index) => {
          if (index === 0) {
            return { ...item, value: Number(data.taxes) }
          }
          if (index === 1) {
            return {
              ...item,
              value: Math.floor(Number(data.notaryAmount)),
            }
          }
          if (index === 2) {
            return {
              ...item,
              value: Math.floor(Number(data.landRegistryAmount)),
            }
          }
          return item
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const renderTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div
          style={{
            backgroundColor: '#ffff',
            padding: '5px',
            border: '1px solid #cccc',
          }}
        >
          <label>
            <>
              {`${t(payload[0].name)}: `}
              {NumberFormattingService.toRoundNumber(payload[0].value, 0)}
              {` \u20AC`}
            </>
          </label>
        </div>
      )
    }
    return null
  }

  const renderColorfulLegendText = (value: string, entry: any) => {
    const { color } = entry
    const found = chartData.find((item) => item.name === value)
    const FORM_VALUE =
      found && found?.value
        ? NumberFormattingService.toRoundNumber(found.value)
        : '-'

    return (
      <span style={{ color }}>
        {t(value)}:{` `}
        {FORM_VALUE}
        {` \u20AC`}
      </span>
    )
  }

  const renderLabel = (entry: ChartData) => {
    if (entry.value == null) return '-'
    return (
      <text {...entry}>{`${NumberFormattingService.toRoundNumber(
        entry.value
      )} \u20AC`}</text>
    )
  }

  return (
    <Grid container justifyContent="center">
      <Grid item sx={{ pb: 2 }}>
        <Typography
          align="center"
          variant="h5"
          sx={{
            borderBottom: (theme) => `2px solid ${theme.palette.primary.main}`,
            px: 2,
          }}
        >
          {t('detailPage.calculator.buy.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs>
            <ResponsiveContainer width={'100%'} height={400} debounce={2}>
              <PieChart width={300} height={300}>
                <Pie
                  dataKey="value"
                  data={chartData}
                  innerRadius={80}
                  label={renderLabel}
                >
                  {chartData.map((entry) => (
                    <Cell key={`cell-${entry.id}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip content={renderTooltip} />
                <Legend formatter={renderColorfulLegendText} />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" fontSize={20}>
              <Typography
                component="span"
                display="inline"
                sx={{ color: '#3333cc' }}
              >
                {t('calculatorPage.chart.total')}
              </Typography>
              {`: `}
              <strong>
                {NumberFormattingService.toRoundNumber(
                  chartData.reduce<number>((acc, item) => {
                    return acc + Number(item.value)
                  }, 2)
                )}
              </strong>
              {` \u20AC`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormField
              name="propertyValue"
              control={control}
              rules={{
                required: `${t('calculatorPage.fieldRequired')}`,
                validate: (value) => value > toNumber('0') || ' ',
              }}
              label={t('detailPage.calculator.buy.fields.valueEstate.label')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              name="category"
              control={control}
              label={t('detailPage.calculator.buy.fields.typeEstate.label')}
              TextFieldProps={{
                select: true,
                disabled: !matchCalculator,
                children: PROPERTY_CATEGORY_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {t(option.label)}
                  </MenuItem>
                )),
              }}
            />
          </Grid>
          {watchPropertyType !== 'PROFESSIONAL_ESTATE_BUY' ? (
            <>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} sx={{ flexGrow: 1 }}>
                    <CalcInputLabel>
                      {t('detailPage.calculator.buy.fields.firstHouse.label')}
                    </CalcInputLabel>
                    <Grid item xs={6}>
                      <FormField
                        name="rightOfMainResidence"
                        control={control}
                        TextFieldProps={{
                          select: true,
                          children: FIRST_HOUSE_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {t(option.label)}
                            </MenuItem>
                          )),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: watchFirstHouse === 1 ? 'default' : 'none',
                }}
              >
                <Collapse in={watchFirstHouse === 1}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sx={{ flexGrow: 1 }}>
                      <CalcInputLabel>
                        {t(
                          'detailPage.calculator.buy.fields.maritalStatus.label'
                        )}
                      </CalcInputLabel>
                      <FormField
                        name="maritalStatus"
                        control={control}
                        TextFieldProps={{
                          select: true,
                          children: MARITAL_STATUS_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {t(option.label)}
                            </MenuItem>
                          )),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ flexGrow: 1 }}>
                      <CalcInputLabel>
                        {t(
                          'detailPage.calculator.buy.fields.childrenStatus.label'
                        )}
                      </CalcInputLabel>
                      <FormField
                        name="children"
                        control={control}
                        TextFieldProps={{
                          select: true,
                          children: CHILDREN_STATUS_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {t(option.label)}
                            </MenuItem>
                          )),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6} sx={{ flexGrow: 1 }}>
                <CalcInputLabel>
                  {t('detailPage.calculator.buy.fields.mortageVat.label')}
                </CalcInputLabel>
                <FormField
                  name="mortageVat"
                  control={control}
                  TextFieldProps={{
                    select: true,
                    children: MORTAGE_VAT_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {t(option.label)}
                      </MenuItem>
                    )),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <LoadingCalcButton
              isLoading={loading ?? false}
              isError={reqError ?? false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Buy
