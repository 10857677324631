import React from 'react'
import shallow from 'zustand/shallow'
import { Grid, SxProps, Zoom, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

import SearchInput from 'components/Landing/NumberFormatInput/SearchInput'
import { useSearchStore } from 'store'
import SearchLabel from 'components/Landing/SearchLabel'
import NumberFormatCustom from 'components/Landing/NumberFormatInput'
import useDebounce from 'hooks/useDebounce'
import { ViewVariant } from 'types'
import UpdateControl from './UpdateControl'
import { UrlField } from 'types/enums'

interface FactorProps {
  variant?: ViewVariant
  sx?: SxProps
  onRefetch?: () => void
  handleCloseFilters?: () => void
}

function Factor(props: FactorProps) {
  const { variant, onRefetch, handleCloseFilters, ...rest } = props

  const theme = useTheme()

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()

  const IS_INNER = variant === 'inner'

  const [
    buildFactor,
    coverageFactor,
    handleNumChange,
    activeField,
    setActiveField,
    searchCountFn,
  ] = useSearchStore(
    (state) => [
      state.buildFactor,
      state.coverageFactor,
      state.handleNumChange,
      state.activeField,
      state.setActiveField,
      state.searchCountFn,
    ],
    shallow
  )

  const urlFields = [UrlField.buildFactor, UrlField.coverageFactor]
  const IS_ACTIVE = urlFields?.includes(activeField as UrlField)

  const handleChange = (e) => {
    handleNumChange(e.target.value, e.target.name)
    if (IS_INNER && onRefetch) {
      setActiveField(e.target.name)
    } else searchCountFn()
  }

  const debouncedHandleChange = useDebounce(handleChange, 600)

  const handleUpdate = () => {
    if (onRefetch) onRefetch()
    setActiveField('')
    if (matchesSM && handleCloseFilters) {
      handleCloseFilters()
    }
  }

  return (
    <>
      <Grid container {...rest}>
        <Grid item xs={12}>
          <SearchLabel
            cVariant={variant}
            isActive={IS_INNER ? true : !!buildFactor || !!coverageFactor}
          >
            {t('landingPage.moreFilters.land.factor.label')}
          </SearchLabel>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6} sm={6} md={6}>
            <NumberFormatCustom
              value={buildFactor}
              variant={variant}
              placeholder={t(
                'landingPage.moreFilters.land.factor.minBuildFactor'
              )}
              name="buildFactor"
              onChange={debouncedHandleChange}
              customInput={SearchInput}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <NumberFormatCustom
              value={coverageFactor}
              variant={variant}
              placeholder={t(
                'landingPage.moreFilters.land.factor.minCovFactor'
              )}
              name="coverageFactor"
              onChange={debouncedHandleChange}
              customInput={SearchInput}
            />
          </Grid>
        </Grid>
      </Grid>
      <Zoom in={IS_ACTIVE} unmountOnExit>
        <div>
          <UpdateControl onChange={handleUpdate} />
        </div>
      </Zoom>
    </>
  )
}

export default Factor
