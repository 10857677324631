import React from 'react'
import { Grid, Box, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ILandCharacteristicsOption, Option } from 'store/SearchSlice/types'
import SearchLabel from 'components/Landing/SearchLabel'
import SearchButton from './SearchButton'
import { ViewVariant } from 'types'
import {
  IEstateTypeOption,
  IEstateConditionOption,
  IEstateThermalOption,
  ICommercialTypeOption,
  ILandTypeOption,
  ICommercialExtrasOption,
  IEstateExtrasOption,
  IEstateThermalTypeOption,
  ILandExtrasOption,
} from 'types/propertyAttributes'

export type GeneralOption =
  | Option
  | IEstateTypeOption
  | IEstateThermalOption
  | IEstateThermalTypeOption
  | IEstateConditionOption
  | ICommercialTypeOption
  | ILandExtrasOption
  | IEstateExtrasOption
  | ICommercialExtrasOption
  | ILandTypeOption
  | ILandCharacteristicsOption

interface SearchListProps {
  variant?: ViewVariant
  isActive?: boolean
  label?: string
  options: GeneralOption[]
  onChange: (args: GeneralOption) => void
  getOptionLabel: (option: GeneralOption) => string
  getOptionKey: (option: GeneralOption) => string
  getOptionSelected: (option: GeneralOption) => boolean
  sx?: SxProps
}

const SearchList = (props: SearchListProps) => {
  const { t } = useTranslation()

  const {
    variant,
    options = [],
    onChange,
    getOptionLabel,
    getOptionSelected,
    label,
    isActive,
    sx: sxFromProps = {},
  } = props

  const IS_INNER = variant === 'inner'

  const renderOptions = () => {
    return options.map((option, index) => (
      <Grid item key={option.label} xs={6} md={IS_INNER ? 6 : 3}>
        <SearchButton
          cVariant={variant}
          onClick={() => onChange(option)}
          selected={getOptionSelected(option)}
          label={t(getOptionLabel(option))}
        ></SearchButton>
      </Grid>
    ))
  }

  return (
    <Box sx={{ ...sxFromProps }}>
      {label ? (
        <SearchLabel cVariant={variant} isActive={IS_INNER ? true : isActive}>
          {label}
        </SearchLabel>
      ) : null}
      <Grid
        container
        alignItems="center"
        justifyItems="center"
        spacing={IS_INNER ? 1 : 2}
      >
        {renderOptions()}
      </Grid>
    </Box>
  )
}

export default SearchList
