import {
  FavoritesListsEntity,
  UserFavoritesList,
} from 'api/services/UserFavoritesService/types'
import { StoreSlice } from '..'

export interface IFavoritesListsSlice {
  favoritesLists: UserFavoritesList[]
  setFavorites: (favLists: UserFavoritesList[]) => void
  resetFavorites: () => void
  addToList: (fl: UserFavoritesList, property: FavoritesListsEntity) => void
  removeFromList: (listId: number, propertyId: string) => void
}

export const favoritesListsSlice: StoreSlice<IFavoritesListsSlice> = (
  set,
  get
) => ({
  favoritesLists: [],
  resetFavorites: () =>
    set({
      favoritesLists: [],
    }),
  setFavorites: (favLists) => {
    set({ favoritesLists: favLists })
  },
  addToList: (fl, property) => {
    const favorites = get().favoritesLists
    const currentListIndex = favorites.findIndex((item) => item.id === fl.id)
    favorites[currentListIndex].userFavorites?.push(property)
    set({ favoritesLists: favorites })
  },
  removeFromList: (listId, propertyId) => {
    const favorites = get().favoritesLists
    const currentListIndex = favorites.findIndex((item) => item.id === listId)
    const newFavorites = favorites
    const newUserFavorites = favorites[currentListIndex].userFavorites?.filter(
      (item) => item.id !== propertyId
    )
    if (typeof newUserFavorites !== 'undefined') {
      newFavorites[currentListIndex].userFavorites = newUserFavorites
    }
    set({ favoritesLists: newFavorites })
  },
})
