import React from 'react'
import { Button } from '@mui/material'
import { lighten } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useSearchStore } from 'store'

import {
  handleSingleField,
  variableToString,
  handleMultipleField,
} from './helpers'

interface Props {
  onChange: (arg?: number) => void
}

function UpdateControl(props: Props) {
  const { onChange } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [
    minPrice,
    maxPrice,
    minSqM,
    maxSqM,
    minWC,
    maxWC,
    minBedrooms,
    maxBedrooms,
    minRooms,
    maxRooms,
    buildFactor,
    coverageFactor,
    minBuildSqM,
    maxBuildSqM,
    estatePropertyType,
    commercialExtras,
    commercialType,
    estatePropertyCondition,
    propertyExtras,
    thermals,
    thermalsType,
    access,
    landType,
    extras,
    landCharacteristics,
    floor,
    floorDirection,
    sortingField,
    sortingBy,
    setPage,
    minConstructionYear,
    maxConstructionYear,
    setActiveField,
  ] = useSearchStore((state) => [
    state.minPrice,
    state.maxPrice,
    state.minSqM,
    state.maxSqM,
    state.minWC,
    state.maxWC,
    state.minBedrooms,
    state.maxBedrooms,
    state.minRooms,
    state.maxRooms,
    state.buildFactor,
    state.coverageFactor,
    state.minBuildSqM,
    state.maxBuildSqM,
    state.estatePropertyType,
    state.commercialExtras,
    state.commercialType,
    state.estatePropertyCondition,
    state.propertyExtras,
    state.thermals,
    state.thermalsType,
    state.access,
    state.landType,
    state.extras,
    state.landCharacteristics,
    state.floor,
    state.floorDirection,
    state.sortingField,
    state.sortingBy,
    state.setPage,
    state.minConstructionYear,
    state.maxConstructionYear,
    state.setActiveField,
  ])
  const { t } = useTranslation()

  const handeUpdate = () => {
    setActiveField('')
    setPage(1)
    ;[
      { key: variableToString({ minPrice }), value: minPrice },
      { key: variableToString({ maxPrice }), value: maxPrice },
      { key: variableToString({ minSqM }), value: minSqM },
      { key: variableToString({ maxSqM }), value: maxSqM },
      { key: variableToString({ minWC }), value: minWC },
      { key: variableToString({ maxWC }), value: maxWC },
      {
        key: variableToString({ minConstructionYear }),
        value: minConstructionYear,
      },
      {
        key: variableToString({ maxConstructionYear }),
        value: maxConstructionYear,
      },
      {
        key: variableToString({ minBedrooms }),
        value: minBedrooms,
      },
      {
        key: variableToString({ maxBedrooms }),
        value: maxBedrooms,
      },
      { key: variableToString({ minRooms }), value: minRooms },
      { key: variableToString({ maxRooms }), value: maxRooms },
      {
        key: variableToString({ buildFactor }),
        value: buildFactor,
      },
      {
        key: variableToString({ coverageFactor }),
        value: coverageFactor,
      },
      { key: variableToString({ minBuildSqM }), value: minBuildSqM },
      { key: variableToString({ maxBuildSqM }), value: maxBuildSqM },
      {
        key: variableToString({ estatePropertyType }),
        value: estatePropertyType,
      },
      {
        key: variableToString({ commercialExtras }),
        value: commercialExtras,
      },
      {
        key: variableToString({ commercialType }),
        value: commercialType,
      },
      {
        key: variableToString({ estatePropertyCondition }),
        value: estatePropertyCondition,
      },
      {
        key: variableToString({ propertyExtras }),
        value: propertyExtras,
      },
      {
        key: variableToString({ thermals }),
        value: thermals,
      },
      {
        key: variableToString({ thermalsType }),
        value: thermalsType,
      },
      {
        key: variableToString({ access }),
        value: access,
      },
      {
        key: variableToString({ landType }),
        value: landType,
      },
      {
        key: variableToString({ extras }),
        value: extras,
      },
      {
        key: variableToString({ landCharacteristics }),
        value: landCharacteristics,
      },
      {
        key: variableToString({ floor }),
        value: floor,
      },
      {
        key: variableToString({ floorDirection }),
        value: floorDirection,
        shouldHide: floor === '',
      },
      {
        key: variableToString({ sortingField }),
        value: sortingField,
      },
      {
        key: variableToString({ sortingBy }),
        value: sortingBy,
        shouldHide: sortingField === '',
      },
    ].forEach(({ key, value, shouldHide }) => {
      if (Array.isArray(value)) {
        handleMultipleField(value, key, searchParams, shouldHide)
      } else {
        handleSingleField(value, key, searchParams, shouldHide)
      }
    })

    searchParams.set('page', '1')
    setSearchParams(searchParams, {
      replace: true,
    })
    onChange(1)
  }

  return (
    <Button
      fullWidth
      onClick={handeUpdate}
      sx={{
        p: 1,
        color: 'common.black',
        fontWeight: 'bolder',
        bgcolor: 'background.paper',
        border: `1px solid rgba(0, 0, 0, 0.2)`,
        boxShadow: 25,
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: (theme) =>
            `10px 10px 1px -2px ${lighten(theme.palette.common.black, 0.4)}`,
          transition: (theme) => theme.transitions.create(['box-shadow']),
        },
        borderRadius: 2.5,
        my: 1,
      }}
    >
      {t('searchPage.update')}
    </Button>
  )
}

export default UpdateControl
