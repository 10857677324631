import { Alert, Box, Button, Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function ErrorAlert() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          maxWidth: (theme) => theme.breakpoints.values.lg,
          mb: 5,
        }}
      >
        <Grid item xs={12}>
          <Alert
            sx={{ mt: 5 }}
            action={
              <Button
                variant="text"
                color="inherit"
                sx={{ textTransform: 'none' }}
                onClick={() => navigate(-1)}
              >
                {t('agentDetailPage.alert.close')}
              </Button>
            }
            severity="error"
          >
            {t('error.unspecific')}
          </Alert>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ErrorAlert
