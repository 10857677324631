import * as React from 'react'
import { styled } from '@mui/material/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogProps,
  Typography,
  Stack,
  Link,
} from '@mui/material'
import { Close, Phone } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { ParsedProperty } from 'api/services/PropertyAd/types'
import PropertyAdAnalyticsService from 'api/services/PropertyAdAnalyticsService'

const DialogPhoneDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

const DialogPhoneTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'common.black',
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface IDialogProps {
  open: boolean
  onClose: () => void
  landLineNumber?: string | null
  mobileNumber?: string | null
  landLineNumber2?: string | null
  mobileNumber2?: string | null
  propertyAd: ParsedProperty
}

export default function DialogPhone(props: IDialogProps) {
  const { t } = useTranslation()
  const {
    open,
    onClose,
    landLineNumber,
    mobileNumber,
    landLineNumber2,
    mobileNumber2,
    propertyAd,
  } = props

  const sendAnalytic = PropertyAdAnalyticsService.hooks.useSendAnalytic()
  React.useEffect(() => {
    if (propertyAd.id) {
      sendAnalytic.mutate({ id: propertyAd.id, type: 'PHONE' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DialogPhoneDialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogPhoneTitle onClose={onClose}>
        {t('detailPage.dialog.phone.label')}
      </DialogPhoneTitle>
      <DialogContent dividers>
        {[landLineNumber, mobileNumber, landLineNumber2, mobileNumber2].every(
          (phone) => !phone
        ) ? (
          <Typography>{t('detailPage.dialog.phone.noNumbers')}</Typography>
        ) : null}
        {[landLineNumber, mobileNumber, landLineNumber2, mobileNumber2]
          .filter(Boolean)
          .map((item) => (
            <Link key={item} underline="none" href={'tel:' + item}>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Phone />
                <Typography color="textPrimary" sx={{ ml: 1 }}>
                  <strong>{item}</strong>
                </Typography>
              </Stack>
            </Link>
          ))}
      </DialogContent>
    </DialogPhoneDialog>
  )
}
