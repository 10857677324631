/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useLocation } from 'react-router-dom'
import shallow from 'zustand/shallow'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { SnackbarProvider } from 'notistack'
import useKlaro from 'hooks/useKlaro'

import ReferenceDataService from 'api/services/ReferenceData'
import { useRouteStore, useCalculatorStore } from 'store'
import AppRoutes from './Routes'

const App = () => {
  const location = useLocation()

  useKlaro()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notistackRef = React.createRef<any>()
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  const [setInterest, setFinancePercentage] = useCalculatorStore((state) => [
    state.setInterest,
    state.setFinancePercentage,
  ])
  const updateCounter = useRouteStore((state) => state.updateCounter, shallow)

  ReferenceDataService.hooks.useReferenceData({
    onSuccess: (data) => {
      const foundInterest = data.find(
        (refDoc) => refDoc.type === 'INTEREST_RATE'
      )
      if (foundInterest) {
        setInterest(foundInterest?.refValue)
      }
      const foundFinancing = data.find(
        (refDoc) => refDoc.type === 'FINANCING_PERCENTAGE'
      )
      if (foundFinancing) {
        setFinancePercentage(foundFinancing?.refValue)
      }
    },
  })

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
    updateCounter()
  }, [location?.pathname])

  return (
    <SnackbarProvider
      hideIconVariant={true}
      ref={notistackRef}
      maxSnack={1}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      action={(key) => (
        <IconButton
          size="small"
          sx={{ color: 'common.white' }}
          onClick={onClickDismiss(key)}
        >
          <Close fontSize="small" />
        </IconButton>
      )}
    >
      <AppRoutes />
    </SnackbarProvider>
  )
}

export default App
