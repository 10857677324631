import {
  ISearchOption,
  IEstateTypeOption,
  IParkingTypeOption,
  ICommercialTypeOption,
  IEstateThermalOption,
  IEstateConditionOption,
  IEstateThermalTypeOption,
  IEstateExtrasOption,
  ICommercialExtrasOption,
  ILandExtrasOption,
  IFloorOption,
  IEnergyClassOption,
  IGardenOption,
  IDoorOption,
  IFrameOption,
  IWindowTypeOption,
  ILandAccessOption,
  IDirectionOption,
  IFloorTypeOption,
  IElectricityTypeOption,
  IViewOption,
  IEquipmentOption,
  ILandConditionOption,
  IPoolOption,
  IUsageOption,
  ILandCityPlanOption,
  ILandTypeOption,
} from 'types/propertyAttributes'
import { Source } from 'types'
import { ListCategoryEnum, PropertyAdCategoryEnum } from 'types/enums'
import { IFloorDirectionOption } from 'store/SearchSlice/types'

const ROLE_ADMIN = 'admin' as const
const ROLE_AGENT = 'estateAgent' as const
const ROLE_USER = 'user' as const
export const KEYCLOAK_ROLES = {
  ROLE_ADMIN,
  ROLE_AGENT,
  ROLE_USER,
}

const OPTION_BUY = 'buy' as const
const OPTION_LOAN = 'rent' as const
export const INTEREST_OPTIONS = {
  OPTION_BUY,
  OPTION_LOAN,
}

const SEARCH_ESTATE = 'estate' as const
const SEARCH_COMMERCIAL = 'commercial' as const
const SEARCH_LAND = 'land' as const
export const SEARCH_TYPES = {
  ESTATE: SEARCH_ESTATE,
  LAND: SEARCH_LAND,
  COMMERCIAL: SEARCH_COMMERCIAL,
}

const TAG_DELIMITER = '#'

const ESTATE_TYPE_PREFIX = 'ESTATE_PROPERTY_TYPE'
const ESTATE_TYPE_APARTMENT = 'appartment' as const
const ESTATE_TYPE_SINGLE_RESIDENCE = 'single_residence' as const
const ESTATE_TYPE_MAISONETTE = 'maisonette' as const
const ESTATE_TYPE_BUILDING = 'building' as const
const ESTATE_TYPE_REST = 'rest_types' as const
export const ESTATE_TYPES = {
  APARTMENT:
    `${ESTATE_TYPE_PREFIX}${TAG_DELIMITER}${ESTATE_TYPE_APARTMENT}` as const,
  SINGLE_RESIDENCE:
    `${ESTATE_TYPE_PREFIX}${TAG_DELIMITER}${ESTATE_TYPE_SINGLE_RESIDENCE}` as const,
  MAISONETTE:
    `${ESTATE_TYPE_PREFIX}${TAG_DELIMITER}${ESTATE_TYPE_MAISONETTE}` as const,
  BUILDING:
    `${ESTATE_TYPE_PREFIX}${TAG_DELIMITER}${ESTATE_TYPE_BUILDING}` as const,
  REST: `${ESTATE_TYPE_PREFIX}${TAG_DELIMITER}${ESTATE_TYPE_REST}` as const,
}

export const PASSWORD_REGEX =
  /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/

export const EMAIL_REGEX = new RegExp(
  // eslint-disable-next-line no-control-regex
  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
)

export type SourceOption = ISearchOption<Source>

export const SOURCE_OPTIONS: SourceOption[] = [
  {
    label: 'dashboard.pages.createAgent.company.fields.crmOptions.none',
    value: 'NONE',
  },
  {
    label: 'dashboard.pages.createAgent.company.fields.crmOptions.estatePlus',
    value: 'ESTATE_PLUS',
  },
  {
    label: 'dashboard.pages.createAgent.company.fields.crmOptions.fortunet',
    value: 'FORTUNET',
  },
  {
    label: 'dashboard.pages.createAgent.company.fields.crmOptions.orbit',
    value: 'ORBIT',
  },
  {
    label: 'dashboard.pages.createAgent.company.fields.crmOptions.realStatus',
    value: 'REAL_STATUS',
  },
]

export const ESTATE_TYPE_OPTIONS: IEstateTypeOption[] = [
  {
    label: 'property.estatePropertyType.appartment.base',
    value: 'ESTATE_PROPERTY_TYPE#appartment',
  },
  {
    label: 'property.estatePropertyType.singleResidence.base',
    value: 'ESTATE_PROPERTY_TYPE#single_residence',
  },
  {
    label: 'property.estatePropertyType.maisonette.base',
    value: 'ESTATE_PROPERTY_TYPE#maisonette',
  },
  {
    label: 'property.estatePropertyType.building.base',
    value: 'ESTATE_PROPERTY_TYPE#building',
  },
  {
    label: 'property.estatePropertyType.restTypes.base',
    value: 'ESTATE_PROPERTY_TYPE#rest_types',
  },
]

export const COMMERCIAL_TYPE_OPTIONS: ICommercialTypeOption[] = [
  {
    label: 'property.estatePropertyCommercialType.office.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#office',
  },
  {
    label: 'property.estatePropertyCommercialType.store.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#store',
  },
  {
    label: 'property.estatePropertyCommercialType.industrialArea.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#industrial_area',
  },
  {
    label: 'property.estatePropertyCommercialType.craftSpace.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#craft_space',
  },
  {
    label: 'property.estatePropertyCommercialType.warehouse.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#warehouse',
  },
  {
    label: 'property.estatePropertyCommercialType.hotel.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#hotel',
  },
  {
    label: 'property.estatePropertyCommercialType.building.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#commercial_building',
  },
  {
    label: 'property.estatePropertyCommercialType.room.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#hall',
  },
  {
    label: 'property.estatePropertyCommercialType.parking.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#parking',
  },
  {
    label: 'property.estatePropertyCommercialType.restTypes.base',
    value: 'ESTATE_PROPERTY_COMMERCIAL_TYPE#rest_commercial_types',
  },
]

export const LAND_TYPE_OPTIONS: ILandTypeOption[] = [
  {
    label: 'property.estatePropertyLandType.plotOfLand.base',
    value: 'ESTATE_PROPERTY_LAND_TYPE#plot_of_land',
  },
  {
    label: 'property.estatePropertyLandType.island.base',
    value: 'ESTATE_PROPERTY_LAND_TYPE#island',
  },
  {
    label: 'property.estatePropertyLandType.plot.base',
    value: 'ESTATE_PROPERTY_LAND_TYPE#plot',
  },
  {
    label: 'property.estatePropertyLandType.otherLandTypes.base',
    value: 'ESTATE_PROPERTY_LAND_TYPE#other_land_types',
  },
]

export const ESTATE_CONDITION_OPTIONS: IEstateConditionOption[] = [
  {
    label: 'property.estatePropertyCondition.needOfRenovation.base',
    value: 'ESTATE_PROPERTY_CONDITION#need_of_renovation',
  },
  {
    label: 'property.estatePropertyCondition.needOfRenovationFull.base',
    value: 'ESTATE_PROPERTY_CONDITION#need_of_renovation_full',
  },
  {
    label: 'property.estatePropertyCondition.renovated.base',
    value: 'ESTATE_PROPERTY_CONDITION#renovated',
  },
  {
    label: 'property.estatePropertyCondition.newBuilt.base',
    value: 'ESTATE_PROPERTY_CONDITION#new_built',
  },
  {
    label: 'property.estatePropertyCondition.semiBuilt.base',
    value: 'ESTATE_PROPERTY_CONDITION#semi_built',
  },
  {
    label: 'property.estatePropertyCondition.goodCondition.base',
    value: 'ESTATE_PROPERTY_CONDITION#good_condition',
  },
  {
    label: 'property.estatePropertyCondition.buildable.base',
    value: 'ESTATE_PROPERTY_CONDITION#buildable',
  },
  {
    label: 'property.estatePropertyCondition.underConstruction.base',
    value: 'ESTATE_PROPERTY_CONDITION#under_construction',
  },
  {
    label: 'property.estatePropertyCondition.underCompletion.base',
    value: 'ESTATE_PROPERTY_CONDITION#under_completion',
  },
  {
    label: 'property.estatePropertyCondition.veryGood.base',
    value: 'ESTATE_PROPERTY_CONDITION#very_good',
  },
  {
    label: 'property.estatePropertyCondition.freshPainted.base',
    value: 'ESTATE_PROPERTY_CONDITION#fresh_painted',
  },
  {
    label: 'property.estatePropertyCondition.moderate.base',
    value: 'ESTATE_PROPERTY_CONDITION#moderate',
  },
  {
    label: 'property.estatePropertyCondition.amazing.base',
    value: 'ESTATE_PROPERTY_CONDITION#amazing',
  },
  {
    label: 'property.estatePropertyCondition.minimalDesign.base',
    value: 'ESTATE_PROPERTY_CONDITION#minimal_design',
  },
  {
    label: 'property.estatePropertyCondition.in_the_drawings.base',
    value: 'ESTATE_PROPERTY_CONDITION#in_the_drawings',
  },
  {
    label: 'property.estatePropertyCondition.neoclassic.base',
    value: 'ESTATE_PROPERTY_CONDITION#neoclassic',
  },
]

export const HEAT_OPTIONS: IEstateThermalOption[] = [
  {
    label: 'property.estatePropertyHeat.without.base',
    value: 'ESTATE_PROPERTY_HEAT#heat_without',
  },
  {
    label: 'property.estatePropertyHeat.central.base',
    value: 'ESTATE_PROPERTY_HEAT#heat_central',
  },
  {
    label: 'property.estatePropertyHeat.individual.base',
    value: 'ESTATE_PROPERTY_HEAT#heat_individual',
  },
  {
    label: 'property.estatePropertyHeat.autonomous.base',
    value: 'ESTATE_PROPERTY_HEAT#heat_autonomous',
  },
]

export const ESTATE_THERMAL_TYPE_OPTIONS: IEstateThermalTypeOption[] = [
  {
    label: 'property.estatePropertyHeatType.naturalGas.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_natural_gas',
  },
  {
    label: 'property.estatePropertyHeatType.convector.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_convector',
  },
  {
    label: 'property.estatePropertyHeatType.geothermalPower.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_geothermal_power',
  },
  {
    label: 'property.estatePropertyHeatType.thermalAccumulator.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_thermal_accumulator',
  },
  {
    label: 'property.estatePropertyHeatType.electricity.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_electricity',
  },
  {
    label: 'property.estatePropertyHeatType.liquidGas.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_liquid_gas',
  },
  {
    label: 'property.estatePropertyHeatType.underfloor.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_underfloor',
  },
  {
    label: 'property.estatePropertyHeatType.airConditioning.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_air_conditioning',
  },
  {
    label: 'property.estatePropertyHeatType.stove.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_stove',
  },
  {
    label: 'property.estatePropertyHeatType.pellet.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_pellet',
  },
  {
    label: 'property.estatePropertyHeatType.fanCoil.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_fan_coil',
  },
  {
    label: 'property.estatePropertyHeatType.teleheating.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_teleheating',
  },
  {
    label: 'property.estatePropertyHeatType.infrared.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_infrared',
  },
  {
    label: 'property.estatePropertyHeatType.petrol.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_petrol',
  },
  {
    label: 'property.estatePropertyHeatType.heatPump.base',
    value: 'ESTATE_PROPERTY_HEAT_TYPE#heat_type_heat_pump',
  },
]

export const ESTATE_EXTRAS_OPTIONS: IEstateExtrasOption[] = [
  {
    label: 'property.estatePropertyExtras.parking.base',
    value: 'ESTATE_PROPERTY_EXTRAS#parking',
  },
  {
    label: 'property.estatePropertyExtras.furnished.base',
    value: 'ESTATE_PROPERTY_EXTRAS#furnished',
  },
  {
    label: 'property.estatePropertyExtras.elevator.base',
    value: 'ESTATE_PROPERTY_EXTRAS#elevator',
  },
  {
    label: 'property.estatePropertyExtras.garden.base',
    value: 'ESTATE_PROPERTY_EXTRAS#garden',
  },
  {
    label: 'property.estatePropertyExtras.airCondition.base',
    value: 'ESTATE_PROPERTY_EXTRAS#air_condition',
  },
  {
    label: 'property.estatePropertyExtras.view.base',
    value: 'ESTATE_PROPERTY_EXTRAS#view',
  },
  {
    label: 'property.estatePropertyExtras.penthouse.base',
    value: 'ESTATE_PROPERTY_EXTRAS#penthouse',
  },
  {
    label: 'property.estatePropertyExtras.waterHeater.base',
    value: 'ESTATE_PROPERTY_EXTRAS#solar_water_heater',
  },
  {
    label: 'property.estatePropertyExtras.alarm.base',
    value: 'ESTATE_PROPERTY_EXTRAS#alarm',
  },
  {
    label: 'property.estatePropertyExtras.securityDoor.base',
    value: 'ESTATE_PROPERTY_EXTRAS#security_door',
  },
  {
    label: 'property.estatePropertyExtras.disabled_access.base',
    value: 'ESTATE_PROPERTY_EXTRAS#disabled_access',
  },
  {
    label: 'property.estatePropertyExtras.pool.base',
    value: 'ESTATE_PROPERTY_EXTRAS#pool',
  },
  {
    label: 'property.estatePropertyExtras.pets.base',
    value: 'ESTATE_PROPERTY_EXTRAS#pets',
  },
  {
    label: 'property.estatePropertyExtras.veranda.base',
    value: 'ESTATE_PROPERTY_EXTRAS#veranda',
  },
  {
    label: 'property.estatePropertyExtras.warehouse.base',
    value: 'ESTATE_PROPERTY_EXTRAS#storage',
  },
  {
    label: 'property.estatePropertyExtras.fireplace.base',
    value: 'ESTATE_PROPERTY_EXTRAS#fireplace',
  },
]

export const COMMERCIAL_EXTRAS_OPTIONS: ICommercialExtrasOption[] = [
  {
    label: 'landingPage.moreFilters.commercial.extras.parking',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#parking',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.elevator',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#elevator',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.wc',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#wc',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.alarm',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#alarm',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.penthouse',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#penthhouse',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.foodService',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#food_service',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.airCondition',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#air_condition',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.furnished',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#furnished',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.equipped',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#equipped',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.cargoElevator',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cargo_elevator',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.fireSafety',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#fire_safety',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.securityDoor',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#security_door',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.disabled_access',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#accessibility',
  },
  {
    label: 'landingPage.moreFilters.commercial.extras.cargoRamp',
    value: 'ESTATE_PROPERTY_COMMERCIAL_EXTRAS#cargo_ramp',
  },
]

export const LAND_EXTRAS_OPTIONS: ILandExtrasOption[] = [
  {
    label: 'landingPage.moreFilters.land.extras.withBuilding',
    value: 'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#with_building',
  },
  {
    label: 'landingPage.moreFilters.land.extras.enclosure',
    value: 'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#enclosure',
  },
  {
    label: 'landingPage.moreFilters.land.extras.waterSupply',
    value: 'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#water_supply',
  },
  {
    label: 'landingPage.moreFilters.land.extras.drains',
    value: 'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#drains',
  },
  {
    label: 'landingPage.moreFilters.land.extras.electrification',
    value: 'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#electrification',
  },
  {
    label: 'landingPage.moreFilters.land.extras.view',
    value: 'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#view',
  },
  {
    label: 'landingPage.moreFilters.land.extras.compensation',
    value: 'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#compensation',
  },
  {
    label: 'landingPage.moreFilters.land.extras.nonForest',
    value: 'ESTATE_PROPERTY_LAND_TYPE_EXTRAS#non_forest',
  },
]

export const FLOOR_DIRECTION_OPTIONS: IFloorDirectionOption[] = [
  {
    value: 'up',
    label: 'landingPage.moreFilters.shared.floors.directionUp',
  },
  {
    value: 'down',
    label: 'landingPage.moreFilters.shared.floors.directionDown',
  },
]

export const FLOOR_OPTIONS: IFloorOption[] = [
  {
    value: 'ESTATE_PROPERTY_FLOOR#2nd_basement',
    label: 'property.estatePropertyFloor.2ndBasement.base',
    order: 1,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#basement',
    label: 'property.estatePropertyFloor.basement.base',
    order: 2,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#semi_basment',
    label: 'property.estatePropertyFloor.semiBasement.base',
    order: 3,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#mezzanine',
    label: 'property.estatePropertyFloor.mezzanine.base',
    order: 4,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#mezzanine_raised',
    label: 'property.estatePropertyFloor.mezzanineRaised.base',
    order: 5,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#ground_floor',
    label: 'property.estatePropertyFloor.groundFloor.base',
    order: 6,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#ground_floor_raised',
    label: 'property.estatePropertyFloor.groundFloorRaised.base',
    order: 7,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#1st',
    label: 'property.estatePropertyFloor.1st.base',
    order: 8,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#1st_raised',
    label: 'property.estatePropertyFloor.1stRaised.base',
    order: 9,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#2nd',
    label: 'property.estatePropertyFloor.2nd.base',
    order: 10,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#3rd',
    label: 'property.estatePropertyFloor.3rd.base',
    order: 11,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#4th',
    label: 'property.estatePropertyFloor.4th.base',
    order: 12,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#5th',
    label: 'property.estatePropertyFloor.5th.base',
    order: 13,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#6th',
    label: 'property.estatePropertyFloor.6th.base',
    order: 14,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#7th',
    label: 'property.estatePropertyFloor.7th.base',
    order: 15,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#8th',
    label: 'property.estatePropertyFloor.8th.base',
    order: 16,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#9th',
    label: 'property.estatePropertyFloor.9th.base',
    order: 17,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#10th',
    label: 'property.estatePropertyFloor.10th.base',
    order: 18,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#11th',
    label: 'property.estatePropertyFloor.11th.base',
    order: 19,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#12th',
    label: 'property.estatePropertyFloor.12th.base',
    order: 20,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#13th',
    label: 'property.estatePropertyFloor.13th.base',
    order: 21,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#14th',
    label: 'property.estatePropertyFloor.14th.base',
    order: 22,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#15th',
    label: 'property.estatePropertyFloor.15th.base',
    order: 23,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#16th',
    label: 'property.estatePropertyFloor.16th.base',
    order: 24,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#17th',
    label: 'property.estatePropertyFloor.17th.base',
    order: 25,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#18th',
    label: 'property.estatePropertyFloor.18th.base',
    order: 26,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#19th',
    label: 'property.estatePropertyFloor.19th.base',
    order: 27,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#20th',
    label: 'property.estatePropertyFloor.20th.base',
    order: 28,
  },
  {
    value: 'ESTATE_PROPERTY_FLOOR#20+',
    label: 'property.estatePropertyFloor.20+.base',
    order: 29,
  },
]

export const PARKING_TYPE_OPTIONS: IParkingTypeOption[] = [
  {
    label: 'property.estatePropertyParking.has.base',
    value: 'ESTATE_PROPERTY_PARKING#has',
  },
  {
    label: 'property.estatePropertyParking.hasNot.base',
    value: 'ESTATE_PROPERTY_PARKING#has_not',
  },
  {
    label: 'property.estatePropertyParking.open.base',
    value: 'ESTATE_PROPERTY_PARKING#open',
  },
  {
    label: 'property.estatePropertyParking.closed.base',
    value: 'ESTATE_PROPERTY_PARKING#closed',
  },
  {
    label: 'property.estatePropertyParking.pilot.base',
    value: 'ESTATE_PROPERTY_PARKING#pilot',
  },
  {
    label: 'property.estatePropertyParking.basement.base',
    value: 'ESTATE_PROPERTY_PARKING#basement',
  },
]

export const GARDEN_OPTIONS: IGardenOption[] = [
  {
    label: 'property.estatePropertyGarden.has.base',
    value: 'ESTATE_PROPERTY_GARDEN#has',
  },
  {
    label: 'property.estatePropertyGarden.hasNot.base',
    value: 'ESTATE_PROPERTY_GARDEN#has_not',
  },
  {
    label: 'property.estatePropertyGarden.private.base',
    value: 'ESTATE_PROPERTY_GARDEN#private',
  },
  {
    label: 'property.estatePropertyGarden.communal.base',
    value: 'ESTATE_PROPERTY_GARDEN#communal',
  },
]

export const DOOR_OPTIONS: IDoorOption[] = [
  {
    label: 'property.estatePropertyDoor.door_simple.base',
    value: 'ESTATE_PROPERTY_DOOR#simple',
  },
  {
    label: 'property.estatePropertyDoor.door_armored.base',
    value: 'ESTATE_PROPERTY_DOOR#armored',
  },
  {
    label: 'property.estatePropertyDoor.door_security.base',
    value: 'ESTATE_PROPERTY_DOOR#security',
  },
]

export const ENERGY_CLASS_OPTIONS: IEnergyClassOption[] = [
  {
    label: 'property.estatePropertyEnergyClass.aPlus.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#a+',
  },
  {
    label: 'property.estatePropertyEnergyClass.a.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#a',
  },
  {
    label: 'property.estatePropertyEnergyClass.bPlus.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#b+',
  },
  {
    label: 'property.estatePropertyEnergyClass.b.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#b',
  },
  {
    label: 'property.estatePropertyEnergyClass.c.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#c',
  },
  {
    label: 'property.estatePropertyEnergyClass.d.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#d',
  },
  {
    label: 'property.estatePropertyEnergyClass.e.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#e',
  },
  {
    label: 'property.estatePropertyEnergyClass.f.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#f',
  },
  {
    label: 'property.estatePropertyEnergyClass.g.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#g',
  },
  {
    label: 'property.estatePropertyEnergyClass.h.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#h',
  },
  {
    label: 'property.estatePropertyEnergyClass.z.base',
    value: 'ESTATE_PROPERTY_ENERGY_CLASS#z',
  },
]

export const FRAME_OPTIONS: IFrameOption[] = [
  {
    label: 'property.estatePropertyFrames.aluminium.base',
    value: 'ESTATE_PROPERTY_FRAMES#aluminium',
  },
  {
    label: 'property.estatePropertyFrames.aluminiumSliding.base',
    value: 'ESTATE_PROPERTY_FRAMES#aluminium_sliding',
  },
  {
    label: 'property.estatePropertyFrames.synthetic.base',
    value: 'ESTATE_PROPERTY_FRAMES#synthetic',
  },
  {
    label: 'property.estatePropertyFrames.wooden.base',
    value: 'ESTATE_PROPERTY_FRAMES#wooden',
  },
  {
    label: 'property.estatePropertyFrames.pvc.base',
    value: 'ESTATE_PROPERTY_FRAMES#pvc',
  },
]

export const FLOOR_TYPE_OPTIONS: IFloorTypeOption[] = [
  {
    label: 'property.estatePropertyFloorType.marbles.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#marbles',
  },
  {
    label: 'property.estatePropertyFloorType.wood.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#wood',
  },
  {
    label: 'property.estatePropertyFloorType.stone.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#stone',
  },
  {
    label: 'property.estatePropertyFloorType.tile.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#tile',
  },
  {
    label: 'property.estatePropertyFloorType.mosaic.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#mosaic',
  },
  {
    label: 'property.estatePropertyFloorType.marble_wood.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#marble_wood',
  },
  {
    label: 'property.estatePropertyFloorType.marble_tile.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#marble_tile',
  },
  {
    label: 'property.estatePropertyFloorType.stone_wood.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#stone_wood',
  },
  {
    label: 'property.estatePropertyFloorType.stone_marble.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#stone_marble',
  },
  {
    label: 'property.estatePropertyFloorType.tile_wood.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#tile_wood',
  },
  {
    label: 'property.estatePropertyFloorType.mosaic_wood.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#mosaic_wood',
  },
  {
    label: 'property.estatePropertyFloorType.unspecified.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#unspecified',
  },
  {
    label: 'property.estatePropertyFloorType.cement_mortat.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#cement_mortat',
  },
  {
    label: 'property.estatePropertyFloorType.laminate.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#laminate',
  },
  {
    label: 'property.estatePropertyFloorType.granite.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#granite',
  },
  {
    label: 'property.estatePropertyFloorType.industrial_flooring.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#industrial_flooring',
  },
  {
    label: 'property.estatePropertyFloorType.granite_wood.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#granite_wood',
  },
  {
    label: 'property.estatePropertyFloorType.traditional_stone.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#traditional_stone',
  },
  {
    label: 'property.estatePropertyFloorType.canvas.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#canvas',
  },
  {
    label: 'property.estatePropertyFloorType.oak.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#oak',
  },
  {
    label: 'property.estatePropertyFloorType.parquet.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#parquet',
  },
  {
    label: 'property.estatePropertyFloorType.plastic.base',
    value: 'ESTATE_PROPERTY_FLOOR_TYPE#plastic',
  },
]

export const LAND_ACCESS_OPTIONS: ILandAccessOption[] = [
  {
    label: 'property.estatePropertyAccess.asphalt.base',
    value: 'ESTATE_PROPERTY_ACCESS#asphalt',
  },
  {
    label: 'property.estatePropertyAccess.pavement.base',
    value: 'ESTATE_PROPERTY_ACCESS#pavement',
  },
  {
    label: 'property.estatePropertyAccess.paved.base',
    value: 'ESTATE_PROPERTY_ACCESS#paved',
  },
  {
    label: 'property.estatePropertyAccess.dirtRoad.base',
    value: 'ESTATE_PROPERTY_ACCESS#dirt_road',
  },
  {
    label: 'property.estatePropertyAccess.noRoad.base',
    value: 'ESTATE_PROPERTY_ACCESS#no_road',
  },
  {
    label: 'property.estatePropertyAccess.ruralRoad.base',
    value: 'ESTATE_PROPERTY_ACCESS#rural_road',
  },
  {
    label: 'property.estatePropertyAccess.municipalRoad.base',
    value: 'ESTATE_PROPERTY_ACCESS#minicipal_road',
  },
  {
    label: 'property.estatePropertyAccess.countryRoad.base',
    value: 'ESTATE_PROPERTY_ACCESS#country_road',
  },
  {
    label: 'property.estatePropertyAccess.sea.base',
    value: 'ESTATE_PROPERTY_ACCESS#sea',
  },
  {
    label: 'property.estatePropertyAccess.nationalRoad.base',
    value: 'ESTATE_PROPERTY_ACCESS#national_road',
  },
  {
    label: 'property.estatePropertyAccess.commonAre.base',
    value: 'ESTATE_PROPERTY_ACCESS#common_are',
  },
  {
    label: 'property.estatePropertyAccess.subway.base',
    value: 'ESTATE_PROPERTY_ACCESS#subway',
  },
  {
    label: 'property.estatePropertyAccess.suburban.base',
    value: 'ESTATE_PROPERTY_ACCESS#suburban',
  },
  {
    label: 'property.estatePropertyAccess.electric.base',
    value: 'ESTATE_PROPERTY_ACCESS#electric',
  },
  {
    label: 'property.estatePropertyAccess.attikiOdos.base',
    value: 'ESTATE_PROPERTY_ACCESS#attiki_odos',
  },
  {
    label: 'property.estatePropertyAccess.immediate.base',
    value: 'ESTATE_PROPERTY_ACCESS#immediate',
  },
  {
    label: 'property.estatePropertyAccess.difficult.base',
    value: 'ESTATE_PROPERTY_ACCESS#difficult',
  },
  {
    label: 'property.estatePropertyAccess.good.base',
    value: 'ESTATE_PROPERTY_ACCESS#good',
  },
  {
    label: 'property.estatePropertyAccess.moderate.base',
    value: 'ESTATE_PROPERTY_ACCESS#moderate',
  },
  {
    label: 'property.estatePropertyAccess.bus.base',
    value: 'ESTATE_PROPERTY_ACCESS#bus',
  },
  {
    label: 'property.estatePropertyAccess.ktel.base',
    value: 'ESTATE_PROPERTY_ACCESS#ktel',
  },
  {
    label: 'property.estatePropertyAccess.ose.base',
    value: 'ESTATE_PROPERTY_ACCESS#ose',
  },
  {
    label: 'property.estatePropertyAccess.tram.base',
    value: 'ESTATE_PROPERTY_ACCESS#tram',
  },
  {
    label: 'property.estatePropertyAccess.sidewalk.base',
    value: 'ESTATE_PROPERTY_ACCESS#sidewalk',
  },
  {
    label: 'property.estatePropertyAccess.unspecified.base',
    value: 'ESTATE_PROPERTY_ACCESS#unspecified',
  },
]

export const VIEW_OPTIONS: IViewOption[] = [
  {
    label: 'property.estatePropertyView.unlimited.base',
    value: 'ESTATE_PROPERTY_VIEW#unlimited',
  },
  {
    label: 'property.estatePropertyView.forest.base',
    value: 'ESTATE_PROPERTY_VIEW#forest',
  },
  {
    label: 'property.estatePropertyView.mountain.base',
    value: 'ESTATE_PROPERTY_VIEW#mountain',
  },
  {
    label: 'property.estatePropertyView.sea.base',
    value: 'ESTATE_PROPERTY_VIEW#sea',
  },
  {
    label: 'property.estatePropertyView.park.base',
    value: 'ESTATE_PROPERTY_VIEW#park',
  },
  {
    label: 'property.estatePropertyView.square.base',
    value: 'ESTATE_PROPERTY_VIEW#square',
  },
  {
    label: 'property.estatePropertyView.good.base',
    value: 'ESTATE_PROPERTY_VIEW#good',
  },
  {
    label: 'property.estatePropertyView.sea_park.base',
    value: 'ESTATE_PROPERTY_VIEW#sea_park',
  },
  {
    label: 'property.estatePropertyView.panoramic.base',
    value: 'ESTATE_PROPERTY_VIEW#panoramic',
  },
  {
    label: 'property.estatePropertyView.mountain_sea.base',
    value: 'ESTATE_PROPERTY_VIEW#mountain_sea',
  },
  {
    label: 'property.estatePropertyView.cityscape.base',
    value: 'ESTATE_PROPERTY_VIEW#cityscape',
  },
  {
    label: 'property.estatePropertyView.outdoors.base',
    value: 'ESTATE_PROPERTY_VIEW#outdoors',
  },
  {
    label: 'property.estatePropertyView.open_horizon.base',
    value: 'ESTATE_PROPERTY_VIEW#open_horizon',
  },
  {
    label: 'property.estatePropertyView.side_view.base',
    value: 'ESTATE_PROPERTY_VIEW#side_view',
  },
  {
    label: 'property.estatePropertyView.acropole.base',
    value: 'ESTATE_PROPERTY_VIEW#acropole',
  },
  {
    label: 'property.estatePropertyView.garden.base',
    value: 'ESTATE_PROPERTY_VIEW#garden',
  },
]

export const POOL_OPTIONS: IPoolOption[] = [
  {
    label: 'property.estatePropertyPool.interior.base',
    value: 'ESTATE_PROPERTY_POOL#interior',
  },
  {
    label: 'property.estatePropertyPool.exterior.base',
    value: 'ESTATE_PROPERTY_POOL#exterior',
  },
  {
    label: 'property.estatePropertyPool.private.base',
    value: 'ESTATE_PROPERTY_POOL#private',
  },
  {
    label: 'property.estatePropertyPool.communal.base',
    value: 'ESTATE_PROPERTY_POOL#communal',
  },
  {
    label: 'property.estatePropertyPool.no_pool.base',
    value: 'ESTATE_PROPERTY_POOL#no_pool',
  },
  {
    label: 'property.estatePropertyPool.yes.base',
    value: 'ESTATE_PROPERTY_POOL#yes',
  },
]

export const USAGE_OPTIONS: IUsageOption[] = [
  {
    label: 'property.estatePropertyUsage.pure_house.base',
    value: 'ESTATE_PROPERTY_USAGE#pure_house',
  },
  {
    label: 'property.estatePropertyUsage.industrial_zone.base',
    value: 'ESTATE_PROPERTY_USAGE#industrial_zone',
  },
  {
    label: 'property.estatePropertyUsage.craft_zone.base',
    value: 'ESTATE_PROPERTY_USAGE#craft_zone',
  },
  {
    label: 'property.estatePropertyUsage.grazing_land.base',
    value: 'ESTATE_PROPERTY_USAGE#grazing_land',
  },
  {
    label: 'property.estatePropertyUsage.general_house.base',
    value: 'ESTATE_PROPERTY_USAGE#general_house',
  },
  {
    label: 'property.estatePropertyUsage.forest_area.base',
    value: 'ESTATE_PROPERTY_USAGE#forest_area',
  },
  {
    label: 'property.estatePropertyUsage.red_zone.base',
    value: 'ESTATE_PROPERTY_USAGE#red_zone',
  },
  {
    label: 'property.estatePropertyUsage.arable_land.base',
    value: 'ESTATE_PROPERTY_USAGE#arable_land',
  },
  {
    label: 'property.estatePropertyUsage.tourist_investment.base',
    value: 'ESTATE_PROPERTY_USAGE#tourist_investment',
  },
  {
    label:
      'property.estatePropertyUsage.industrial_park_light_industrial_serving.base',
    value: 'ESTATE_PROPERTY_USAGE#industrial_park_light_industrial_serving',
  },
  {
    label:
      'property.estatePropertyUsage.industrial_park_full_industrial_serving.base',
    value: 'ESTATE_PROPERTY_USAGE#industrial_park_full_industrial_serving',
  },
  {
    label:
      'property.estatePropertyUsage.industrial_park_low_and_medium_industrial_serving.base',
    value:
      'ESTATE_PROPERTY_USAGE#industrial_park_low_and_medium_industrial_serving',
  },
  {
    label: 'property.estatePropertyUsage.uncharacteristic_zone.base',
    value: 'ESTATE_PROPERTY_USAGE#uncharacteristic_zone',
  },
  {
    label: 'property.estatePropertyUsage.wholesale_zone.base',
    value: 'ESTATE_PROPERTY_USAGE#wholesale_zone',
  },
  {
    label: 'property.estatePropertyUsage.agricultural_area.base',
    value: 'ESTATE_PROPERTY_USAGE#agricultural_area',
  },
  {
    label: 'property.estatePropertyUsage.commercial_zone.base',
    value: 'ESTATE_PROPERTY_USAGE#commercial_zone',
  },
  {
    label: 'property.estatePropertyUsage.unspecified.base',
    value: 'ESTATE_PROPERTY_USAGE#unspecified',
  },
  {
    label: 'property.estatePropertyUsage.farmers_buildings.base',
    value: 'ESTATE_PROPERTY_USAGE#farmers_buildings',
  },
  {
    label: 'property.estatePropertyUsage.agricultural_warehouses.base',
    value: 'ESTATE_PROPERTY_USAGE#agricultural_warehouses',
  },
  {
    label: 'property.estatePropertyUsage.tanks.base',
    value: 'ESTATE_PROPERTY_USAGE#tanks',
  },
  {
    label: 'property.estatePropertyUsage.greenhouses.base',
    value: 'ESTATE_PROPERTY_USAGE#greenhouses',
  },
  {
    label: 'property.estatePropertyUsage.industrial_buildings.base',
    value: 'ESTATE_PROPERTY_USAGE#industrial_buildings',
  },
  {
    label: 'property.estatePropertyUsage.warehouses.base',
    value: 'ESTATE_PROPERTY_USAGE#warehouses',
  },
  {
    label: 'property.estatePropertyUsage.sports_facilities.base',
    value: 'ESTATE_PROPERTY_USAGE#sports_facilities',
  },
  {
    label: 'property.estatePropertyUsage.schools.base',
    value: 'ESTATE_PROPERTY_USAGE#schools',
  },
  {
    label: 'property.estatePropertyUsage.charitable_foundations.base',
    value: 'ESTATE_PROPERTY_USAGE#charitable_foundations',
  },
  {
    label: 'property.estatePropertyUsage.hospitals.base',
    value: 'ESTATE_PROPERTY_USAGE#hospitals',
  },
  {
    label: 'property.estatePropertyUsage.clinics.base',
    value: 'ESTATE_PROPERTY_USAGE#clinics',
  },
  {
    label: 'property.estatePropertyUsage.offices.base',
    value: 'ESTATE_PROPERTY_USAGE#offices',
  },
  {
    label: 'property.estatePropertyUsage.stores.base',
    value: 'ESTATE_PROPERTY_USAGE#stores',
  },
  {
    label: 'property.estatePropertyUsage.tourist_facilities.base',
    value: 'ESTATE_PROPERTY_USAGE#tourist_facilities',
  },
  {
    label: 'property.estatePropertyUsage.churches.base',
    value: 'ESTATE_PROPERTY_USAGE#churches',
  },
  {
    label: 'property.estatePropertyUsage.within_the_urban_plan.base',
    value: 'ESTATE_PROPERTY_USAGE#within_the_urban_plan',
  },
  {
    label: 'property.estatePropertyUsage.out_of_the_urban_plan.base',
    value: 'ESTATE_PROPERTY_USAGE#out_of_the_urban_plan',
  },
  {
    label: 'property.estatePropertyUsage.mild_harassment.base',
    value: 'ESTATE_PROPERTY_USAGE#mild_harassment',
  },
  {
    label: 'property.estatePropertyUsage.urban_center.base',
    value: 'ESTATE_PROPERTY_USAGE#urban_center',
  },
  {
    label: 'property.estatePropertyUsage.public_benefit_organizations.base',
    value: 'ESTATE_PROPERTY_USAGE#public_benefit_organizations',
  },
  {
    label: 'property.estatePropertyUsage.audience_gathering_space.base',
    value: 'ESTATE_PROPERTY_USAGE#audience_gathering_space',
  },
  {
    label: 'property.estatePropertyUsage.religious_space.base',
    value: 'ESTATE_PROPERTY_USAGE#religious_space',
  },
]

export const ELECTRICITY_TYPE_OPTIONS: IElectricityTypeOption[] = [
  {
    label: 'property.estatePropertyElectricityType.typeA.base',
    value: 'ESTATE_PROPERTY_ELECTRICITY_TYPE#electricity_type_a',
  },
  {
    label: 'property.estatePropertyElectricityType.typeB.base',
    value: 'ESTATE_PROPERTY_ELECTRICITY_TYPE#electricity_type_b',
  },
  {
    label: 'property.estatePropertyElectricityType.night.base',
    value: 'ESTATE_PROPERTY_ELECTRICITY_TYPE#night_electricity',
  },
  {
    label: 'property.estatePropertyElectricityType.triPhase.base',
    value: 'ESTATE_PROPERTY_ELECTRICITY_TYPE#three_phase',
  },
  {
    label: 'property.estatePropertyElectricityType.singlePhase.base',
    value: 'ESTATE_PROPERTY_ELECTRICITY_TYPE#single_phase',
  },
  {
    label: 'property.estatePropertyElectricityType.industrial.base',
    value: 'ESTATE_PROPERTY_ELECTRICITY_TYPE#industrial',
  },
  {
    label: 'property.estatePropertyElectricityType.mediumPower.base',
    value: 'ESTATE_PROPERTY_ELECTRICITY_TYPE#medium_power',
  },
  {
    label: 'property.estatePropertyElectricityType.highPower.base',
    value: 'ESTATE_PROPERTY_ELECTRICITY_TYPE#high_power',
  },
  {
    label: 'property.estatePropertyElectricityType.substation.base',
    value: 'ESTATE_PROPERTY_ELECTRICITY_TYPE#substation',
  },
]

export const EQUIPMENT_OPTIONS: IEquipmentOption[] = [
  {
    label: 'property.estatePropertyEquipment.restaurant.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#restaurant',
  },
  {
    label: 'property.estatePropertyEquipment.cafe.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#cafe',
  },
  {
    label: 'property.estatePropertyEquipment.office.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#office',
  },
  {
    label: 'property.estatePropertyEquipment.hair_salon.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#hair_salon',
  },
  {
    label: 'property.estatePropertyEquipment.pastry_shop.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#pastry_shop',
  },
  {
    label: 'property.estatePropertyEquipment.jewelery_store.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#jewelery_store',
  },
  {
    label: 'property.estatePropertyEquipment.bookstore.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#bookstore',
  },
  {
    label: 'property.estatePropertyEquipment.store.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#store',
  },
  {
    label: 'property.estatePropertyEquipment.exhibition_space.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#exhibition_space',
  },
  {
    label: 'property.estatePropertyEquipment.food.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#food',
  },
  {
    label: 'property.estatePropertyEquipment.printing.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#printing',
  },
  {
    label: 'property.estatePropertyEquipment.chemicals.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#chemicals',
  },
  {
    label: 'property.estatePropertyEquipment.petrochemicals_plastics.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#petrochemicals_plastics',
  },
  {
    label: 'property.estatePropertyEquipment.wood_processing.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#wood_processing',
  },
  {
    label: 'property.estatePropertyEquipment.tanners.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#tanners',
  },
  {
    label: 'property.estatePropertyEquipment.farming.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#farming',
  },
  {
    label: 'property.estatePropertyEquipment.metal_processing.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#metal_processing',
  },
  {
    label: 'property.estatePropertyEquipment.paper_processing.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#paper_processing',
  },
  {
    label: 'property.estatePropertyEquipment.textile_industry.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#textile_industry',
  },
  {
    label: 'property.estatePropertyEquipment.hotel.base',
    value: 'ESTATE_PROPERTY_EQUIPMENT#hotel',
  },
]

export const CITY_PLAN_OPTIONS: ILandCityPlanOption[] = [
  {
    label: 'property.estatePropertyCityPlan.out.base',
    value: 'ESTATE_LAND_PROPERTY_CITY_PLAN#out_of_city_plans',
  },
  {
    label: 'property.estatePropertyCityPlan.in.base',
    value: 'ESTATE_LAND_PROPERTY_CITY_PLAN#in_city_plans',
  },
  {
    label: 'property.estatePropertyCityPlan.withinSettlement.base',
    value: 'ESTATE_LAND_PROPERTY_CITY_PLAN#within_settlement',
  },
  {
    label: 'property.estatePropertyCityPlan.inBuildingZone.base',
    value: 'ESTATE_LAND_PROPERTY_CITY_PLAN#in_building_zone',
  },
  {
    label: 'property.estatePropertyCityPlan.settlementLimits.base',
    value: 'ESTATE_LAND_PROPERTY_CITY_PLAN#settlement_limits',
  },
  {
    label: 'property.estatePropertyCityPlan.tangentialPlan.base',
    value: 'ESTATE_LAND_PROPERTY_CITY_PLAN#tangential_plan',
  },
  {
    label: 'property.estatePropertyCityPlan.toBeIncluded.base',
    value: 'ESTATE_LAND_PROPERTY_CITY_PLAN#to_be_included',
  },
]

export const LAND_CONDITION_OPTIONS: ILandConditionOption[] = [
  {
    label: 'property.estatePropertyLandCondition.even.base',
    value: 'LAND_CONDITION#even',
  },
  {
    label: 'property.estatePropertyLandCondition.buildable.base',
    value: 'LAND_CONDITION#buildable',
  },
]

export const DIRECTION_OPTIONS: IDirectionOption[] = [
  {
    label: 'property.estatePropertyDirection.east.base',
    value: 'ESTATE_PROPERTY_DIRECTION#east',
  },
  {
    label: 'property.estatePropertyDirection.west.base',
    value: 'ESTATE_PROPERTY_DIRECTION#west',
  },
  {
    label: 'property.estatePropertyDirection.east_meridian.base',
    value: 'ESTATE_PROPERTY_DIRECTION#east_meridian',
  },
  {
    label: 'property.estatePropertyDirection.north.base',
    value: 'ESTATE_PROPERTY_DIRECTION#north',
  },
  {
    label: 'property.estatePropertyDirection.north_east.base',
    value: 'ESTATE_PROPERTY_DIRECTION#north_east',
  },
  {
    label: 'property.estatePropertyDirection.north_west.base',
    value: 'ESTATE_PROPERTY_DIRECTION#north_west',
  },
  // {
  //   label: 'property.estatePropertyDirection.west.base',
  //   value: 'ESTATE_PROPERTY_DIRECTION#west',
  // }, // double entry in conf page,to be removed
  {
    label: 'property.estatePropertyDirection.west_meridian.base',
    value: 'ESTATE_PROPERTY_DIRECTION#west_meridian',
  },
  {
    label: 'property.estatePropertyDirection.meridian.base',
    value: 'ESTATE_PROPERTY_DIRECTION#meridian',
  },
  {
    label: 'property.estatePropertyDirection.south.base',
    value: 'ESTATE_PROPERTY_DIRECTION#south',
  },
  {
    label: 'property.estatePropertyDirection.south_east.base',
    value: 'ESTATE_PROPERTY_DIRECTION#south_east',
  },
  {
    label: 'property.estatePropertyDirection.south_west.base',
    value: 'ESTATE_PROPERTY_DIRECTION#south_west',
  },
  {
    label: 'property.estatePropertyDirection.undefined.base',
    value: 'ESTATE_PROPERTY_DIRECTION#undefined',
  },
]

export const WINDOW_OPTIONS: IWindowTypeOption[] = [
  {
    label: 'property.estatePropertyWindowType.single.base',
    value: 'ESTATE_PROPERTY_WINDOWS_TYPE#single',
  },
  {
    label: 'property.estatePropertyWindowType.double.base',
    value: 'ESTATE_PROPERTY_WINDOWS_TYPE#double',
  },
]

export const AdCategoryIdToListCategoryMap = new Map<
  PropertyAdCategoryEnum,
  ListCategoryEnum
>([
  [
    PropertyAdCategoryEnum.ESTATE_PROPERTY_SALES,
    ListCategoryEnum.ESTATE_PROPERTY_SALES,
  ],
  [
    PropertyAdCategoryEnum.ESTATE_PROPERTY_RENT,
    ListCategoryEnum.ESTATE_PROPERTY_RENT,
  ],
  [
    PropertyAdCategoryEnum.ESTATE_COMMERCIAL_SALES,
    ListCategoryEnum.ESTATE_COMMERCIAL_SALES,
  ],
  [
    PropertyAdCategoryEnum.ESTATE_COMMERCIAL_RENT,
    ListCategoryEnum.ESTATE_COMMERCIAL_RENT,
  ],
  [
    PropertyAdCategoryEnum.ESTATE_LAND_SALES,
    ListCategoryEnum.ESTATE_LAND_SALES,
  ],
  [PropertyAdCategoryEnum.ESTATE_LAND_RENT, ListCategoryEnum.ESTATE_LAND_RENT],
])
