import React from 'react'
import { styled } from '@mui/material/styles'
import { TextField, TextFieldProps } from '@mui/material'
import NumberFormatInput from 'components/Landing/NumberFormatInput'

export const CTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  function CTextField(props, ref) {
    return (
      <TextField
        inputRef={ref}
        InputProps={{
          inputComponent: NumberFormatInput,
          sx: {
            backgroundColor: 'transparent',
            fontSize: 16,
            width: '100%',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            borderRadius: 2.5,
            '& .Mui-disabled': {
              backgroundColor: 'lightgrey',
            },
            '& input': {
              borderRadius: 2.5,
            },
          },
        }}
        SelectProps={{
          sx: {
            borderRadius: 2.5,
            width: '100%',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
          },
          MenuProps: {
            sx: {
              '& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected': {
                bgcolor: 'primary.main',
              },
            },
          },
        }}
        sx={{
          '& .MuiDisabled': {
            backgroundColor: 'lightgrey',
          },
        }}
        variant="outlined"
        {...props}
      />
    )
  }
)

const CalculatorTextField = styled(CTextField)<TextFieldProps>(() => ({
  '& .MuiInputBase-input': {
    backgroundColor: 'transparent',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
  },
}))
export default CalculatorTextField
