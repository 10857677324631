import * as React from 'react'
import { styled } from '@mui/material/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Button,
  ButtonProps,
  DialogProps,
} from '@mui/material'
import {
  Close,
  Facebook,
  ContentCopy,
  LinkedIn,
  Twitter,
  Mail,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useCopyToClipboard from 'hooks/useCopyToClipboard'

const DialogShareDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

const DialogEmailTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props
  return (
    <DialogTitle sx={{ m: 0, p: 2, pt: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'common.black',
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface IDialogProps {
  open: boolean
  onClose: () => void
  title: string
  sharePath: string
  shareLabel: string
}

export default function DialogShare(props: IDialogProps) {
  const { t } = useTranslation()
  const { open, onClose, title, sharePath, shareLabel } = props

  const { copy: copyFn } = useCopyToClipboard()
  const handleAction = (path) => {
    const win = window.open(`${path}`, '_blank')
    if (win != null) {
      win.focus()
    }
  }

  interface ISocial {
    icon?: React.ReactNode
    label?: string
    name?: ButtonProps['color']
    handler?: () => void
  }

  const SOCIAL: ISocial[] = [
    {
      icon: <Facebook />,
      label: 'Facebook',
      name: 'fb',
      handler: () =>
        handleAction(
          `https://www.facebook.com/sharer/sharer.php?u=${sharePath.replace(
            '#',
            '%23'
          )}`
        ),
    },

    {
      icon: <LinkedIn />,
      label: 'LinkedIn',
      name: 'linkedin',
      handler: () =>
        handleAction(
          `https://www.linkedin.com/shareArticle?mini=true&url=${sharePath}&amp;title=${
            shareLabel ? shareLabel : sharePath
          }`
        ),
    },
    {
      icon: <Twitter />,
      label: 'Twitter',
      name: 'twitter',
      handler: () =>
        handleAction(
          `https://twitter.com/intent/tweet?url=${sharePath}&amp;text=${
            shareLabel ? shareLabel : sharePath
          }`
        ),
    },
    {
      icon: <Mail />,
      label: 'Mail',
      name: 'primary',
      handler: () =>
        handleAction(
          `mailto:?subject=${sharePath}&amp;body=${
            shareLabel ? shareLabel : sharePath
          }`
        ),
    },
    {
      icon: <ContentCopy />,
      label: 'Copy Link',
      name: 'primary',
      handler: async () => {
        await copyFn(sharePath)
      },
    },
  ]

  return (
    <DialogShareDialog
      fullWidth
      onClose={onClose}
      open={open}
      sx={{ maxWidth: { xs: 320, sm: 600 }, mx: 'auto' }}
    >
      <DialogEmailTitle onClose={onClose}>
        {title ? title : t('detailPage.dialog.share.label')}
      </DialogEmailTitle>
      <DialogContent dividers>
        <Grid container spacing={3} alignItems="center">
          {SOCIAL.map((item) => (
            <Grid item xs={12} sm={6} key={item.name}>
              <Button
                fullWidth
                color={item.name}
                variant="contained"
                startIcon={item.icon}
                onClick={item.handler}
              >
                {item.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </DialogShareDialog>
  )
}
