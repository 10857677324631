/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Stack, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import Pagination from './Pagination'
import FeatureCard from './FeatureCard'
import Ad from 'components/Detail/Ad'

interface InfiniteQueryData {
  data: any[]
  page: number
  nextPage: number
  totalPages: number
  totalCount: number
}

interface PageProps {
  data: InfiniteQueryData
  page: number
  pageLimit: number
  totalCount: number
  totalPages: number
  onChangePage: (e: any) => Promise<void>
  onRefetch: () => void
}

function Page(props: PageProps) {
  const {
    data,
    page,
    totalPages,
    totalCount,
    onChangePage,
    onRefetch,
    pageLimit,
  } = props

  const theme = useTheme()

  return (
    <React.Fragment>
      <Stack justifyContent={{ xs: 'space-evenly', md: 'flex-start' }}>
        {data ? (
          <Box sx={{ flexGrow: 1 }}>
            <Pagination
              pageLimit={pageLimit}
              onRefetch={onRefetch}
              data={data}
              totalCount={totalCount}
              totalPages={totalPages}
              page={page}
              onChangePage={onChangePage}
            />
          </Box>
        ) : null}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 2,
            [theme.breakpoints.down('lg')]: {
              gridTemplateColumns: '1fr 1fr ',
            },
            [theme.breakpoints.down('sm')]: {
              gridTemplateColumns: '1fr',
            },
          }}
        >
          {data && data?.data?.length
            ? data?.data.map((property, index) =>
                typeof property === 'string' ? (
                  <Ad
                    key={'ad' + page + property}
                    id={property}
                    sx={{
                      justifySelf: 'center',
                    }}
                  />
                ) : (
                  <FeatureCard
                    key={'result' + page + property?.id}
                    data={property}
                  />
                )
              )
            : null}{' '}
        </Box>
      </Stack>
    </React.Fragment>
  )
}

export default Page
