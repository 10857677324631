import React from 'react'
import { Grid, Box } from '@mui/material'

import { EstateAgent } from 'types'

interface Props {
  data: EstateAgent
}
function Banner(props: Props) {
  if (props.data?.bannerURL) {
    return (
      <Grid item container justifyContent="center">
        <Box
          component="img"
          src={props?.data?.bannerURL}
          sx={{ maxHeight: 370, height: '100%', mx: 'auto' }}
        ></Box>
      </Grid>
    )
  } else {
    return null
  }
}

export default Banner
